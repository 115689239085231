import { Box, HStack, Progress, Text, VStack } from "@chakra-ui/react";
import { useContext } from "react";
import { useDispatch } from "react-redux";
import { useParams, useSearchParams } from "react-router-dom";
import {
  AssetTaxLot,
  AssetTypeEnum,
  CurrencyCodeEnum,
  PortfolioAssetPosition,
} from "src/api/generated/types";
import { AwakenTooltip } from "src/components";
import { AssetIcon } from "src/components/styled/Assets";
import { useTheme } from "src/hooks/useTheme";
import { colors } from "src/theme";
import { D, formatNum } from "src/utils/helpers";
import { AssetKeyInfoContext } from "../utils";

const NAME_FLEX = 3;
const VALUE_FLEX = 2;

export const TaxLotPosition = ({
  position,
  clientId,
  currency,
  isLast,
}: {
  position: AssetTaxLot;
  clientId: string;
  currency: CurrencyCodeEnum;
  isLast: boolean;
}) => {
  const { symbol, iconImageUrl } = useContext(AssetKeyInfoContext);
  const theme = useTheme();
  const [search, setSearchParams] = useSearchParams();

  const calculateHoldingPeriod = (purchaseDate: Date) => {
    const purchaseTime = new Date(purchaseDate).getTime();
    const currentTime = new Date().getTime();
    const daysHeld = Math.floor(
      (currentTime - purchaseTime) / (1000 * 60 * 60 * 24)
    );
    const progress = Math.min((daysHeld / 365) * 100, 100);
    return { daysHeld, progress };
  };

  const _onClickTxn = (e: any) => {
    if (!position.transactionId) {
      return;
    }

    e.preventDefault();
    e.stopPropagation();

    search.delete("transactionId"); // delete the current txn id
    setSearchParams(search);
    search.append("transactionId", position.transactionId);
    setSearchParams(search);
  };

  const { daysHeld, progress } = calculateHoldingPeriod(position.purchasedAt);
  const dispatch = useDispatch();

  return (
    <HStack
      style={{
        justifyContent: "space-between",
        borderBottom: isLast ? "none" : `1px solid ${theme.border}`,
      }}
      _hover={{
        bg: theme.medBackground,
      }}
      cursor="pointer"
      onClick={_onClickTxn}
      width="100%"
      padding="1rem 0.75rem"
    >
      <div style={{ marginRight: 15, position: "relative" }}>
        <AssetIcon
          asset={{
            iconImageUrl: iconImageUrl || "",
            symbol: symbol,
            type: AssetTypeEnum.FungibleToken,
          }}
          size={35}
        />

        <img
          src={position.account?.iconImageUrl || ""}
          style={{
            width: "1.25rem",
            height: "1.25rem",
            borderRadius: "100%",
            bottom: -5,
            border: `1px solid ${theme.border}`,
            backgroundColor: theme.border,
            right: -5,
            borderRight: "100%",
            position: "absolute",
          }}
        />
      </div>

      <VStack align="flex-start" flex={1} spacing={2}>
        <HStack w="100%" justify="space-between">
          <Text fontSize="md" fontWeight="500" color={theme.header}>
            {formatNum(Number(position.amount) ?? 0, false, "0,0.[0000]")}{" "}
            {symbol}
          </Text>
          <Text fontSize="md" fontWeight="500" color={theme.header}>
            {position.fiatAmountFormatted}
          </Text>
        </HStack>

        <HStack w="100%">
          <div style={{ flex: 1 }}>
            <AwakenTooltip
              message={`${
                daysHeld >= 365 ? "Long term" : "Short term"
              } capital gains.${
                daysHeld >= 365
                  ? ` Held for ${daysHeld} days.`
                  : ` Hold for ${
                      365 - daysHeld
                    } more days to unlock long term capital gains.`
              }`}
              openDelay={0}
            >
              <HStack>
                <Box w="100%" maxW={75}>
                  <Progress
                    size="xs"
                    value={progress}
                    height="5px"
                    borderRadius={2}
                    bg={theme.secondaryBackground2}
                    colorScheme={daysHeld >= 365 ? "green" : "yellow"}
                  />
                </Box>

                {daysHeld >= 365 ? (
                  <i
                    style={{ color: colors.positive, fontSize: 10 }}
                    className="fa-solid fa-shield-check"
                  />
                ) : (
                  <i
                    style={{ color: colors.yellow50, fontSize: 10 }}
                    className="fa-solid fa-clock"
                  />
                )}
              </HStack>
            </AwakenTooltip>

            <Text
              textAlign="left"
              flex={1}
              marginTop={"3px"}
              fontSize="xs"
              color={theme.text}
            >
              Purchased {new Date(position.purchasedAt).toLocaleDateString()}
            </Text>
          </div>

          <div>
            <AwakenTooltip message={`Average cost basis.`} openDelay={0}>
              <Text fontSize="sm" color={theme.header}>
                {position.avgPricePerTokenFormatted}/{symbol}
              </Text>
            </AwakenTooltip>
          </div>
        </HStack>
      </VStack>

      <i
        style={{
          color: theme.text,
          fontSize: 14,
          marginLeft: 15,
        }}
        className="fa-solid fa-chevron-right"
      />
    </HStack>
  );
};

const CurrentValue = ({
  breakdown,
  currency,
}: {
  breakdown: PortfolioAssetPosition;
  currency: CurrencyCodeEnum;
}) => {
  const { clientId } = useParams<{ clientId: string }>();

  const currentValue = breakdown.fiatAmountCents;
  const theme = useTheme();

  return (
    <Box>
      <Text
        fontSize="md"
        isNumeric
        color={theme.header}
        style={{
          fontWeight: "500",
        }}
      >
        {D(currentValue || 0, currency).toFormat()}
      </Text>
      {/* <Text
          fontSize="sm"
          marginTop={0}
          isTruncated
          // fontStyle="italic"
          style={{ color: colors.gray30 }}
        >
          {D(breakdown.currentTokenPriceFiatAmount || 0, currency).toFormat()}/
          {isNFT
            ? singular(tokenName)
            : asset?.symbol?.toUpperCase() || singular(tokenName)}
        </Text> */}
    </Box>
  );
};
