import { useLazyQuery, useMutation } from "@apollo/client";
import {
  Box,
  Divider,
  Flex,
  HStack,
  Text,
  Tooltip,
  VStack,
} from "@chakra-ui/react";
import BigNumber from "bignumber.js";
import { isNil } from "lodash";
import numbro from "numbro";
import { useContext, useEffect } from "react";
import { useParams } from "react-router-dom";
import { api } from "src/api";
import { BaseAssetFields } from "src/api/fragments";
import {
  CurrencyCodeEnum,
  GetAssetPositionsResponse,
  PortfolioBalance,
  QueryGetAssetPositionsArgs,
} from "src/api/generated/types";
import StatusTag from "src/components/styled/StatusTag";
import WhiteBox from "src/components/styled/WhiteBox";
import { Maybe, hasValue } from "src/core";
import { useClientById, useMyToast } from "src/hooks";
import { useTheme } from "src/hooks/useTheme";
import { colors } from "src/theme";
import { D, formatNum } from "src/utils/helpers";
import { AssetKeyInfoContext } from "./utils";

type PortfolioAssetWithAssetInfo = PortfolioBalance & {
  assetInfo: Maybe<BaseAssetFields>;
};

export type PortfolioAccountWithAssetInfo = any & {
  assets: PortfolioAssetWithAssetInfo[];
};

const Finances = () => {
  const { clientId, assetKey } = useParams<{
    clientId: string;
    assetKey: string;
  }>();
  const { client } = useClientById(clientId);

  const { symbol } = useContext(AssetKeyInfoContext);

  const toast = useMyToast();

  const [
    fetchAssetPortfolio,
    { data: portfolioData, loading: positionsLoading, error: positionsErrors },
  ] = useLazyQuery<{
    getAssetPositions: GetAssetPositionsResponse;
  }>(api.portfolio.getAssetPositions, {
    notifyOnNetworkStatusChange: true,
    fetchPolicy: "cache-and-network",
  });

  const [reportUnpricedAsset] = useMutation(api.assets.reportUnpricedAsset);

  useEffect(() => {
    if (!clientId) return;
    const variables: QueryGetAssetPositionsArgs = {
      clientId,
      assetPricingKey: assetKey || "",
    };

    fetchAssetPortfolio({
      variables,
    });
  }, [clientId]);

  const totalCostBasis =
    portfolioData?.getAssetPositions?.totalCostBasisFiatAmountCents;
  const currentFiatValue =
    portfolioData?.getAssetPositions?.totalFiatAmountCents;
  const amount = portfolioData?.getAssetPositions?.totalAmount;
  const totalComputedAmount =
    portfolioData?.getAssetPositions?.totalComputedAmount;

  const avgBasisFiatAmount =
    portfolioData?.getAssetPositions?.avgCostBasisFiatAmountCents;
  const avgCostBasisFiatAmountFormatted =
    portfolioData?.getAssetPositions?.avgCostBasisFiatAmountFormatted;

  const hasGainLoss =
    hasValue(totalCostBasis) &&
    hasValue(currentFiatValue) &&
    !portfolioData?.getAssetPositions.showWarning;

  const showEightDecimals =
    !isNil(avgBasisFiatAmount) &&
    new BigNumber(avgBasisFiatAmount?.toFixed(2)).isZero();
  const theme = useTheme();
  const position = portfolioData?.getAssetPositions;

  return (
    <Box
      paddingBottom="1.5rem"
      style={{
        width: "100%",
      }}
      border="none"
    >
      <HStack flex={1} marginBottom="1.25rem" alignItems="center">
        <Flex alignItems="center" flex={1}>
          <Text
            fontSize={18}
            fontWeight="semibold"
            marginRight="5px"
            color={theme.header}
            style={{ fontStretch: "extra-expanded" }}
          >
            Overall Position
          </Text>
        </Flex>
      </HStack>

      <div>
        <WhiteBox
          style={{
            boxShadow: "none", // other.lighterBoxShadow,
          }}
          border={`1px solid ${theme.border}`}
          padding="1rem 0"
          marginTop="0"
          h="100%"
          minW="none"
        >
          <HStack>
            <VerticalInfoRow
              label="Amount"
              showWarning={position?.showWarning}
              warningMessage={`Awaken calculated you had ${numbro(
                totalComputedAmount
              ).format("0,0.[0000]")} ${
                symbol || "tokens"
              } in your wallet, but you actually have ${numbro(amount).format(
                "0,0.[0000]"
              )}. This usually means you need to label or add transactions.`}
              value={
                <>
                  <Tooltip
                    openDelay={250}
                    trigger="hover"
                    placement="bottom-start"
                    label={`You have ${numbro(amount).format("0,0.[0000]")} ${
                      symbol || "tokens"
                    } across your wallets.`}
                  >
                    <Text color={theme.header} fontSize="md">
                      {!isNil(amount)
                        ? formatNum(amount, false, "0,0.[0000]") +
                          " " +
                          (symbol || "tokens")
                        : "-"}
                    </Text>
                  </Tooltip>
                </>
              }
            />
            <VerticalInfoRow
              label="Current Value"
              value={
                <Text color={theme.header} fontSize="md" w="100%">
                  {isNil(currentFiatValue)
                    ? "-"
                    : D(currentFiatValue ?? 0).toFormat()}{" "}
                  &nbsp;&nbsp;
                </Text>
              }
            />
          </HStack>

          {/* only show if no warning */}
          {!portfolioData?.getAssetPositions.showWarning && (
            <HStack alignItems="flex-start">
              <VerticalInfoRow
                label="Avg cost"
                value={
                  !isNil(avgCostBasisFiatAmountFormatted)
                    ? avgCostBasisFiatAmountFormatted
                    : "-"
                }
                showWarning={portfolioData?.getAssetPositions.showWarning}
              />
              <VerticalInfoRow
                label="Total cost"
                value={
                  !isNil(totalCostBasis)
                    ? D(totalCostBasis ?? 0).toFormat()
                    : "-"
                }
                showWarning={portfolioData?.getAssetPositions.showWarning}
              />
            </HStack>
          )}

          {!!hasGainLoss && (
            <>
              <Divider style={{ margin: "1rem 0" }} />

              <InfoRow
                isLast
                label="Total Return"
                value={
                  <GainLoss
                    currency={client?.currency ?? CurrencyCodeEnum.Usd}
                    balance={portfolioData?.getAssetPositions ?? null}
                  />
                }
              />
            </>
          )}
        </WhiteBox>
      </div>
    </Box>
  );
};

const InfoRow = ({
  label,
  value,
  isLast,
}: {
  label: string;
  value: string | number | JSX.Element;
  isLast?: boolean;
}) => {
  const theme = useTheme();

  return (
    <HStack
      padding="0.5rem 1rem"
      //   borderBottom={isLast ? "none" : `1px solid ${colors.gray85}`}
    >
      <Flex flex={1}>
        <Text color={theme.header} fontSize="sm" fontWeight="normal">
          {label}
        </Text>
      </Flex>
      <Flex flex={2} justifyContent="flex-end">
        {typeof value == "string" ? (
          <Text color={theme.header} fontSize="sm">
            {value}
          </Text>
        ) : (
          value
        )}
      </Flex>
    </HStack>
  );
};

const VerticalInfoRow = ({
  label,
  value,
  isLast,
  showWarning,
  warningMessage,
}: {
  label: string;
  value: string | number | JSX.Element;
  isLast?: boolean;
  showWarning?: boolean;
  warningMessage?: string;
}) => {
  const theme = useTheme();

  return (
    <VStack
      padding="0.5rem 1rem"
      marginLeft="0 !important"
      flex={1}
      alignItems="flex-start"
      //   borderBottom={isLast ? "none" : `1px solid ${colors.gray85}`}
    >
      <Flex flex={1}>
        <Text color={theme.header} fontSize="sm" fontWeight="normal">
          {label}
        </Text>
      </Flex>
      <Flex flex={1} fontWeight={500} justifyContent="flex-end">
        {typeof value == "string" ? (
          <Text fontSize="md" color={theme.header}>
            {value}
          </Text>
        ) : (
          value
        )}{" "}
        {showWarning && (
          <div style={{ marginLeft: 5 }}>
            <StatusTag
              type="none"
              iconName="fa-sharp fa-exclamation-circle"
              infoMessage={warningMessage || "Missing cost basis."}
            />
          </div>
        )}
      </Flex>
    </VStack>
  );
};

const GainLoss = ({
  balance,
  currency,
}: {
  balance: Maybe<GetAssetPositionsResponse>;
  currency: CurrencyCodeEnum;
}) => {
  if (!balance || balance.showWarning) {
    return (
      <HStack alignItems="center">
        <Text margin="0 !important">-</Text>
      </HStack>
    );
  }

  const isNeg = (balance.overallGainLossFiatAmountCents ?? 0) < 0;
  const color = isNeg ? colors.negative : colors.positive;

  return (
    <HStack
      alignItems="center"
      justifyContent="flex-end"
      style={{ width: "100%" }}
    >
      <i
        className={isNeg ? "fa-sharp fa-caret-down" : "fa-sharp fa-caret-up"}
        style={{
          fontSize: 16,
          marginRight: 5,
          position: "relative",
          color,
        }}
      />
      <VStack
        alignItems="flex-start"
        style={{
          fontWeight: "500",
          color,
        }}
      >
        <Text
          fontWeight="semibold"
          fontSize="sm"
          color={
            color //  colors.black //
          }
          marginBottom="0"
        >
          {isNeg ? "-" : "+"}
          {D(
            Math.abs(balance.overallGainLossFiatAmountCents ?? 0),
            currency
          ).toFormat()}{" "}
          ({balance.overallGainLossPercentageFormatted}%)
        </Text>
      </VStack>
    </HStack>
  );
};

export default Finances;
