import { useEffect, useMemo } from "react";
import { useNavigate } from "react-router-dom";

import { useLazyQuery, useQuery } from "@apollo/client";
import { HStack, Text } from "@chakra-ui/react";
import { Currency } from "dinero.js";
import moment from "moment";
import {
  Bar,
  BarChart,
  Cell,
  ResponsiveContainer,
  Tooltip,
  XAxis,
} from "recharts";
import { api } from "src/api";
import { BaseClientFields } from "src/api/fragments";
import {
  GetIncomeAndCapGainsResponse,
  QueryGetIncomeAndCapGainsArgs,
  TaxYearBracket,
} from "src/api/generated/types";
import { AwakenTooltip } from "src/components";
import { useTheme } from "src/hooks/useTheme";
import {
  getGainsLossesLink,
  getIncomeLink,
} from "src/modules/ledger/transactions";
import { colors } from "src/theme";
import { D } from "src/utils/helpers";

export const CapitalGainsYearlyChart = ({
  clientId,
  height,
}: {
  clientId: string;
  height?: number;
}) => {
  const navigate = useNavigate();
  const currentYear = new Date().getFullYear();

  const { data: clientData } = useQuery<{
    getClientById?: BaseClientFields;
  }>(api.clients.retrieve, {
    fetchPolicy: "cache-only",
    variables: { clientId },
  });

  const currency = clientData?.getClientById?.currency as Currency;

  const variables = useMemo(
    () => ({
      year: currentYear.toString(),
      clientId,
    }),
    [currentYear, clientId]
  );

  const { data } = useQuery(api.reports.monthlyPoints, {
    variables,
    fetchPolicy: "cache-and-network",
  });

  const points = data?.getMonthlyPoints ?? [];
  const theme = useTheme();

  const [getIncomeAndCapGains, { data: incomeAndCapGainsData }] = useLazyQuery<
    { getIncomeAndCapGains: GetIncomeAndCapGainsResponse },
    QueryGetIncomeAndCapGainsArgs
  >(api.reports.getIncomeAndCapGains, {
    fetchPolicy: "cache-and-network",
  });

  useEffect(() => {
    if (!currentYear || !clientId) return;
    getIncomeAndCapGains({
      variables: {
        clientId,
        year: currentYear.toString(),
      },
    });
  }, [currentYear]);

  const chartData = points.map((point: any) => ({
    timestamp: moment(point.timestamp).utc().format("MMM"),
    Capital_Gains: D(point.totalCapGainsCents).toUnit(),
    _data: point,
    // totalCapGainsCents: point.totalCapGainsCents,
  }));

  const _openCapGains = (entry: any) => {
    const start = moment(entry._data.timestamp).utc();
    const end = start.clone().add(1, "month");

    const link = getGainsLossesLink({
      clientId: clientId || "",
      startDate: start.toDate(),
      endDate: end.toDate(),
    });

    navigate(link);
  };

  const totalCapGains =
    incomeAndCapGainsData?.getIncomeAndCapGains?.capGainsTotal;
  const isNeg = totalCapGains && totalCapGains.includes("-");

  if (!chartData.length) {
    return null;
  }

  if (!totalCapGains) {
    return null;
  }

  return (
    <div
      style={{
        marginLeft: 0,
        width: 400,
        marginTop: 15,
        padding: 15,
        paddingBottom: 0,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        background:
          theme.theme === "light" ? theme.background : theme.medBackground,
        border: theme.theme === "light" ? `1px solid ${theme.border}` : "none",
        borderRadius: 15,
        // gap in between only if large
        gap: 0,
      }}
    >
      <div style={{ flex: 1, width: "100%" }}>
        <HStack marginBottom={5} alignItems="center">
          <AwakenTooltip
            placement="bottom"
            message={`This is a tax preview for next tax season (${currentYear}) to help you get ahead. You can view the full numbers on the 'Taxes' page.`}
          >
            <Text
              style={{
                fontStretch: "extra-expanded",
              }}
              color={theme.header}
              fontSize="md"
              fontWeight="bold"
            >
              Tax Preview ({new Date().getFullYear()})
            </Text>
          </AwakenTooltip>
          <Text
            flex={1}
            fontSize="md"
            textAlign="right"
            color={isNeg ? colors.negative : colors.positive}
          >
            {totalCapGains}
          </Text>
        </HStack>

        <ResponsiveContainer
          className={"rechart-" + theme.theme}
          style={{
            borderRadius: 15,
            marginBottom: 0,
          }}
          width="100%"
          height={height || 150}
        >
          <BarChart data={chartData}>
            <XAxis dataKey="timestamp" tick={false} axisLine={false} />
            <Tooltip
              content={
                <CustomTooltip
                  currency={currency}
                  field="totalCapGainsFormatted"
                />
              }
            />

            <Bar
              dataKey="Capital_Gains"
              // make the bar at least 5 high
              minPointSize={5}
              shape={(props: any) => (
                <RoundedBar
                  {...props}
                  radius={10}
                  onClick={() => _openCapGains(props.payload)}
                />
              )}
            >
              {chartData.map((entry: any, index: number) => (
                <Cell
                  key={`cell-${index}`}
                  fill={
                    entry["Capital_Gains"] === 0
                      ? theme.secondaryBackground
                      : entry["Capital_Gains"] > 0
                      ? colors.positive
                      : colors.negative
                  }
                />
              ))}
            </Bar>
          </BarChart>
        </ResponsiveContainer>
      </div>
    </div>
  );
};

export const IncomeYearlyChart = ({
  year,
  clientId,
  height,
}: {
  year: TaxYearBracket;
  clientId: string;
  height?: number;
}) => {
  const navigate = useNavigate();

  const { data: clientData } = useQuery<{
    getClientById?: BaseClientFields;
  }>(api.clients.retrieve, {
    variables: { clientId },
    fetchPolicy: "cache-only",
  });

  const currency = clientData?.getClientById?.currency as Currency;

  const variables = useMemo(
    () => ({
      year: year.taxYear,
      clientId,
    }),
    [year, clientId]
  );

  const { data } = useQuery(api.reports.monthlyPoints, {
    variables,
    fetchPolicy: "cache-and-network",
  });

  const points = data?.getMonthlyPoints ?? [];
  const theme = useTheme();

  const chartData = points.map((point: any) => ({
    timestamp: moment(point.timestamp).utc().format("MMM"),
    Income: D(point.netIncomeCents).toUnit(),
    _data: point,
    // totalCapGainsCents: point.totalCapGainsCents,
  }));

  const _openIncome = (entry: any) => {
    const start = moment(entry._data.timestamp).utc();
    const end = start.clone().add(1, "month");

    const link = getIncomeLink(clientId, start.toDate(), end.toDate());

    navigate(link);
  };

  if (!chartData.length) {
    return null;
  }

  return (
    <div>
      <Text
        color={theme.header}
        fontWeight="bold"
        fontSize="lg"
        marginBottom={15}
      >
        Income
      </Text>
      <ResponsiveContainer
        className={"rechart-" + theme.theme}
        style={{
          background: theme.background,
          borderRadius: 15,
          marginBottom: 25,
        }}
        width="100%"
        height={height || 150}
      >
        <BarChart data={chartData}>
          <XAxis dataKey="timestamp" tick={false} axisLine={false} />
          <Tooltip
            content={
              <CustomTooltip currency={currency} field="netIncomeFormatted" />
            }
          />

          <Bar
            dataKey="Income"
            minPointSize={5}
            shape={(props: any) => (
              <RoundedBar
                {...props}
                radius={10}
                onClick={() => _openIncome(props.payload)}
              />
            )}
          >
            {chartData.map((entry: any, index: number) => (
              <Cell
                key={`cell-${index}`}
                fill={
                  entry["Income"] === 0
                    ? theme.secondaryBackground
                    : entry["Income"] > 0
                    ? colors.positive
                    : colors.negative
                }
              />
            ))}
          </Bar>
        </BarChart>
      </ResponsiveContainer>
    </div>
  );
};

const RoundedBar = (props: any) => {
  const { fill, x, y, width, height } = props;
  const radius = 5; // Adjust the border-radius as needed

  if (height < 0) {
    return (
      <g>
        <rect
          x={x}
          y={y + height}
          onClick={() => {
            if (props.onClick) {
              props.onClick();
            }
          }}
          width={width}
          height={-height}
          fill={fill}
          // only do the bottom right and bottom left radius
          rx={radius}
          ry={radius}
          style={{ cursor: "pointer", transition: "opacity 0.3s" }}
        />
      </g>
    );
  }

  return (
    <g>
      <rect
        x={x}
        y={y}
        width={width}
        height={height}
        onClick={() => {
          if (props.onClick) {
            props.onClick();
          }
        }}
        fill={fill}
        rx={radius}
        ry={radius}
        style={{ cursor: "pointer", transition: "opacity 0.3s" }}
      />
    </g>
  );
};

const CustomTooltip = ({
  active,
  payload,
  label,
  field,
  currency,
}: {
  active?: boolean;
  payload?: any;
  label?: string;
  field: string;
  currency: Currency;
}) => {
  const theme = useTheme();

  if (active && payload && payload.length) {
    const value = ((payload || [])[0]?.payload?._data || {})[field];

    return (
      <div
        style={{
          padding: "5px 15px",
          background: theme.header,
          borderRadius: 100,
        }}
      >
        <p style={{ color: theme.background, fontWeight: 500, fontSize: 16 }}>
          <b>{label}: </b>
          {value || D(payload[0].value * 100, currency || "USD").toFormat()}
        </p>
      </div>
    );
  }
  return null;
};
