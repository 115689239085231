import {
  ApolloClient,
  ApolloLink,
  DefaultOptions,
  InMemoryCache,
} from "@apollo/client";
import { BatchHttpLink } from "@apollo/client/link/batch-http";
import { setContext } from "@apollo/client/link/context";
import { RetryLink } from "@apollo/client/link/retry";
import { createUploadLink } from "apollo-upload-client";
import { config } from "src/config";
import { getAuthToken } from "src/utils/firebase";

const uri = `${config.apiUrl}/graphql`;

const defaultOptions: DefaultOptions = {
  watchQuery: {
    errorPolicy: "all",
    fetchPolicy: "cache-first",
  },
  query: {
    fetchPolicy: "no-cache",
  },
};

const MAX_CACHE_SIZE = Math.pow(2, 10);

const cache = new InMemoryCache({
  // resultCacheMaxSize: Math.pow(2, 6),
});

setInterval(() => {
  const cacheSize = cache.extract();
  // const cacheSizeKB = new Blob([JSON.stringify(cacheSize)]).size / 1024;
  // console.log(`Apollo Cache Size: ${cacheSizeKB.toFixed(2)} KB`);
  // console.log(`Number of cached items: ${Object.keys(cacheSize).length}`);

  // If cache items exceed the max size, reset the cache
  if (Object.keys(cacheSize).length > MAX_CACHE_SIZE) {
    console.log("Cache cleared due to size limit");
    // evict the lru
    cache.gc();
  }
}, 15_000);

const retryLink = new RetryLink({
  delay: {
    initial: 300,
    max: 5000, // Cap maximum delay at 5 seconds
    jitter: true,
  },
  attempts: {
    max: 3, // Retry up to 3 times
    retryIf: (error, _operation) => {
      // Retry on network errors, timeouts, or 5xx server errors
      return (
        !!error &&
        (error.networkError ||
          error.message === "Request timed out" ||
          (error.statusCode >= 500 && error.statusCode < 600))
      );
    },
  },
});

const authLink = setContext(async (_, { headers }) => {
  const token = await getAuthToken();

  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      Authorization: token ? `Bearer ${token}` : "",
    },
  };
});

const dynamicUriLink = setContext((operation) => {
  const isPortfolioQuery = operation.operationName?.includes("Portfolio");

  const apiUrl = isPortfolioQuery
    ? `${config.apiPortfolioUrl}/graphql`
    : `${config.apiUrl}/graphql`;

  return {
    uri: apiUrl,
  };
});

const uploadLink: any = createUploadLink();

const batchLink = new BatchHttpLink({
  // We rely on dynamicUriLink to set the URI, so we don't specify it here.
  batchInterval: 250, // combine queries within 500ms into a single request
  // You can also specify batchMax if desired, e.g., batchMax: 10
  batchMax: 5,
});

// Helper to determine if variables contain file uploads
function containsFileUploads(variables: any): boolean {
  if (!variables || typeof variables !== "object") return false;

  for (const value of Object.values(variables)) {
    if (value instanceof File || value instanceof Blob) {
      return true;
    }

    // If nested object/array, check recursively
    if (typeof value === "object" && containsFileUploads(value)) {
      return true;
    }
  }

  return false;
}

// Helper to determine if operation should be batched
function shouldBatchOperation(operation: any): boolean {
  // Don't batch GetPortfolioV2 operations
  if (
    operation.operationName === "GetPortfolioV2" ||
    operation.operationName === "GetMe" ||
    operation.operationName === "GetClientById"
  ) {
    return false;
  }

  // Don't batch operations with file uploads
  return !containsFileUploads(operation.variables);
}

// Split link: If the operation shouldn't be batched, use regular HTTP link, else use batchLink
const splitLink = ApolloLink.split(
  (operation) => shouldBatchOperation(operation),
  batchLink,
  uploadLink
);

const timeoutLink = new ApolloLink((operation, forward) => {
  // Skip timeout for DeleteClient operation
  if (operation.operationName === "DeleteClient") {
    return forward(operation);
  }

  let timeout = 45_000;

  // Set a longer timeout for summarizeTransaction
  if (
    operation.operationName?.toLowerCase() === "summarizetransaction" ||
    operation.operationName?.toLowerCase() === "deleteclient"
  ) {
    timeout = 60_000;
  }

  const controller = new AbortController();
  const signal = controller.signal;

  // Set a timeout to cancel the request
  const timeoutId = setTimeout(() => {
    controller.abort(); // Abort the request
    operation.setContext(({ response }: any) => ({
      ...response,
      error: new Error("Request timed out"),
    }));
  }, timeout);

  // Pass the abort signal to the context of the operation
  operation.setContext({
    fetchOptions: {
      signal,
    },
  });

  return forward(operation).map((data) => {
    clearTimeout(timeoutId); // Clear timeout if request completes in time
    return data;
  });
});

export const apolloClient = new ApolloClient({
  // does auth link and then after does http request
  link: ApolloLink.from([
    timeoutLink,
    retryLink,
    authLink,
    dynamicUriLink,
    splitLink,
  ]),
  cache,
  defaultOptions,
});
