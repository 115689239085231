import { AccountTypeEnum } from "src/api/generated/types";
import { Maybe } from "src/core";

export enum IntegrationAction {
  Oauth = "oauth",
  FileUpload = "file_upload",
  PlaidLink = "plaid",
  TriggerModal = "trigger_modal",
  HatchfiLink = "link",
  VezgoLink = "vezgo_link",
  AwakenCsvFileUpload = "awaken_csv_file_upload",
}

export type IntegrationOption = {
  buttonText?: string;
  action: IntegrationAction;
  provider: string;
  hatchfiProvider: Maybe<string>;
  vezgoProvider?: string;
  onlySuperUser?: boolean;
  modalName: Maybe<string>;
  modalProps?: any;
};

export type IntegrationProviderInfo = {
  name: string;
  isFree2024?: boolean;
  description?: string | JSX.Element;
  isExtraordinary?: boolean;
  awakenCsvModalDescription?: string | JSX.Element;
  provider: string;
  keywordsV2?: string[];
  keywords?: string; // ex. metamask,coinbase wallet, etc... things to match against
  infoMessage?: string;
  logoUrl: Maybe<string>;
  isLive: boolean;
  isNew?: boolean;
  isFree?: boolean;
  isBeta?: boolean;
  type: AccountTypeEnum;
  options: IntegrationOption[];
};

export type IntegrationOptionProps = {
  integration: IntegrationProviderInfo;
  loadingIntegration: Maybe<string>;
  onSuccess: () => void;
  isReadOnly?: boolean;
  onAddIntegration: (
    info: IntegrationProviderInfo,
    usedIntegration: IntegrationOption
  ) => Promise<void>;
};
