import { useMutation } from "@apollo/client";
import { Button, Container, Divider, Text, Textarea } from "@chakra-ui/react";
import { useState } from "react";
import { connectModal, InjectedProps } from "redux-modal";
import { api } from "src/api";
import { Modal } from "src/components/Modal";
import { useMe, useMyToast } from "src/hooks";
import { useTheme } from "src/hooks/useTheme";

type Props = InjectedProps & {
  startingFeedback?: string;
};

function _CommunityCodeModal({
  handleHide,
  show: isVisible,
  startingFeedback,
}: Props) {
  const [feedback, setFeedback] = useState(startingFeedback || "");
  const [submitFeedback, { loading }] = useMutation(api.contact.feedback);
  const toast = useMyToast();
  const theme = useTheme();
  const { me } = useMe();

  const _onSubmit = async function () {
    if (!feedback)
      return toast.show({
        message: "Please fill out all the form fields.",
        status: "error",
      });

    try {
      await submitFeedback({
        variables: {
          feedback: `${feedback}\n\nURL: ${window.location.href}\nType: Community Code Application\nEmail:${me?.email}`,
        },
      });

      toast.show({
        message: "Thank you for your interest! We'll be in touch.",
        status: "success",
      });
    } catch (err) {
      toast.show({
        message: (err as Error).message || "An error occurred.",
        status: "error",
      });
    }

    handleHide();
  };

  return (
    <Modal
      title="Community Codes"
      isVisible={isVisible}
      handleHide={handleHide}
      Footer={
        <Button
          width="100%"
          variant="primary"
          isLoading={loading}
          onClick={_onSubmit}
        >
          Submit
        </Button>
      }
    >
      <Container padding="0px" marginTop="0px !important">
        <Text style={{ color: theme.text }} fontSize="md" fontWeight="500">
          Let's get you a code for your community (or a community you're a
          member of)!
          <br />
          <br />
          When you share your code, we'll give everyone who uses it BONUS credit
          and you get Awaken free if it's used 10+ times.
        </Text>
        <br />
        <Divider />
        <br />
        {/* label */}
        <Text
          style={{ color: theme.header }}
          fontSize="md"
          fontWeight="600"
          marginBottom={"10px"}
        >
          Can you tell us a little more about your community?
        </Text>
        <Textarea
          value={feedback}
          noOfLines={6}
          height={150}
          style={{
            color: theme.header,
          }}
          onChange={(e) => setFeedback(e.target.value)}
          // label="Client Full Name"
          placeholder="Type here..."
        />
      </Container>
    </Modal>
  );
}

export const CommunityCodeModal = connectModal({
  name: "CommunityCodeModal",
})(_CommunityCodeModal);
