import { Box, useMediaQuery } from "@chakra-ui/react";
import { useEffect, useRef, useState } from "react";
import { useIsInViewport } from "src/hooks/common/useIsInViewport";
import { colors } from "src/theme";

type TestimonialsScrollingProps = {
  direction?: string; // SCROLL_LEFT, SCROLL_RIGHT (default)
  tweetIds: { url: string; image: string }[]; // Array of tweet IDs instead of images
  height?: string;
  useFour?: boolean;
};

type StyleOverride = {
  width: string;
  left?: string;
  right?: string;
};

export const TestimonialsScrolling = ({
  direction = "SCROLL_RIGHT",
  tweetIds,
  height,
  useFour,
}: TestimonialsScrollingProps) => {
  const ref = useRef<any>(null);
  const [offset, setOffset] = useState(0);
  const { isInViewport, ref: isInViewportRef } = useIsInViewport();
  const [isLargerThan900] = useMediaQuery("(min-width: 900px)");

  useEffect(() => {
    if (isInViewport) {
      const intervalId = setInterval(() => {
        setOffset((prevOffset) => {
          const step = direction === "SCROLL_LEFT" ? 0.25 : -0.25;
          return prevOffset + step;
        });
      }, 10);
      return () => clearInterval(intervalId);
    }
  }, [isInViewport, direction]);

  const WIDTH = isLargerThan900 ? 450 : 300;

  const tweetWidthPx = WIDTH;
  const totalWidthPx = tweetWidthPx * tweetIds.length;

  const style: StyleOverride = {
    width: `${totalWidthPx * 2}px`,
    ...(direction === "SCROLL_LEFT"
      ? { left: `${-1 * (offset % totalWidthPx)}px` }
      : { right: `${offset % totalWidthPx}px` }),
  };

  return (
    <Box
      w="100%"
      h={height || "30rem"}
      overflow="hidden"
      position="relative"
      ref={ref}
      margin="1rem 0"
    >
      <Box
        ref={isInViewportRef}
        position="absolute"
        top="0"
        display="flex"
        height="100%"
        style={style}
      >
        {[...tweetIds, ...tweetIds].map((tweet, i) => (
          <a href={tweet.url} target="_blank" rel="noopener noreferrer">
            <Box
              key={i}
              width={`${WIDTH}px`}
              height="100%"
              padding="10px 0"
              overflow="hidden"
              margin="0 10px"
              border={`2px solid ${colors.gray90}`}
              borderRadius="1rem"
              alignItems="flex-start"
            >
              <img
                src={tweet.image}
                alt="Tweet"
                style={{
                  width: "100%",
                  maxHeight: "100%",
                  objectFit: "contain",
                }}
              />
            </Box>
          </a>
        ))}
      </Box>
    </Box>
  );
};
