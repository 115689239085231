import { createAction, createReducer, createSelector } from "@reduxjs/toolkit";
import { GlobalState, ReduxState } from "../types";

// initial state
const initialState: GlobalState = {
  isRecalculating: false,
  recalculateFinishEta: null,
  isImporting: false,
  isGlobalRuleMode: false,
  hasBanner: true,
  showConfetti: false,
  theme: "light",
};

// actions
export const setGlobalRuleMode = createAction<boolean>(
  "SET_GLOBAL_STATE_RULE_MODE"
);

export const setHasBanner = createAction<boolean>("SET_HAS_BANNER");

export const setShowConfetti = createAction<boolean>("SET_SHOW_CONFETTI");

export const setTheme = createAction<"light" | "dark">("SET_THEME");

// reducer
export const globalStateReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(setGlobalRuleMode, (state, action) => {
      state.isGlobalRuleMode = action.payload;
    })
    .addCase(setHasBanner, (state, action) => {
      state.hasBanner = action.payload;
    })
    .addCase(setTheme, (state, action) => {
      state.theme = action.payload;
    })
    .addCase(setShowConfetti, (state, action) => {
      state.showConfetti = action.payload;
    });
});

export const getIsGlobalRuleMode = (state: ReduxState): boolean =>
  state.global.isGlobalRuleMode;

export const getHasBanner = (state: ReduxState): boolean =>
  state.global.hasBanner;

export const getShowConfetti = (state: ReduxState): boolean =>
  state.global.showConfetti;

export const getTheme = createSelector(
  (state: ReduxState) => state.global,
  (g) => g.theme
);
