import { Box, Image, Text } from "@chakra-ui/react";
import React, { CSSProperties, useEffect, useMemo, useState } from "react";
import { BaseAssetFields } from "src/api/fragments";
import { AssetTypeEnum } from "src/api/generated/types";
import { Maybe } from "src/core";
import { useTheme } from "src/hooks/useTheme";
import { getAssetUrl, getBackupImage } from "src/modules/getAssetUrl";
import { colors } from "src/theme";
import { getImageKitCDNUrl } from "src/utils/imagekit";
import truncateMiddle from "truncate-middle";

const _AssetIcon = ({
  asset,
  size: _size,
  numCharacters = 3,
  style,
  textStyle,
  className,
}: {
  asset: Maybe<
    Pick<BaseAssetFields, "type" | "iconImageUrl" | "imageUrl" | "symbol"> & {
      backupImageUrls?: string[] | null;
    }
  >;
  className?: string;
  size?: number;
  style?: CSSProperties;
  textStyle?: CSSProperties;
  numCharacters?: number;
}) => {
  const isNFT = asset?.type === AssetTypeEnum.Nft;
  const assetUrl = useMemo(() => getAssetUrl(asset), [asset]);
  const [_rawImageUrl, setImageUrl] = useState(assetUrl);
  const size = _size ?? 35;
  const assetSymbol = (
    truncateMiddle(asset?.symbol?.toUpperCase() || "", numCharacters, 1, ".") ||
    ""
  ).slice(0, 3);
  const [errorCount, setErrorCount] = useState(0);

  const { background, border, header, secondaryBackground } = useTheme();

  useEffect(() => setImageUrl(assetUrl), [assetUrl]);

  // if url
  if (_rawImageUrl) {
    const cdnUrl = getImageKitCDNUrl(_rawImageUrl, { width: 150, height: 150 });

    // if it is a github url (like for solana) we need to pass raw as query
    const isGithub = cdnUrl.includes("github.com");
    const imageUrl = isGithub ? cdnUrl + "?raw=true" : cdnUrl;
    const isVideo = imageUrl.includes(".mp4");

    if (isVideo) {
      // render a video component with the url
      return (
        <video
          style={{
            width: size,
            height: size,
            objectFit: "cover",
            overflow: "hidden",
            flexShrink: 0,
            borderRadius: 5,
            border: isNFT ? "1px solid" + border : "none",
            ...style,
          }}
          autoPlay
          loop
          muted={true}
          className={className}
        >
          <source
            src={imageUrl}
            type="video/mp4"
            onError={({ currentTarget }) => {
              currentTarget.onerror = null; // prevents looping
              currentTarget.src = "";
              setImageUrl(null);
            }}
          />
        </video>
      );
    }

    return (
      <Image
        src={imageUrl}
        style={{
          width: size,
          height: size,
          objectFit: "cover",
          overflow: "hidden",
          flexShrink: 0,
          borderRadius: isNFT ? 5 : "50%",
          border: isNFT ? "1px solid" + border : "1px solid " + border,
          ...style,
        }}
        className={className}
        bg={colors.gray90}
        onError={({ currentTarget }) => {
          if (errorCount === 0) {
            // Try backupImageUrl first if available
            if (asset?.backupImageUrls && asset.backupImageUrls.length > 0) {
              console.log("using backup image url", asset.backupImageUrls[0]);
              currentTarget.src = asset.backupImageUrls[0];
              setErrorCount(1);
              setImageUrl(asset.backupImageUrls[0]);
            } else {
              // Fall back to default backup image if no backupImageUrl
              const newImageUrl = getBackupImage(asset);
              currentTarget.src = newImageUrl || "";
              setErrorCount(1);
              setImageUrl(newImageUrl);
            }
          } else {
            // just wipe
            currentTarget.src = "";
            currentTarget.onerror = null; // prevents looping
            setImageUrl(null);
          }
        }}
      />
    );
  }

  return (
    <Box
      style={{
        width: size,
        height: size,
        justifyContent: "center",
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        borderRadius: 5,
        flexShrink: 0,
        ...style,
      }}
      bg={secondaryBackground}
      className={className}
    >
      <Text
        style={{ fontSize: 10, color: header, ...textStyle }}
        fontWeight={700}
      >
        {isNFT
          ? "NFT"
          : (numCharacters === 1 ? asset?.symbol?.charAt(0) : assetSymbol) ||
            "🪙"}
      </Text>
    </Box>
  );
};

export const AssetIcon = React.memo(_AssetIcon);
