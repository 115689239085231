import { Box, Flex, HStack, Text } from "@chakra-ui/react";

import { useLazyQuery, useMutation } from "@apollo/client";
import { useContext, useEffect, useMemo } from "react";
import { api, apolloClient } from "src/api";
import {
  Mutation,
  Query,
  TransactionTypeOption,
} from "src/api/generated/types";
import { Button } from "src/components";
import { BulkLabelSelect } from "src/components/Labels";
import { Maybe } from "src/core";
import { useMyToast } from "src/hooks";
import { useTheme } from "src/hooks/useTheme";
import { colors, other } from "src/theme";
import { SelectTransactionsContext } from "./context";

export function BatchTransactionsEdit() {
  const toast = useMyToast();
  const { selectedTransactionIds, wipeTxnIds, activeLabel, setActiveLabel } =
    useContext(SelectTransactionsContext);
  const theme = useTheme();

  const [getAvailableLabels, { loading, data, error }] = useLazyQuery<{
    getLabelOptionsForTransactions: Query["getLabelOptionsForTransactions"];
  }>(api.transactions.availableLabelsForTransactions, {
    fetchPolicy: "cache-first",
  });

  const [labelTransactions, { loading: loadingLabel }] = useMutation<{
    labelTransactions: Mutation["labelTransactions"];
  }>(api.transactions.labelMany);

  useEffect(() => {
    getAvailableLabels({
      variables: {
        transactionIds: selectedTransactionIds,
      },
    });
  }, [selectedTransactionIds]);

  const _saveLabel = async () => {
    try {
      await labelTransactions({
        variables: {
          transactionIds: selectedTransactionIds,
          label: activeLabel,
        },
      });

      apolloClient.refetchQueries({
        include: [api.transactions.retrieve, api.clients.transactions],
      });

      wipeTxnIds();
      setActiveLabel(null);

      toast.show({
        status: "success",
        message: `Successfully labelled ${selectedTransactionIds.length} transactions!`,
      });
    } catch (err) {
      toast.show({
        status: "error",
        message: (err as Error).message || "Error saving label!",
      });
    }
  };

  const onSelectOption = (o: Maybe<TransactionTypeOption>) => {
    setActiveLabel(o?.value || null);
  };

  const availableLabels = data?.getLabelOptionsForTransactions?.labels || [];
  const applicableLabels = (
    data?.getLabelOptionsForTransactions?.labels || []
  ).filter((l) => l.applicable);

  const selectedLabel = useMemo(
    () => availableLabels.find((l) => l.value === activeLabel),
    [availableLabels, activeLabel]
  );

  const isApplicable = useMemo(
    () => !!applicableLabels.find((l) => l.value === activeLabel),
    [applicableLabels, activeLabel]
  );

  if (selectedTransactionIds.length < 2) {
    return null;
  }

  const isLoading = loading || loadingLabel;
  const isNotApplicable = !isApplicable && !isLoading && !!activeLabel;
  const errorMessage = error?.message || null;

  return (
    <Box
      display="flex"
      position="fixed"
      flexDir="column"
      right="calc(50% - 250px - 125px)"
      width={"500px"}
      bottom="40px"
      alignItems="center"
      cursor="pointer"
      padding="1rem 2rem"
      textAlign="center"
      bg={theme.theme === "light" ? theme.background : theme.medBackground}
      borderRadius={7}
      boxShadow={other.boxShadow}
      border={`1px solid ${theme.border}`}
      zIndex={101}
    >
      <div style={{ margin: "auto", marginTop: "0.75rem" }}>
        <HStack style={{ width: "100%" }}>
          <Flex flex={1} minWidth="250px">
            <BulkLabelSelect
              labelOptions={availableLabels}
              selectedOption={selectedLabel}
              onSelectOption={onSelectOption}
              loading={loading}
            />
          </Flex>
          <Button
            disabled={!activeLabel || !isApplicable || loading}
            padding="0.6rem 1rem"
            minWidth="none"
            variant="primary"
            isLoading={loadingLabel} // disable when loading more as well
            onClick={_saveLabel}
          >
            Save ({selectedTransactionIds.length})
          </Button>
        </HStack>

        {(isNotApplicable || !!errorMessage) && (
          <Text fontSize="sm" marginTop="0.75rem" color={theme.header}>
            {errorMessage ? (
              <span style={{ fontWeight: "bold" }}>{errorMessage}</span>
            ) : (
              <>
                <span style={{ fontWeight: "bold" }}>
                  {selectedLabel?.label}
                </span>{" "}
                does not apply to all transactions
              </>
            )}
          </Text>
        )}

        <Text
          marginTop={"15px"}
          display={"inline-block"}
          fontSize="sm"
          onClick={() => wipeTxnIds()}
          _hover={{ textDecoration: "underline" }}
          color={theme.text}
        >
          <i
            className="fa-sharp fa-trash-undo"
            style={{ marginRight: 5, color: colors.red50 }}
          />
          Unselect Transactions
        </Text>
      </div>
    </Box>
  );
}
