import { useMutation } from "@apollo/client";
import {
  Box,
  HStack,
  Image,
  Link,
  Spinner,
  Text,
  Tooltip,
  VStack,
} from "@chakra-ui/react";
import BigNumber from "bignumber.js";
import { isEqual, pick, truncate, uniqBy } from "lodash";
import { isNil } from "lodash/fp";
import numbro from "numbro";
import React, { CSSProperties, useContext, useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { show } from "redux-modal";
import { api } from "src/api";
import {
  BaseAccountFields,
  BaseAccountWithCurrentJobFields,
} from "src/api/fragments";
import {
  AccountIntegrationStatusEnum,
  AccountStatusEnum,
  ImportTypeEnum,
  Maybe,
  Mutation,
} from "src/api/generated/types";
import { ActionSheet, AwakenTooltip, Button, Copy, Info } from "src/components";
import {
  useHandleGetOAuthLink,
  useHandleVezgoLink,
} from "src/components/modals/AccountModal/AccountsWorkflow";
import StatusTag from "src/components/styled/StatusTag";
import WhiteBox from "src/components/styled/WhiteBox";
import { Touchable } from "src/components/Touchable";
import { hasValue } from "src/core";
import { useMe, useMyToast } from "src/hooks";
import { useDownloadFile, useInterval } from "src/hooks/common";
import { useTheme } from "src/hooks/useTheme";
import { getAccountLink } from "src/modules/ledger/transactions";
import { colors } from "src/theme";
import { toLuxonUTC } from "src/utils";
import Helpers, { getEtaMessage } from "src/utils/helpers";
import { getImageKitCDNUrl } from "src/utils/imagekit";
import truncateMiddle from "truncate-middle";
import { AccountsContext } from "./context";
import NumberOfTransactions from "./NumberOfTransactions";
import {
  ACCOUNT_FIELDS,
  getShouldReconnectCoinbase,
  getShouldReconnectGemini,
  getShouldReconnectKraken,
} from "./utils";

type AccountProps = {
  account: BaseAccountWithCurrentJobFields;
  containerStyle?: CSSProperties;
};

const _Account = ({ account, containerStyle }: AccountProps) => {
  const { clientId } = useParams();
  const toast = useMyToast();
  const [renameAccount] = useMutation<{
    renameAccount: BaseAccountFields;
  }>(api.accounts.rename);

  const navigate = useNavigate();
  const { me } = useMe("cache-only");
  const { download } = useDownloadFile();
  const isSuperUser = me?.isSuperuser || false;

  const shouldReconnectCoinbase =
    account.provider === "coinbase" &&
    account.importType === ImportTypeEnum.Hatchfi;

  const [deleteAccount] = useMutation<{
    deleteAccount: BaseAccountFields;
  }>(api.accounts.delete);

  const [syncAccount, { loading: isSyncing }] = useMutation<{
    syncAccount: BaseAccountFields;
  }>(api.accounts.sync);

  const [downloadAccountFile] = useMutation<{
    downloadAccountFile: string;
  }>(api.accounts.downloadFile);

  const [hardRefreshAccount, { loading: isHardRefresh }] = useMutation<{
    hardRefreshAccount: BaseAccountFields;
  }>(api.accounts.hardRefresh);

  const [cancelImportAccount] = useMutation<
    Pick<Mutation, "cancelAccountImport">
  >(api.accounts.cancelImport);

  const reconnect = useHandleGetOAuthLink(clientId);
  const {
    groupModeOn,
    setAccounts: setAccountsGroup,
    accountsToGroup,
    client,
    magicEdenAccountIds,
    isEligibleForMagicEden,
  } = useContext(AccountsContext);

  const walletIsEligibleForMEDiscount = useMemo(() => {
    if (!isEligibleForMagicEden) return false;
    return magicEdenAccountIds.includes(account.id);
  }, [JSON.stringify(magicEdenAccountIds), account.id, isEligibleForMagicEden]);

  const _onClickRename = async () => {
    try {
      const newName = prompt(
        `What do you want to rename ${account.description}`,
        account.description
      );

      if (!newName || newName.length === 0) {
        throw Error("You must provide a name for the new wallet.");
      }

      const res = await renameAccount({
        variables: {
          accountId: account.id,
          newName,
        },
        refetchQueries: [],
      });

      toast.show({
        message: `Renamed wallet to ${newName}`,
        status: "success",
      });
    } catch (err) {
      toast.show({
        message: (err as Error).message || "An error occurred.",
        status: "error",
      });
    }
  };

  const _reconnectOauth = async () => {
    const link = await reconnect({
      provider: account.provider,
      name: account.provider,
    });

    localStorage.setItem("v1:oauth_link:account_id", account.id);

    if (link) window.location.href = link;
  };

  const _deleteAccount = async () => {
    try {
      if (
        window.confirm(
          `Are you sure you want to delete this account? This action is irreversible.`
        )
      ) {
        await deleteAccount({
          variables: {
            accountId: account.id,
          },
          refetchQueries: [
            api.clients.accounts,
            api.portfolio.get,
            api.transactions.countTransactions,
          ],
        });

        toast.show({
          message: `We are deleting your account. This may take a few minutes. When it is done, make sure to hit 'Recalculate' to update your transactions!`,
          status: "success",
        });
      } else return;
    } catch (err) {
      toast.show({
        message: (err as Error).message || "An error occurred.",
        status: "error",
      });
    }
  };

  const _syncAccount = async () => {
    try {
      await syncAccount({
        variables: {
          accountId: account.id,
          skipRecalculate: true,
        },
        refetchQueries: [api.clients.accounts, api.portfolio.get],
      });

      toast.show({
        message: `Syncing account.`,
        status: "success",
      });
    } catch (err) {
      toast.show({
        message: (err as Error).message || "An error occurred.",
        status: "error",
      });
    }
  };

  const _syncFromBeginningAccount = async () => {
    try {
      await syncAccount({
        variables: {
          accountId: account.id,
          skipRecalculate: true,
          // means we get it from the very beginning
          shouldGetLastImportedAt: false,
        },
        refetchQueries: [api.clients.accounts, api.portfolio.get],
      });

      toast.show({
        message: `Syncing account.`,
        status: "success",
      });
    } catch (err) {
      toast.show({
        message: (err as Error).message || "An error occurred.",
        status: "error",
      });
    }
  };

  const _downloadFile = async () => {
    try {
      const response = await downloadAccountFile({
        variables: {
          accountId: account.id,
        },
        refetchQueries: [],
      });

      toast.show({
        message: `Downloading file.`,
        status: "success",
      });

      const url = response?.data?.downloadAccountFile || "";

      download(url || "#");
    } catch (err) {
      toast.show({
        message: (err as Error).message || "An error occurred.",
        status: "error",
      });
    }
  };

  const _hardRefreshAccount = async (
    shouldApplyCurrentEdits: boolean,
    defaultMessage?: string
  ) => {
    try {
      const msg =
        defaultMessage ||
        (shouldApplyCurrentEdits
          ? `Are you sure you want to wipe all your transactions and only copy over your labels, notes, and purchase price adjustments?`
          : `Are you sure you want to wipe all your transactions? This action cannot be undone.`);

      const confirmation = window.confirm(msg);

      if (!confirmation) {
        return;
      }

      await hardRefreshAccount({
        variables: {
          accountId: account.id,
          shouldApplyCurrentEdits,
        },
        refetchQueries: [api.clients.accounts, api.portfolio.get],
      });

      toast.show({
        message: `Syncing account.`,
        status: "success",
      });
    } catch (err) {
      toast.show({
        message: (err as Error).message || "An error occurred.",
        status: "error",
      });
    }
  };

  const _cancelImportAccount = async () => {
    try {
      await cancelImportAccount({
        variables: {
          accountId: account.id,
        },
        fetchPolicy: "no-cache",
        refetchQueries: [api.clients.accounts, api.portfolio.get],
        awaitRefetchQueries: true,
      });

      toast.show({
        message: `Cancelling import...`,
        status: "success",
      });
    } catch (err) {
      toast.show({
        message: (err as Error).message || "An error occurred.",
        status: "error",
      });
    }
  };

  const _onClickAccount = () => {
    if (!groupModeOn) {
      navigate(getAccountLink(clientId || "", account.id));
      return;
    }

    const isInGroup = accountsToGroup.filter((a) => a.id === account.id).length;

    if (isInGroup) {
      const newAccounts = accountsToGroup.filter((a) => a.id !== account.id);
      setAccountsGroup(newAccounts);
      return;
    }

    const newAccounts = uniqBy([...accountsToGroup, account], (a) => a.id);

    setAccountsGroup(newAccounts);
  };

  const dispatch = useDispatch();
  const errorMessage = _getErrorMessage(account);

  const showLoadingAccount =
    account.status === AccountStatusEnum.Syncing && !account.errorMessage;

  const shouldShowRetry =
    !!errorMessage || account.shouldRecommendSyncing === true;

  const isAutomaticallySynced =
    account.importType === ImportTypeEnum.Hatchfi ||
    account.importType === ImportTypeEnum.Address ||
    account.importType === ImportTypeEnum.OauthToken;

  const isGrouped = useMemo(() => {
    return (
      accountsToGroup.filter((a) => a.id === account.id).length > 0 &&
      groupModeOn
    );
  }, [accountsToGroup, groupModeOn]);

  const shouldShowReconnect =
    account.status === AccountStatusEnum.Failed &&
    errorMessage?.includes("reconnect");

  const { background, border, secondaryBackground, text, theme, header } =
    useTheme();

  const isMagicEdenEligible = walletIsEligibleForMEDiscount;
  const hasClaimedMagicEden = client?.hasClaimedMagicEden ?? false;

  const isFree = account.isFree;

  return (
    <WhiteBox
      display="flex"
      alignItems="center"
      cursor="pointer"
      flexDir="column"
      position="relative"
      padding={{ base: "1.75rem 0.5rem", lg: "1.25rem 1rem" }}
      width="100%"
      h="100%"
      marginTop="0"
      bg={
        isGrouped
          ? theme === "light"
            ? colors.lightBlue110
            : colors.lightBlue10
          : isMagicEdenEligible
          ? theme === "light"
            ? colors.pink100
            : colors.pink10
          : isFree
          ? background
          : // theme === "light"
            //   ? `linear-gradient(to right, ${colors.lightBlue90}, ${colors.green110})`
            //   : `linear-gradient(to right, ${colors.lightBlue10}, ${colors.green05})`
            background
      }
      border={
        isGrouped
          ? `1px solid ${colors.primary}`
          : errorMessage
          ? `1px solid ${colors.red50}`
          : shouldReconnectCoinbase
          ? `1px solid ${colors.primary}`
          : isMagicEdenEligible
          ? `1px solid ${colors.pink50}`
          : isFree
          ? `1px solid ${border}`
          : `1px solid ${border}`
      }
      onClick={() => _onClickAccount()}
      style={containerStyle}
    >
      {isMagicEdenEligible && (
        <AwakenTooltip message="Awaken has partnered with Magic Eden to make any wallet that has traded on Magic Eden 100% free for the 2023 tax year! This wallet has used ME to trade NFTs, so it is 100% free.">
          <div
            style={{
              // magic eden pink:
              position: "absolute",
              top: -7,
              right: -7,
              zIndex: 100,
            }}
          >
            <Button
              onClick={(e) => {
                e.stopPropagation();
                dispatch(show("MagicEdenDiscountModal"));
              }}
              style={{
                height: 30,
                fontSize: 12,
                backgroundColor: "#e32575",
                color: "#FFF",
                borderRadius: 100,
              }}
            >
              {hasClaimedMagicEden ? "Claimed " : "Free "}Magic Eden{" "}
              <i
                style={{ marginLeft: 5 }}
                className={"fa-sharp fa-party-horn"}
              />
            </Button>
          </div>
        </AwakenTooltip>
      )}

      {isFree && (
        <AwakenTooltip
          openDelay={0}
          message={account.freeReason || "This wallet is free this year!"}
        >
          <div
            style={{
              position: "absolute",
              top: -5,
              right: -5,
              zIndex: 1,
            }}
          >
            <Button
              style={{
                height: 30,
                fontSize: 12,
                background: `linear-gradient(to right, rgb(31, 162, 255), rgb(77 246 147))`,
                color: "#fff",
                borderRadius: 0,
                fontWeight: "black",
              }}
              borderRadius={"5px !important"}
            >
              Free
              <i
                style={{ marginLeft: 5 }}
                className={"fa-sharp fa-party-horn"}
              />
            </Button>
          </div>
        </AwakenTooltip>
      )}
      <HStack alignItems="center" justifyContent="center" w="100%" h="100%">
        <div
          style={{
            width: 70,
            // background: "red",
            display: "flex",
            alignItems: "center",
            flexDirection: "column",
            justifyContent: "center",
            marginRight: "0.25rem",
          }}
        >
          <Image
            src={getImageKitCDNUrl(account.iconImageUrl, {
              width: 160,
              height: 160,
            })}
            w="3rem"
            h="3rem"
            borderRadius={100}
            borderColor={secondaryBackground}
            bg={secondaryBackground}
            flexShrink={0}
            marginBottom={"5px"}
          />
          {showLoadingAccount && (
            <Syncing
              showLoadingAccount={showLoadingAccount}
              etaFinishedAt={account.etaImportFinishedAt ?? null}
            />
          )}
        </div>
        <VStack flex={1} alignItems="flex-start" overflowY="hidden">
          <HStack flex={1} alignItems="center" marginTop="0.4rem">
            <AwakenTooltip message={account.description}>
              <Text
                isTruncated
                margin="0 !important"
                fontSize="md"
                fontWeight="medium"
                color={header}
              >
                {truncate(account.description || "", {
                  length:
                    account.importType === ImportTypeEnum.VirtualAccount
                      ? 40
                      : 20,
                  omission: "..",
                })}
              </Text>
            </AwakenTooltip>

            {/* {account.importType === ImportTypeEnum.VirtualAccount && (
              <StatusTag
                type="none"
                label="External"
                infoMessage="You have a staking or lending position at this address, or had one in the past."
              />
            )} */}
            {account.importType === ImportTypeEnum.Manual && (
              <StatusTag
                type="none"
                label="Manual"
                infoMessage="We will not automatically import transactions for this account."
              />
            )}
            {(account.importType === ImportTypeEnum.AwakenCsvFileUpload ||
              account.importType === ImportTypeEnum.FileUpload) && (
              <StatusTag
                type="none"
                label="CSV Upload"
                infoMessage="We imported the transactions for this account from the CSV file you uploaded."
              />
            )}

            {account.status === AccountStatusEnum.Queued && (
              <StatusTag
                type="none"
                label="Queued"
                infoMessage="We will automatically import this account soon ⏱."
              />
            )}

            {isAutomaticallySynced && <LastSyncedAtIcon account={account} />}

            <AccountWarning
              account={account}
              isMagicEden={isMagicEdenEligible}
            />
          </HStack>
          <AccountDescription
            account={account}
            isMagicEden={isMagicEdenEligible}
          />
          {(showLoadingAccount || shouldShowRetry) && (
            <HStack marginBottom="0.5rem">
              {shouldShowRetry && (
                <>
                  {errorMessage ? (
                    <>
                      <StatusTag
                        type={account.hasDoneInitialSync ? "warning" : "error"}
                        infoMessage={
                          account.hasDoneInitialSync
                            ? "This is a temporary error and should recover the next time the account is synced (which will happen every day, or you can the three dots and select the 'Sync Transactions' option). Error: " +
                              errorMessage
                            : "Try removing the account and re-adding if the issue persists. If that doesn't work, send us a message and we'll help you out! Error: " +
                              errorMessage
                        }
                        label={
                          account.hasDoneInitialSync
                            ? "Error syncing recent transactions"
                            : "Error Syncing"
                        }
                      />
                      {account.hasDoneInitialSync ? (
                        shouldShowReconnect ? (
                          <Touchable
                            onClick={async (e) => {
                              e.stopPropagation();
                              await _reconnectOauth();
                            }}
                            label="Reconnect"
                            iconName="fa-sharp fa-plug"
                          />
                        ) : (
                          <Touchable
                            onClick={async (e) => {
                              e.stopPropagation();
                              await _syncAccount();
                            }}
                            label="Retry Sync"
                            iconName="fa-sharp fa-sync"
                          />
                        )
                      ) : showLoadingAccount ? null : (
                        <Touchable
                          onClick={async (e) => {
                            e.stopPropagation();
                            await _hardRefreshAccount(true);
                          }}
                          label="Retry Import"
                          iconName="fa-sharp fa-sync"
                        />
                      )}
                    </>
                  ) : showLoadingAccount ? null : (
                    <Touchable
                      onClick={async (e) => {
                        e.stopPropagation();
                        await _hardRefreshAccount(true);
                      }}
                      bg={colors.pink100}
                      labelStyle={{ color: colors.pink40, fontSize: 14 }}
                      iconStyle={{ color: colors.pink40, fontSize: 14 }}
                      label="Retry Import"
                      _hover={{
                        bg: colors.pink90,
                        opacity: 0.9,
                      }}
                      iconName="fa-sharp fa-sync"
                    />
                  )}
                </>
              )}
            </HStack>
          )}

          <AccountMessage account={account} />
        </VStack>
        <Box>
          <ActionSheet
            popover={{ placement: "bottom-end", trigger: "click" }}
            content={{ width: 250 }}
            commands={[
              account.provider === "bitcoin"
                ? {
                    label: "View ordinals",
                    iconName: "fa-sharp fa-scribble",
                    link: `https://ordiscan.com/address/${account.walletAddress}`,
                  }
                : null,
              account.blockExplorerUrl
                ? {
                    label: "Open in block explorer",
                    iconName: "fa-sharp fa-network-wired",
                    iconImageSrc: account.iconImageUrl || "",
                    link: account.blockExplorerUrl ?? "",
                  }
                : null,
              {
                label: "Rename",
                iconName: "fa-sharp fa-pen",
                onClick: _onClickRename,
              },
              {
                label: "Sync new transactions",
                iconName: "fa-sharp fa-refresh",
                infoMessage:
                  "This will only sync new transactions, so it's much faster.",
                onClick: _syncAccount,
              },
              account.importType === ImportTypeEnum.OauthToken
                ? {
                    label: "Reconnect",
                    iconName: "fa-sharp fa-plug",
                    onClick: _reconnectOauth,
                  }
                : null,
              {
                label: "Copy ID",
                iconName: "fa-sharp fa-copy",
                onClick: () => {
                  navigator.clipboard.writeText(account.id || "");
                  toast.show({
                    message: "Copied to clipboard!",
                    status: "info",
                  });
                },
              },
              // isSuperUser
              //   ? {
              //       label: "Wipe and re-import",
              //       iconName: "fa-sharp fa-trash-can-arrow-up",
              //       onClick: () => _hardRefreshAccount(false),
              //       infoMessage:
              //         "This will delete all the transactions for the account and reimport them. It will remove all labels, notes, and any edits you made to proceeds or purchase price of assets.",
              //     }
              //   : null,
              isAllowedReimport(account) &&
              account?.status !== AccountStatusEnum.Syncing
                ? {
                    label: "Re-import",
                    iconName: "fa-sharp fa-rotate-exclamation",
                    onClick: () => _hardRefreshAccount(true),
                    infoMessage:
                      "This will delete all the transactions and re-import them, but copy over any labels, notes, or edits you made to proceeds or purchase price of assets.",
                  }
                : null,
              account?.status === AccountStatusEnum.Syncing
                ? {
                    label: "Cancel Import",
                    iconName: "fa-sharp fa-xmark",
                    onClick: () => _cancelImportAccount(),
                  }
                : null,
              me?.isSuperuser
                ? {
                    label: "Re-sync all transactions",
                    iconName: "fa-sharp fa-refresh",
                    infoMessage:
                      "This will sync transactions from the very beginning of the wallet. If some transactions were missed and our integration for the chain improved, those transactions may be pulled in. It can be pretty slow.",
                    onClick: _syncFromBeginningAccount,
                  }
                : null,
              account.fileObjectKey
                ? {
                    label: "Download File",
                    iconName: "fa-sharp fa-download",
                    onClick: _downloadFile,
                  }
                : null,
              {
                label: "Delete",
                iconName: "fa-sharp fa-trash",
                color: colors.red50,
                iconColor: colors.red50,
                hasDivider: true,
                onClick: _deleteAccount,
              },
            ].filter(hasValue)}
          >
            <Touchable iconName="fa-sharp fa-ellipsis-v" />
          </ActionSheet>
        </Box>
      </HStack>
      <AccountReimport
        onReimportClick={(canKeepUserEdits: boolean) =>
          _hardRefreshAccount(
            canKeepUserEdits,
            "Are you sure you want to update this account? It can take a few minutes to re-sync your data."
          )
        }
        account={account}
      />
    </WhiteBox>
  );
};

const Syncing = ({
  showLoadingAccount,
  etaFinishedAt,
}: {
  showLoadingAccount: boolean;
  etaFinishedAt: Maybe<Date>;
}) => {
  const theme = useTheme();
  const [finishedAtMessage, setFinishedAtMessage] = useState<string | null>("");

  const _getEtaMessage = () => {
    if (!etaFinishedAt) return "";

    const message = getEtaMessage(null, etaFinishedAt);

    return message;
  };

  useInterval(() => {
    const message = _getEtaMessage();
    setFinishedAtMessage(message);
  }, 1000);

  if (!showLoadingAccount) {
    return null;
  }

  return (
    <StatusTag
      type="none"
      label={finishedAtMessage || "Syncing"}
      labelStyle={{
        fontSize: 10,
        marginRight: 0,
      }}
      iconStyle={{
        marginLeft: 0,
      }}
      boxProps={{
        style: {
          padding: "3px",
        },
      }}
      rightIcon={<Spinner color={theme.header} size="xs" />}
      infoMessage={finishedAtMessage ? "Estimated time until finished." : ""}
    />
  );
};

const isAllowedReimport = (a: BaseAccountFields) => {
  if (a.importType === ImportTypeEnum.Address) return true;
  if (a.importType === ImportTypeEnum.FileUpload) return true;
  if (a.importType === ImportTypeEnum.Vezgo) return true;
  if (a.importType === ImportTypeEnum.OauthToken) return true;
  return false;
};

const _getErrorMessage = (account: BaseAccountFields) => {
  if (account.provider === "kraken" && account.errorMessage) {
    return (
      `Make sure you set permission for 'Query Funds', 'Query open orders & trades', 'Query closed orders & trades', 'Query ledger entries', and 'Export data'. ` +
      account.errorMessage
    );
  }

  return account.errorMessage || null;
};

const SLOW_IMPORT_PROVIDER = new Set([
  "coinbase",
  "coinbase_pro",
  "binance_us",
  "binance",
  "gemini",
  "kraken",
  "kucoin",
  // "solana",
]);

const AccountWarning = ({
  account,
  isMagicEden,
}: {
  account: BaseAccountFields;
  isMagicEden: boolean;
}) => {
  const hasSlowWarning =
    SLOW_IMPORT_PROVIDER.has(account.provider) &&
    !account.numberOfTransactions &&
    !account.hasDoneInitialSync &&
    (account.importType === ImportTypeEnum.Hatchfi ||
      account.importType === ImportTypeEnum.Address);

  if (account.importType === ImportTypeEnum.Manual) {
    return null;
  }

  if (account.status === AccountStatusEnum.Queued) {
    return null;
  }

  // don't show warning if the import just plain up failed
  if (account.status === AccountStatusEnum.Failed || !!account.errorMessage) {
    return null;
  }

  if (hasSlowWarning) {
    return (
      <StatusTag
        type="warning"
        label="Slow Import 🐌"
        infoMessage="Import may take 10+ minutes."
        className="progress-border-yellow"
      />
    );
  }

  // if (account.provider === "robinhood") {
  //   return (
  //     <StatusTag
  //       type="none"
  //       label="Only Crypto"
  //       infoMessage="We only import your Robinhood Crypto transactions (not stock or equities trades)."
  //     />
  //   );
  // }

  return null;
};

const AccountDescription = ({
  account,
  isMagicEden,
}: {
  account: BaseAccountFields;
  isMagicEden: boolean;
}) => {
  const errorMessage = _getErrorMessage(account);
  const { text } = useTheme();

  const showNumberOfTxns =
    !errorMessage &&
    account.importType !== ImportTypeEnum.VirtualAccount &&
    account.importType !== ImportTypeEnum.Manual;

  const isWallet = !!account.walletAddress;

  const addressUrl = account.blockExplorerUrl;
  const identifier = isWallet ? account.walletAddress : account.id;
  const isSyncing = account.status === AccountStatusEnum.Syncing;

  const _trunc2 = (num: number) => {
    if (new BigNumber(num).isNaN()) return 0;
    return numbro(num).format("0,0.[00000000]");
  };

  const _getDescription = () => {
    if (
      account.fileObjectKey &&
      account.startDate &&
      account.endDate &&
      !account.walletAddress
    ) {
      return (
        <HStack height="2rem">
          <Text color={text} margin="0 !important" fontSize="sm">
            {toLuxonUTC(new Date(account.startDate)).toFormat("LLL dd, yyyy")} -{" "}
            {toLuxonUTC(new Date(account.endDate)).toFormat("LLL dd, yyyy")}{" "}
            <Info message="The transactions in this spreadsheet are in between this date range." />
          </Text>
          <div style={{ marginLeft: 15 }}>
            <AccountNumTxns
              account={account}
              isSyncing={isSyncing}
              isMagicEden={isMagicEden}
              showNumberOfTxns={showNumberOfTxns}
            />
          </div>
        </HStack>
      );
    }

    const isAutomaticallySynced =
      account.importType === ImportTypeEnum.Hatchfi ||
      account.importType === ImportTypeEnum.Address;

    return (
      <HStack height="2rem">
        <Link
          color={colors.gray20}
          fontWeight={500}
          href={addressUrl || "#"}
          target="_blank"
          onClick={(e) => e.stopPropagation()}
        >
          <HStack height="2rem">
            <Text
              _hover={{
                color: colors.primary,
                textDecoration: "underline",
              }}
              color={text}
              margin="0 !important"
              fontSize="sm"
            >
              {truncateMiddle(identifier, 8, 8, "..")}
            </Text>
            <Copy value={identifier || ""} />
          </HStack>
        </Link>
        <AccountNumTxns
          isSyncing={isSyncing}
          account={account}
          showNumberOfTxns={showNumberOfTxns}
          isMagicEden={isMagicEden}
        />
      </HStack>
    );
  };

  return (
    <Box margin="0 !important">
      <Box>{_getDescription()}</Box>
      {account.provider === "bitcoin" && (
        <BitcoinDescription account={account} />
      )}
    </Box>
  );
};

const BitcoinDescription = ({ account }: { account: BaseAccountFields }) => {
  const toast = useMyToast();
  const [hardRefreshAccount, { loading: isHardRefresh }] = useMutation<{
    hardRefreshAccount: BaseAccountFields;
  }>(api.accounts.hardRefresh);
  const theme = useTheme();

  const _hardRefreshAccount = async (
    shouldApplyCurrentEdits: boolean,
    defaultMessage?: string
  ) => {
    try {
      const msg =
        defaultMessage ||
        (shouldApplyCurrentEdits
          ? `Are you sure you want to wipe all your transactions and only copy over your labels, notes, and purchase price adjustments?`
          : `Are you sure you want to wipe all your transactions? This action cannot be undone.`);

      const confirmation = window.confirm(msg);

      if (!confirmation) {
        return;
      }

      await hardRefreshAccount({
        variables: {
          accountId: account.id,
          shouldApplyCurrentEdits,
        },
        refetchQueries: [api.clients.accounts, api.portfolio.get],
      });

      toast.show({
        message: `Syncing account.`,
        status: "success",
      });
    } catch (err) {
      toast.show({
        message: (err as Error).message || "An error occurred.",
        status: "error",
      });
    }
  };

  return null;

  if (account.includeOrdinals) {
    return (
      <Text
        fontWeight={500}
        color={theme.text}
        margin="5px 0 10px 0 !important"
        fontSize="sm"
      >
        {!account.accountGroupId && (
          <span
            style={{
              color: theme.text,
              marginRight: 20,
              // fontStyle: "italic",
            }}
          >
            Group accounts?{" "}
            <Info
              style={{
                marginLeft: 0,
                color: theme.text,
              }}
              message="If you have multiple Bitcoin public keys for the same Bitcoin wallet (very common), you can click 'Group Accounts' in the top right to combine them into one wallet. This often fixes missing cost basis problems."
            />
          </span>
        )}
        {/* <Link
          target="_blank"
          onClick={(e) => e.stopPropagation()}
          color={theme.text}
          fontWeight={500}
          href={`https://ordiscan.com/address/${account.walletAddress}`}
          _hover={{
            textDecoration: "underline",
            color: colors.primary,
          }}
        >
          View ordinals <i className="fa-sharp fa-scribble" />
        </Link> */}
      </Text>
    );
  }

  return null;
  // return (
  //   <AwakenTooltip message="This will delete and re-import the account. You will lose any labeling you did.">
  //     <div>
  //       <Button
  //         variant="none"
  //         onClick={async (e) => {
  //           e.stopPropagation();
  //           _hardRefreshAccount(true);
  //         }}
  //         style={{ padding: "5px 10px", margin: "5px 0" }}
  //         height={30}
  //         bg={colors.gray90}
  //       >
  //         <Text
  //           fontWeight={500}
  //           color={colors.gray20}
  //           margin="0 !important"
  //           fontSize="sm"
  //         >
  //           Have ordinals? Click to import them{" "}
  //           <i className="fa-sharp fa-scribble" />
  //         </Text>
  //       </Button>
  //     </div>
  //   </AwakenTooltip>
  // );
};

const LastSyncedAtIcon = ({ account }: { account: BaseAccountFields }) => {
  const toast = useMyToast();
  const [message, setMessage] = useState("");
  const [syncAccount, { loading: isSyncing }] = useMutation<{
    syncAccount: BaseAccountFields;
  }>(api.accounts.sync);

  const theme = useTheme();

  const _syncAccount: React.MouseEventHandler<HTMLDivElement> = async (e) => {
    try {
      e.stopPropagation();
      e.preventDefault();
      if (isSyncing) return;

      await syncAccount({
        variables: {
          accountId: account.id,
          skipRecalculate: true,
        },
        refetchQueries: [api.clients.accounts, api.portfolio.get],
      });

      toast.show({
        message: `Syncing account.`,
        status: "success",
      });
    } catch (err) {
      toast.show({
        message: (err as Error).message || "An error occurred.",
        status: "error",
      });
    }
  };

  useInterval(() => {
    const lastSyncedMessage = account.lastSyncedAt
      ? toLuxonUTC(new Date(account.lastSyncedAt)).toRelative({
          style: "short",
        })
      : "";
    setMessage(lastSyncedMessage || "");
  }, 1000);

  if (!message) return null;
  if (!account.lastSyncedAt) return null;
  // if syncing or queued don't render this thing
  if (account.status === AccountStatusEnum.Syncing) return null;
  if (account.status === AccountStatusEnum.Queued) return null;
  if (isSyncing) return null;
  if (!account.hasDoneInitialSync) return null;

  return (
    <>
      <AwakenTooltip message={"Last synced " + message + "."}>
        <div>
          <HStack
            // onClick={_syncAccount} // FIXME: people are clicking this too often
            style={{ marginLeft: 5, color: theme.theme }}
          >
            <i
              style={{
                margin: "0 5px",
                marginRight: 0,
                fontSize: 10,
                color: theme.text,
              }}
              className="fa-sharp fa-sync"
            />
            <Text
              style={{
                fontSize: 13,
                marginRight: 2,
                fontWeight: "500",
                color: theme.text,
              }}
              fontSize="sm"
            >
              {message.replace("ago", "").replace(".", "")}
            </Text>
          </HStack>
        </div>
      </AwakenTooltip>
    </>
  );
};

const AccountReimport = ({
  account,
  onReimportClick,
}: {
  account: BaseAccountFields;
  onReimportClick: (keepUserCurrentEdits: boolean) => Promise<void>;
}) => {
  const canReImport = isAllowedReimport(account);
  const isOutOfDate =
    account.integrationStatus === AccountIntegrationStatusEnum.OutOfDate;
  const isSyncing = account.status === AccountStatusEnum.Syncing;
  const theme = useTheme();

  // force them to include ordinals
  if (account.provider === "bitcoin" && !account.includeOrdinals) {
    return (
      <Box margin="0 !important" w="100%">
        <HStack
          display="flex"
          flexWrap="wrap"
          borderRadius={5}
          marginTop="0.5rem"
          padding="0.15rem 0.15rem 0.15rem 0.5rem"
          justifyContent={"flex-start"}
          alignItems="center"
          w="100%"
          bg={theme.theme === "light" ? colors.green100 : colors.green10}
          border={"1px solid " + colors.green50}
        >
          <Text fontWeight="bold" flex={1} fontSize="xs" color={colors.green50}>
            Updates for {Helpers.capitalize(account.provider)}{" "}
            <Info
              message={
                "Your labels and notes will unfortunately be deleted (just this once). We are improving our Bitcoin support and it will require a hard re-import, but after this it won't happen again."
              }
            />
          </Text>

          <Touchable
            onClick={async (e) => {
              e.stopPropagation();
              await onReimportClick(false); // force the txns to be wiped bc we need to migrate
            }}
            label="Update Now"
            borderRadius={5}
            // borderTopLeftRadius={0}
            // borderBottomLeftRadius={0}
            labelStyle={{
              color: colors.white,
              fontSize: 12,
            }}
            iconStyle={{ color: colors.white }}
            // iconName="fa-sharp fa-square-up"
            _hover={{
              bg: colors.green40 + " !important",
            }}
            style={{
              backgroundColor: colors.green50,
              color: colors.white,
            }}
          />
        </HStack>
      </Box>
    );
  }

  if (!canReImport || !isOutOfDate || isSyncing) {
    return null;
  }

  return (
    <Box margin="0 !important" w="100%">
      <HStack
        display="flex"
        flexWrap="wrap"
        borderRadius={5}
        marginTop="0.5rem"
        padding="0.15rem 0.15rem 0.15rem 0.5rem"
        justifyContent={"flex-start"}
        alignItems="center"
        w="100%"
        bg={theme.theme === "light" ? colors.green100 : colors.green10}
        border={"1px solid " + colors.green50}
      >
        <Text flex={1} fontWeight="bold" fontSize="xs" color={colors.green50}>
          Updates for {Helpers.capitalize(account.provider)}{" "}
          <Info
            message={`${
              account.integrationOutOfDateMessage
                ? account.integrationOutOfDateMessage + ". "
                : ""
            }Awaken is always improving our integrations. By updating, you'll get all the latest integration improvements. We'll copy over all your labels, notes, and basis adjustments.`}
          />
        </Text>

        <Touchable
          onClick={async (e) => {
            e.stopPropagation();
            await onReimportClick(true);
          }}
          label="Update Now"
          borderRadius={5}
          // borderTopLeftRadius={0}
          // borderBottomLeftRadius={0}
          labelStyle={{
            color: colors.white,
            fontSize: 12,
          }}
          iconStyle={{ color: colors.white }}
          // iconName="fa-sharp fa-square-up"
          _hover={{
            bg: colors.green40 + " !important",
          }}
          style={{
            backgroundColor: colors.green50,
            color: colors.white,
          }}
        />
      </HStack>
    </Box>
  );
};

const AccountNumTxns = ({
  account,
  showNumberOfTxns,
  isSyncing,
  isMagicEden,
}: {
  account: BaseAccountFields;
  showNumberOfTxns: boolean;
  isSyncing: boolean;
  isMagicEden: boolean;
}) => {
  if (!showNumberOfTxns) {
    return null;
  }

  if (
    isSyncing &&
    (isNil(account.numberOfTransactions) || account.numberOfTransactions === 0)
  ) {
    return null;
  }

  return (
    <StatusTag
      type={isMagicEden ? "beta" : "info"}
      infoMessage="Number of transactions."
      boxProps={{
        style: {
          padding: "1px 10px",
          borderRadius: 100,
          border: isMagicEden
            ? `1px solid ${colors.pink50}`
            : `1px solid ${colors.lightBlue50}`,
        },
      }}
      label={
        <>
          <NumberOfTransactions
            showAnimation={isSyncing}
            count={account.numberOfTransactions ?? null}
          />

          <i
            style={{ marginLeft: 7, fontSize: 12 }}
            className="fa-sharp fa-sharp fa-arrow-right-arrow-left"
          />
        </>
      }
    />
  );
};

const AccountMessage = ({ account }: { account: BaseAccountFields }) => {
  const { clientId } = useParams<{ clientId: string }>();
  const { text } = useTheme();

  const reconnect = useHandleGetOAuthLink(clientId);
  const linkVezgo = useHandleVezgoLink(clientId);
  const [deleteAccount] = useMutation<{
    deleteAccount: BaseAccountFields;
  }>(api.accounts.delete);

  const _deleteAccount = async () => {
    try {
      await deleteAccount({
        variables: {
          accountId: account.id,
        },
        refetchQueries: [
          api.clients.accounts,
          api.portfolio.get,
          api.transactions.countTransactions,
        ],
      });
    } catch (err) {
      console.error(err);
    }
  };

  const _reconnectOauth = async () => {
    const link = await reconnect({
      provider: account.provider,
      name: account.provider,
    });

    localStorage.setItem("v1:oauth_link:account_id", account.id);

    if (link) window.location.href = link;
  };

  const _reconnectVezgo = async () => {
    await _deleteAccount();

    linkVezgo(
      { name: "Kraken" },
      {
        vezgoProvider: "kraken",
      }
    );
  };

  const shouldReconnectCoinbase = getShouldReconnectCoinbase(account);

  const shouldReconnectKraken = getShouldReconnectKraken(account);

  const shouldReconnectGemini = getShouldReconnectGemini(account);
  const theme = useTheme();

  let Component = null;

  if (shouldReconnectCoinbase) {
    Component = (
      <Tooltip label="We have rebuilt our Coinbase integration so that we can pull in your transactions in real-time. We'll be removing older Coinbase connections in a few months, so make sure to reconnect it now!">
        <div>
          <Touchable
            label="Reconnect"
            iconName="fa-sharp fa-plug"
            bg={theme.theme === "light" ? colors.red100 : colors.red10}
            border={`1px solid ${colors.red50}`}
            _hover={{
              bg: theme.theme === "light" ? colors.red90 : colors.red20,
            }}
            iconStyle={{ color: colors.red50 }}
            labelStyle={{ color: colors.red50 }}
            onClick={async (e) => {
              e.stopPropagation();
              await _reconnectOauth();
            }}
          />
        </div>
      </Tooltip>
    );
  }

  if (shouldReconnectKraken) {
    Component = (
      <Tooltip label="You must migrate your Kraken account. We will be removing Kraken accounts within the coming months.">
        <div>
          <Touchable
            label="Reconnect"
            iconName="fa-sharp fa-plug"
            bg={theme.theme === "light" ? colors.red100 : colors.red10}
            border={`1px solid ${colors.red50}`}
            _hover={{
              bg: theme.theme === "light" ? colors.red90 : colors.red20,
            }}
            iconStyle={{ color: colors.red50 }}
            labelStyle={{ color: colors.red50 }}
            onClick={async (e) => {
              e.stopPropagation();
              await _reconnectVezgo();
            }}
          />
        </div>
      </Tooltip>
    );
  }

  if (shouldReconnectGemini) {
    Component = (
      <Tooltip label="You must migrate your Gemini account. We will be removing Gemini accounts within the coming months.">
        <div>
          <Touchable
            label="Reconnect"
            iconName="fa-sharp fa-plug"
            bg={theme.theme === "light" ? colors.red100 : colors.red10}
            border={`1px solid ${colors.red50}`}
            _hover={{
              bg: theme.theme === "light" ? colors.red90 : colors.red20,
            }}
            iconStyle={{ color: colors.red50 }}
            labelStyle={{ color: colors.red50 }}
            onClick={async (e) => {
              e.stopPropagation();
              await _reconnectVezgo();
            }}
          />
        </div>
      </Tooltip>
    );
  }

  if (!Component) {
    return null;
  }

  return <HStack marginBottom="0.5rem">{Component}</HStack>;
};

export const AccountRow = React.memo(_Account, (prev, next) =>
  isEqual(
    pick(prev.account, ACCOUNT_FIELDS),
    pick(next.account, ACCOUNT_FIELDS)
  )
);
