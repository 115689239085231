import { ApolloError, useMutation } from "@apollo/client";
import { Box, HStack, Image, Text, VStack } from "@chakra-ui/react";
import { getAssociatedTokenAddress } from "@solana/spl-token";
import { PublicKey } from "@solana/web3.js";
import BigNumber from "bignumber.js";
import _, { noop } from "lodash";
import { isNil } from "lodash/fp";
import numbro from "numbro";
import { useContext, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Link, useParams } from "react-router-dom";
import { show } from "redux-modal";
import { api } from "src/api";
import {
  BaseFullTransactionFields,
  BaseTransferFieldsV2,
  PartialAccountFields,
  PartialAssetFields,
} from "src/api/fragments";
import {
  AssetTypeEnum,
  CurrencyCodeEnum,
  ImportTypeEnum,
  IsDirtyEnum,
  MutationUpdateTransferArgs,
} from "src/api/generated/types";
import RedSearch from "src/assets/awaken/icons/red-search.png";
import { ActionSheet, AwakenTooltip } from "src/components/styled";
import { Command } from "src/components/styled/ActionSheet/Command";
import { AssetIcon } from "src/components/styled/Assets";
import StatusTag from "src/components/styled/StatusTag";
import { Touchable } from "src/components/Touchable";
import { Warning } from "src/components/Warning";
import { ActiveTransactionContext } from "src/context";
import { Maybe, hasValue } from "src/core";
import { useClientById, useMyToast } from "src/hooks";
import { useTheme } from "src/hooks/useTheme";
import { getAssetOnMarketplaceOrCoingeckoV2 } from "src/modules/getAssetUrl";
import { getAssetSymbolOrName } from "src/modules/ledger/assets";
import { colors } from "src/theme";
import { D, getCurrencySymbol } from "src/utils/helpers";
import { isInternalTransfer } from "src/utils/labels";
import { getAssetKeyForUrl } from "src/views/client/Harvest/Assets/utils";
import { PROVIDER_TO_LOGO_URL } from "../../AccountModal/constants";
import { CreditFinancialInfo } from "../components/CreditFinancialInfo";
import { InternalTransferLinks } from "../components/InternalTransferLinks";
import { formatCryptoAmount, getTransformedRawAsset } from "../utils";
import { TransferType, TransferWithExtraInfo } from "./TransferForm/utils";

export const TransferComponent = ({
  transaction,
  transfer,
  type,
  provider,
  isInternal,
  index,
}: {
  transaction: Maybe<BaseFullTransactionFields>;
  transfer: TransferWithExtraInfo;
  type: Maybe<TransferType>;
  provider: string;
  isInternal: boolean;
  index: number;
}) => {
  const { clientId } = useParams();
  const [isOpen, setOpen] = useState(false);
  const { client } = useClientById(clientId, {
    onlyFetchClient: true,
    clientFetchPolicy: "cache-first",
  });

  const [updateTransfer] = useMutation(api.transactions.updateTransfer);
  const asset = (transfer.fullAsset ||
    getTransformedRawAsset(transfer.rawAsset)) as PartialAssetFields;
  const contractUrl =
    provider === "hyperliquid" ? null : asset?.blockExplorerUrl;

  const cryptoAmountFormatted = formatCryptoAmount(transfer.value, asset);
  const transferValue = _getValue(isInternal, type, cryptoAmountFormatted);

  // if is internal -> use the debit / credit amount. only one will be set so just use the one that is not null
  const defaultFiatAmount = isInternal
    ? transfer.assetDebitFiatAmount ?? transfer.assetCreditFiatAmount
    : 0;
  const _fiatAmountFormatted = !isNil(transfer.fiatAmountCents)
    ? D(transfer.fiatAmountCents, client?.currency || "USD")
    : D(defaultFiatAmount ?? null, client?.currency || "USD");

  const isNotClean = transaction?.isDirty !== IsDirtyEnum.Clean;

  const _onEditTransfer = () => {
    // open it up in transfer edit modal
    dispatch(
      show("TransferModal", {
        transfer,
        field: "snapshot",
        mode: "editing",
        startingFiatValue: fiatAmountFormatted.getAmount(),
        transaction,
      })
    );
  };

  const _removeTransfer = async () => {
    try {
      const variables: MutationUpdateTransferArgs = {
        transactionId: transaction?.id || "",
        transferId: transfer.id,
        isHidden: true,
      };

      await updateTransfer({
        variables,
        fetchPolicy: "no-cache",
        refetchQueries: [
          {
            query: api.transactions.retrieve,
            variables: { transactionId: transaction?.id },
          },
          {
            query: api.graph.getReplayInfo,
            variables: { clientId },
          },
        ],
        awaitRefetchQueries: true,
      });

      toast.show({
        status: "success",
        message:
          "Transfer removed! You will have to recalculate to show updated gain/loss.",
      });
    } catch (err) {
      if (err instanceof ApolloError) {
        toast.show({
          status: "error",
          message: err.message,
        });
      }
    }
  };

  const _editAmount = async () => {
    try {
      // ask them what amount they want it to be
      const newAmount = window.prompt(
        "What would you like the new transfer amount to be?",
        transfer.value?.toString()
      );

      // check that amount is a valid number
      if (!newAmount) return;
      if (new BigNumber(newAmount).isNaN()) {
        toast.show({
          status: "error",
          message: "Invalid number",
        });
        return;
      }

      const number = new BigNumber(newAmount);

      const variables: MutationUpdateTransferArgs = {
        transactionId: transaction?.id || "",
        transferId: transfer.id,
        amount: number.toNumber(),
      };

      await updateTransfer({
        variables,
        fetchPolicy: "no-cache",
        refetchQueries: [api.transactions.retrieve, api.graph.getReplayInfo],
        awaitRefetchQueries: true,
      });

      toast.show({
        status: "success",
        message: "Transfer amount edited!",
      });
    } catch (err) {
      if (err instanceof ApolloError) {
        toast.show({
          status: "error",
          message: err.message,
        });
      }
    }
  };

  // show the user inputted fiat amount if the txn isn't clean. otherwise we show what
  // is actually in our ledger. this is usually the same, but sometimes it can be different
  // ex. if you label something as wallet transfer where you receive assets, we will pour the txn fees
  // onto the assets. FIXME: we might want to change this later but too hard / annoying right now
  const fiatAmountFormatted = isNotClean
    ? _fiatAmountFormatted
    : D(
        transfer.assetDebitFiatAmount || 0,
        transaction?.fiatCurrency || CurrencyCodeEnum.Usd
      );

  const theme = useTheme();
  const assetKey = getAssetKeyForUrl(transfer?.fullAsset ?? null);

  const color = _getColorForTransferType(isInternal, type, theme.header);

  const dispatch = useDispatch();
  const showEdit = _shouldShowEdit(transaction, transfer);
  const isSent = type === "sent";
  const isReceived = type === "received";
  const symbolOrName = getAssetSymbolOrName(asset, 8, true);

  // block explorer name
  const hostname = contractUrl ? new URL(contractUrl).hostname : "";
  const blockExplorerName =
    hostname === "" ? "Explorer" : _.startCase(hostname.split(".")[0]);
  const assetInfoUrl = getAssetOnMarketplaceOrCoingeckoV2(asset);

  const isLabelledInternalTransfer = isInternalTransfer(
    transaction?.labelUsed || ""
  );
  const hasDebitValue = !isNil(transfer.assetDebitFiatAmount);
  const isEditableInternalTransfer =
    isLabelledInternalTransfer && hasDebitValue;

  // we don't want internal transfers that are just labelled that way (wallet transfers)
  // showing the transfer calculation because it isn't relevant
  const showInternalTransferLinks = isInternal && !isEditableInternalTransfer;
  const toast = useMyToast();
  const isSpam = shouldShowSpamWarning(transaction, transfer);
  const { header, text, border, background } = useTheme();

  return (
    <HStack w="100%" alignItems="start" padding="0.25rem 1rem 0.25rem 0">
      <AssetIcon asset={asset} size={30} />

      <Box flex={2}>
        <HStack alignItems="flex-start" marginTop="0">
          <VStack style={{ flex: 1, alignItems: "flex-start" }}>
            <Link to={`/clients/${clientId}/transactions?assetKey=${assetKey}`}>
              <Text
                _hover={{
                  textDecoration: "underline",
                }}
                fontSize="md"
                color={color}
              >
                <strong>{transferValue}</strong> {symbolOrName}
              </Text>
            </Link>

            <PricePerToken transfer={transfer} />
          </VStack>

          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-end",
            }}
          >
            {/* <Touchable
              iconName="fa-sharp fa-pen"
              iconPosition="right"
              style={{
                marginRight: 10,
              }}
              onClick={_onEditTransfer}
            /> */}

            {/* <Touchable
              iconName="fa-sharp fa-trash"
              iconPosition="right"
              iconStyle={{
                color: colors.red50,
              }}
              labelStyle={{ color: colors.red50 }}
              style={{
                color: colors.red50,
                marginRight: 10,
              }}
              onClick={_removeTransfer}
            /> */}

            <ActionSheet
              popover={{
                placement: "bottom",
                trigger: "hover",
                isOpen,
                onOpen: () => setOpen(true),
                onClose: () => setOpen(false),
              }}
              commands={[
                {
                  label: `Edit transfer`,
                  iconName: "fa-sharp fa-pen",
                  onClick: () => {
                    dispatch(
                      show("TransferModal", {
                        transfer,
                        field: "snapshot",
                        mode: "editing",
                        startingFiatValue: fiatAmountFormatted.getAmount(),
                        transaction,
                      })
                    );
                  },
                },
                {
                  label: `Edit asset`,
                  iconName: "fa-sharp fa-coin",
                  onClick: () => {
                    dispatch(
                      show("AssetModal", {
                        asset: asset,
                        provider: asset.provider,
                        mode: "editing",
                        onUpdate: noop,
                      })
                    );
                  },
                },
                {
                  label: `Remove this transfer`,
                  iconColor: colors.red50,
                  iconName: "fa-sharp fa-trash",
                  onClick: _removeTransfer,
                },
                // {
                //   // hasDivider: true,
                //   label: `View ${
                //     transfer.fullAsset?.symbol || "asset"
                //   } transactions`,
                //   link: getLink(clientId || "", {
                //     assetIds: transfer.fullAsset?.id
                //       ? [transfer.fullAsset.id]
                //       : undefined,
                //   }),
                //   iconName: "fa-sharp fa-exchange-alt",
                // },
                // assetKey
                //   ? {
                //       label: "Open asset details",
                //       link: assetDetailLink,
                //       iconName: "fa-sharp fa-chart-candlestick",
                //     }
                //   : null,
                // {
                //   label: "Copy asset ID",
                //   onClick: () => {
                //     navigator.clipboard.writeText(transfer.fullAsset?.id || "");
                //     toast.show({
                //       status: "info",
                //       message: "Copied to clipboard!",
                //     });
                //   },
                //   iconName: "fa-sharp fa-copy",
                // },
                contractUrl
                  ? {
                      hasDivider: true,
                      label: `View asset on ${blockExplorerName}`,
                      link: contractUrl,
                      iconImageStyle: { borderRadius: 100 },
                      iconImageSrc: PROVIDER_TO_LOGO_URL[provider] || RedSearch,
                    }
                  : null,
                ...assetInfoUrl.map((i) => ({
                  label: i.label,
                  link: i.url,
                  iconImageStyle: { borderRadius: 100 },
                  iconImageSrc: i.imageUrl,
                })),
                !!asset.contractAddress &&
                asset.type === AssetTypeEnum.FungibleToken &&
                !asset.coinGeckoTokenId &&
                asset.provider === "solana"
                  ? {
                      link: `https://www.dexscreener.com/solana/${asset.contractAddress}`,
                      label: "View on Dexscreener",
                      iconImageStyle: { borderRadius: 100 },
                      iconImageSrc:
                        "https://assets.awaken.tax/icons/dexscreener.png",
                    }
                  : null,
                transaction?.provider === "solana"
                  ? {
                      label: "Solana", // not used
                      component: (
                        <SolanaTokenAccountInfo
                          transfer={transfer}
                          provider={transaction?.provider || ""}
                        />
                      ),
                    }
                  : null,
              ].filter(hasValue)}
            >
              <Touchable
                iconName="fa-sharp fa-chevron-down"
                iconStyle={{
                  color: header,
                }}
                labelStyle={{
                  color: header,
                }}
                label="More"
                style={{
                  color: header,
                  opacity: 0.8,
                }}
              />
            </ActionSheet>
          </div>
        </HStack>

        {/* only show the fiat amount override for received assets with a value */}
        {fiatAmountFormatted && showEdit && (
          <HStack alignItems="center" spacing={0} w="100%" margin="0.5rem 0">
            <Text
              flex={1}
              padding="0.4rem 0"
              style={{
                fontSize: 14,
                fontWeight: "normal",
              }}
              color={header}
            >
              Cost Basis
            </Text>

            <Text
              flex={1}
              style={{
                textAlign: "right",
                fontSize: 14,
                fontWeight: 500,
                marginRight: 5,
              }}
              color={header}
            >
              {fiatAmountFormatted.toFormat()}
            </Text>

            <div>
              <Touchable
                onClick={() =>
                  dispatch(
                    show("TransferModal", {
                      transfer,
                      field: "snapshot",
                      mode: "editing",
                      startingFiatValue: fiatAmountFormatted.getAmount(),
                      transaction,
                    })
                  )
                }
                iconName="fa-sharp fa-pen"
                iconPosition="right"
              />
            </div>
          </HStack>
        )}

        {isSpam && isReceived && (
          <Warning
            marginTop="1rem"
            marginBottom="1rem"
            message={
              <>
                We do not use your edited value for transactions labeled "Spam".
                If you would like us to use it, label this transaction.
              </>
            }
            width="95%"
          />
        )}

        {isSent && (
          <CreditFinancialInfo
            transfer={transfer}
            fiatCurrency={transaction?.fiatCurrency ?? CurrencyCodeEnum.Usd}
            transaction={transaction}
            proceeds={transfer.proceeds ?? null}
            basis={transfer.basis ?? null}
            profit={transfer.profit ?? null}
            assetCreditId={transfer.assetCreditId ?? null}
            timezone={client?.timezone || "UTC"}
          />
        )}

        {showInternalTransferLinks && (
          <InternalTransferLinks
            transfer={transfer}
            transaction={transaction}
            assetCreditId={transfer.assetCreditId ?? null}
            timezone={client?.timezone || "UTC"}
          />
        )}

        {/* {subText && <Text fontSize="sm">{subText}</Text>} */}

        <HStack marginTop="0.5rem">
          <TransferEntities transfer={transfer} />
        </HStack>

        {/* <AssetContractLink
          contractUrl={contractUrl || null}
          transfer={transfer}
          provider={provider}
        /> */}
      </Box>
    </HStack>
  );
};

const shouldShowSpamWarning = (
  t: Maybe<BaseFullTransactionFields>,
  transfer: Maybe<TransferWithExtraInfo>
) => {
  try {
    if (!t || !transfer) return false;
    const isMaybeSpam =
      (t.labelUsed || "").includes("spam") ||
      (t.autoReviewReason || "").includes("spam");

    // if is span + transfer has an amount -> we need to do a warning
    if (isMaybeSpam && transfer.fiatAmountCents) return true;

    return false;
  } catch (err) {
    return false;
  }
};

type ViewOptionParams = {
  src: string;
  title: string;
  link: string;
  blacken?: boolean;
};

const ViewOption = ({ src, title, link, blacken }: ViewOptionParams) => (
  <HStack
    w="100%"
    padding="0.5rem 1rem"
    cursor="pointer"
    onClick={() => {
      window.open(link, "_blank");
    }}
    _hover={{
      bg: colors.gray90,
    }}
  >
    <Box w="2rem" display="flex" justifyContent={"center"} alignItems="center">
      <Image
        w="1.2rem"
        h="1.2rem"
        borderRadius={3}
        src={src}
        objectFit="contain"
        filter={blacken ? "brightness(0%)" : undefined}
      />
    </Box>

    <Text fontSize="sm" w="100%">
      {title}
    </Text>
  </HStack>
);

const SolanaTokenAccountInfo = ({
  transfer,
  provider,
}: {
  provider: string;
  transfer: TransferWithExtraInfo;
}) => {
  const isSol = provider === "solana";
  const mint = transfer.rawAsset?.contractAddress;
  const address =
    transfer.transferType === "sent"
      ? transfer.fromAddress
      : transfer.toAddress;

  const [tokenAddress, setTokenAddress] = useState("");

  useEffect(() => {
    if (!mint) return;
    if (!address) return;
    if (provider !== "solana") return;

    (async () => {
      const account = await getAssociatedTokenAddress(
        new PublicKey(mint),
        new PublicKey(address)
      );
      setTokenAddress(account.toBase58());
    })();
  }, [mint, address, provider]);

  if (!isSol || !mint) {
    return null;
  }

  if (!tokenAddress) {
    return null;
  }

  const url = `https://solscan.io/account/${tokenAddress}`;

  return (
    <Command
      iconImageSrc={`https://assets.awaken.tax/icons/solscan.png`}
      label={`View token account`}
      link={url}
    />
  );
};

const HyperliquidAccountInfo = ({
  transfer,
  provider,
}: {
  provider: string;
  transfer: TransferWithExtraInfo;
}) => {
  const isHyperliquid = provider === "hyperliquid";
  const blockExplorerUrl = transfer?.fullAsset?.blockExplorerUrl || "";

  if (!isHyperliquid || !blockExplorerUrl) {
    return null;
  }

  return (
    <Command
      iconImageSrc={`https://assets.awaken.tax/icons/hypurrscan.png`}
      label={`View on Hypurrscan`}
      link={blockExplorerUrl}
    />
  );
};

const _shouldShowEdit = (
  txn: Maybe<BaseFullTransactionFields>,
  transfer: TransferWithExtraInfo
) => {
  const type = transfer.transferCategory;
  const isFiat = transfer.fullAsset?.type === AssetTypeEnum.FiatCurrency;
  const hasDebit = !isNil(transfer.assetDebitFiatAmount);

  if (isFiat) return false;

  // if received, always show the edit for created by
  if (type === "received" && transfer.isCreatedByUser) return true;

  // if (transfer.isCreatedByUser) return true;

  // if there is no asset debit or credit for this transfer, it means
  // there is no ledger entries for it. so we shouldn't let them edit the price bc it won't do anything
  // ex. Coinbase staking receive, where the transfer just doesn't have an asset
  if (!transfer.assetDebitId && !transfer.assetCreditId) {
    return false;
  }

  // you can edit the value if it is received OR it has a type of internal BUT it only has a to account
  // this happens when we label a txn as an IXfer, but don't actually have a to account for it (so it is a receive from an outside place,
  // and we might need to edit the basis for it because the basis is transferred)
  const isReceived =
    type === "received" ||
    (type === "internal" &&
      !transfer.fromAccountId &&
      transfer.toAccountId &&
      hasDebit) ||
    (type === "split_internal" && hasDebit);
  // || (type === "received_from_virtual" && hasDebit);

  // always let them edit it for now. was causind weird cases, ex. labeled as receive and want to edit it.
  // return true;

  // if it was just labelled as wallet transfer and it is a debit, let them edit it
  if (isInternalTransfer(txn?.labelUsed || null) && hasDebit) {
    return true;
  }

  return isReceived;
};

const _getAccountIfNotVirtual = (acct: PartialAccountFields) => {
  if (acct?.importType === ImportTypeEnum.VirtualAccount) return null;
  return acct;
};

const TransferEntities = ({ transfer }: { transfer: BaseTransferFieldsV2 }) => {
  const fromAccount = _getAccountIfNotVirtual(transfer.fromAccount);
  const toAccount = _getAccountIfNotVirtual(transfer.toAccount);
  const hasFromWallet = !!fromAccount?.walletAddress;
  const hasToWallet = !!toAccount?.walletAddress;
  const { clientId } = useParams();
  const toast = useMyToast();

  return (
    <HStack>
      {fromAccount && (
        <StatusTag
          label={"From: " + fromAccount?.description}
          rightIcon={
            <ActionSheet
              popover={{ trigger: "hover" }}
              content={{ width: 250 }}
              commands={[
                {
                  label: "View transactions",
                  link: `/clients/${clientId}/transactions?accountIds=${fromAccount.id}`,
                  iconName: "fa-sharp fa-exchange-alt",
                },
                hasFromWallet
                  ? {
                      label: "Copy wallet address",
                      onClick: () => {
                        navigator.clipboard.writeText(
                          fromAccount?.walletAddress || ""
                        );
                        toast.show({
                          status: "info",
                          message: "Copied to clipboard!",
                        });
                      },
                      iconName: "fa-sharp fa-copy",
                    }
                  : null,
                hasFromWallet && fromAccount.blockExplorerUrl
                  ? {
                      label: "Open in block explorer",
                      link: fromAccount.blockExplorerUrl || "",
                      iconImageSrc:
                        PROVIDER_TO_LOGO_URL[fromAccount.provider] ?? "",
                    }
                  : null,
                hasFromWallet &&
                fromAccount.blockExplorerUrl &&
                fromAccount.provider === "bitcoin"
                  ? {
                      label: "Open in Ordiscan",
                      link: `https://ordiscan.com/address/${fromAccount.walletAddress}`,
                      iconImageSrc: `https://assets.awaken.tax/icons/ordiscan.png`,
                      iconImageStyle: { borderRadius: 10 },
                    }
                  : null,
                // hasFromWallet
                //   ? {
                //       label: "Copy account ID",
                //       onClick: () => {
                //         navigator.clipboard.writeText(fromAccount?.id || "");
                //         toast.show({
                //           status: "info",
                //           message: "Copied to clipboard!",
                //         });
                //       },
                //       iconName: "fa-sharp fa-copy",
                //     }
                //   : null,
                // {
                //   label: "Copy account ID",
                //   onClick: () =>
                //     navigator.clipboard.writeText(fromAccount?.id || ""),
                //   iconName: "fa-sharp fa-copy",
                // },
              ].filter(hasValue)}
            >
              <Touchable iconName="fa-sharp fa-ellipsis-v" />
            </ActionSheet>
          }
          type="none"
          boxProps={{ style: { maxWidth: "12rem" } }}
          // infoMessage={`Transferred from ${fromAccount.description}${
          //   fromAccount.walletAddress &&
          //   fromAccount.walletAddress.toLowerCase() !==
          //     fromAccount.description.toLowerCase()
          //     ? ` (${fromAccount.walletAddress})`
          //     : ""
          // }.`}
        />
      )}
      {fromAccount && toAccount && (
        <i
          style={{ fontSize: 12, color: colors.gray30 }}
          className="fa-sharp fa-chevron-right"
        />
      )}
      {toAccount && (
        <StatusTag
          // copyValue={hasToWallet ? toAccount?.walletAddress || "" : null}
          label={"To: " + toAccount?.description}
          type="none"
          rightIcon={
            <ActionSheet
              popover={{ trigger: "hover" }}
              content={{ width: 250 }}
              commands={[
                {
                  label: "View transactions",
                  link: `/clients/${clientId}/transactions?accountIds=${toAccount.id}`,
                  iconName: "fa-sharp fa-exchange-alt",
                },
                hasToWallet
                  ? {
                      label: "Copy wallet address",
                      onClick: () => {
                        navigator.clipboard.writeText(
                          toAccount?.walletAddress || ""
                        );
                        toast.show({
                          status: "info",
                          message: "Copied to clipboard!",
                        });
                      },
                      iconName: "fa-sharp fa-copy",
                    }
                  : null,

                hasToWallet && toAccount.blockExplorerUrl
                  ? {
                      label: "Open in block explorer",
                      link: toAccount.blockExplorerUrl || "",
                      iconImageSrc:
                        PROVIDER_TO_LOGO_URL[toAccount.provider] ?? "",
                    }
                  : null,
                hasFromWallet &&
                fromAccount.blockExplorerUrl &&
                fromAccount.provider === "bitcoin"
                  ? {
                      label: "Open in Ordiscan",
                      link: `https://ordiscan.com/address/${fromAccount.walletAddress}`,
                      iconImageSrc: `https://assets.awaken.tax/icons/ordiscan.png`,
                      iconImageStyle: { borderRadius: 10 },
                    }
                  : null,
                // hasToWallet
                //   ? {
                //       label: "Copy account ID",
                //       onClick: () => {
                //         navigator.clipboard.writeText(toAccount?.id || "");
                //         toast.show({
                //           status: "info",
                //           message: "Copied to clipboard!",
                //         });
                //       },
                //       iconName: "fa-sharp fa-copy",
                //     }
                //   : null,
                // {
                //   label: "Copy account ID",
                //   onClick: () =>
                //     navigator.clipboard.writeText(fromAccount?.id || ""),
                //   iconName: "fa-sharp fa-copy",
                // },
              ].filter(hasValue)}
            >
              <Touchable iconName="fa-sharp fa-ellipsis-v" />
            </ActionSheet>
          }
          boxProps={{ style: { maxWidth: "12rem" } }}
          // infoMessage={`Transferred to ${toAccount?.description}${
          //   toAccount.walletAddress ? ` (${toAccount.walletAddress})` : ""
          // }.`}
        />
      )}
    </HStack>
  );
};

const PricePerToken = ({ transfer }: { transfer: TransferWithExtraInfo }) => {
  const { text } = useTheme();
  const { transaction } = useContext(ActiveTransactionContext);
  const asset = (transfer.fullAsset ||
    getTransformedRawAsset(transfer.rawAsset)) as PartialAssetFields;
  const symbolOrName = getAssetSymbolOrName(asset, 8, true);

  // if has a credit -> use proceeds. otherwise use fiatAmountCents
  // if there this is still null (ex. i think maybe ixfer but not sure), defualt to fiat amount
  const fiatAmountCents =
    (transfer.assetCreditId ? transfer.proceeds : transfer.fiatAmountCents) ||
    transfer.fiatAmountCents;

  if (transfer.assetCreditId && transfer.assetDebitId) {
    return null;
  }

  if (isNil(transfer.value) || isNil(fiatAmountCents) || fiatAmountCents < 0) {
    return null;
  }

  // don't show the price per sol for small amounts of rent
  if (
    symbolOrName?.toLowerCase() === "sol" &&
    new BigNumber(transfer.value || 0).lte(0.01)
  ) {
    return null;
  }

  // just don't return for stables so doesn't confuse people
  if (
    symbolOrName?.toLowerCase() === "usdc" ||
    symbolOrName?.toLowerCase() === "usdt" ||
    symbolOrName?.toLowerCase() === "dai" ||
    asset?.type === AssetTypeEnum.FiatCurrency
  ) {
    return null;
  }

  const pricePerToken = new BigNumber(fiatAmountCents)
    .dividedBy(100)
    .dividedBy(transfer.value);

  // if it's less than 0 if 10^8 just don't render
  if (new BigNumber(fiatAmountCents).dp(0).eq(0)) {
    return null;
  }

  // there are no tokens even worth over $250k right now, so don't show those numbers
  if (pricePerToken.gt(250_000)) {
    return null;
  }

  const format = pricePerToken.abs().isLessThan(0.05)
    ? "0,0.00000000"
    : "0,0.00";

  return (
    <AwakenTooltip message="Price per token">
      <Text
        fontWeight="normal"
        marginTop={"2px !important"}
        fontSize="sm"
        color={text}
      >
        {getCurrencySymbol(transaction?.fiatCurrency || CurrencyCodeEnum.Usd)}
        {numbro(pricePerToken.toNumber()).format(format)}/{symbolOrName}
      </Text>
    </AwakenTooltip>
  );
};

const _getValue = (
  isInternal: boolean,
  type: Maybe<TransferType>,
  valueWithSpace: string | number
) => {
  if (isInternal) {
    return valueWithSpace;
  }

  return type === "sent" ? `-${valueWithSpace}` : `+${valueWithSpace}`;
};

const _getColorForTransferType = (
  isInternal: boolean,
  type: Maybe<TransferType>,
  defaultColor: string
) => {
  if (isInternal) {
    return defaultColor;
  }
  return type === "sent" ? colors.negative : colors.positive;
};
