import { useMutation } from "@apollo/client";
import { Box, HStack, Progress, Text, Tooltip, VStack } from "@chakra-ui/react";
import BigNumber from "bignumber.js";
import { isNil, truncate } from "lodash";
import numbro from "numbro";
import { Link as ReactRouterLink } from "react-router-dom";
import { api } from "src/api";
import {
  AssetTypeEnum,
  AssetWarning,
  CurrencyCodeEnum,
  PortfolioBalanceV2,
} from "src/api/generated/types";
import { AwakenTooltip } from "src/components";
import { AssetIcon } from "src/components/styled/Assets";
import { Maybe } from "src/core";
import { useMyToast } from "src/hooks";
import { useIsLargeScreen } from "src/hooks/useScreenSize";
import { useTheme } from "src/hooks/useTheme";
import { getPortfolioLink } from "src/modules/ledger/transactions";
import { colors } from "src/theme";
import { D, formatNum } from "src/utils/helpers";
import { NAME_FLEX, VALUE_FLEX } from "./constants";
import { getGenericTokenName } from "./utils";

const MIN_DECIMALS = 8;

const _shouldRenderBreakdown = (b: { amount: number }) =>
  new BigNumber(b.amount).dp(MIN_DECIMALS).gt(0);

export const AssetRow = ({
  portfolioBalance,
  clientId,
  currency,
  warning,
}: {
  portfolioBalance: PortfolioBalanceV2;
  clientId: string;
  currency: CurrencyCodeEnum;
  warning: Maybe<AssetWarning>;
}) => {
  const {
    background,
    text,
    border,
    medBackground,
    secondaryBackground2,
    ternaryBackground,
    header,
  } = useTheme();

  const isNotAllowedBitcoinAsset = false;
  // portfolioBalance.provider === "bitcoin" &&
  // portfolioBalance.symbol !== "BTC";

  const assetKey = portfolioBalance.assetPricingKey;
  const assetDetailLink = `${getPortfolioLink(clientId)}/${assetKey}`;

  const tokenName = getGenericTokenName(
    {
      asset: { type: portfolioBalance.type },
      amount: portfolioBalance.totalAmount,
    },
    true
  );
  const provider = portfolioBalance.provider;
  const isNeg = portfolioBalance.dailyFiatAmountCents
    ? portfolioBalance.dailyFiatAmountCents < 0
    : false;
  const hasAmount = portfolioBalance.totalAmount > 0;
  const isNFT = portfolioBalance.type === AssetTypeEnum.Nft;

  const isLarge = useIsLargeScreen();

  return (
    <>
      <Box
        style={{
          display: "flex",
          alignItems: "center",
          padding: isLarge ? "0rem 1rem" : "0 0.25rem",
          borderRadius: 10,
        }}
        bg={background}
        _hover={{
          backgroundColor: medBackground,
        }}
      >
        <ReactRouterLink
          style={{
            flex: 1,
            cursor: isNotAllowedBitcoinAsset ? "text" : "pointer",
          }}
          to={isNotAllowedBitcoinAsset ? "#" : assetDetailLink}
          color={colors.black}
        >
          <HStack
            opacity={hasAmount ? 1 : 0.3}
            // borderRadius={7}
            // borderBottom="none !important"
            width="100%"
            padding={isLarge ? "1rem 0.25rem" : "1rem 0rem"}
          >
            <Box
              // style={{ width: isLarge ? 35 : 30 }}
              flexShrink={0}
              marginRight={isLarge ? "0.5rem !important" : "0"}
            >
              <Box
                style={{ position: "relative", display: "flex", flexShrink: 0 }}
              >
                <AssetImage balance={portfolioBalance} />
              </Box>
            </Box>

            <Box
              display="flex"
              style={{
                flex: isLarge ? NAME_FLEX : NAME_FLEX - 1,
              }}
              alignItems="center"
              position="relative"
            >
              <VStack alignItems="flex-start">
                <HStack alignItems="center" role="group">
                  <Text
                    w="100%"
                    fontSize="md"
                    color={header}
                    margin="0 !important"
                    isTruncated
                    fontWeight="semibold"
                    _groupHover={{
                      textDecor: "underline",
                    }}
                  >
                    {truncate(portfolioBalance.name ?? "", {
                      length: isLarge ? 30 : 10,
                    })}{" "}
                    {isNFT && isLarge ? " Collection" : ""}
                  </Text>
                </HStack>

                {/* <AssetDescription breakdown={breakdown} assetById={assetById} /> */}

                <Tooltip
                  label={`${numbro(portfolioBalance.totalAmount ?? 0).format(
                    "0,000.[0000000000000000]"
                  )} ${portfolioBalance.symbol || "Token"}`}
                  openDelay={500}
                >
                  <Text
                    isTruncated
                    fontSize="sm"
                    isNumeric
                    fontWeight="500"
                    color={text}
                    w="100%"
                    marginTop={"2px !important"}
                    whiteSpace="pre-wrap"
                  >
                    {truncate(portfolioBalance.symbol || "", { length: 10 })}
                  </Text>
                </Tooltip>
              </VStack>
            </Box>

            {isLarge && (
              <Box
                style={{
                  flex: VALUE_FLEX,
                  maxWidth: 100,
                  marginRight: 50,
                  display: "flex",
                  justifyContent: "flex-start",
                  alignItems: "center",
                  marginLeft: 0,
                }}
              >
                <AwakenTooltip
                  message={`${portfolioBalance.percentOfPortfolio}% of your portfolio`}
                >
                  <div
                    style={{
                      width: "100%",
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                    }}
                  >
                    <Text
                      fontSize="xs"
                      color={text}
                      style={{ marginRight: 10 }}
                    >
                      {portfolioBalance.percentOfPortfolio?.toFixed(0)}%
                    </Text>

                    <Progress
                      width="100%"
                      value={portfolioBalance.percentOfPortfolio ?? 0}
                      max={100}
                      height={2}
                      borderRadius={2}
                      bg={secondaryBackground2}
                      colorScheme="green"
                    />
                  </div>
                </AwakenTooltip>
              </Box>
            )}

            <Box
              style={{
                flex: VALUE_FLEX,
                maxWidth: 175,
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "center",
                marginLeft: 0,
              }}
            >
              <PricePerToken
                portfolioBalance={portfolioBalance}
                currency={CurrencyCodeEnum.Usd} //FIXME:
                isNeg={isNeg}
              />
            </Box>

            <Box
              style={{
                flex: VALUE_FLEX,
                maxWidth: 175,
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "center",
                marginLeft: 0,
              }}
            >
              <CurrentValue
                portfolioBalance={portfolioBalance}
                currency={CurrencyCodeEnum.Usd} //FIXME:
                isNeg={isNeg}
              />
            </Box>

            {isLarge && (
              <Box
                style={{
                  alignItems: "center",
                  maxWidth: 175,
                  justifyContent: "flex-end",
                  display: "flex",
                  fontWeight: "500",
                  marginLeft: 0,
                  flex: VALUE_FLEX,
                }}
              >
                <TotalGainLoss
                  portfolioBalance={portfolioBalance}
                  currency={CurrencyCodeEnum.Usd} //FIXME:
                />
              </Box>
            )}

            {isLarge && (
              <div style={{ width: 15 }}>
                <i
                  className="fa-solid fa-chevron-right"
                  style={{
                    fontSize: 12,
                    marginLeft: 10,
                    color: text,
                  }}
                />
              </div>
            )}
          </HStack>
        </ReactRouterLink>
        {/*<Box
          width="50px"
          marginRight="10px"
          alignItems="flex-end"
          justifyContent="flex-end"
          display="flex"
        >
           <Box
            style={
              {
                //marginLeft: 5
              }
            }
          >
            <MoreAssetInfo
              assetKey={portfolioBalance.assetKey}
              asset={portfolioBalance.asset}
            />
          </Box> 
        </Box>*/}
      </Box>
    </>
  );
};

const AssetImage = ({ balance }: { balance: PortfolioBalanceV2 }) => {
  const isLarge = useIsLargeScreen();

  return (
    <AssetIcon
      textStyle={{ fontSize: 10 }}
      size={isLarge ? 35 : 30}
      style={{ flexShrink: 0, borderRadius: 100 }}
      asset={{
        type: balance.type,
        iconImageUrl: balance.iconImageUrl,
        imageUrl: balance.iconImageUrl,
        symbol: balance.symbol,
        backupImageUrls: balance.backupImageUrls,
      }}
    />
  );
};

const TotalGainLoss = ({
  portfolioBalance,
  currency,
}: {
  portfolioBalance: PortfolioBalanceV2;
  currency: CurrencyCodeEnum;
}) => {
  const isLarge = useIsLargeScreen();
  const theme = useTheme();

  // console.log(portfolioBalance);

  if (
    portfolioBalance.isMissingBasis ||
    !portfolioBalance.gainLossCents ||
    isNaN(portfolioBalance.gainLossCents)
  ) {
    return (
      <AwakenTooltip message="We don't have the full cost basis of this asset. This can mean you need to label more transactions or there is potentially a missing transaction you'll have to hunt down.">
        <HStack alignItems="center" style={{ color: theme.text }}>
          <i className="fa-sharp fa-minus" style={{ color: theme.text }} />
        </HStack>
      </AwakenTooltip>
    );
  }

  const isNeg = portfolioBalance.gainLossCents < 0;
  const color = isNil(portfolioBalance.positionPercentageFormatted)
    ? theme.text
    : isNeg
    ? colors.negative
    : colors.positive;

  // if (isNil(portfolioBalance.positionPercentageFormatted)) {
  //   return <HStack alignItems="center">—</HStack>;
  // }

  return (
    <AwakenTooltip
      placement="bottom-end"
      message={`Your unrealized ${portfolioBalance.symbol} gains/losses.`}
    >
      <HStack
        alignItems="center"
        style={{ width: "100%", justifyContent: "flex-end" }}
      >
        {/* {isLarge && (
        <i
          className={isNeg ? "fa-sharp fa-caret-down" : "fa-sharp fa-caret-up"}
          style={{
            fontSize: 16,
            color,
          }}
        />
      )} */}

        <VStack
          alignItems="flex-end"
          style={{
            fontWeight: "500",
            color,
            width: "100%",
            maxWidth: 100,
          }}
        >
          <Text
            fontWeight="500"
            fontSize="md"
            color={theme.header}
            marginBottom="0"
          >
            {D(Math.abs(portfolioBalance.gainLossCents), currency).toFormat()}{" "}
          </Text>
          <Text
            fontSize="sm"
            fontWeight="bold"
            marginTop="2px !important"
            style={{
              color: isNil(portfolioBalance.positionPercentageFormatted)
                ? theme.text
                : color,
            }}
          >
            {/* {isNeg ? "-" : ""} */}
            {portfolioBalance.positionPercentageFormatted || "—"}
          </Text>
        </VStack>
      </HStack>
    </AwakenTooltip>
  );
};

const DailyGainLoss = ({
  portfolioBalance,
  currency,
}: {
  portfolioBalance: PortfolioBalanceV2;
  currency: CurrencyCodeEnum;
}) => {
  const isLarge = useIsLargeScreen();

  if (
    !portfolioBalance.dailyFiatAmountCents ||
    isNaN(portfolioBalance.dailyFiatAmountCents) ||
    !portfolioBalance.hasPrice
  ) {
    return <HStack alignItems="center">—</HStack>;
  }

  const isNeg = portfolioBalance.dailyFiatAmountCents < 0;
  const color = isNeg ? colors.negative : colors.positive;

  return (
    <HStack
      alignItems="center"
      style={{ width: "100%", justifyContent: "flex-end" }}
    >
      {isLarge && (
        <i
          className={isNeg ? "fa-sharp fa-caret-down" : "fa-sharp fa-caret-up"}
          style={{
            fontSize: 16,
            color,
          }}
        />
      )}

      <VStack
        alignItems="flex-end"
        style={{
          fontWeight: "500",
          color,
          width: "100%",
          maxWidth: 100,
        }}
      >
        <Text fontWeight="700" fontSize="md" color={color} marginBottom="5px">
          {D(
            Math.abs(portfolioBalance.dailyFiatAmountCents),
            currency
          ).toFormat()}{" "}
        </Text>
        <Text fontSize="md" marginTop="0 !important" style={{ color }}>
          {/* {isNeg ? "-" : ""} */}
          {portfolioBalance.dailyPercentageFormatted}
        </Text>
      </VStack>
    </HStack>
  );
};

const CurrentValue = ({
  portfolioBalance,
  currency,
  isNeg,
}: {
  portfolioBalance: PortfolioBalanceV2;
  currency: CurrencyCodeEnum;
  isNeg: boolean;
}) => {
  const showUnpriced = false;
  const toast = useMyToast();
  const isLarge = useIsLargeScreen();
  const { header, text } = useTheme();

  const [reportUnpricedAsset] = useMutation(api.assets.reportUnpricedAsset);

  const _reportAsset = async () => {
    // try {
    //   await reportUnpricedAsset({
    //     variables: {
    //       assetId: portfolioBalance.asset.id,
    //       chain: portfolioBalance.asset.provider,
    //       contractAddress: portfolioBalance.asset.contractAddress,
    //     },
    //   });
    //   toast.show({
    //     status: "success",
    //     message: "Successfully reported asset!",
    //   });
    // } catch (err) {
    //   toast.show({
    //     status: "error",
    //     message: "Failed to report asset!",
    //   });
    // }
  };

  const color = isNeg ? colors.negative : colors.positive;
  const tokenName = getGenericTokenName(
    {
      asset: { type: portfolioBalance.type || "" },
      amount: portfolioBalance.totalAmount,
    },
    true
  );

  const isNFT = false;
  // if less than 5, show with 6 decimals. Otherwise, show with 2
  const assetPriceCents = portfolioBalance.assetPriceCents;
  const decimals = assetPriceCents && assetPriceCents < 5 ? 8 : 2;
  const formattedPrice =
    "$" +
    numbro((assetPriceCents || 0) / 100).format({
      thousandSeparated: true,
      mantissa: decimals,
    });

  return (
    <Box>
      <Text
        fontSize="md"
        isNumeric
        style={{
          fontWeight: "500",
        }}
        marginBottom="5px"
        color={header}
      >
        {D(portfolioBalance.totalFiatAmountCents || 0, currency).toFormat()}
      </Text>
      <Text
        fontSize="sm"
        marginTop={0}
        // fontStyle="italic"
        style={{ color: text }}
      >
        {formatNum(portfolioBalance.totalAmount ?? 0, false, "0,0.[0000]")}{" "}
        {truncate(portfolioBalance.symbol || "", { length: 10 })}
      </Text>
    </Box>
  );
};

const PricePerToken = ({
  portfolioBalance,
  currency,
  isNeg,
}: {
  portfolioBalance: PortfolioBalanceV2;
  currency: CurrencyCodeEnum;
  isNeg: boolean;
}) => {
  const { header, text } = useTheme();
  const color = isNeg ? colors.negative : colors.positive;

  // if less than 5, show with 6 decimals. Otherwise, show with 2
  const assetPriceCents = portfolioBalance.assetPriceCents;
  const decimals = assetPriceCents && assetPriceCents < 5 ? 8 : 2;
  const formattedPrice =
    "$" +
    numbro((assetPriceCents || 0) / 100).format({
      thousandSeparated: true,
      mantissa: decimals,
    });

  return (
    <Box>
      <Text
        fontSize="md"
        isNumeric
        style={{
          fontWeight: "500",
        }}
        marginBottom="0"
        color={header}
      >
        {formattedPrice}
      </Text>
      <AwakenTooltip message="Daily percent change.">
        <Text
          fontSize="sm"
          fontWeight="bold"
          marginTop="2px !important"
          style={{
            color: isNil(portfolioBalance.dailyPercentageFormatted)
              ? text
              : color,
          }}
        >
          {/* {isNeg ? "-" : ""} */}
          {portfolioBalance.dailyPercentageFormatted || "—"}
        </Text>
      </AwakenTooltip>
    </Box>
  );
};
