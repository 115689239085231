import { useApolloClient } from "@apollo/client";
import { useInterval } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { api } from "src/api";
import { ClientStatusEnum } from "src/api/generated/types";
import { Maybe } from "src/core";
import { useClientById } from "src/hooks";
import { useIsLargeScreen } from "src/hooks/useScreenSize";
import { useTheme } from "src/hooks/useTheme";
import { getEtaMessage } from "src/utils/helpers";

function Recalculating() {
  const [etaMessage, setEtaMessage] = useState<string | null>("");
  const { clientId } = useParams();
  const apolloClient = useApolloClient();
  const theme = useTheme();
  const { client } = useClientById(clientId, {
    clientFetchPolicy: "network-only",
    onlyFetchClient: true,
  });

  const _getEtaMessage = () => {
    const recalculateEta = client?.recalculateEta;
    const message = getEtaMessage(null, recalculateEta || null);
    return message ? `(${message})` : null;
  };

  useInterval(() => {
    const message = _getEtaMessage();
    setEtaMessage(message ?? null);
  }, 1000);

  // check for a new ETA for the client's ETA
  useEffect(() => {
    let timeout: Maybe<NodeJS.Timeout> = null;

    // if it is recalculating but don't have an ETA, wait 3 seconds and refetch
    // the refetch will make the client pointer change which will recall this whole thing
    if (
      client &&
      client?.status === ClientStatusEnum.Recalculating &&
      !client.recalculateEta
    ) {
      console.log("[refetching]");
      timeout = setInterval(
        () =>
          apolloClient.refetchQueries({
            include: [api.clients.retrieve],
          }),
        3000
      );
    }

    return () => {
      if (timeout) {
        clearTimeout(timeout);
      }
    };
  }, [client?.status, client?.recalculateEta]);

  const dispatch = useDispatch();
  const isLarge = useIsLargeScreen();

  // if importing don't return anything bc makes the application feel like it doesn't have everything
  if (!client?.status || client.status !== ClientStatusEnum.Recalculating) {
    if (!isLarge) {
      return null;
    }

    return null;
    // return (
    //   <Box
    //     position="fixed"
    //     cursor="pointer"
    //     onClick={() => dispatch(show("CXModal"))}
    //     bottom="30px"
    //     borderRadius={100}
    //     right="30px"
    //     padding="1rem 1.25rem"
    //     textAlign="center"
    //     width="auto"
    //     bg={`linear-gradient(to right, ${colors.primary}, ${colors.lightBlue70})`}
    //     boxShadow={other.boxShadow}
    //     border={`1px solid ${theme.border}`}
    //     // className={"progress-border-2-thick"}
    //     zIndex={100}
    //   >
    //     <Text
    //       style={{
    //         color: colors.white,
    //         fontSize: 14,
    //         fontWeight: 600,
    //         fontStretch: "expanded",
    //       }}
    //     >
    //       Contact Support{" "}
    //       <i style={{ marginLeft: 5 }} className="fa-sharp fa-comments" />
    //     </Text>
    //   </Box>
    // );
  }

  return null;

  // return (
  //   <Box
  //     position="fixed"
  //     bottom="30px"
  //     borderRadius={100}
  //     right="30px"
  //     padding="1rem 1.5rem"
  //     textAlign="center"
  //     width="auto"
  //     bg={colors.gray20}
  //     boxShadow={other.boxShadow}
  //     // className={"progress-border-2-thick"}
  //     zIndex={10000}
  //   >
  //     <div
  //       style={{
  //         position: "relative",
  //         display: "flex",
  //         height: "100%",
  //         width: "100%",
  //         flexDirection: "column",
  //         alignItems: "center",
  //         justifyContent: "center",
  //       }}
  //     >
  //       <HStack>
  //         <Spinner color={colors.white} marginRight="5px" size="sm" />

  //         <Text
  //           width="100%"
  //           color={colors.white}
  //           textAlign="center"
  //           fontSize={14}
  //           fontWeight="600"
  //         >
  //           Recalculating...{etaMessage ? " " + etaMessage : ""}
  //         </Text>
  //       </HStack>
  //     </div>
  //   </Box>
  // );
}

export default Recalculating;
