import { useCallback, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getTheme, setTheme } from "src/redux/reducers/globalState";
import { colors } from "src/theme";

export const useTheme = () => {
  const theme = useSelector(getTheme);
  const dispatch = useDispatch();

  const _toggleDarkMode = useCallback(async () => {
    try {
      const newTheme = theme === "light" ? "dark" : "light";
      await window.localStorage.setItem("theme", newTheme);
      dispatch(setTheme(newTheme));
    } catch (error) {
      console.log(error);
    }
  }, [theme]);

  const data = useMemo(
    () => ({
      background: theme === "light" ? colors.white : colors.black,
      medBackground: theme === "light" ? colors.gray100 : colors.gray05,
      secondaryBackground: theme === "light" ? colors.gray90 : colors.gray10,
      secondaryBackground2: theme === "light" ? colors.gray85 : colors.gray20,
      ternaryBackground: theme === "light" ? colors.gray80 : colors.gray20,
      header: theme === "light" ? colors.black : colors.white,
      theme,
      text: theme === "light" ? colors.gray30 : colors.gray60,
      placeholder: theme === "light" ? colors.gray60 : colors.gray30,
      border: theme === "light" ? colors.gray85 : colors.gray10,
      greenBg: theme === "light" ? colors.green100 : colors.green10,
      blueBg: theme === "light" ? colors.lightBlue90 : colors.lightBlue10,
      redBg: theme === "light" ? colors.red100 : colors.red10,
      yellowBg: theme === "light" ? colors.yellow90 : colors.yellow00,
      toggleDarkMode: _toggleDarkMode,
    }),
    [theme, _toggleDarkMode]
  );

  return data;
};
