import { Box, Text } from "@chakra-ui/react";
import { useTheme } from "src/hooks/useTheme";
import { colors } from "src/theme";

function VideoLink({
  videoUrl,
  description,
}: {
  videoUrl: string | null;
  description: JSX.Element;
}) {
  const theme = useTheme();

  if (!videoUrl) return null;

  return (
    <a href={videoUrl} target="_blank" rel="noreferrer">
      <Box
        _hover={{
          bg: theme.secondaryBackground,
        }}
        bg={theme.medBackground}
        style={{
          border: `1px solid ${theme.border}`,
          marginBottom: 10,
          padding: "15px 12px",
          borderRadius: 15,
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <i
          style={{
            color: colors.primary,
            fontSize: 24,
            marginRight: 10,
          }}
          className="fa-solid fa-video"
        />
        <div style={{ flex: 1 }}>
          <Text
            color={theme.text}
            style={{ fontStretch: "extra-expanded" }}
            fontWeight="bold"
            fontSize="md"
            mb={"0"}
          >
            Watch our video tutorial
          </Text>

          {description}
        </div>

        <i
          className="fas fa-chevron-right"
          style={{ color: theme.text, fontSize: 16 }}
        />
      </Box>
    </a>
  );
}

export default VideoLink;
