import { useApolloClient } from "@apollo/client";
import { useCallback } from "react";
import { api } from "src/api";
import { useInterval } from "./common";

const POLL_INTERVAL = 15 * 1000;

// will refetch the query for client
export const useClientPoller = () => {
  const apolloClient = useApolloClient();

  const _resetClientCache = useCallback(
    () =>
      apolloClient.refetchQueries({
        include: [api.clients.retrieve, api.transactions.countTransactions],
      }),
    []
  );

  useInterval(_resetClientCache, POLL_INTERVAL);

  return null;
};
