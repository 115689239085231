import {
  Box,
  FormLabel,
  HStack,
  Input,
  Popover,
  PopoverContent,
  PopoverTrigger,
  Text,
} from "@chakra-ui/react";
import EmojiPicker from "emoji-picker-react";
import { useTheme } from "src/hooks/useTheme";
import { colors, other } from "src/theme";

type Props = {
  defaultName: string;
  label: string;
  setLabel: (label: string) => void;
  emoji: string;
  setEmoji: (emoji: string) => void;
  isOpenEmoji: boolean;
  setOpenEmoji: (isOpen: boolean) => void;
};

export function EmojiWalletName({
  label,
  setLabel,
  emoji,
  setEmoji,
  isOpenEmoji,
  setOpenEmoji,
  defaultName,
}: Props) {
  const theme = useTheme();

  return (
    <>
      <FormLabel
        color={theme.header}
        fontSize={16}
        marginBottom="0"
        fontWeight="semibold"
      >
        Wallet Name
      </FormLabel>
      <Text marginBottom="0.5rem" color={theme.text} fontSize={14}>
        A nickname for your wallet so it's easier for you to identify.
      </Text>
      <HStack w="100%" height="3rem" marginBottom="1.25rem">
        <Popover
          trigger="click"
          onOpen={() => setOpenEmoji(true)}
          onClose={() => setOpenEmoji(false)}
          isOpen={isOpenEmoji}
          placement="bottom"
        >
          <PopoverTrigger>
            <Box
              cursor="pointer"
              width="2.5rem"
              height="2.5rem"
              border={`1px solid ${theme.border}`}
              borderRadius={other.borderRadius}
              textAlign="center"
              display="flex"
              alignItems="center"
              justifyContent="center"
            >
              <Text margin="0" padding="0">
                {emoji}
              </Text>
            </Box>
          </PopoverTrigger>
          <PopoverContent>
            <EmojiPicker
              onEmojiClick={(emojiObject, e) => {
                setEmoji(emojiObject.emoji);
              }}
              // searchDisabled={true} // was causing slowness in local testing // but customers want it bad
            />
          </PopoverContent>
        </Popover>
        <Input
          value={label}
          onChange={(e) => setLabel(e.target.value)}
          label=""
          // Note: removing the default name for now just cause feel like it was confusing.
          // had a friend and dad, and they were confused by the placeholder (idk why seemed obvious to me)
          placeholder="Enter any nickname..." // {defaultName}
          marginRight="0"
          margin="0"
          flex={1}
          style={{
            color: theme.header,
          }}
          borderColor={theme.border}
          focusBorderColor={colors.primary}
          containerStyle={{
            margin: 0,
            marginLeft: "0.5rem",
            flex: 1,
          }}
        />
      </HStack>
    </>
  );
}
