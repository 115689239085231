import {
  ApolloError,
  useLazyQuery,
  useMutation,
  useQuery,
} from "@apollo/client";
import {
  Box,
  BoxProps,
  Divider,
  Flex,
  HStack,
  Image,
  Popover,
  PopoverContent,
  PopoverTrigger,
  Text,
  Tooltip,
  useToast,
} from "@chakra-ui/react";
import BigNumber from "bignumber.js";
import Fuse from "fuse.js";
import { truncate } from "lodash";
import { compose, last, orderBy } from "lodash/fp";
import { sleep } from "radash";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import Helmet from "react-helmet";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import { show } from "redux-modal";
import { api, apolloClient } from "src/api";
import { BaseClientFields } from "src/api/fragments";
import {
  ClientAccountantStatusEnum,
  MutationUpdateClientArgs,
  Query,
  QueryGetClientByIdArgs,
  Subscription,
} from "src/api/generated/types";
import PlusFilled from "src/assets/awaken/plus-filled.png";
import { config } from "src/config";
import { Maybe } from "src/core";
import { useIsLargeScreen } from "src/hooks/useScreenSize";
import { useTheme } from "src/hooks/useTheme";
import {
  getIsGlobalRuleMode,
  setGlobalRuleMode,
} from "src/redux/reducers/globalState";
import { colors } from "src/theme";
import { getMetadataInfo, isWhiteLabeledDomain } from "src/utils/whitelabel";
import { MyToast } from "../MyToast";
import { Input } from "../styled";
import StatusTag, { StatusTagType } from "../styled/StatusTag";
import { Touchable } from "../Touchable";

const SEARCH_OPTIONS: Fuse.IFuseOptions<BaseClientFields> = {
  includeScore: true,
  minMatchCharLength: 1,
  location: 0,
  threshold: 0.2, // 0.2 means it is pretty strict match
  keys: ["name"],
};

export const ClientSelector = () => {
  const { clientId } = useParams<{ clientId: string }>();
  const toast = useToast();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [search, setSearch] = useState("");
  const [popoverOpen, setPopoverOpen] = useState(false);
  const inputRef = useRef<HTMLInputElement>(null);
  const { theme, header, background, border } = useTheme();

  const { data: meData, refetch: refetchMe } = useQuery<Pick<Query, "me">>(
    api.users.me,
    {
      fetchPolicy: "no-cache",
    }
  );
  const isSuperUser = meData?.me?.isSuperuser || false;
  const role = meData?.me?.role || null;

  const [updateClient] = useMutation(api.clients.update);

  const [fetchClient, { data: clientData }] = useLazyQuery<
    {
      getClientById?: BaseClientFields;
    },
    QueryGetClientByIdArgs
  >(api.clients.retrieve, {
    fetchPolicy: "cache-and-network",
  });

  // Redux
  const _showModal = compose(dispatch, show);

  // this is the boolean
  const isGlobalRuleMode = useSelector(getIsGlobalRuleMode);
  // you can call this _setGlobalRuleMode(true) to set it to true (or pass false to false). sets the global variable
  // this gets reset when you refresh the page -> it is only in memory
  const _setGlobalRuleMode = compose(dispatch, setGlobalRuleMode);

  // API hooks
  const { data: clientsData, refetch } = useQuery<{
    // FIXME: this isn't true, it doesn't have some of the fields that require job lookups
    // bc those are expensive atm
    getMyClients?: BaseClientFields[];
  }>(api.clients.list, {
    fetchPolicy: "no-cache",
  });

  const [getSubscriptions] = useLazyQuery(api.subscriptions.list);

  const [giveCredit] = useMutation(api.clients.giveCredit);
  const [adjustSubscriptionTier] = useMutation(
    api.clients.adjustSubscriptionTier
  );

  // Mutations
  const [
    setActiveClient,
    { loading: loadingActiveClient, error: errorActiveClient },
  ] = useMutation(api.users.clients.setActive);

  const active = clientData?.getClientById || null;

  // Functions
  const _setActiveClient = useCallback(
    async (clientId: string) => {
      const oldClientId = active?.id;

      if (loadingActiveClient) {
        return;
      }

      try {
        await setActiveClient({
          variables: {
            clientId,
          },
          refetchQueries: [api.users.clients.getActive],
        });

        const currentPath = last(window.location.pathname.split("/"));
        const isValidPath = new Set([
          "dashboard",
          "transactions",
          "assets",
          "analytics",
          "team",
          "taxes",
        ]).has(currentPath || "");

        const newPath = "dashboard"; //  isValidPath ? currentPath :

        navigate(`/clients/${clientId}/${newPath}`);
      } catch (err) {
        if (err instanceof Error) {
          return toast({
            position: "top",
            render: () => (
              <MyToast message={(err as Error).message} status="error" />
            ),
          });
        }
      }
    },
    [active]
  );

  const _onChangeSelect = useCallback(
    async (client?: Maybe<BaseClientFields>) => {
      if (!client) {
        return;
      }

      return _setActiveClient(client.id);
    },
    [_showModal, _setActiveClient]
  );

  useEffect(() => {
    if (clientId) {
      refetch();
    }
  }, [clientId || "none"]);

  useEffect(() => void refetchMe(), []);

  const _clients = useMemo(
    () => orderBy(["createdAt", "id"], "desc", clientsData?.getMyClients || []),
    [clientsData?.getMyClients || []]
  );

  useEffect(() => {
    if (_clients.length > 0) {
      inputRef.current?.focus();
    }
  }, [_clients]);

  useEffect(() => {
    if (clientId) void fetchClient({ variables: { clientId } });
  }, [clientId]);

  const _changeClientName = async () => {
    // prompt for name input
    const newName = prompt("Enter new client name", active?.name || "");

    if (!newName) {
      return;
    }

    const variables: MutationUpdateClientArgs = {
      clientId: clientId || "",
      name: newName,
    };

    try {
      await updateClient({
        variables,
        refetchQueries: [api.clients.retrieve],
      });

      toast({
        position: "top",
        render: () => (
          <MyToast message={"Successfully changed name!"} status="success" />
        ),
      });
    } catch (err) {
      if (err instanceof ApolloError) {
        return alert(err.message);
      }
      return alert("An error occurred.");
    }
  };

  const _giveCredit = async () => {
    try {
      setPopoverOpen(false);

      await sleep(250);

      const response = await getSubscriptions({
        variables: { clientId },
      });

      const subs = (response?.data?.getSubscriptions || []).filter(
        (s: Subscription) => s.transactionCeiling > 25
      );

      if (subs.length > 0) {
        const confirmed = window.confirm(
          "This client already have a subscription. Do you still want to gove them credit?"
        );
        if (!confirmed) return;
      }

      const amountOfCredit = window.prompt(
        "Enter the amount of credit to give",
        "25"
      );

      if (!amountOfCredit) {
        return;
      }

      const amount = new BigNumber(amountOfCredit).multipliedBy(100).dp(0);

      if (amount.isNaN()) {
        alert("Invalid amount");
        return;
      }

      await giveCredit({
        variables: { clientId: clientId, newCreditCents: amount.toNumber() },
        refetchQueries: [api.clients.retrieve],
      });
    } catch (err) {
      if (err instanceof ApolloError) {
        return alert(err.message);
      }
      return alert("An error occurred.");
    }
  };

  const _upgradeSubscription = async () => {
    try {
      setPopoverOpen(false);

      await sleep(250);

      const newCeiling = window.prompt(
        "How much should their new tier be?",
        "1500"
      );

      if (!newCeiling) {
        return;
      }

      const amount = new BigNumber(newCeiling).dp(0);

      if (amount.isNaN()) {
        alert("Invalid amount");
        return;
      }

      await adjustSubscriptionTier({
        variables: { clientId: clientId, newCeiling: amount.toNumber() },
      });

      await apolloClient.refetchQueries({
        include: [api.clients.retrieve, api.subscriptions.active],
      });

      toast({
        position: "top",
        render: () => (
          <MyToast
            message={"Successfully upgraded subscription!"}
            status="success"
          />
        ),
      });
    } catch (err) {
      if (err instanceof ApolloError) {
        return alert(err.message);
      }
      return alert("An error occurred.");
    }
  };

  const _onCopyID = () => {
    navigator.clipboard.writeText(active?.id || "");
    setPopoverOpen(false);
    toast({
      position: "top",
      render: () => <MyToast message={"Copied!"} status="info" />,
    });
  };

  const _onCopyEmail = () => {
    navigator.clipboard.writeText(active?.email || "");
    setPopoverOpen(false);
    toast({
      position: "top",
      render: () => (
        <MyToast message={`Copied ${active?.email}!`} status="info" />
      ),
    });
  };

  const _enterCodeModal = () => {
    _showModal("ReferralCodeModal", { clientId });
  };

  const _deleteUser = () => {
    _showModal("DeleteUserModal", { email: active?.email });
  };

  // only show the dashboard if they have multiple clients
  const fuse = useMemo(() => new Fuse(_clients, SEARCH_OPTIONS), [_clients]);
  const clients = useMemo(
    () => (search ? fuse.search(search).map((s) => s.item) : _clients),
    [fuse, search, _clients]
  );
  const isLarge = useIsLargeScreen();
  const isWhiteLabel = isWhiteLabeledDomain();
  const metadata = getMetadataInfo();

  return (
    <>
      <Helmet>
        <title>
          {metadata?.name || "Awaken"} - {active?.name || ""}
        </title>
      </Helmet>
      <Popover
        isOpen={popoverOpen}
        closeOnBlur={isLarge ? undefined : false}
        onClose={() => {
          console.log("close");
          setPopoverOpen(false);
        }}
        onOpen={() => {
          console.log("open");
          setPopoverOpen(true);
        }}
        placement={isLarge ? "bottom" : "bottom-end"}
        autoFocus
        initialFocusRef={inputRef}
      >
        <PopoverTrigger>
          <Box
            _hover={{
              bg: border,
            }}
            style={{
              cursor: "pointer",
              padding: "15px",
              borderBottom: "1px solid " + border,
              background: background,
            }}
            w="100%"
          >
            <HStack>
              <Flex flex={1}>
                <HStack style={{ cursor: "pointer", width: "100%" }}>
                  <ProfilePicture client={active} />

                  <Text color={header} flex={1} style={{ fontSize: 14 }}>
                    {truncate(active?.name || "", { length: 22 })}
                  </Text>

                  <div
                    style={{
                      fill: "rgba(55, 53, 47, 0.45)",
                      width: 12,
                      height: 12,
                      flexShrink: 0,
                      flexGrow: 0,
                    }}
                  >
                    {/* */}
                    <svg
                      viewBox="-1 -1 9 11"
                      fill={header}
                      style={{
                        width: "100%",
                        height: "100%",
                        display: "block",
                        fill: "inherit",
                        flexShrink: 0,
                        backfaceVisibility: "hidden",
                      }}
                    >
                      <path
                        fill={header}
                        id="path0_stroke"
                        d="M 3.5 0L 3.98809 -0.569442L 3.5 -0.987808L 3.01191 -0.569442L 3.5 0ZM 3.5 9L 3.01191 9.56944L 3.5 9.98781L 3.98809 9.56944L 3.5 9ZM 0.488094 3.56944L 3.98809 0.569442L 3.01191 -0.569442L -0.488094 2.43056L 0.488094 3.56944ZM 3.01191 0.569442L 6.51191 3.56944L 7.48809 2.43056L 3.98809 -0.569442L 3.01191 0.569442ZM -0.488094 6.56944L 3.01191 9.56944L 3.98809 8.43056L 0.488094 5.43056L -0.488094 6.56944ZM 3.98809 9.56944L 7.48809 6.56944L 6.51191 5.43056L 3.01191 8.43056L 3.98809 9.56944Z"
                      ></path>
                    </svg>
                  </div>
                </HStack>
              </Flex>
            </HStack>
          </Box>
        </PopoverTrigger>
        <PopoverContent
          left={3}
          bg={background}
          borderRadius={5}
          border={`1px solid ${border} !important`}
          style={{
            maxHeight: "80vh",
            overflow: "scroll",
            boxShadow: "0 0 3px 1px rgba(0,0,0,0.1)",
            width: isLarge ? undefined : 300,
          }}
        >
          <HStack
            style={{
              padding: "10px 15px",
              borderBottom: "1px solid " + border,
            }}
            alignItems="center"
          >
            <Text
              flex={1}
              style={{
                fontSize: 12,
                color: header,
              }}
            >
              Logged in as <b>{truncate(meData?.me?.email, { length: 30 })}</b>
            </Text>
            {/* accountant icon with font awesome */}
            {meData?.me?.role === "accountant" && (
              <Tooltip label="Accountant View">
                <i style={{ color: header }} className="fa-sharp fa-book" />
              </Tooltip>
            )}
          </HStack>
          {_clients.length > 1 && (
            <Input
              ref={inputRef}
              autoFocus
              containerStyle={{
                marginBottom: 0,
                borderBottom: "1px solid " + border,
              }}
              style={{ fontSize: 14 }}
              iconLeft={
                <i
                  className="fa-sharp fa-search"
                  style={{ color: colors.gray50, fontSize: 14 }}
                />
              }
              border="none"
              value={search}
              onChange={(e) => setSearch(e.target.value)}
              placeholder="Search..."
              focusBorderColor="none"
            />
          )}
          <Box
            style={{
              maxHeight: 200,
              overflowY: "scroll",
              overflowX: "hidden",
              backgroundColor: colors.white,
            }}
          >
            <ClientRow
              onChangeSelect={() =>
                _showModal("AddClientModal", {
                  name: search || "",
                })
              }
              key="add-client"
              client={null}
              active={active}
              isAccountant={false}
              imgOverride={PlusFilled}
              nameOverride={`Add ${search ? '"' + search + '"' : "new client"}`}
              boxProps={
                {
                  // bgColor: colors.black,
                  // color: colors.white,
                  // _hover: {
                  //   bgColor: colors.black,
                  //   color: colors.white,
                  // },
                }
              }
            />
            {clients.slice(0, 40).map((c) => (
              <ClientRow
                onChangeSelect={_onChangeSelect}
                key={c.id}
                client={c}
                active={active}
                isAccountant={meData?.me?.role === "accountant"}
              />
            ))}
          </Box>

          <Divider style={{ borderColor: border }} />

          <Box
            style={{
              backgroundColor: background,
              padding: "10px 0 0 0",
            }}
          >
            {/* <Touchable
              iconName="fa-sharp fa-comments"
              iconPosition="left"
              style={{ margin: 0, borderRadius: 0 }}
              label={<>Chat with support</>}
            /> */}
            {/* {!isWhiteLabel && (
              <Touchable
                onClick={() => {
                  window.location.href = config.discordLink;
                }}
                iconName="fa-brands fa-discord"
                iconPosition="left"
                style={{ margin: 0, borderRadius: 0 }}
                label={<>Discord Support Forum</>}
              />
            )} */}

            <a href={config.twitterLink}>
              <Touchable
                iconName="fab fa-twitter"
                iconPosition="left"
                iconStyle={{
                  // twitter blue
                  color: "#1DA1F2",
                }}
                style={{ margin: 0, borderRadius: 0 }}
                label="Follow on Twitter"
              />
            </a>

            <Touchable
              onClick={() => {
                _showModal("FeedbackModal");
              }}
              iconName="fa-sharp fa-heart"
              iconPosition="left"
              iconStyle={{
                color: colors.red50,
                animation: "pulse-big 1.5s infinite",
              }}
              style={{ margin: 0, borderRadius: 0 }}
              label={
                <>
                  Leave Feedback{" "}
                  {/* <Info message="Due to the overwhelming number of customer requests, it may take us a few days to get back to you. We'd recommend trying Discord for your questions first." />{" "} */}
                </>
              }
            />

            {/* <Link to="/landing">
              <Touchable
                iconName="fa-sharp fa-mountains"
                iconPosition="left"
                style={{ margin: 0, borderRadius: 0 }}
                label="Landing"
              />
            </Link> */}

            {/* <Touchable
              iconName="fa-sharp fa-heart"
              iconPosition="left"
              onClick={_onLeaveFeedback}
              style={{ margin: 0, borderRadius: 0 }}
              label="Leave Feedback"
            /> */}

            <Touchable
              style={{ margin: 0, borderRadius: 0 }}
              onClick={_changeClientName}
              iconName="fa-sharp fa-edit"
              iconPosition="left"
              label={`Change account name`}
            />

            <Touchable
              style={{ margin: 0, borderRadius: 0 }}
              onClick={() => dispatch(show("CompetitorCreditModal"))}
              // credit money icon
              iconName="fa-sharp fa-money-bill"
              iconPosition="left"
              label={`Competitor credit match`}
            />

            <a href="https://help.awaken.tax">
              <Touchable
                iconName="fa-sharp fa-circle-info"
                iconPosition="left"
                style={{ margin: 0, borderRadius: 0 }}
                label="Help Center"
              />
            </a>

            <Divider style={{ margin: "0.5rem 0", borderColor: border }} />

            <Link to="/logout">
              <Touchable
                iconName="fa-sharp fa-power-off"
                iconPosition="left"
                style={{ margin: 0, borderRadius: 0 }}
                label="Logout"
              />
            </Link>

            {isSuperUser && (
              <>
                <Divider style={{ borderColor: border, margin: "0.5rem 0" }} />

                <Text
                  color={header}
                  padding="0.25rem 0.75rem"
                  fontSize="xs"
                  fontWeight="bold"
                >
                  ADMIN ONLY
                </Text>
                <Touchable
                  style={{ margin: 0, borderRadius: 0 }}
                  onClick={() => _setGlobalRuleMode(!isGlobalRuleMode)}
                  iconName={
                    isGlobalRuleMode ? "fal fa-globe" : "fa-sharp fa-globe"
                  }
                  iconPosition="left"
                  label={`${
                    isGlobalRuleMode ? "Exit" : "Enter"
                  } Global Rule Mode`}
                />

                <Touchable
                  style={{ margin: 0, borderRadius: 0 }}
                  onClick={_giveCredit}
                  iconName="fa-sharp fa-gift"
                  iconPosition="left"
                  label={`Give credit`}
                />

                <Touchable
                  style={{ margin: 0, borderRadius: 0 }}
                  onClick={_upgradeSubscription}
                  iconName="fa-sharp fa-arrow-up-right-from-square"
                  iconPosition="left"
                  label={`Upgrade subscription`}
                />

                <Touchable
                  style={{ margin: 0, borderRadius: 0 }}
                  onClick={() => dispatch(show("BuyLinkModal"))}
                  iconName="fa-sharp fa-file-invoice-dollar"
                  iconPosition="left"
                  label={`Generate payment link`}
                />

                <Touchable
                  style={{ margin: 0, borderRadius: 0 }}
                  onClick={_onCopyEmail}
                  iconName="fa-sharp fa-envelope"
                  iconPosition="left"
                  label={`Copy client email`}
                />
                {/* <Touchable
                  style={{ margin: 0, borderRadius: 0 }}
                  onClick={_deleteUser}
                  iconName="fa-sharp fa-trash"
                  iconPosition="left"
                  label={`Delete user`}
                /> */}

                <Link to={`/clients/${clientId}/jobs`}>
                  <Touchable
                    style={{ margin: 0, borderRadius: 0 }}
                    iconName="fa-sharp fa-cogs"
                    iconPosition="left"
                    label="Jobs"
                  />
                </Link>
                <Link to={`/clients/${clientId}/rules`}>
                  <Touchable
                    style={{ margin: 0, borderRadius: 0 }}
                    iconName="fa-sharp fa-ruler"
                    iconPosition="left"
                    label="Global Rules"
                  />
                </Link>
              </>
            )}
          </Box>
        </PopoverContent>
      </Popover>
    </>
  );
};

const GET_STATUS_TYPE: Record<
  ClientAccountantStatusEnum,
  {
    type: StatusTagType;
    iconName: string;
    infoMessage: string;
  }
> = {
  [ClientAccountantStatusEnum.InProgress]: {
    type: "warning",
    infoMessage: "In progress.",
    iconName: "fa-sharp fa-person-running",
  },
  [ClientAccountantStatusEnum.Blocked]: {
    type: "error",
    infoMessage: "Blocked.",
    iconName: "fa-sharp fa-circle-x",
  },
  [ClientAccountantStatusEnum.Finished]: {
    type: "success",
    infoMessage: "Finished!",
    iconName: "fa-sharp fa-party-horn",
  },
};

const ClientStatus = ({ client: c }: { client?: Maybe<BaseClientFields> }) => {
  if (!c || !c.accountantStatus) {
    return null;
  }

  const type = GET_STATUS_TYPE[c.accountantStatus];

  if (!type) return null;

  return (
    <StatusTag
      type={type.type}
      iconStyle={{ fontSize: 14 }}
      iconName={type.iconName}
      infoMessage={type.infoMessage}
    />
  );
};

const ClientRow = ({
  client: c,
  active,
  onChangeSelect,
  isAccountant,
  key,
  imgOverride,
  nameOverride,
  boxProps,
}: {
  client: Maybe<BaseClientFields>;
  active: Maybe<BaseClientFields>;
  onChangeSelect: (c?: Maybe<BaseClientFields>) => void;
  isAccountant: boolean;
  key: string;
  imgOverride?: string;
  nameOverride?: string;
  boxProps?: BoxProps;
}) => {
  const dispatch = useDispatch();
  const theme = useTheme();

  const _editClientInfo = () => {
    dispatch(show("EditClientModal", { client: c }));
  };

  const isActive = c?.id === active?.id;

  return (
    <Box
      onClick={() => onChangeSelect(c)}
      color={theme.text}
      background={theme.background}
      style={{
        padding: "10px 15px 10px 0",
        fontSize: 16,
        cursor: "pointer",
        borderBottom: "1px solid " + theme.border,
      }}
      key={key}
      _hover={{ backgroundColor: theme.secondaryBackground }}
      {...boxProps}
    >
      <HStack>
        <div
          style={{
            height: 30,
            width: 10,
            marginRight: 10,
            borderTopRightRadius: 5,
            borderBottomRightRadius: 5,
            backgroundColor: isActive ? colors.primary : "transparent",
          }}
        />
        <ProfilePicture client={c} imgOverride={imgOverride} />
        <Text flex={1} isTruncated style={{ fontSize: 14, fontWeight: "500" }}>
          {c ? truncate(c.name || "", { length: 34 }) : nameOverride}
        </Text>

        {isAccountant && (
          <HStack>
            {c?.accountantNotes && (
              <Tooltip
                trigger="hover"
                bg={colors.black}
                placement="top"
                borderRadius="0.25rem"
                label={c.accountantNotes}
                hasArrow
              >
                <i
                  style={{
                    marginLeft: 10,
                    marginRight: 10,
                    fontSize: 14,
                    color: colors.gray30,
                  }}
                  className="fa-sharp fa-comment-alt-lines"
                />
              </Tooltip>
            )}
            <ClientStatus client={c} />
            <Touchable
              iconStyle={{ fontSize: 14 }}
              iconName="fa-sharp fa-pen"
              onClick={_editClientInfo}
            />
          </HStack>
        )}
      </HStack>
    </Box>
  );
};

const ProfilePicture = ({
  client,
  imgOverride,
}: {
  client?: Maybe<BaseClientFields>;
  imgOverride?: Maybe<string>;
}) => {
  const [profileUrl, setProfileUrl] = useState(client?.profilePicture);

  useEffect(
    () => setProfileUrl(client?.profilePicture || ""),
    [client?.profilePicture]
  );

  if (imgOverride) {
    return (
      <Image
        src={imgOverride || ""}
        style={{
          width: 30,
          height: 30,
          objectFit: "cover",
          overflow: "hidden",
          flexShrink: 0,
          borderRadius: 5,
          border: "1px solid " + colors.gray60,
        }}
        bg={colors.gray100}
        onError={({ currentTarget }) => {
          currentTarget.onerror = null; // prevents looping
          currentTarget.src = "";
          setProfileUrl(null);
        }}
      />
    );
  }

  if (profileUrl) {
    const isGithub = profileUrl.includes("github.com");
    const imageUrl = isGithub ? profileUrl + "?raw=true" : profileUrl;
    const isVideo = imageUrl.includes(".mp4");

    if (isVideo) {
      // render a video component with the url
      return (
        <video
          style={{
            width: 30,
            height: 30,
            borderRadius: 5,
            objectFit: "cover",
            overflow: "hidden",
            flexShrink: 0,
            border: "1px solid",
          }}
          autoPlay
          loop
          muted={true}
        >
          <source
            src={imageUrl}
            type="video/mp4"
            onError={({ currentTarget }) => {
              currentTarget.onerror = null; // prevents looping
              currentTarget.src = "";
              setProfileUrl(null);
            }}
          />
        </video>
      );
    }

    return (
      <Image
        src={imageUrl}
        style={{
          width: 30,
          height: 30,
          objectFit: "cover",
          overflow: "hidden",
          flexShrink: 0,
          borderRadius: 5,
          border: "1px solid " + colors.gray60,
        }}
        bg={colors.gray100}
        onError={({ currentTarget }) => {
          currentTarget.onerror = null; // prevents looping
          currentTarget.src = "";
          setProfileUrl(null);
        }}
      />
    );
  }

  return (
    <Box
      style={{
        width: 30,
        height: 30,
        borderRadius: 5,
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        flexShrink: 0,
        flexGrow: 0,
        justifyContent: "center",
        fontSize: 14,
        background: `linear-gradient(45deg, ${colors.blue70}, ${colors.purple80})`,
        fontWeight: "500",
        marginRight: 0,
        color: colors.white,
      }}
    >
      {client?.name?.charAt(0).toUpperCase()}
    </Box>
  );
};
