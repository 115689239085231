import { HStack } from "@chakra-ui/react";
import BigNumber from "bignumber.js";
import { BaseSimpleTransactionFields } from "src/api/fragments";
import { CurrencyCodeEnum, IsDirtyEnum } from "src/api/generated/types";
import { StatusTagType } from "src/components/styled/StatusTag";
import { Maybe } from "src/core";
import { useTheme } from "src/hooks/useTheme";
import { colors } from "src/theme";
import { formatNum, getCurrencySymbol } from "src/utils/helpers";
import { AwakenTooltip } from "../AwakenTooltip";

type PriorityInfo = {
  iconName: string;
  type: StatusTagType;
  message: string;
  infoMessage: string;
};

export const IncomeTag = ({
  transaction,
  isDirty,
}: {
  transaction: BaseSimpleTransactionFields;
  isDirty?: Maybe<IsDirtyEnum>;
}): JSX.Element | null => {
  const theme = useTheme();

  const symbol = getCurrencySymbol(
    transaction.fiatCurrency || CurrencyCodeEnum.Usd
  );

  if (transaction.isImporting === true) {
    return null;
  }

  if (
    transaction.incomeSum !== null &&
    transaction.incomeSum !== undefined &&
    String(transaction.incomeSum) !== ""
  ) {
    const dollars =
      Number(transaction.incomeSum || transaction.incomeSum) / 100;
    const isNeg = dollars < 0;
    const cashAmount = formatNum(Math.abs(dollars), false);

    if (new BigNumber(dollars).dp(2).isZero()) {
      return null;
    }

    return (
      <AwakenTooltip message={`Earned ${symbol}${dollars} of income.`}>
        <HStack>
          <i
            className="fa-sharp fa-solid fa-sack-dollar"
            style={{
              fontSize: 12,
              color:
                !isDirty || isDirty === IsDirtyEnum.Clean
                  ? colors.positive
                  : theme.header,
            }}
          />
          <span
            style={{
              color:
                !isDirty || isDirty === IsDirtyEnum.Clean
                  ? colors.positive
                  : theme.header,
              fontWeight: "bold",
              textDecoration:
                !isDirty || isDirty === IsDirtyEnum.Clean
                  ? undefined
                  : "strikethrough",
            }}
          >
            {isNeg ? "-" : "+"}
            {symbol}
            {cashAmount}
          </span>
          {/* <StatusTag <- too colorful
          iconName={info.iconName}
          type={info.type}
          label={info.message}
          infoMessage={info.infoMessage}
        ></StatusTag> */}
        </HStack>
      </AwakenTooltip>
    );
  }

  return null;
};
