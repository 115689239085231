import { useApolloClient } from "@apollo/client";
import { useEffect } from "react";
import { api } from "src/api";
import { Maybe } from "src/core";
import { RECALCULATE_REFETCH_QUERIES } from "src/modules/jobs/recalculate/constants";
import { pusher } from "src/utils/pusher";

export const useClientListener = (clientId: Maybe<string>) => {
  const apolloClient = useApolloClient();

  useEffect(() => {
    if (!clientId) return;

    const channelName = `client-${clientId}`;
    const channel = pusher.subscribe(channelName);

    console.log("[pusher] subscribed to channel", channelName);

    channel.bind("accounts.update", (_data: any) => {
      console.log("[pusher] accounts.update", _data);
      apolloClient.refetchQueries({
        include: [
          api.clients.accounts,
          api.clients.transactions,
          api.transactions.countTransactions,
          api.transactions.retrieve,
          api.subscriptions.active,
          api.clients.retrieve,
          api.portfolio.get,
        ],
      });
    });

    channel.bind("client.update", (_data: any) => {
      console.log("[pusher] client.update", _data);
      apolloClient.refetchQueries({
        include: [
          api.clients.retrieve,
          api.clients.accounts,
          api.jobs.list,
          api.transactions.countTransactions,
          api.subscriptions.active,
        ],
      });
    });

    channel.bind("recalculate.completed", (_data: any) => {
      console.log("[pusher] recalculate.completed", _data);
      apolloClient.refetchQueries({
        include: [...RECALCULATE_REFETCH_QUERIES],
      });
    });

    channel.bind("assets.refreshed", (_data: any) => {
      console.log("[pusher] assets.refreshed", _data);
      apolloClient.refetchQueries({
        include: [api.clients.assetOptions],
      });
    });

    return () => {
      pusher.unsubscribe(channelName);
    };
  }, [clientId]);
};
