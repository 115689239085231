import { useMutation } from "@apollo/client";
import { Box, HStack, Spinner, Text } from "@chakra-ui/react";
import BigNumber from "bignumber.js";
import { compose } from "lodash/fp";
import { useContext, useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import { useLocation, useParams } from "react-router-dom";
import { show } from "redux-modal";
import { api } from "src/api";
import { CurrencyCodeEnum } from "src/api/generated/types";
import { Button, Info } from "src/components/styled";
import WhiteBox from "src/components/styled/WhiteBox";
import { useClientById, useMe, useMyToast } from "src/hooks";
import { useIsLargeScreen } from "src/hooks/useScreenSize";
import { useTheme } from "src/hooks/useTheme";
import { other } from "src/theme";
import { PortfolioContext } from "../context";
import { AssetRow } from "./AssetRow";
import { NAME_FLEX, VALUE_FLEX } from "./constants";

function Assets() {
  const { clientId } = useParams<{ clientId: string }>();
  const { client } = useClientById(clientId);

  const dispatch = useDispatch();
  const _showModal = compose(dispatch, show);
  const location = useLocation();
  const toast = useMyToast();
  const { me } = useMe();
  const { isLoadingCoins, balances } = useContext(PortfolioContext);
  const { background, header, text, border, secondaryBackground } = useTheme();
  const isSuperUser = me?.isSuperuser || false;

  const [sortColumn, setSortColumn] = useState<"holdings" | "return" | null>(
    null
  );
  const [isAscending, setIsAscending] = useState(true);
  const [showAll, setShowAll] = useState(false);

  const handleSort = (column: "holdings" | "return") => {
    if (sortColumn === column) {
      setIsAscending(!isAscending);
    } else {
      setSortColumn(column);
      setIsAscending(true);
    }
  };

  const _onAddWallet = () => {
    _showModal("AccountModal", {
      location: "no_transactions_page",
    });
  };

  const [syncMetadata] = useMutation(api.assets.syncMetadata);

  const _hardRefreshAssetImages = async () => {
    try {
      await syncMetadata({
        variables: {
          clientId,
        },
        refetchQueries: [api.clients.assetOptions, api.portfolio.get],
      });
    } catch (err) {
      console.error(err);
      toast.show({
        status: "error",
        message: "Error syncing metadata",
      });
    }
  };

  const sortedBalances = useMemo(() => {
    if (!sortColumn)
      return balances.filter((b) =>
        new BigNumber(b.totalFiatAmountCents).dp(0).gt(100)
      );

    return [...balances]
      .filter((b) => new BigNumber(b.totalFiatAmountCents).dp(0).gt(100))
      .sort((a, b) => {
        let comparison = 0;
        if (sortColumn === "holdings") {
          comparison = new BigNumber(a.totalFiatAmountCents)
            .minus(b.totalFiatAmountCents)
            .toNumber();
        } else if (sortColumn === "return") {
          const returnA = new BigNumber(a.gainLossCents || 0);
          const returnB = new BigNumber(b.gainLossCents || 0);
          comparison = returnA.minus(returnB).toNumber();
        }
        return isAscending ? comparison : -comparison;
      });
  }, [balances, sortColumn, isAscending]);

  const displayedBalances = useMemo(() => {
    const numToDisplay = 15;

    const filtered = sortedBalances.filter((b) =>
      new BigNumber(b.totalFiatAmountCents).dp(0).gt(100)
    );
    return showAll ? filtered : filtered.slice(0, numToDisplay);
  }, [sortedBalances, showAll]);

  if (!isLoadingCoins && !balances.length) {
    return (
      <Box
        width="100%"
        padding="3rem 1rem"
        textAlign="center"
        style={{
          borderRadius: 15,
          marginTop: "2rem",
          border: `1px solid ${border}`,
        }}
      >
        <Text color={text}>
          No accounts yet. Add an account to view your portfolio 👇
        </Text>
        <Button variant="primary" marginTop="1rem" onClick={_onAddWallet}>
          Add Account
        </Button>
      </Box>
    );
  }

  return (
    <div
      style={{
        width: "100%",
        flexGrow: 0,
        paddingBottom: 100,
        // marginTop: "1.5rem", FIXME: add back when ready
      }}
    >
      <Box
        display="flex"
        flexWrap="wrap"
        alignSelf="flex-start"
        alignContent="flex-start"
        w="100%"
        // marginTop="2rem" FIXME: add back when ready
      >
        <WhiteBox
          padding="0"
          w="100%"
          marginTop="1rem"
          style={{
            boxShadow: "none",
            //minHeight: LIMIT * 70
          }} // just so less jolty when navigating
          border="none"
          bg={background}
        >
          <AssetHeader
            sortColumn={sortColumn}
            isAscending={isAscending}
            onSort={handleSort}
          />

          {isLoadingCoins && !balances.length ? (
            <div
              style={{
                padding: "10rem 3rem",
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Spinner color={header} />
            </div>
          ) : (
            <>
              {displayedBalances.map((balance) => (
                <AssetRow
                  key={balance.groupKey}
                  portfolioBalance={balance}
                  warning={null}
                  clientId={clientId || ""}
                  currency={client?.currency || CurrencyCodeEnum.Usd}
                />
              ))}

              {sortedBalances.length > 10 && !showAll && (
                <Box textAlign="center" padding="1rem">
                  <Button
                    style={{
                      color: header,
                      backgroundColor: "transparent",
                    }}
                    variant="ghost"
                    onClick={() => setShowAll(true)}
                  >
                    Show More{" "}
                    <i
                      style={{ marginLeft: 10 }}
                      className="fa-solid fa-chevron-down"
                    />
                  </Button>
                </Box>
              )}
            </>
          )}
        </WhiteBox>
      </Box>

      {isSuperUser && (
        <Button
          bg={secondaryBackground + " !important"}
          marginRight="0.5rem"
          marginTop="5rem"
          padding="0.5rem 1rem"
          borderRadius={other.borderRadius}
          onClick={_hardRefreshAssetImages}
        >
          <i
            style={{ marginRight: 5, color: text }}
            className="fa-sharp fa-refresh"
          />{" "}
          <Text color={text} fontSize="sm" padding="0" fontWeight="semibold">
            Refresh Asset Metadata{" "}
            <Info message="Updates images and names of assets if they have changed." />
          </Text>
        </Button>
      )}
    </div>
  );
}

const SortIcon = ({
  active,
  ascending,
}: {
  active: boolean;
  ascending: boolean;
}) => {
  const { text } = useTheme();
  return (
    <Box marginLeft="0.5rem" color={active ? text : `${text}50`}>
      <i
        className={`fa-solid fa-sort${
          active ? (ascending ? "-up" : "-down") : ""
        }`}
      />
    </Box>
  );
};

const AssetHeader = ({
  sortColumn,
  isAscending,
  onSort,
}: {
  sortColumn: "holdings" | "return" | null;
  isAscending: boolean;
  onSort: (column: "holdings" | "return") => void;
}) => {
  const isLarge = useIsLargeScreen();
  const { border, background, text, secondaryBackground, medBackground } =
    useTheme();

  if (!isLarge) {
    return null;
  }

  return (
    <HStack
      width="100%"
      padding="0.35rem 1.25rem 0.35rem 1.25rem"
      style={{
        justifyContent: "space-between",
      }}
      borderRadius={10}
      bg={medBackground}
    >
      {/* <Box width={35} /> */}

      <Box
        display="flex"
        alignItems="center"
        position="relative"
        fontWeight="500"
        fontSize={isLarge ? "sm" : "xs"}
        style={{
          color: text,
          flex: isLarge ? NAME_FLEX : NAME_FLEX - 1,
          marginRight: "calc(35px + 0.5rem)", // bc of the width of the image. hacky
        }}
        // marginLeft="1.25rem !important"
      >
        Asset
      </Box>

      {/* <Tooltip label="Your cost basis/purchase price.">
        <Box
          style={{
            fontWeight: "500",
            cursor: "pointer",
            color: colors.gray10,
            flex: VALUE_FLEX,
          }}
          onClick={() => {
            setSortColumn(PortfolioSortColumnEnum.CostBasis);
            setIsAscending(!isAscending);
          }}
        >
          Basis{" "}
          <SortTriangle
            isSorted={PortfolioSortColumnEnum.CostBasis === sortColumn}
            isAscending={
              isAscending && PortfolioSortColumnEnum.CostBasis === sortColumn
            }
          />
        </Box>
      </Tooltip> */}

      <Box
        style={{
          fontWeight: "500",
          cursor: "pointer",
          marginRight: 50,
          display: "flex",
          flexDirection: "row",
          color: text,
          maxWidth: 100,
          flex: VALUE_FLEX,
          alignItems: "center",
          marginLeft: 0,
        }}
        fontSize={isLarge ? "sm" : "xs"}
        // onClick={() => {
        //   setSortColumn(PortfolioSortColumnEnum.CurrentValue);
        //   setIsAscending(!isAscending);
        // }}
      >
        Percent
      </Box>

      <Box
        style={{
          fontWeight: "500",
          cursor: "pointer",
          color: text,
          maxWidth: 175,
          flex: VALUE_FLEX,
          marginLeft: 0,
        }}
        fontSize={isLarge ? "sm" : "xs"}
        // onClick={() => {
        //   setSortColumn(PortfolioSortColumnEnum.CurrentValue);
        //   setIsAscending(!isAscending);
        // }}
      >
        Price{" "}
      </Box>

      <Box
        style={{
          fontWeight: "500",
          cursor: "pointer",
          color: text,
          maxWidth: 175,
          flex: VALUE_FLEX,
          marginLeft: 0,
          display: "flex",
          alignItems: "center",
        }}
        fontSize={isLarge ? "sm" : "xs"}
        onClick={() => onSort("holdings")}
      >
        Holdings
        <SortIcon active={sortColumn === "holdings"} ascending={isAscending} />
      </Box>

      <Box
        style={{
          alignItems: "center",
          justifyContent: "flex-end",
          color: text,
          display: "flex",
          fontWeight: "500",
          marginLeft: 0,
          maxWidth: 175,
          cursor: "pointer",
          flex: VALUE_FLEX,
        }}
        fontSize={isLarge ? "sm" : "xs"}
        onClick={() => onSort("return")}
      >
        Return
        <SortIcon active={sortColumn === "return"} ascending={isAscending} />
      </Box>

      <div style={{ width: 15 }} />

      {/* <Box width="50px" /> */}
    </HStack>
  );
};

export default Assets;
