import {
  ApolloError,
  FetchPolicy,
  NetworkStatus,
  useLazyQuery,
  useMutation,
  useQuery,
  WatchQueryFetchPolicy,
} from "@apollo/client";
import { keyBy } from "lodash";
import { DateTime } from "luxon";
import { useCallback, useEffect } from "react";
import { useDispatch } from "react-redux";
import { api, BaseTransactionFullFields } from "src/api";
import {
  BaseAccountWithCurrentJobFields,
  BaseClientFields,
  BaseLedgerAccountWithChildrenFields,
  BaseSimpleTransactionFields,
  PartialAssetFields,
} from "src/api/fragments";
import {
  LedgerAccount,
  Query,
  QueryGetClientAssetsArgs,
  QueryGetClientTransactionsArgs,
} from "src/api/generated/types";
import { Maybe } from "src/core";
import { setPrepopulatedTxns } from "src/redux/reducers/active";
import { isLoadingGQL } from "src/utils/helpers";

// frequency we auto-sync for them if they have Awaken open
const MAX_SYNC_BUFFER_MINUTES = 12 * 60;

export type GetTransactionsQuery = Omit<
  QueryGetClientTransactionsArgs,
  "clientId"
>;

// FIXME: put this somewhere else
export type SearchResults = {
  account: BaseLedgerAccountWithChildrenFields;
  ancestors: BaseLedgerAccountWithChildrenFields[];
};

export type PartialLedgerAccount = Pick<
  LedgerAccount,
  "id" | "name" | "type" | "subType" | "classification"
>;

export type UseClientResponse = {
  // Data
  accounts: BaseAccountWithCurrentJobFields[];
  transactions: BaseSimpleTransactionFields[];
  ledgerAccounts: {
    tree: BaseLedgerAccountWithChildrenFields[];
    accounts: PartialLedgerAccount[];
  };
  assets: PartialAssetFields[];
  client: Maybe<BaseClientFields>;
  search: {
    ledgerAccountResults: SearchResults[];
  };
  // Network state
  isLoadingClient: boolean;
  isLoadingAssets: boolean;
  isLoadingAccounts: boolean;
  isLoadingTransactions: boolean;
  isLoadingLedgerAccounts: boolean;
  isLoadingSearchLedgerAccounts: boolean;
  // Failed
  errorGetTransactions: ApolloError | undefined;
  // Totals
  totalTransactions: number;
  // Functions
  getTransactions: (query: GetTransactionsQuery) => Promise<void>;
  preFetchTransactions: (query: GetTransactionsQuery) => Promise<void>;
  getLedgerAccounts: (depth?: number) => Promise<void>;
  getAssets: () => Promise<void>;
  searchLedgerAccounts: (search: Maybe<string>) => Promise<void>;
  checkToSyncNewTransactionsForClient: () => Promise<void>;
  refetchClient: (variables?: any) => Promise<any>;
};

type Opts = {
  pollAccountsMs?: number | null;
  accountFetchPolicy?: FetchPolicy;
  clientFetchPolicy?: FetchPolicy;
  txnFetchPolicy?: WatchQueryFetchPolicy;
  skipFetchAssetsOnLoad?: boolean;
  onlyFetchClient?: boolean;
};

export const useClientById = (
  clientId?: Maybe<string>,
  opts?: Opts
): UseClientResponse => {
  const skipFetchAssetsOnLoad = opts?.skipFetchAssetsOnLoad || false;
  const onlyFetchClient = opts?.onlyFetchClient || false;

  const {
    data: clientData,
    networkStatus: clientNetworkStatus,
    refetch: refetchClient,
  } = useQuery<{
    getClientById?: BaseClientFields;
  }>(api.clients.retrieve, {
    notifyOnNetworkStatusChange: true,
    skip: !clientId,
    variables: { clientId },
    fetchPolicy: opts?.clientFetchPolicy || "cache-and-network",
  });

  const [
    fetchAccounts,
    {
      data: accountsData,
      networkStatus: accountsNetworkStatus,
      startPolling,
      stopPolling,
    },
  ] = useLazyQuery<{ getClientAccounts?: BaseAccountWithCurrentJobFields[] }>(
    api.clients.accounts,
    {
      notifyOnNetworkStatusChange: true,
      fetchPolicy: opts?.accountFetchPolicy || "no-cache",
    }
  );

  useEffect(() => {
    if (opts?.pollAccountsMs) {
      startPolling(opts?.pollAccountsMs);
    } else {
      stopPolling();
    }
  }, [opts?.pollAccountsMs]);

  const [
    fetchTransactions,
    {
      data: transactionData,
      networkStatus: transactionNetworkStatus,
      loading: transactionLoading,
      error: errorGetTransactions,
      client: transactionClient,
    },
  ] = useLazyQuery<{
    getClientTransactions?: {
      transactions: BaseSimpleTransactionFields[];
      total: number;
    };
  }>(api.clients.transactions, {
    notifyOnNetworkStatusChange: true,
    fetchPolicy: opts?.txnFetchPolicy || "network-only",
  });

  // console.log(transactionData);

  // cache the full data
  const [fetchForTransactionIds, { client: fetchForTransactionIdsClient }] =
    useLazyQuery<Pick<Query, "getTransactions">>(
      api.clients.fetchForTransactionIds,
      {
        notifyOnNetworkStatusChange: true,
        fetchPolicy: opts?.txnFetchPolicy || "network-only",
      }
    );

  const [
    fetchLedgerAccounts,
    { data: ledgerAccountsData, networkStatus: ledgerAccountsNetworkStatus },
  ] = useLazyQuery<
    {
      getClientLedgerAccounts?: {
        accounts: PartialLedgerAccount[];
        tree: BaseLedgerAccountWithChildrenFields[];
      };
    },
    { clientId: string; depth: number }
  >(api.clients.ledgerAccounts, {
    notifyOnNetworkStatusChange: true,
    fetchPolicy: "cache-and-network",
  });

  const dispatch = useDispatch();

  const [fetchAssets, { data: assetData, networkStatus: assetNetworkStatus }] =
    useLazyQuery<
      Pick<Query, "getClientAssetsOptions">,
      QueryGetClientAssetsArgs
    >(api.clients.assetOptions, {
      fetchPolicy: "cache-and-network",
      notifyOnNetworkStatusChange: true,
    });

  const [
    searchLedgerAccounts,
    {
      data: searchLedgerAccountsData,
      networkStatus: searchLedgerAccountsNetworkStatus,
    },
  ] = useLazyQuery<
    {
      searchClientLedgerAccounts?: SearchResults[];
    },
    { clientId: string; search: string }
  >(api.clients.searchLedgerAccounts, {
    notifyOnNetworkStatusChange: true,
    fetchPolicy: "cache-and-network",
  });

  const [syncAll] = useMutation(api.accounts.syncAll);

  const client = clientData?.getClientById || null;

  const _fetchAssets = useCallback(async () => {
    if (!clientId) {
      return;
    }

    await fetchAssets({
      variables: { clientId, hideSpamAssets: true },
    });
  }, [clientId, fetchAssets]);

  useEffect(() => {
    if (!clientId) {
      return;
    }

    if (onlyFetchClient) {
      return;
    }

    void fetchAccounts({
      variables: { clientId },
    });

    if (!skipFetchAssetsOnLoad) {
      void fetchAssets({
        variables: { clientId, hideSpamAssets: true },
      });
    }
  }, [clientId, onlyFetchClient, skipFetchAssetsOnLoad]);

  const _fetchClientTransactions = useCallback(
    async (query: Omit<QueryGetClientTransactionsArgs, "clientId">) => {
      if (!clientId) {
        return;
      }
      const variables: QueryGetClientTransactionsArgs = {
        ...query,
        clientId,
      };

      const txns = await fetchTransactions({
        variables,
      });

      const txnIds = txns.data?.getClientTransactions?.transactions.map(
        (txn) => txn.id
      );

      // console.log(`prefetching for  ${txnIds?.join(",")}`);

      const prefetchedTransactionsResponse = await fetchForTransactionIds({
        variables: { clientId, transactionIds: txnIds },
      });

      const prefetchedFullTxns = (prefetchedTransactionsResponse?.data
        ?.getTransactions ?? []) as any[] as BaseTransactionFullFields[];

      if (prefetchedFullTxns) {
        const byId = keyBy(prefetchedFullTxns, (t) => t.id);
        dispatch(setPrepopulatedTxns(byId));
      }
    },
    [clientId]
  );

  const _preFetchClientTransactions = useCallback(
    async (query: Omit<QueryGetClientTransactionsArgs, "clientId">) => {
      console.log(`[prefetching txns]`);
      if (!clientId) {
        return;
      }

      const variables: QueryGetClientTransactionsArgs = {
        ...query,
        clientId,
      };

      try {
        // Prefetch the transactions into Apollo cache
        const { data: txnListData } = await transactionClient.query({
          query: api.clients.transactions,
          variables,
          fetchPolicy: "network-only", // Ensure we get fresh data
        });

        const txnIds = txnListData?.getClientTransactions?.transactions?.map(
          (txn: BaseSimpleTransactionFields) => txn.id
        );

        if (txnIds?.length) {
          // Prefetch full transaction details using the dedicated client
          void fetchForTransactionIdsClient
            .query({
              query: api.clients.fetchForTransactionIds,
              variables: { clientId, transactionIds: txnIds },
              fetchPolicy: "network-only",
            })
            .then((response) => {
              // const fullTxns = response.data
              //   ?.getTransactions as BaseTransactionFullFields[];
              // if (fullTxns) {
              //   const byId = keyBy(fullTxns, (t) => t.id);
              //   dispatch(setPrepopulatedTxns(byId));
              // }
            });
        }
      } catch (error) {
        console.error("Error prefetching transactions:", error);
      }
    },
    [clientId, transactionClient, fetchForTransactionIdsClient, dispatch]
  );

  const _fetchLedgerAccounts = useCallback(
    async (depth = 4) => {
      if (!clientId) {
        return;
      }

      await fetchLedgerAccounts({
        variables: { clientId, depth },
      });
    },
    [clientId]
  );

  const _searchClientLedgerAccounts = useCallback(
    async (search: Maybe<string>) => {
      if (!clientId || !search) {
        return;
      }

      await searchLedgerAccounts({
        variables: { clientId, search },
      });
    },
    [clientId]
  );

  const _checkToSyncNewTransactionsForClient = useCallback(async () => {
    const oldLastSyncedAt = client?.lastSyncedAt;
    if (!client) return;
    if (!client.canContinuouslySync) return;

    // console.log("[checking sync new txns]");
    if (!oldLastSyncedAt) {
      await syncAll({
        variables: {
          clientId: clientId,
          isContinuousSync: true,
        },
        refetchQueries: [api.clients.retrieve],
      });
      return;
    }

    const lastSyncedAt = DateTime.fromJSDate(new Date(oldLastSyncedAt));
    const absValue = Math.abs(lastSyncedAt.diffNow("minutes").minutes);

    // console.log(`[last synced at ${lastSyncedAt} (${absValue} min ago)]`);
    // if last synced at is more than 10 minutes in the past, force a full resync
    // this minutes will be negative, so need to check it is greater than the buffer
    if (absValue > MAX_SYNC_BUFFER_MINUTES) {
      await syncAll({
        variables: {
          clientId: clientId,
          isContinuousSync: true,
        },
        refetchQueries: [api.clients.retrieve],
      });
      return;
    }
  }, [clientId, client?.lastSyncedAt]);

  const isLoadingClient = isLoadingGQL(clientNetworkStatus);
  const isLoadingAssets = isLoadingGQL(assetNetworkStatus);
  const isLoadingAccounts = accountsNetworkStatus === NetworkStatus.loading; // don't want to show the spinner for the full page if it is just the refetch
  const isLoadingTransactions =
    isLoadingGQL(transactionNetworkStatus) || transactionLoading;
  const isLoadingLedgerAccounts = isLoadingGQL(ledgerAccountsNetworkStatus);
  const isLoadingSearchLedgerAccounts = isLoadingGQL(
    searchLedgerAccountsNetworkStatus
  );

  return {
    // Data
    client,
    accounts: accountsData?.getClientAccounts || [],
    transactions: transactionData?.getClientTransactions?.transactions || [],
    ledgerAccounts: {
      tree: ledgerAccountsData?.getClientLedgerAccounts?.tree || [],
      accounts: ledgerAccountsData?.getClientLedgerAccounts?.accounts || [],
    },
    assets: (assetData?.getClientAssetsOptions?.assets ||
      []) as PartialAssetFields[],
    search: {
      ledgerAccountResults:
        searchLedgerAccountsData?.searchClientLedgerAccounts || [],
    },
    // Loading
    isLoadingClient,
    isLoadingAssets,
    isLoadingAccounts,
    isLoadingTransactions,
    isLoadingLedgerAccounts,
    isLoadingSearchLedgerAccounts,
    // Errors
    errorGetTransactions,
    // totals
    totalTransactions: transactionData?.getClientTransactions?.total || 0,
    // Functions
    getTransactions: _fetchClientTransactions,
    preFetchTransactions: _preFetchClientTransactions, // to fetch the next page
    getAssets: _fetchAssets,
    getLedgerAccounts: _fetchLedgerAccounts,
    searchLedgerAccounts: _searchClientLedgerAccounts,
    checkToSyncNewTransactionsForClient: _checkToSyncNewTransactionsForClient,
    refetchClient: refetchClient,
  };
};
