import { useMutation } from "@apollo/client";
import {
  Box,
  Checkbox,
  Collapse,
  Container,
  HStack,
  Text,
  useToast,
} from "@chakra-ui/react";
import { PublicKey } from "@solana/web3.js";
import moment from "moment";
import { useCallback, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import { connectModal, InjectedProps } from "redux-modal";
import { api } from "src/api";
import { BaseAccountFields } from "src/api/fragments";
import {
  AccountProviderEnum,
  AccountTypeEnum,
  ImportTypeEnum,
  MutationCreateAccountArgs,
} from "src/api/generated/types";
import { Modal } from "src/components/Modal";
import { MyToast } from "src/components/MyToast";
import { AwakenTooltip, Button, DatePicker, Info } from "src/components/styled";
import { Input } from "src/components/styled/Form/Input";
import { Touchable } from "src/components/Touchable";
import YoutubeLink from "src/components/YoutubeLink";
import { useClientById, useMyToast } from "src/hooks";
import { useIsLargeScreen } from "src/hooks/useScreenSize";
import { useTheme } from "src/hooks/useTheme";
import {
  ALLOWED_EVM_PROVIDERS,
  EVM_PROVIDERS,
  isValidEVMAddress,
} from "src/modules/ledger/accounts";
import { colors } from "src/theme";
import { useHandleVezgoLink } from "../AccountModal/AccountsWorkflow";
import { IntegrationProviderInfo } from "../AccountModal/types";
import { DEFAULT_EMOJIS } from "./defaultEmojis";
import { EmojiWalletName } from "./EmojiWalletName";
import { WalletHeaderImage } from "./WalletHeaderImage";

type Props = InjectedProps & {
  provider: string;
  onSuccess: (acct?: BaseAccountFields) => void;
  messageForWalletInput?: string;
  integration: IntegrationProviderInfo;
  label?: string;
  emoji?: string;
};

// same exact function on the backend
function isValidSolanaAddress(address: string): boolean {
  try {
    new PublicKey(address);
    return true;
  } catch (e) {
    return false;
  }
}

function _WalletAccountModal({
  handleHide,
  onSuccess,
  show: isVisible,
  provider,
  integration,
  messageForWalletInput,
  label: _label,
  emoji: _emoji,
}: Props) {
  const isEVM = ALLOWED_EVM_PROVIDERS.has(provider?.toLowerCase() as any);
  const toast = useToast();
  const { clientId } = useParams<{ clientId: string }>();

  const { client } = useClientById(clientId, {
    accountFetchPolicy: "cache-first",
    clientFetchPolicy: "cache-first",
    skipFetchAssetsOnLoad: true,
    onlyFetchClient: true,
  });

  const timezone = client?.timezone || "UTC";

  const myToast = useMyToast();
  const [label, setLabel] = useState(_label || "");
  const [address, setAddress] = useState("");
  const [skipRecalculate, setSkipRecalculate] = useState(false);
  const [includeStakingRewards, setIncludeStakingRewards] = useState(true);
  const [isLargeAccount, setIsLargeAccount] = useState(false);

  const [shouldUploadAllEVM, setShouldUploadAllEVM] = useState<boolean>(isEVM);
  const [createAccount, { loading }] = useMutation(api.accounts.create);

  const [isOpenEmoji, setOpenEmoji] = useState(false);
  const [defaultEmoji, defaultName] =
    DEFAULT_EMOJIS[Math.floor(Math.random() * DEFAULT_EMOJIS.length)];
  const [emoji, setEmoji] = useState(_emoji || defaultEmoji);

  const vezgoLink = useHandleVezgoLink(clientId);

  const [showAdvanced, setShowAdvanced] = useState(false);
  const [startImportingAt, setStartImportingAt] = useState("");

  const formattedDate = useMemo(() => {
    if (!startImportingAt) return "";

    // move the date to UTC, and then add the timezone to it

    const d = moment.tz(startImportingAt, timezone);

    return d.toISOString();
  }, [startImportingAt, timezone]);

  const _onAddWallet = useCallback(async () => {
    try {
      if (!label || !address) {
        return toast({
          position: "top",
          render: () => (
            <MyToast
              message={"Missing fields, please fill out below 👇"}
              status="error"
            />
          ),
        });
      }

      const trimmedAddress = address.replace(" ", ""); // remove spaces

      const isSUI = provider?.toLowerCase() === "sui";
      const isEVM = EVM_PROVIDERS.has(provider?.toLowerCase());

      if (isSUI) {
        // just make sure starts with 0x
        if (!trimmedAddress.startsWith("0x")) {
          return toast({
            position: "top",
            render: () => (
              <MyToast
                message={"Invalid Sui address. Please check and try again."}
                status="error"
              />
            ),
          });
        }
      }

      if (isEVM) {
        const isValidEMV = isValidEVMAddress(trimmedAddress);

        if (!isValidEMV) {
          // is the name a valid evm address tho?
          const isNameValid = isValidEVMAddress(label);

          return toast({
            position: "top",
            render: () => (
              <MyToast
                message={`Invalid EVM address. ${
                  isNameValid
                    ? "It looks like you have put your address in the 'Wallet Name' field instead of the 'Wallet Address' field :)."
                    : ""
                }`}
                status="error"
              />
            ),
          });
        }
      }

      if (
        provider?.toLowerCase() === AccountProviderEnum.Solana.toLowerCase() &&
        !isValidSolanaAddress(trimmedAddress)
      ) {
        return toast({
          position: "top",
          render: () => (
            <MyToast
              message={"Invalid Solana address. Please check and try again."}
              status="error"
            />
          ),
        });
      }

      if (
        provider?.toLowerCase() === AccountProviderEnum.Bitcoin.toLowerCase() &&
        // if xpub ypub zpub etc
        (trimmedAddress.includes("xpub") ||
          trimmedAddress.includes("ypub") ||
          trimmedAddress.includes("zpub"))
      ) {
        return toast({
          position: "top",
          render: () => (
            <MyToast
              message={
                "To add an xpub/ypub/zpub address, please click the 'Add xpub/ypub/zpub' link below the 'Add Account' button."
              }
              status="error"
            />
          ),
        });
      }
      // add the offset to the date to get the UTC date

      const browserOffset = new Date().getTimezoneOffset();
      const offset = moment.tz.zone(timezone)?.utcOffset(Date.now()) || 0;
      const dateInClientTz = moment
        .utc(startImportingAt)
        .add(offset, "minutes")
        .subtract(Math.abs(browserOffset), "minutes");

      const createAccountArgs: MutationCreateAccountArgs = {
        code: null,
        provider: provider,
        type: AccountTypeEnum.Wallet,
        redirectUrl: null,
        address: trimmedAddress,
        importType: ImportTypeEnum.Address,
        label: label ? `${emoji} ${label}` : null,
        clientId: clientId || null,
        skipRecalculate: skipRecalculate,
        isLargeAccount: isLargeAccount,
        includeStakingRewards,
        shouldUploadAllEVM: isEVM ? shouldUploadAllEVM : null,
        startImportingAt: dateInClientTz.toDate(),
      };

      await createAccount({
        variables: createAccountArgs,
        refetchQueries: [api.clients.accounts, api.portfolio.get],
      });

      myToast.show({
        message: `Importing transactions 🤖 This may take a while.`,
        status: "success",
      });

      handleHide();

      if (onSuccess) {
        onSuccess();
      }
    } catch (err) {
      if (err instanceof Error) {
        toast({
          position: "top",
          render: () => (
            <MyToast message={(err as Error).message} status="error" />
          ),
        });
      }
    }
  }, [
    address,
    emoji,
    label,
    clientId,
    skipRecalculate,
    isLargeAccount,
    shouldUploadAllEVM,
    includeStakingRewards,
    startImportingAt,
    timezone,
  ]);

  const BestInfo = useMemo(() => {
    const text = <div />;

    // doing extraordinary support on AddAccountModal instead
    // if (provider === "ethereum") {
    //   text = (
    //     <Text marginTop="1rem" w="100%" textAlign={"left"} fontSize="sm">
    //       🎖 Best tax software for Ethereum{" "}
    //       <Info message="Awaken supports NFTs, staking, liquidity pools, internal transfers, bridging, and more on Ethereum." />
    //     </Text>
    //   );
    // }
    // else if (provider === "coinbase") {
    //   text = (
    //     <Text marginTop="1rem" w="100%" textAlign={"left"} fontSize="sm">
    //       🎖 Best tax software for Coinbase{" "}
    //       <Info message="Awaken supports exchange-to-wallet transactions, staking, and even advanced trades on Coinbase." />
    //     </Text>
    //   );
    // }

    // kraken: withdrawals, deposits, trades, and staking. dunno about margin there either

    return text;
  }, [provider]);

  const openLink = useCallback(async () => {
    if (integration.provider !== "bitcoin") return null;

    await vezgoLink(integration, {
      vezgoProvider: "bitcoin",
    });
  }, [integration]);

  const theme = useTheme();
  const isLarge = useIsLargeScreen();

  return (
    <Modal
      title={
        <>
          <WalletHeaderImage
            logoUrl={
              integration && integration.logoUrl // `integration` is undefined sometimes for some reason
            }
          />
          Add Account
        </>
      }
      isVisible={isVisible}
      handleHide={handleHide}
      maxW="600px"
      w={isLarge ? undefined : "95%"}
      overflow="visible"
      Footer={
        <Box w="100%">
          <Button
            width="100%"
            variant="primary"
            isLoading={loading}
            onClick={_onAddWallet}
          >
            Add Account
          </Button>
          {BestInfo}
          {/* <HStack marginTop="1.5rem">
            <Checkbox
              isChecked={skipRecalculate}
              onChange={(e) => {
                setSkipRecalculate(e.target.checked);
              }}
            />
            <Text fontSize="sm">
              Advanced: skip recalculation{" "}
              <Info message="If you are uploading a lot of wallets upfront, you may want to skip our tax recalculation. This will make the import faster. You can easily run our tax recalculation on the Tax Reports page at any time." />
            </Text>
          </HStack> */}

          {isEVM && shouldUploadAllEVM && (
            <HStack marginTop="0.75rem" w="100%" textAlign="center">
              <AwakenTooltip
                message={`We'll automatically import your EVM wallets across multiple blockchains including: ${Array.from(
                  ALLOWED_EVM_PROVIDERS
                ).join(", ")}.`}
              >
                <Text
                  w="100%"
                  fontSize="sm"
                  color={theme.text}
                  textAlign="center"
                >
                  We'll automatically import all of your EVM wallets{" "}
                  <i
                    className="fas fa-check-circle"
                    style={{ color: colors.positive }}
                  />
                </Text>
              </AwakenTooltip>
            </HStack>
          )}
        </Box>
      }
      headerProps={{
        style: {
          padding: isLarge ? "2rem 2rem 1rem 2rem" : "1rem",
        },
      }}
      footerProps={{
        style: {
          padding: isLarge ? "2rem" : "1rem",
        },
      }}
      bodyProps={{
        style: {
          padding: isLarge ? "0 2rem" : "1rem",
        },
      }}
    >
      <Container padding="0px" marginTop="0px !important" paddingBottom="2rem">
        {provider === "solana" && (
          <YoutubeLink
            youtubeVideo={
              "https://youtu.be/UYAIxne3g6Y?si=Kb4XjHABw3CqH0dk&t=130"
            }
          />
        )}

        {provider === "bitcoin" && (
          <YoutubeLink
            youtubeVideo={
              "https://youtu.be/qYBfYYyWzW0?si=iau7ZjmrXUTITohx&t=141"
            }
          />
        )}

        {provider === "base" && (
          <YoutubeLink
            youtubeVideo={
              "https://youtu.be/6TxCLUdWaos?si=2gJcjMoCS_Bkz0dL&t=108"
            }
          />
        )}
        <br />
        <EmojiWalletName
          label={label}
          setLabel={setLabel}
          emoji={emoji}
          setEmoji={setEmoji}
          isOpenEmoji={isOpenEmoji}
          setOpenEmoji={setOpenEmoji}
          defaultName={defaultName}
        />
        <Input
          value={address}
          onChange={(e) => setAddress(e.target.value)}
          label="Wallet Address"
          subtitle={
            messageForWalletInput ||
            (isEVM ? `Your public key or ENS address.` : "")
          }
          infoMessage="Your wallet address or ENS address. For ETH/Polygon, this starts with 0x. Or an ENS address like bigduca.eth."
          placeholder={""}
        />

        {integration?.provider === "bitcoin" && (
          <HStack marginTop="1.5rem">
            <Text
              onClick={openLink}
              color={colors.primary}
              fontWeight="bold"
              fontSize="sm"
              cursor="pointer"
              textDecoration="underline"
            >
              Click here to add xpub/ypub/zpub{" "}
              <i className="fas fa-arrow-right" />
            </Text>
          </HStack>
        )}

        <Box mt={"20px"}>
          <Touchable
            onClick={() => setShowAdvanced(!showAdvanced)}
            iconPosition="right"
            iconName="fa-sharp fa-chevron-down"
            label={"See Advanced Options"}
            bg={theme.medBackground}
          />

          <Collapse in={showAdvanced}>
            <Box mt={2} p={4} bg={theme.medBackground} borderRadius="md">
              <DatePicker
                containerStyle={{ marginBottom: 5 }}
                label="Import Transactions From Date"
                subtitle="Only import transactions after this date. If you have transactions before this date you'll need to make sure you add them!"
                infoMessage="If you set this date and are missing previous transactions, your tax history will be incomplete. If you choose to do this, make sure to address missing cost basis; we cannot help you with this."
                name="date"
                onChange={(date) => {
                  // Create a moment in the client's timezone from the selected date
                  // const dateInClientTz = moment.tz(date, timezone);
                  setStartImportingAt(date?.toISOString() || "");
                }}
                value={startImportingAt ? new Date(startImportingAt) : null}
                dateFormat="MM/dd/yyyy"
                showTimeSelect={false}
                placeholder=""
              />

              {formattedDate && (
                <>
                  <Text fontSize="sm" marginBottom="1.5rem" color={theme.text}>
                    Will import after{" "}
                    {moment(formattedDate).format("h:mma M/D/YYYY")} ({timezone}
                    ){" "}
                    <Info message="This is the time of the first transaction in your wallet. It's inclusive, so if you import from 12:00 AM, it will include transactions that happened at exactly 12:00 AM and onwards." />
                  </Text>
                </>
              )}

              {provider === "solana" && (
                <HStack marginTop="1rem">
                  <Checkbox
                    isChecked={includeStakingRewards}
                    onChange={(e) => {
                      setIncludeStakingRewards(e.target.checked);
                    }}
                  />
                  <Text fontSize="sm" color={theme.text}>
                    Include Solana staking reward transactions{" "}
                    <Info
                      message={`If you have staking accounts and you'd like us to pull in the rewards as transactions, which will default to being taxed as income when the staking account receives them, check this box. Otherwise, reward income is realized when you unstake, and not as they are paid to your staking accounts. Note: this will make the import take up to 5 minutes longer.`}
                    />
                  </Text>
                </HStack>
              )}

              {isEVM && (
                <HStack marginTop="0.5rem">
                  <Checkbox
                    isChecked={shouldUploadAllEVM}
                    onChange={(e) => {
                      setShouldUploadAllEVM(e.target.checked);
                    }}
                  />
                  <Text color={theme.text} fontSize="sm">
                    Upload all EVM wallets{" "}
                    <Info
                      message={`We'll import wallets for ${Array.from(
                        ALLOWED_EVM_PROVIDERS
                      ).join(", ")} automatically.`}
                    />
                  </Text>
                </HStack>
              )}

              {isEVM && (
                <HStack marginTop="1rem">
                  <Checkbox
                    isChecked={isLargeAccount}
                    onChange={(e) => {
                      setIsLargeAccount(e.target.checked);
                    }}
                  />
                  <Text color={theme.text} fontSize="sm">
                    Large Wallet (over 10k transactions){" "}
                    <Info message="These uploads take longer. Please check this for wallets with 10k+ transactions so we can scan the full blockchain for your history. We have a cap at 30,000 transactions; if you exceed this, contact support and we will help you!" />
                  </Text>
                </HStack>
              )}
            </Box>
          </Collapse>
        </Box>
      </Container>
    </Modal>
  );
}

export const WalletAccountModal = connectModal({
  name: "WalletAccountModal",
})(_WalletAccountModal);
