import { Spinner } from "@chakra-ui/react";
import {
  ColorType,
  CrosshairMode,
  LineStyle,
  MouseEventParams,
  SingleValueData,
  createChart,
} from "lightweight-charts";
import { throttle } from "lodash";
import React, { useEffect, useRef } from "react";
import { useParams } from "react-router-dom";
import { Maybe } from "src/core";
import { useTheme } from "src/hooks/useTheme";
import { TimeFrame, getTimeFormat } from "../utils";

const CHART_HEIGHT = 325;

export const PriceChart = React.memo(
  ({
    timezone,
    timeFrame,
    lineColor,
    currentValue,
    onHoverValue,
    onHoverPoint,
    points,
    isLoading,
  }: {
    timezone: string;
    timeFrame: Maybe<TimeFrame>;
    lineColor: string;
    currentValue: Maybe<SingleValueData>;
    onHoverPoint: any;
    onHoverValue: any;
    points: SingleValueData[];
    isLoading: boolean;
  }) => {
    const chartContainerRef = useRef(null);
    const { clientId } = useParams<{ clientId: string }>();
    const theme = useTheme();

    useEffect(() => {
      if (!chartContainerRef.current) return;

      const handleResize = () => {
        chart.timeScale().fitContent();
      };

      const dateTimeFormat = getTimeFormat(timezone, timeFrame);

      const chart = createChart(chartContainerRef.current!, {
        handleScroll: {
          mouseWheel: false,
          pressedMouseMove: false,
          horzTouchDrag: false,
          vertTouchDrag: false,
        },
        handleScale: {
          axisPressedMouseMove: false,
          mouseWheel: false,
          pinch: false,
        },
        layout: {
          background: { type: ColorType.Solid, color: theme.background },
          // textColor,
        },
        grid: {
          vertLines: {
            visible: false,
          },
          horzLines: {
            visible: false,
          },
        },
        crosshair: {
          mode: CrosshairMode.Magnet,
          vertLine: {
            style: LineStyle.Solid,
            labelVisible: true,
            // labelBackgroundColor: "white",
          },
          horzLine: { visible: false },
        },
        rightPriceScale: {
          visible: false,
        },
        timeScale: {
          visible: true,
          borderVisible: false,
          secondsVisible: true,
          timeVisible: !!dateTimeFormat.timeFormat,
          ticksVisible: false,
          tickMarkFormatter: () => "",
        },
        localization: {
          dateFormat: dateTimeFormat.dateFormat ?? undefined,
          timeFormatter: dateTimeFormat.timeFormat ?? undefined,
        },
        height: CHART_HEIGHT,
        autoSize: true,
      });

      const lineSeries = chart.addLineSeries({
        color: lineColor,
        lineWidth: 2,
        lineStyle: LineStyle.Solid,
        // priceLineColor: "blue",
        priceLineVisible: false,
      });

      // const costBasisLine = chart.addLineSeries({
      //   color: colors.yellow50,
      //   lineWidth: 2,
      //   lineStyle: LineStyle.Dashed,
      //   priceLineVisible: false,
      // });

      const clickEventHandler = (param: MouseEventParams) => {
        if (!param.point) {
          return;
        }

        const data = param.seriesData.get(lineSeries);

        if (data) {
          const value = data as SingleValueData;
          // onHover.current(true);
          onHoverPoint.current(value ?? currentValue ?? null);
          onHoverValue.current(value?.value ?? currentValue?.value ?? null);

          const date = new Date((value.time as any) * 1000);

          // setStartDate(date);
          // setEndDate(null);

          return;
        }
      };

      const subscribeCrosshairMove = throttle((param: MouseEventParams) => {
        if (!param.point) {
          // onHover.current(false);
          onHoverPoint.current(currentValue);
          onHoverValue.current(currentValue?.value ?? null);
          return;
        }
        const data = param.seriesData.get(lineSeries);
        const value = data as SingleValueData;
        // onHover.current(true);
        onHoverPoint.current(value ?? currentValue ?? null);
        onHoverValue.current(value?.value ?? currentValue?.value ?? null);
      }, 150);

      chart.subscribeClick(clickEventHandler);
      chart.subscribeCrosshairMove(subscribeCrosshairMove);

      const totalPoints = points
        .slice(0, 5_000)
        .filter(
          (p) => (p.time as number) <= Math.floor(new Date().getTime() / 1_000)
        );

      lineSeries.setData(totalPoints);

      // if (
      //   points.length &&
      //   timeFrame?.type === HistoricalBalanceIntervalEnum.Day
      // ) {
      //   lineSeries.setMarkers([
      //     {
      //       shape: "circle",
      //       time: points[points.length - 1].time,
      //       position: "inBar",
      //       size: 1.5,
      //       id: "last-point",
      //       color: lineColor,
      //     },
      //   ]); // Set color for the most recent point
      // }

      chart.timeScale().fitContent();

      window.addEventListener("resize", handleResize);

      return () => {
        window.removeEventListener("resize", handleResize);
        chart.unsubscribeClick(clickEventHandler);
        chart.unsubscribeCrosshairMove(subscribeCrosshairMove);
        chart.remove();
      };
    }, [
      theme.background,
      points,
      timezone,
      timeFrame,
      currentValue,
      lineColor,
      clientId,
    ]);

    if (isLoading) {
      return (
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "center",
            height: "100%",
            width: "100%",
            background: theme.background,
          }}
        >
          <Spinner size="md" color={theme.text} />
        </div>
      );
    }

    return (
      <div
        style={{
          background: theme.background,
          width: "100%",
          height: "100%",
          position: "relative",
        }}
        ref={chartContainerRef}
      />
    );
  },
  (prev, next) => {
    return (
      prev.lineColor === next.lineColor &&
      prev.isLoading === next.isLoading &&
      JSON.stringify(prev.points) === JSON.stringify(next.points) &&
      JSON.stringify(prev.timeFrame) === JSON.stringify(next.timeFrame) &&
      JSON.stringify(prev.currentValue) === JSON.stringify(next.currentValue)
    );
  }
);
