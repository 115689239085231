import { useQuery } from "@apollo/client";
import {
  Box,
  BoxProps,
  Flex,
  Popover,
  PopoverContent,
  PopoverTrigger,
  Text,
  TextProps,
  VStack,
} from "@chakra-ui/react";
import { CSSProperties, useCallback, useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { api } from "src/api";
import { ClientStatusEnum, Query } from "src/api/generated/types";
import { useClientById, useRecalculate } from "src/hooks";
import { useIsLargeScreen } from "src/hooks/useScreenSize";
import { useTheme } from "src/hooks/useTheme";
import { colors, other } from "src/theme";
import { formatNum, getTimeMessage } from "src/utils/helpers";
import { Touchable } from "../Touchable";
import { AwakenTooltip } from "./AwakenTooltip";
import { Button } from "./Button";
import { Info } from "./Info";

type RecalculateButtonProps = BoxProps & {
  message?: string;
  buttonProps?: BoxProps;
  textProps?: TextProps;
  iconStyle?: CSSProperties;
  showReplay?: boolean;
};

export function RecalculateButton({
  message,
  buttonProps,
  textProps,
  iconStyle,
  showReplay,
  ...props
}: RecalculateButtonProps) {
  const { clientId } = useParams();
  const _recalculate = useRecalculate();
  const theme = useTheme();
  const [isOpen, setIsOpen] = useState(false);
  const { client } = useClientById(clientId, {
    onlyFetchClient: true,
  });

  const { data, refetch } = useQuery<Pick<Query, "getReplayOverview">>(
    api.graph.getReplayInfo,
    {
      variables: {
        clientId: clientId,
      },
      skip: false,
      fetchPolicy: "cache-and-network", // To ensure data updates
    }
  );

  useEffect(() => {
    if (showReplay || isOpen) {
      refetch();
    }
  }, [showReplay, isOpen]);

  const replayInfo = data?.getReplayOverview;

  // console.log(replayInfo);

  const recalculate = useCallback(
    async (doNotReplay?: boolean) => {
      if (!showReplay) {
        await _recalculate(true);
        setIsOpen(false);
        return;
      }
      // if no replay or it isn't needed, replay the whole thing
      if (!replayInfo || !replayInfo.isReplayNeeded) {
        await _recalculate(true);
        setIsOpen(false);
        return;
      }
      await _recalculate(doNotReplay);
      setIsOpen(false);
    },
    [showReplay, replayInfo]
  );

  const isLarge = useIsLargeScreen();
  const { background, secondaryBackground, border, text } = useTheme();
  const isDisabled =
    client?.status === ClientStatusEnum.Recalculating ||
    (showReplay && !!replayInfo && !replayInfo?.isReplayNeeded);

  const RecalcBtn = (
    <div style={{ display: "inherit" }}>
      <Touchable
        _hover={{
          bg: isDisabled
            ? theme.secondaryBackground2
            : buttonProps?.bg || secondaryBackground,
        }}
        padding={isLarge ? "0.5rem 1rem" : "0.5rem"}
        borderRadius={other.borderRadius}
        cursor={isDisabled ? "not-allowed" : "pointer"}
        onClick={() => recalculate()}
        labelStyle={{ color: isDisabled ? theme.text : colors.white }}
        w="auto"
        {...buttonProps}
        style={{
          ...buttonProps?.style,
        }}
        iconStyle={{
          color: isDisabled ? theme.text : colors.white,
        }}
        bg={isDisabled ? theme.secondaryBackground : buttonProps?.bg}
      >
        <i
          className="fa-sharp fa-calculator"
          style={{
            ...iconStyle,
            color: isDisabled ? theme.text : iconStyle?.color || text,
          }}
        />
        <Text
          fontSize={isLarge ? "sm" : "xs"}
          padding="0"
          color={text}
          fontWeight="semibold"
          {...textProps}
          style={{
            ...textProps?.style,
            color: isDisabled ? theme.text : iconStyle?.color || text,
          }}
        >
          Recalculate
        </Text>
      </Touchable>
    </div>
  );

  if (!showReplay) {
    return RecalcBtn;
  }

  if (replayInfo && replayInfo.isUsingReplay) {
    // popover
    const hasTime =
      replayInfo.estimatedTimeInMS && replayInfo.estimatedTimeInMS > 0;

    return (
      <Popover
        trigger="hover"
        placement="bottom-end"
        onOpen={() => setIsOpen(true)}
        isOpen={isOpen}
        onClose={() => setIsOpen(false)}
      >
        <PopoverTrigger>{RecalcBtn}</PopoverTrigger>
        <PopoverContent
          overflowY="scroll"
          style={{
            width: 375,
            boxShadow: other.boxShadow,
            background: background,
            border: `1px solid ${border}`,
          }}
        >
          <Box
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              padding: "0 0.5rem",
            }}
          >
            <Box
              style={{
                width: 30,
                height: 30,
                display: "flex",
                alignItems: "center",
                borderRadius: 100,
                justifyContent: "center",
                background: colors.primary,
              }}
            >
              <i
                style={{
                  color: colors.white,
                  fontSize: 16,
                }}
                className="fa-solid fa-fast-forward"
              />
            </Box>

            <Text
              style={{ padding: "0.5rem 0.5rem" }}
              flex={1}
              fontSize="sm"
              fontWeight="500"
              color={text}
            >
              {formatNum(replayInfo.numberOfTransactions)} transactions to
              recalculate.
              {hasTime ? (
                <>
                  <br />
                  This should take ~
                  {getTimeMessage(replayInfo.estimatedTimeInMS || 0)}.
                </>
              ) : (
                ""
              )}
            </Text>

            <AwakenTooltip message="If you want to hard recalculate from the very beginning, click this. Hard recalculates are much much slower.">
              <div>
                <Touchable onClick={() => recalculate(true)}>
                  <i
                    style={{ color: theme.text }}
                    className="fa-solid fa-sync"
                  />
                </Touchable>
              </div>
            </AwakenTooltip>
          </Box>

          {replayInfo?.dirtyCount && replayInfo.dirtyCount > 0 && (
            <div
              style={{
                background: theme.medBackground,
              }}
            >
              <Box
                style={{
                  alignItems: "center",
                  padding: "0 0.75rem",
                  borderRadius: 0,
                  borderTop: `1px solid ${theme.border}`,
                  display: "flex",
                  width: "100%",
                  flexDirection: "row",
                  paddingTop: 15,
                }}
              >
                <Text
                  fontSize="md"
                  textAlign="left"
                  color={theme.header}
                  fontWeight="600"
                >
                  Edited Transactions
                </Text>
                <Flex flex={1} />
                <AwakenTooltip
                  placement="bottom-end"
                  message="This is how many transactions have recent adjustments."
                >
                  <Text
                    fontSize="xs"
                    textAlign="right"
                    color={theme.text}
                    fontWeight="600"
                    style={{
                      background: theme.secondaryBackground2,
                      padding: "0.25rem 0.5rem",
                      borderRadius: 15,
                    }}
                  >
                    {replayInfo.dirtyCount} dirty{" "}
                    <i
                      style={{ marginLeft: 5 }}
                      className="fa-solid fa-circle-info"
                    />
                  </Text>
                </AwakenTooltip>
              </Box>

              {replayInfo.transactions && (
                <Box
                  mt={2}
                  p={2}
                  borderRadius={other.borderRadius}
                  maxHeight="200px"
                  overflowY="auto"
                >
                  {replayInfo.transactions.map((transaction) => (
                    <Link
                      to={`/clients/${clientId}/transactions?transactionId=${transaction.id}`}
                    >
                      <Box
                        key={transaction.id}
                        style={{
                          padding: "0.5rem",
                          borderRadius: other.borderRadius,
                          marginBottom: "0.5rem",
                          width: "100%",
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                          justifyContent: "center",
                          border: `1px solid ${theme.border}`,
                        }}
                        bg={theme.background}
                        _hover={{
                          background: theme.secondaryBackground2,
                        }}
                      >
                        <VStack style={{ flex: 1, alignItems: "flex-start" }}>
                          <AwakenTooltip
                            placement="bottom-start"
                            message={transaction.title}
                          >
                            <Text
                              fontSize="sm"
                              fontWeight="500"
                              color={theme.header}
                              overflowX="hidden"
                              marginBottom="0 !important"
                              noOfLines={1}
                            >
                              {transaction.title}
                            </Text>
                          </AwakenTooltip>
                          {transaction.description && (
                            <Text
                              fontSize="xs"
                              color={theme.text}
                              mt={"0 !important"}
                            >
                              {transaction.description}
                            </Text>
                          )}
                        </VStack>
                        <Text fontSize="xs" color={colors.primary} mt={1}>
                          <i className="fa-solid fa-arrow-up-right-from-square" />
                        </Text>
                      </Box>
                    </Link>
                  ))}
                </Box>
              )}
            </div>
          )}
        </PopoverContent>
      </Popover>
    );
  }

  return (
    <Popover
      trigger="hover"
      placement="bottom-end"
      isOpen={isOpen}
      onOpen={() => setIsOpen(true)}
      onClose={() => setIsOpen(false)}
    >
      <PopoverTrigger>{RecalcBtn}</PopoverTrigger>
      <PopoverContent
        overflowY="scroll"
        style={{
          width: "100%",
          maxWidth: 400,
          boxShadow: other.boxShadow,
          background: background,
          border: `1px solid ${border}`,
        }}
      >
        <Box
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            padding: "0 0.5rem",
          }}
        >
          <Box
            style={{
              width: 30,
              height: 30,
              display: "flex",
              alignItems: "center",
              borderRadius: 100,
              justifyContent: "center",
              background: colors.positive,
            }}
          >
            <i
              style={{
                color: colors.white,
                fontSize: 16,
              }}
              className="fa-sharp fa-turtle"
            />
          </Box>

          <Text
            style={{ padding: "0.5rem 0.5rem" }}
            flex={1}
            fontSize="sm"
            fontWeight="500"
            color={text}
          >
            It doesn't seem like you have any changes. Do you want to hard
            recalculate your tax history from the very beginning? This can take
            a while.{" "}
            <Info message="Awaken implements algorithms to make it so we only recalculate transactions you have changed, which makes it a lot faster! If you see an issue though and want to recalculate from the very beginning, you can click the button below!" />
          </Text>
        </Box>

        <Box
          style={{
            padding: "0.5rem",
            alignItems: "flex-end",
            justifyContent: "flex-end",
            display: "flex",
          }}
        >
          <Button
            size="sm"
            style={{
              height: 35,
              padding: "0.5rem",
            }}
            variant="primary"
            onClick={() => recalculate(true)}
          >
            Yes, hard recalculate{" "}
            <i
              style={{
                marginLeft: 10,
              }}
              className="fa-solid fa-sync"
            />
          </Button>
        </Box>
      </PopoverContent>
    </Popover>
  );
}
