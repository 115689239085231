import {
  BaseAccountFields,
  BaseAccountWithCurrentJobFields,
} from "src/api/fragments";
import { AccountStatusEnum, ImportTypeEnum } from "src/api/generated/types";
import { EVM_PROVIDERS as _EVM_PROVIDERS } from "src/modules/ledger/accounts";

export const ACCOUNT_FIELDS = [
  "id",
  "numberOfTransactions",
  "status",
  "startDate",
  "endDate",
  "integrationStatus",
  "description",
  "name",
  "walletAddress",
] as (keyof BaseAccountFields)[];

export const getShouldReconnectCoinbase = (
  account: Pick<BaseAccountFields, "provider" | "importType">
) =>
  account.provider === "coinbase" &&
  account.importType === ImportTypeEnum.Hatchfi;

export const getShouldReconnectKraken = (
  account: Pick<BaseAccountFields, "provider" | "importType">
) =>
  account.provider === "kraken" &&
  account.importType === ImportTypeEnum.Hatchfi;

export const getShouldReconnectGemini = (
  account: Pick<BaseAccountFields, "provider" | "importType">
) =>
  account.provider === "gemini" &&
  account.importType === ImportTypeEnum.Hatchfi;

export const shouldReconnect = (
  account: Pick<BaseAccountFields, "provider" | "importType">
) =>
  getShouldReconnectCoinbase(account) ||
  getShouldReconnectKraken(account) ||
  getShouldReconnectGemini(account);

export type AccountGroupInfo = {
  label: string;
  icon: string; // You might want to import specific icon types based on your UI library
  accounts: BaseAccountWithCurrentJobFields[][];
  accountCount: number;
  transactionCount: number;
  syncingCount: number;
  failedCount: number;
  iconImageUrl?: string | null;
};

export type EnhancedAccountGroups = {
  bitcoin: AccountGroupInfo;
  evm: AccountGroupInfo;
  solana: AccountGroupInfo;
  sui: AccountGroupInfo;
  exchange: AccountGroupInfo;
  other: AccountGroupInfo;
};

const BITCOIN_PROVIDERS = new Set(["bitcoin"]);
const EVM_PROVIDERS = new Set(Array.from(_EVM_PROVIDERS));
const SOLANA_PROVIDERS = new Set(["solana"]);
const SUI_PROVIDERS = new Set(["sui"]);
const EXCHANGE_PROVIDERS = new Set([
  "coinbase",
  "binance",
  "kraken",
  "gemini",
  "ftx",
  "kucoin",
  "crypto.com",
  "coinbase_pro",
  "robinhood",
  "cashapp",
  "binance_us",
]);

export const groupAccountsByType = (
  accounts: BaseAccountWithCurrentJobFields[][]
): EnhancedAccountGroups => {
  const groups: EnhancedAccountGroups = {
    bitcoin: {
      label: "Bitcoin",
      icon: "bitcoin",
      accounts: [],
      accountCount: 0,
      transactionCount: 0,
      syncingCount: 0,
      failedCount: 0,
      iconImageUrl: "https://assets.awaken.tax/icons/btc.png",
    },
    evm: {
      label: "EVM Chains",
      icon: "ethereum",
      accounts: [],
      accountCount: 0,
      transactionCount: 0,
      syncingCount: 0,
      failedCount: 0,
      iconImageUrl: "https://assets.awaken.tax/icons/eth.png",
    },
    solana: {
      label: "Solana",
      icon: "solana",
      accounts: [],
      accountCount: 0,
      transactionCount: 0,
      syncingCount: 0,
      failedCount: 0,
      iconImageUrl: "https://assets.awaken.tax/icons/solana.png",
    },
    exchange: {
      label: "Exchanges",
      icon: "exchange",
      accounts: [],
      accountCount: 0,
      transactionCount: 0,
      syncingCount: 0,
      failedCount: 0,
      iconImageUrl: null,
    },
    other: {
      label: "Other",
      icon: "wallet",
      accounts: [],
      accountCount: 0,
      transactionCount: 0,
      syncingCount: 0,
      failedCount: 0,
      iconImageUrl: null,
    },
    sui: {
      label: "Sui",
      icon: "sui",
      accounts: [],
      accountCount: 0,
      transactionCount: 0,
      syncingCount: 0,
      failedCount: 0,
      iconImageUrl: "https://assets.awaken.tax/icons/sui.png",
    },
  };

  accounts.forEach((accountGroup) => {
    const account = accountGroup[0];
    if (!account) return;

    const provider = account.provider?.toLowerCase();
    let targetGroup: keyof EnhancedAccountGroups = "other";

    if (provider) {
      if (BITCOIN_PROVIDERS.has(provider)) {
        targetGroup = "bitcoin";
      } else if (EVM_PROVIDERS.has(provider)) {
        targetGroup = "evm";
      } else if (SOLANA_PROVIDERS.has(provider)) {
        targetGroup = "solana";
      } else if (EXCHANGE_PROVIDERS.has(provider)) {
        targetGroup = "exchange";
      } else if (SUI_PROVIDERS.has(provider)) {
        targetGroup = "sui";
      }
    }

    groups[targetGroup].accounts.push(accountGroup);
    groups[targetGroup].accountCount += accountGroup.length;
    groups[targetGroup].transactionCount += accountGroup.reduce(
      (sum, acc) => sum + (acc.numberOfTransactions || 0),
      0
    );
    groups[targetGroup].syncingCount += accountGroup.filter(
      (acc) => acc.status === AccountStatusEnum.Syncing
    ).length;
    groups[targetGroup].failedCount += accountGroup.filter(
      (acc) => acc.status === AccountStatusEnum.Failed
    ).length;
  });

  return groups;
};
