import { useQuery } from "@apollo/client";
import { Text } from "@chakra-ui/react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";
import { show } from "redux-modal";
import { api } from "src/api";
import { Query } from "src/api/generated/types";
import { useClientById, useMe, useMyToast } from "src/hooks";
import { useIsLargeScreen } from "src/hooks/useScreenSize";
import { useTheme } from "src/hooks/useTheme";
import { getHasBanner, setHasBanner } from "src/redux/reducers/globalState";
import { colors } from "src/theme";
import { trackEvent } from "src/utils/analytics";
import { isWhiteLabeledDomain } from "src/utils/whitelabel";
import { Info } from "../styled";
import Banner from "../styled/Banner";

const freeBannerDismissKey = "v1:free_transaction_code";

export function FreeBanner() {
  const dispatch = useDispatch();
  const toast = useMyToast();
  const { clientId } = useParams<{ clientId: string }>();
  const { client } = useClientById(clientId, {
    onlyFetchClient: true,
  });
  const hasBanner = useSelector(getHasBanner);
  const isLarge = useIsLargeScreen();
  const isWhiteLabel = isWhiteLabeledDomain();
  const { me } = useMe();
  const has2FA = me?.hasTwoFactorAuth ?? false;

  useEffect(() => {
    dispatch(setHasBanner(true));

    // if (isWhiteLabel) {
    //   dispatch(setHasBanner(false));
    //   return;
    // }

    if (!isLarge) {
      dispatch(setHasBanner(false));
      return;
    }

    dispatch(setHasBanner(true));
  }, [client, isLarge, isWhiteLabel]);

  const _copyLink = () => {
    const referralCode = client?.referralCode || "";

    navigator.clipboard.writeText(`https://awaken.tax?ref=${referralCode}`);

    toast.show({
      message: "Copied to clipboard!",
      status: "info",
    });

    void trackEvent("Referral Link Copied", { referralCode });
  };

  const path = window.location.pathname;

  const { theme, header } = useTheme();

  // if (path === "/") return null;
  // if (path === "/landing") return null;
  // return <ChangingThings />;

  // return <Maintenance />;

  // return <FullyFreeBanner client={client} />;

  // if (!has2FA) {
  //   return <TwoFactorAuthBanner client={client} />;
  // }
  if (!hasBanner) return null;

  return <ReferralBanner client={client} />;
  // return <ShareAwakenBanner client={client} />;
  // return <ReferralBanner client={client} />;

  // return (
  //   <Banner
  //     padding="0 0.5rem"
  //     // borderBottom={`1px solid ${colors.gray80}`}
  //     // dismissKey={freeBannerDismissKey + ":" + clientId}
  //     message={
  //       <Text
  //         fontSize={{ base: "xs", sm: "sm" }}
  //         style={{ fontWeight: "500", color: header }}
  //       >
  //         {/* 🥁 Awaken's portfolio is now in beta 🥁 */}
  //         {/* Check out our new{" "} */}
  //         {/* <span style={{ fontWeight: "bold", color: colors.lightBlue100 }}>
  //           ➡️ $25 of credit ⬅️
  //         </span>{" "}
  //         when you share{" "} */}
  //         We launched the easiest way to trade memecoins. Check it out:{" "}
  //         <a target="_blank" href="https://movement.market" rel="noreferrer">
  //           <span
  //             style={{
  //               fontWeight: "bold",
  //               color: header,
  //               cursor: "pointer",
  //               textDecoration: "underline",
  //             }}
  //           >
  //             ➡️ Movement ⬅️
  //           </span>
  //         </a>
  //         .
  //       </Text>
  //     }
  //     // bg={`linear-gradient(45deg, ${colors.orange60} 0%, ${colors.yellow60} 50%, ${colors.orange60} 100%)`}
  //     bg={
  //       theme === "light"
  //         ? `linear-gradient(45deg, ${colors.lightBlue60} 0%, ${colors.lightBlue80} 50%, ${colors.lightBlue60} 100%)`
  //         : `linear-gradient(45deg, ${colors.primary} 0%, ${colors.lightBlue50} 50%, ${colors.primary} 100%)`
  //     }
  //   />
  // );
}

const TwoFactorAuthBanner = ({ client }: { client: any }) => {
  const toast = useMyToast();

  return (
    <Link to={`/clients/${client?.id}/team`}>
      <Banner
        padding="0 0.5rem"
        // dismissKey={freeBannerDismissKey + ":" + clientId}
        message={
          <Text
            fontSize={{ base: "xs", sm: "sm" }}
            style={{
              fontWeight: "600",
              color: colors.black,
              animation: "pulse 2s infinite",
            }}
          >
            <i className="fa-solid fa-shield-halved" /> Please enable 2FA in the{" "}
            <span style={{ cursor: "pointer", textDecoration: "underline" }}>
              Settings
            </span>{" "}
            page to keep your account secure. Click to enable{" "}
            <i className="fa-solid fa-shield-halved" />
          </Text>
        }
        // style={{
        //   position: "fixed",
        //   top: 0,
        // }}
        bg={`linear-gradient(45deg, ${colors.blue80} 0%, ${colors.green70} 50%, ${colors.blue80} 100%)`}
      />
    </Link>
  );
};

const ShareAwakenBanner = ({ client }: { client: any }) => {
  const toast = useMyToast();

  const _copyLink = () => {
    const referralCode = client?.referralCode || "";

    navigator.clipboard.writeText(`https://awaken.tax?ref=${referralCode}`);

    toast.show({
      message: "Copied to clipboard!",
      status: "info",
    });

    void trackEvent("Referral Link Copied", { referralCode });
  };

  return (
    <Banner
      padding="0 0.5rem"
      // dismissKey={freeBannerDismissKey + ":" + clientId}
      message={
        <Text
          fontSize={{ base: "xs", sm: "sm" }}
          style={{ fontWeight: "600", color: colors.black }}
        >
          Awaken's mobile app is being sunset so we can focus on our tax product
          🛠️.{" "}
          <a
            style={{
              textDecoration: "underline",
              cursor: "pointer",
              color: colors.black,
              fontWeight: "700",
            }}
            target="_blank"
            href="https://x.com/AwakenTax/status/1864372556528333259"
          >
            Read more.
          </a>
        </Text>
      }
      // style={{
      //   position: "fixed",
      //   top: 0,
      // }}
      bg={`linear-gradient(45deg, #FFD89B 0%, #FF9A8B 50%, #FFD89B 100%)`}
    />
  );
};

const FullyFreeBanner = ({ client }: { client: any }) => {
  const toast = useMyToast();

  const _copyLink = () => {
    const referralCode = client?.referralCode || "";

    navigator.clipboard.writeText(`https://awaken.tax?ref=${referralCode}`);

    toast.show({
      message: "Copied to clipboard!",
      status: "info",
    });

    void trackEvent("Referral Link Copied", { referralCode });
  };

  return (
    <Banner
      padding="0 0.5rem"
      // dismissKey={freeBannerDismissKey + ":" + clientId}
      message={
        <Text
          fontSize={{ base: "xs", sm: "sm" }}
          style={{ fontWeight: "600", color: colors.black }}
        >
          Awaken is{" "}
          <span
            style={{
              fontWeight: "bold",
              textDecoration: "underline",
            }}
          >
            FREE
          </span>{" "}
          if you have less than 500 transactions in the tax year (~transaction a
          day){" "}
          <Info
            style={{ color: colors.black }}
            message="Tell your friends because the more people that use this, the longer we'll run this perk."
          />
          !
        </Text>
      }
      // style={{
      //   position: "fixed",
      //   top: 0,
      // }}
      bg={`linear-gradient(45deg, ${colors.red80} 0%, ${colors.lightBlue80} 50%, ${colors.purple80} 100%)`}
    />
  );
};

const ReferralBanner = ({ client }: { client: any }) => {
  const toast = useMyToast();
  const { data, loading } = useQuery<Pick<Query, "getSubscriptions">>(
    api.subscriptions.list,
    {
      variables: { clientId: client?.id },
      skip: !client?.id,
    }
  );

  const subscriptions = data?.getSubscriptions ?? [];
  const showBadge = !!data && subscriptions.length === 0 && !loading;
  const theme = useTheme();

  const _copyLink = () => {
    const referralCode = client?.referralCode || "";

    navigator.clipboard.writeText(`https://awaken.tax?ref=${referralCode}`);

    toast.show({
      message: "Copied to clipboard!",
      status: "info",
    });

    void trackEvent("Referral Link Copied", { referralCode });
  };
  const dispatch = useDispatch();

  // if (showBadge) {
  //   return (
  //     <Banner
  //       padding="0.25rem 0.5rem"
  //       cursor="pointer"
  //       onClick={() => {
  //         // dispatch(show("ReferralModal"));
  //       }}
  //       // dismissKey={freeBannerDismissKey + ":" + clientId}
  //       message={
  //         <Text
  //           fontSize={{ base: "xs", sm: "sm" }}
  //           style={{ fontWeight: "500", color: colors.black }}
  //         >
  //           <b>Get $25</b> off next year if you buy a 2023 tax plan by August
  //           31st.{" "}
  //         </Text>
  //       }
  //       bg={`linear-gradient(45deg, ${colors.green80} 0%, ${colors.green60} 50%, ${colors.green80} 100%)`}
  //     />
  //   );
  // }

  return (
    <Banner
      padding="0.25rem 0.5rem"
      // dismissKey={freeBannerDismissKey + ":" + clientId}
      message={
        <Text
          fontSize={{ base: "xs", sm: "sm" }}
          style={{
            fontWeight: "500",
            color: theme.header,
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          <span
            style={{
              display: "inline-flex",
              alignItems: "center",
              gap: "-10px",
              marginRight: "4px",
            }}
          >
            <img
              src="https://assets.awaken.tax/icons/hype.png"
              alt="Hyperliquid"
              style={{
                width: "20px",
                borderRadius: 100,
                height: "20px",
                zIndex: 3,
                border: `1px solid ${theme.text}`,
              }}
            />
            <img
              src="https://assets.awaken.tax/icons/sui.png"
              alt="Sui"
              style={{
                width: "20px",
                height: "20px",
                borderRadius: 100,
                marginLeft: "-6px",
                zIndex: 2,
                border: `1px solid ${theme.text}`,
              }}
            />
            <img
              src="https://assets.awaken.tax/icons/btc.png"
              alt="Bitcoin"
              style={{
                width: "20px",
                borderRadius: 100,
                height: "20px",
                marginLeft: "-6px",
                zIndex: 1,
                border: `1px solid ${theme.text}`,
              }}
            />
          </span>
          <b>Hyperliquid, Bitcoin, and Sui</b>&nbsp;are 100% free.&nbsp;
          <span
            onClick={() =>
              dispatch(
                show("PleaseShareModal", {
                  title:
                    "Awaken's #1 goal is to make crypto taxes as stress-free as possible. And to get there, we need your help.",
                })
              )
            }
            style={{
              fontWeight: "bold",
              color: theme.header,
              cursor: "pointer",
              textDecoration: "underline",
            }}
          >
            Help us spread the word <i className="fa-solid fa-heart" />
          </span>
        </Text>
      }
      borderBottom={`1px solid ${theme.border}`}
      // make a bitcoin orange gradient
      // bg={`linear-gradient(45deg, #FFD89B 0%, #FF9A8B 50%, #FFD89B 100%)`}
      bg={`linear-gradient(45deg, ${theme.background} 0%, ${theme.medBackground} 50%, ${theme.background} 100%)`}
    />
  );
};

const Maintenance = () => {
  return (
    <Banner
      padding="0 0.5rem"
      // dismissKey={freeBannerDismissKey + ":" + clientId}
      message={
        <Text
          fontSize={{ base: "xs", sm: "sm" }}
          style={{ fontWeight: "500", color: colors.black }}
        >
          Wallet imports for Base are experiencing high failure rates. We are
          working with <a href="https://basescan.org">basescan</a> to resolve
          this.
        </Text>
      }
      bg={`linear-gradient(45deg, ${colors.yellow50} 0%, ${colors.yellow40} 50%, ${colors.yellow50} 100%)`}
    />
  );
};

const ChangingThings = () => {
  return (
    <Banner
      padding="0 0.5rem"
      // dismissKey={freeBannerDismissKey + ":" + clientId}
      message={
        <Text
          fontSize={{ base: "xs", sm: "sm" }}
          style={{ fontWeight: "500", color: colors.black }}
        >
          We are currently undergoing large changes to our infrastructure, so
          please bear with us. Your data is safe.
        </Text>
      }
      bg={`linear-gradient(45deg, ${colors.yellow50} 0%, ${colors.yellow40} 50%, ${colors.yellow50} 100%)`}
    />
  );
};
