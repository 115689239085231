import { Text } from "@chakra-ui/react";
import { useDispatch } from "react-redux";
import { connectModal, InjectedProps } from "redux-modal";
import { Modal } from "src/components/Modal";
import { config } from "src/config";
import { useMyToast } from "src/hooks";
import { useTheme } from "src/hooks/useTheme";
import { colors } from "src/theme";

type Props = InjectedProps & {
  capLoss: string;
};

function _CXModal({ handleHide, show: isVisible, capLoss }: Props) {
  const theme = useTheme();
  const toast = useMyToast();
  const dispatch = useDispatch();

  return (
    <Modal
      title={`How to reach us`}
      isVisible={isVisible}
      handleHide={handleHide}
    >
      <Text
        style={{
          textDecoration: "underline",
        }}
        color={theme.text}
      >
        We do not provide tax advice.
      </Text>

      <br />

      <Text color={theme.text}>
        A real human will respond to you, so be kind 🙂. If you have a problem,
        please provide specific examples.
      </Text>

      <br />

      <Text fontWeight="bold" color={theme.text}>
        We usually respond in <b>1-3 business days</b> between the hours of{" "}
        <b>9am-5pm PST</b>.
      </Text>

      <br />

      <Text color={theme.text}>
        Our{" "}
        <a
          href="https://help.awaken.tax"
          target="_blank"
          style={{
            fontWeight: "bold",
            color: colors.primary,
            textDecoration: "underline",
          }}
        >
          Help Center
        </a>{" "}
        has some guides (and video walkthroughs) that may be able to help you.
      </Text>

      <br />

      <Text color={theme.text}>
        Please contact{" "}
        <span
          onClick={() => {
            // copy the email
            navigator.clipboard.writeText(config.cxEmail);
            toast.show({
              message: "Support email copied to clipboard!",
              status: "success",
            });
          }}
          style={{
            cursor: "pointer",
            fontWeight: "bold",
            color: colors.primary,
            textDecoration: "underline",
          }}
        >
          {config.cxEmail}
        </span>{" "}
        if you have any questions.
      </Text>

      <br />

      {/* <Text color={theme.text}>
        We usually respond in under 24 hours for paying users 🤝.
      </Text>

      <br /> */}

      {/* <Text
        onClick={() => dispatch(show("FeedbackModal"))}
        style={{
          cursor: "pointer",
          marginBottom: 20,
          borderRadius: 100,
          padding: "0.5rem 1.25rem",
          color: theme.text,
          background: theme.secondaryBackground,
          fontSize: 14,
        }}
      >
        Have product feedback? We'd love to here it.{" "}
        <span style={{ textDecoration: "underline", fontWeight: "bold" }}>
          Leave feedback
        </span>{" "}
        <i
          className="fa-sharp fa-heart"
          style={{
            color: colors.red50,
            animation: "pulse-big 1.5s infinite",
          }}
        />
      </Text> */}
      <br />
    </Modal>
  );
}

export const CXModal = connectModal({
  name: "CXModal",
})(_CXModal);
