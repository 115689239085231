import { useMutation } from "@apollo/client";
import { Button, Container, Text } from "@chakra-ui/react";
import { motion } from "framer-motion";
import { useState } from "react";
import Confetti from "react-confetti";
import { connectModal, InjectedProps } from "redux-modal";
import { api } from "src/api";
import { Mutation } from "src/api/generated/types";
import { Modal } from "src/components/Modal";
import { Input } from "src/components/styled";
import { useMyToast } from "src/hooks";
import { useTheme } from "src/hooks/useTheme";
import { colors } from "src/theme";

type Props = InjectedProps & {
  clientId: string;
};

function _ReferralCodeModal({ handleHide, show: isVisible, clientId }: Props) {
  const [referralCode, setReferralCode] = useState("");
  const [claimCode] = useMutation<Pick<Mutation, "claimCode">>(
    api.referrals.claimCode
  );
  const [runConfetti, setRunConfetti] = useState(false);
  const toast = useMyToast();
  const theme = useTheme();

  const _onSubmit = async function () {
    if (!referralCode)
      return toast.show({
        message: "Please enter a referral code!",
        status: "error",
      });

    try {
      const result = await claimCode({
        variables: { referralCode, clientId },
        refetchQueries: [api.subscriptions.active, api.clients.retrieve],
      });

      toast.show({
        message: result?.data?.claimCode.message || "Success!",
        status: "success",
      });

      setRunConfetti(true);

      setTimeout(() => handleHide(), 3000);
    } catch (err) {
      toast.show({
        message: (err as Error).message || "An error occurred.",
        status: "error",
      });
    }
  };

  return (
    <>
      <Confetti
        style={{ zIndex: 100000 }}
        run={runConfetti}
        colors={[colors.yellow50, colors.orange50]}
        numberOfPieces={250}
        recycle={false}
      />
      <Modal
        title="Enter code"
        isVisible={isVisible}
        handleHide={handleHide}
        Footer={
          <motion.div
            style={{ width: "100%" }}
            whileHover={{ scale: 1.03 }}
            whileTap={{ scale: 1 }}
          >
            <Button
              onClick={_onSubmit}
              padding="1.5rem 0.5rem"
              disabled={runConfetti}
              bg={`linear-gradient(30deg, ${colors.green60} 0%, ${colors.green50} 50%, ${colors.green70} 100%) !important`}
              color={colors.white}
              style={{ width: "100%" }}
            >
              Claim Credit{" "}
              <i
                style={{ marginLeft: 15 }}
                className="fa-sharp fa-arrow-right"
              />
            </Button>
          </motion.div>
        }
      >
        <Container padding="0px" marginTop="0px !important">
          <Text color={theme.text}>
            Got a code from a friend? Have an Awaken giftcard code? Enter it
            here to unlock free credit!
          </Text>

          <br />

          <Input
            value={referralCode}
            label="Enter Code 🔑"
            focusBorderColor={colors.green50}
            onChange={(e) => setReferralCode(e.target.value)}
            placeholder=""
          />
        </Container>
      </Modal>
    </>
  );
}

export const ReferralCodeModal = connectModal({
  name: "ReferralCodeModal",
})(_ReferralCodeModal);
