import { gql } from "@apollo/client";
import { Maybe } from "src/core";
import { client } from "./axios";
import {
  BaseAccountFields,
  BaseAssetFields,
  BaseBookkeepingQuoteFields,
  BaseClientFields,
  BaseClientPermissionFields,
  BaseDefaultRuleFields,
  BaseFilterFormCPAFields,
  BaseFullTransactionFields,
  BaseFullTransactionFieldsV2,
  BaseJobFields,
  BaseLedgerAccountFields,
  BaseLedgerAccountWithChildrenFields,
  BaseLedgerEntryFields,
  BaseLedgerEntryLinkFields,
  BaseLedgerEntryLinkWithCapacityAndLinksFields,
  BaseLedgerEntryWithCapacityFields,
  BasePackFields,
  BasePendingUserFields,
  BaseRecalculateSnapshotDiffTxnPairFields,
  BaseRecalculateSummaryFields,
  BaseReportAccountInfoFields,
  BaseReportAccountInfoWithChildrenFields,
  BaseReportFields,
  BaseRuleFields,
  BaseSimpleTransactionFields,
  BaseUserFields,
} from "./fragments";
import { ClientPermission } from "./generated/types";

// Cache stuff: https://www.apollographql.com/docs/react/data/queries/#supported-fetch-policies

// === types === //

export enum UserType {
  Verified = "verified",
  Pending = "pending",
}

export type BaseLedgerEntryFullFields = BaseLedgerEntryFields & {
  ledgerAccount: Maybe<BaseLedgerAccountFields>;
  asset: Maybe<BaseAssetFields>;
};

export type BaseTransactionFullFields = BaseFullTransactionFields & {
  account: BaseAccountFields;
  client: BaseClientFields;
};

const CreateClient = gql`
  ${BaseClientFields}
  mutation CreateClient(
    $name: String!
    $email: String
    $referredDomainUrl: String!
  ) {
    createClient(
      name: $name
      email: $email
      referredDomainUrl: $referredDomainUrl
    ) {
      ...BaseClientFields
    }
  }
`;

const UpdateClientCostBasisAlgorithm = gql`
  ${BaseClientFields}
  mutation UpdateClientCostBasisAlgorithm(
    $clientId: ID!
    $costBasisAlgorithm: CostBasisAlgorithmEnum!
  ) {
    updateClientCostBasisAlgorithm(
      clientId: $clientId
      costBasisAlgorithm: $costBasisAlgorithm
    ) {
      ...BaseClientFields
    }
  }
`;

const UpdateClient = gql`
  ${BaseClientFields}
  mutation UpdateClient(
    $clientId: ID!
    $timezone: String
    $accountantNotes: String
    $accountantStatus: ClientAccountantStatusEnum
    $currency: CurrencyCodeEnum
    $country: CountryEnum
    $name: String
    $defaultToFloor: Boolean
    $paypalUsername: String
    $allowCustomerSupportAccess: Boolean
  ) {
    updateClient(
      clientId: $clientId
      timezone: $timezone
      accountantNotes: $accountantNotes
      accountantStatus: $accountantStatus
      currency: $currency
      country: $country
      name: $name
      defaultToFloor: $defaultToFloor
      paypalUsername: $paypalUsername
      allowCustomerSupportAccess: $allowCustomerSupportAccess
    ) {
      ...BaseClientFields
    }
  }
`;

const UpdateClientUser = gql`
  ${BaseClientFields}
  mutation UpdateClientUser($clientId: ID!, $newUserId: ID!) {
    updateClientUser(clientId: $clientId, newUserId: $newUserId) {
      ...BaseClientFields
    }
  }
`;

const CreateClientLedgerAccount = gql`
  ${BaseLedgerAccountFields}
  mutation CreateClientLedgerAccount(
    $clientId: ID!
    $name: String!
    $parentLedgerAccountId: ID!
  ) {
    createClientLedgerAccount(
      clientId: $clientId
      name: $name
      parentLedgerAccountId: $parentLedgerAccountId
    ) {
      ...BaseLedgerAccountFields
    }
  }
`;

const DeleteAccountGroup = gql`
  ${BaseAccountFields}
  mutation DeleteAccountGroup($clientId: ID!, $walletAddress: String!) {
    deleteAccountGroup(clientId: $clientId, walletAddress: $walletAddress) {
      ...BaseAccountFields
    }
  }
`;

const CreateAccount = gql`
  ${BaseAccountFields}
  mutation CreateAccount(
    $provider: String!
    $type: AccountTypeEnum!
    $code: String
    $redirectUrl: String
    $address: String
    $label: String
    $clientId: String
    $fileObjectKey: String
    $importType: ImportTypeEnum!
    $plaidPublicToken: String
    $skipRecalculate: Boolean
    $isLargeAccount: Boolean
    $shouldUploadAllEVM: Boolean
    $includeStakingRewards: Boolean
    $startImportingAt: Date
  ) {
    createAccount(
      provider: $provider
      type: $type
      code: $code
      redirectUrl: $redirectUrl
      address: $address
      label: $label
      clientId: $clientId
      fileObjectKey: $fileObjectKey
      importType: $importType
      plaidPublicToken: $plaidPublicToken
      skipRecalculate: $skipRecalculate
      isLargeAccount: $isLargeAccount
      shouldUploadAllEVM: $shouldUploadAllEVM
      includeStakingRewards: $includeStakingRewards
      startImportingAt: $startImportingAt
    ) {
      ...BaseAccountFields
    }
  }
`;

const CreateBatchWallets = gql`
  ${BaseAccountFields}
  mutation CreateBatchWallets(
    $clientId: ID!
    $wallets: [BatchWalletInputParams!]!
    $skipRecalculate: Boolean
  ) {
    createBatchWallets(
      clientId: $clientId
      wallets: $wallets
      skipRecalculate: $skipRecalculate
    ) {
      ...BaseAccountFields
    }
  }
`;

const CreateAccountGroup = gql`
  mutation CreateAccountGroup($clientId: ID!, $name: String!) {
    createAccountGroup(clientId: $clientId, name: $name) {
      id
    }
  }
`;

const SyncAccount = gql`
  mutation SyncAccount(
    $accountId: ID!
    $skipRecalculate: Boolean
    $shouldGetLastImportedAt: Boolean
  ) {
    syncAccount(
      accountId: $accountId
      skipRecalculate: $skipRecalculate
      shouldGetLastImportedAt: $shouldGetLastImportedAt
    ) {
      id
    }
  }
`;

const UpdateAccounts = gql`
  mutation UpdateAccounts($clientId: ID!) {
    updateAccounts(clientId: $clientId)
  }
`;

const UpdateAccount = gql`
  mutation UpdateAccount(
    $accountId: ID!
    $accessToken: String
    $refreshAccessToken: String
    $accountGroupId: ID
  ) {
    updateAccount(
      accountId: $accountId
      accessToken: $accessToken
      refreshAccessToken: $refreshAccessToken
      accountGroupId: $accountGroupId
    ) {
      id
    }
  }
`;

const DownloadAccountFile = gql`
  mutation DownloadAccountFile($accountId: ID!) {
    downloadAccountFile(accountId: $accountId)
  }
`;

const HardRefreshAccount = gql`
  mutation HardRefreshAccount(
    $accountId: ID!
    $shouldApplyCurrentEdits: Boolean
  ) {
    hardRefreshAccount(
      accountId: $accountId
      shouldApplyCurrentEdits: $shouldApplyCurrentEdits
    ) {
      id
    }
  }
`;

const CancelAccountImport = gql`
  mutation CancelAccountImport($accountId: ID!) {
    cancelAccountImport(accountId: $accountId) {
      id
    }
  }
`;

const SyncAllClientAccounts = gql`
  mutation SyncAllClientAccounts($clientId: ID!, $isContinuousSync: Boolean!) {
    syncAllClientAccounts(
      clientId: $clientId
      isContinuousSync: $isContinuousSync
    ) {
      jobIds
    }
  }
`;

const RenameAccount = gql`
  ${BaseAccountFields}
  mutation RenameAccount($accountId: ID!, $newName: String!) {
    renameAccount(accountId: $accountId, newName: $newName) {
      ...BaseAccountFields
    }
  }
`;

const DeleteAccount = gql`
  ${BaseAccountFields}
  mutation DeleteAccount($accountId: ID!) {
    deleteAccount(accountId: $accountId) {
      ...BaseAccountFields
    }
  }
`;

const GetHatchfiLink = gql`
  mutation GetHatchfiLink($clientId: String!) {
    getHatchfiLink(clientId: $clientId)
  }
`;

const GetVezgoLink = gql`
  mutation GetVezgoLink($clientId: ID!, $provider: String!) {
    getVezgoLink(clientId: $clientId, provider: $provider) {
      connectUrl
    }
  }
`;

const SyncHatchfi = gql`
  mutation SyncHatchfi($clientId: String!) {
    syncHatchfi(clientId: $clientId)
  }
`;

const MyAccounts = gql`
  ${BaseAccountFields}
  query MyAccounts {
    myAccounts {
      ...BaseAccountFields
    }
  }
`;

// ==== users ==== //
const CreateUser = gql`
  ${BaseUserFields}
  mutation CreateUser(
    $name: String!
    $email: String!
    $phoneNumber: String
    $referralCode: String
    $referredDomainUrl: String
  ) {
    createUser(
      name: $name
      email: $email
      phoneNumber: $phoneNumber
      referralCode: $referralCode
      referredDomainUrl: $referredDomainUrl
    ) {
      ...BaseUserFields
    }
  }
`;

const VerifyRecaptcha = gql`
  mutation VerifyRecaptcha(
    $token: String!
    $ipAddress: String
    $version: String!
  ) {
    verifyRecaptcha(token: $token, ipAddress: $ipAddress, version: $version)
  }
`;

const DeleteUser = gql`
  mutation DeleteUser($email: String!) {
    deleteUser(email: $email)
  }
`;

const DeleteMe = gql`
  mutation DeleteMe {
    deleteMe
  }
`;

const SendLoginEmail = gql`
  query SendLoginEmail($ipAddress: String!, $browser: String!) {
    sendLoginEmail(ipAddress: $ipAddress, browser: $browser)
  }
`;

const UpdateUser = gql`
  ${BaseUserFields}
  mutation UpdateUser(
    $name: String
    $hasTwoFactorAuth: Boolean
    $marketingSource: String
  ) {
    updateUser(
      name: $name
      hasTwoFactorAuth: $hasTwoFactorAuth
      marketingSource: $marketingSource
    ) {
      ...BaseUserFields
    }
  }
`;

const UnenrollTwoFactor = gql`
  mutation UnenrollTwoFactor {
    unenrollTwoFactor
  }
`;

const CreateUserWithEmail = gql`
  ${BaseUserFields}
  mutation CreateUserWithEmail(
    $name: String!
    $email: String!
    $password: String!
    $phoneNumber: String
    $referralCode: String
    $referredDomainUrl: String
  ) {
    createUserWithEmail(
      name: $name
      email: $email
      password: $password
      phoneNumber: $phoneNumber
      referralCode: $referralCode
      referredDomainUrl: $referredDomainUrl
    ) {
      user {
        ...BaseUserFields
      }
      customToken
    }
  }
`;

const GetMe = gql`
  ${BaseUserFields}
  query GetMe {
    me {
      ...BaseUserFields
    }
  }
`;

const GetPaymentMethods = gql`
  query GetPaymentMethods {
    getPaymentMethods {
      last4
      source
    }
  }
`;

const SetMyActiveClient = gql`
  mutation SetMyActiveClient($clientId: String!) {
    setMyActiveClient(clientId: $clientId) {
      id
    }
  }
`;

// ========== DISCOUNTS

const CheckGMEDiscount = gql`
  mutation CheckGMEDiscount($clientId: String!) {
    checkGMEDiscount(clientId: $clientId)
  }
`;

const ApplyGMEDiscount = gql`
  mutation ApplyGMEDiscount($clientId: String!) {
    applyGMEDiscount(clientId: $clientId) {
      gmeBalance
    }
  }
`;

const CheckMagicEdenDiscount = gql`
  mutation CheckMagicEdenDiscount($clientId: ID!) {
    checkMagicEdenDiscount(clientId: $clientId) {
      accountIds
      isEligible
      totalFreeTransactionCount
      hasClaimed
    }
  }
`;

const ApplyMagicEdenDiscount = gql`
  mutation ApplyMagicEdenDiscount($clientId: ID!) {
    applyMagicEdenDiscount(clientId: $clientId)
  }
`;

// ========== END DISCOUNTS

const GetMyActiveClient = gql`
  ${BaseClientFields}
  query GetMyActiveClient {
    getMyActiveClient {
      ...BaseClientFields
    }
  }
`;

const GetClientById = gql`
  ${BaseClientFields}
  query GetClientById($clientId: String!) {
    getClientById(clientId: $clientId) {
      ...BaseClientFields
      coinbaseWalletInfo {
        coinbaseID
        url
        confirmed
        addresses {
          BTC
        }
      }
    }
  }
`;

const GetMyClients = gql`
  query GetMyClients {
    getMyClients {
      id
      name
      email
      isLocked
      createdAt
      referredByCode
      referredByName
      referralCode
      costBasisAlgorithm
      availableCreditCents
      profilePicture
      timezone
      accountantNotes
      accountantStatus
      createdById
      country
      currency
      lastSyncedAt
      referredDomainUrl
    }
  }
`;

const ToggleLockClient = gql`
  mutation ToggleLockClient($clientId: ID!, $isLocked: Boolean!) {
    toggleLockClient(clientId: $clientId, isLocked: $isLocked)
  }
`;

const GetClientAccounts = gql`
  ${BaseAccountFields}
  query GetClientAccounts($clientId: String!) {
    getClientAccounts(clientId: $clientId) {
      ...BaseAccountFields
    }
  }
`;

const UpdateCoinbaseID = gql`
  mutation UpdateCoinbaseID($clientId: String!, $coinbaseID: String!) {
    updateCoinbaseID(clientId: $clientId, coinbaseID: $coinbaseID) {
      coinbaseID
      url
      BTC
      ETH
      SOL
    }
  }
`;

const ConfirmCoinbaseID = gql`
  mutation ConfirmCoinbaseID($clientId: String!) {
    confirmCoinbaseID(clientId: $clientId)
  }
`;

// ===========

const GetClientTransactions = gql`
  ${BaseSimpleTransactionFields}
  query GetClientTransactions(
    $clientId: ID!
    $reviewed: Boolean
    $page: Int
    $limit: Int
    $startDate: Date
    $endDate: Date
    $accountIds: [ID!]
    $search: String
    $labeledPriority: Int
    $hasIncome: Boolean
    $hasNotes: Boolean
    $sortBy: String
    $ascending: Boolean
    $assetSymbolOrName: String
    $assetId: String
    $isMissingBasis: Boolean
    $isNegativeBalance: Boolean
    $assetKey: String
    $assetKeyV2: String
    $providers: [String!]
    $assetIds: [String!]
    $labels: [String!]
    $includeSpam: Boolean
    $assetType: String
  ) {
    getClientTransactions(
      clientId: $clientId
      page: $page
      reviewed: $reviewed
      limit: $limit
      assetKeyV2: $assetKeyV2
      startDate: $startDate
      endDate: $endDate
      labeledPriority: $labeledPriority
      hasIncome: $hasIncome
      hasNotes: $hasNotes
      accountIds: $accountIds
      search: $search
      sortBy: $sortBy
      ascending: $ascending
      assetSymbolOrName: $assetSymbolOrName
      assetId: $assetId
      isMissingBasis: $isMissingBasis
      isNegativeBalance: $isNegativeBalance
      assetKey: $assetKey
      providers: $providers
      assetIds: $assetIds
      labels: $labels
      includeSpam: $includeSpam
      assetType: $assetType
    ) {
      transactions {
        ...BaseSimpleTransactionFields
      }
      total
    }
  }
`;

const GetClientLedgerAccounts = gql`
  ${BaseLedgerAccountWithChildrenFields}
  query GetClientLedgerAccounts($clientId: ID!, $depth: Int!) {
    getClientLedgerAccounts(clientId: $clientId, depth: $depth) {
      tree {
        ...BaseLedgerAccountWithChildrenFields
      }
      accounts {
        id
        name
        type
        subType
        classification
      }
    }
  }
`;

const SearchClientLedgerAccounts = gql`
  ${BaseLedgerAccountWithChildrenFields}
  query SearchLedgerAccounts($clientId: ID!, $search: String!) {
    searchClientLedgerAccounts(clientId: $clientId, search: $search) {
      account {
        ...BaseLedgerAccountWithChildrenFields
      }
      ancestors {
        ...BaseLedgerAccountWithChildrenFields
      }
    }
  }
`;

const GiveCredit = gql`
  ${BaseClientFields}
  mutation GiveCredit($clientId: ID!, $newCreditCents: Float!) {
    giveCredit(clientId: $clientId, newCreditCents: $newCreditCents) {
      ...BaseClientFields
    }
  }
`;

const AdjustSubscriptionTier = gql`
  mutation AdjustSubscriptionTier($clientId: ID!, $newCeiling: Float!) {
    adjustSubscriptionTier(clientId: $clientId, newCeiling: $newCeiling)
  }
`;

const GetClientAssets = gql`
  ${BaseAssetFields}
  query GetClientAssets($clientId: ID!, $hideSpamAssets: Boolean) {
    getClientAssets(clientId: $clientId, hideSpamAssets: $hideSpamAssets) {
      assets {
        ...BaseAssetFields
      }
      misc {
        ...BaseAssetFields
      }
    }
  }
`;

const GetClientSpamAssets = gql`
  ${BaseAssetFields}
  query GetClientSpamAssets($clientId: ID!) {
    getClientSpamAssets(clientId: $clientId) {
      assets {
        ...BaseAssetFields
        isSpamUserSet
        isSpamAuto
      }
      needsReview {
        ...BaseAssetFields
        isSpamUserSet
        isSpamAuto
      }
    }
  }
`;

const GetClientAssetOptions = gql`
  query GetClientAssetOptions($clientId: ID!, $hideSpamAssets: Boolean) {
    getClientAssetsOptions(
      clientId: $clientId
      hideSpamAssets: $hideSpamAssets
    ) {
      assets {
        id
        provider
        symbol
        type
        contractAddress
        coinGeckoTokenId
        blockExplorerUrl
        name
        tokenId
        iconImageUrl
      }
    }
  }
`;

export type GetClientMembers = Pick<
  ClientPermission,
  | "__typename"
  | "id"
  | "clientId"
  | "pendingUserId"
  | "userId"
  | "createdAt"
  | "client"
  | "user"
  | "pendingUser"
>;

const GetClientMembers = gql`
  ${BaseClientFields}
  ${BaseUserFields}
  ${BasePendingUserFields}
  ${BaseClientPermissionFields}
  query GetClientMembers($clientId: ID!) {
    getClientMembers(clientId: $clientId) {
      ...BaseClientPermissionFields
    }
  }
`;

const AddClientMember = gql`
  ${BaseClientFields}
  ${BaseUserFields}
  ${BasePendingUserFields}
  ${BaseClientPermissionFields}
  mutation AddClientMember(
    $clientId: ID!
    $email: String!
    $whiteLabelUrl: String
  ) {
    addClientMember(
      clientId: $clientId
      email: $email
      whiteLabelUrl: $whiteLabelUrl
    ) {
      ...BaseClientPermissionFields
    }
  }
`;

const RemoveClientMember = gql`
  mutation RemoveClientMember($id: ID!) {
    removeClientMember(id: $id)
  }
`;

// === transactions === //
const GetTransaction = gql`
  ${BaseFullTransactionFieldsV2}
  query GetTransaction($transactionId: ID!) {
    getTransaction(transactionId: $transactionId) {
      ...BaseFullTransactionFieldsV2
      balances {
        accountId
        assetId
        before
        after
        assetSymbol
        accountName
      }
    }
  }
`;

const GetTransactionPage = gql`
  query GetTransactionPage($transactionId: ID!) {
    getTransactionPage(transactionId: $transactionId) {
      page
    }
  }
`;

const RedirectByHash = gql`
  query RedirectByHash($txnHash: String!) {
    redirectByHash(txnHash: $txnHash) {
      page
      clientId
      transactionId
      redirectUrl
    }
  }
`;

const GetTransactionTypeOptions = gql`
  query GetTransactionTypeOptions($transactionId: ID!) {
    getTransactionTypeOptions(transactionId: $transactionId) {
      labels {
        label
        value
        infoMessage
        category
        ruleType
        applicable
        applicableCautious
        keywordMatches
      }
    }
  }
`;

const GetLabelOptionsForTransactions = gql`
  query GetLabelOptionsForTransactions($transactionIds: [ID!]!) {
    getLabelOptionsForTransactions(transactionIds: $transactionIds) {
      labels {
        label
        value
        infoMessage
        category
        ruleType
        applicable
        applicableCautious
      }
      message
    }
  }
`;

const LabelManyTransactions = gql`
  mutation LabelTransactions($transactionIds: [ID!]!, $label: String!) {
    labelTransactions(transactionIds: $transactionIds, label: $label) {
      id
    }
  }
`;

const CountDirty = gql`
  query CountDirty($clientId: ID!) {
    countDirty(clientId: $clientId)
  }
`;

const CountNotDone = gql`
  query CountNotDone($clientId: ID!) {
    countNotDone(clientId: $clientId) {
      dirty
      importing
      isDirty
    }
  }
`;

const UpdateTransaction = gql`
  ${BaseFullTransactionFields}
  mutation UpdateTransactionById(
    $transactionId: ID!
    $updates: UpdateTransactionInput!
    $createDefaultRule: Boolean!
  ) {
    updateTransaction(
      transactionId: $transactionId
      updates: $updates
      createDefaultRule: $createDefaultRule
    ) {
      transaction {
        ...BaseFullTransactionFields
      }
      otherTxnsRuleApplies
      message
      messageStatus
    }
  }
`;

const HideTransaction = gql`
  mutation HideTransaction($transactionId: ID!, $isHidden: Boolean!) {
    hideTransaction(transactionId: $transactionId, isHidden: $isHidden) {
      id
    }
  }
`;

const UpdateTransfer = gql`
  ${BaseFullTransactionFields}
  mutation UpdateTransfer(
    $transactionId: ID!
    $transferId: ID!
    $fiatValue: Float
    $fiatCurrency: String
    $basisFiatValue: Float
    $originalFiatValue: Float
    $isHidden: Boolean
    $amount: Float
    $assetId: String
    $fromAccountId: ID
    $toAccountId: ID
  ) {
    updateTransfer(
      transactionId: $transactionId
      transferId: $transferId
      fiatValue: $fiatValue
      fiatCurrency: $fiatCurrency
      basisFiatValue: $basisFiatValue
      originalFiatValue: $originalFiatValue
      isHidden: $isHidden
      amount: $amount
      assetId: $assetId
      fromAccountId: $fromAccountId
      toAccountId: $toAccountId
    ) {
      ...BaseFullTransactionFields
    }
  }
`;

const CreateTransfer = gql`
  ${BaseFullTransactionFields}
  mutation CreateTransfer(
    $transactionId: ID!
    $fiatValue: Float
    $amount: Float
    $assetId: ID!
    $fromAccountId: ID
    $toAccountId: ID
  ) {
    createTransfer(
      transactionId: $transactionId
      fiatValue: $fiatValue
      amount: $amount
      assetId: $assetId
      fromAccountId: $fromAccountId
      toAccountId: $toAccountId
    ) {
      ...BaseFullTransactionFields
    }
  }
`;

const UnhideTransfers = gql`
  mutation UnhideTransfers($transactionId: ID!) {
    unhideTransfers(transactionId: $transactionId)
  }
`;

const UpdateFee = gql`
  ${BaseFullTransactionFields}
  mutation UpdateFee(
    $transactionId: ID!
    $feeId: ID!
    $fiatValue: Float
    $fiatCurrency: String
  ) {
    updateFee(
      transactionId: $transactionId
      feeId: $feeId
      fiatValue: $fiatValue
      fiatCurrency: $fiatCurrency
    ) {
      ...BaseFullTransactionFields
    }
  }
`;

const CreateTransaction = gql`
  ${BaseFullTransactionFields}
  mutation CreateTransaction(
    $clientId: ID!
    $accountId: ID!
    $txnHash: String
    $createdAt: Date!
  ) {
    createTransaction(
      clientId: $clientId
      accountId: $accountId
      txnHash: $txnHash
      createdAt: $createdAt
    ) {
      ...BaseFullTransactionFields
    }
  }
`;

const SyncTransaction = gql`
  ${BaseFullTransactionFields}
  mutation SyncTransaction($transactionId: ID!) {
    syncTransaction(transactionId: $transactionId) {
      ...BaseFullTransactionFields
    }
  }
`;

const GetTransactionLedgerEntries = gql`
  ${BaseLedgerEntryFields}
  query GetTransactionLedgerEntries($transactionId: ID, $txnHash: String) {
    getTransactionLedgerEntries(
      transactionId: $transactionId
      txnHash: $txnHash
    ) {
      ...BaseLedgerEntryFields
    }
  }
`;

const CountTransactions = gql`
  query CountTransactions($clientId: ID!) {
    getNumTxns(clientId: $clientId) {
      total
      reviewed
      unreviewed
      high
      medium
      low
      unknown
      insignificant
    }
  }
`;

const GetNumTxnTypes = gql`
  ${BaseSimpleTransactionFields}
  query GetNumTxnTypes($clientId: ID!, $gainsLossesPage: Int!) {
    getNumTxnTypes(clientId: $clientId, gainsLossesPage: $gainsLossesPage) {
      numTxnTypes {
        reason
        count
      }
      gasMetrics {
        year
        fiatAmount
      }
      biggestGainsLosses {
        ...BaseSimpleTransactionFields
      }
    }
  }
`;

const UploadManualTransactions = gql`
  mutation UploadManualTransactions(
    $fileObjectKey: String!
    $accountId: ID
    $provider: String
    $clientId: String
  ) {
    uploadManualTransactions(
      fileObjectKey: $fileObjectKey
      accountId: $accountId
      provider: $provider
      clientId: $clientId
    )
  }
`;

// ==== rules ==== //
const CreateRule = gql`
  mutation CreateRule(
    $transactionId: ID!
    $ruleType: RuleTypeEnum!
    $label: String!
  ) {
    createRule(
      transactionId: $transactionId
      ruleType: $ruleType
      label: $label
    )
  }
`;

const GetRules = gql`
  ${BaseRuleFields}
  query GetRules {
    getRules {
      ...BaseRuleFields
    }
  }
`;

const GetLabels = gql`
  query GetLabels {
    getLabels {
      label
      value
      infoMessage
      category
    }
  }
`;

const UpdateRuleLabel = gql`
  ${BaseRuleFields}
  mutation UpdateLabel($ruleId: ID!, $label: String!) {
    updateLabel(ruleId: $ruleId, label: $label) {
      ...BaseRuleFields
    }
  }
`;

const CheckRules = gql`
  query CheckRules($clientId: ID!, $ruleIds: [ID!]!) {
    checkRules(clientId: $clientId, ruleIds: $ruleIds) {
      id
      numTxnsApplied
    }
  }
`;

const CheckRuleUsed = gql`
  query CheckRuleUsed($ruleId: ID!) {
    checkRuleUsed(ruleId: $ruleId) {
      id
      numTxnsRuleUsed
    }
  }
`;

const RemoveRule = gql`
  mutation RemoveRule($ruleId: ID!) {
    removeRule(ruleId: $ruleId)
  }
`;

const GetOtherUserLabels = gql`
  query GetOtherUserLabels($transactionId: ID!) {
    getOtherUserLabels(transactionId: $transactionId) {
      ruleType
      numUsers
      shouldUse
    }
  }
`;

// ==== default rules ==== //
const GetNextDefaultRules = gql`
  query GetNextDefaultRules {
    getNextDefaultRules {
      type
      formattedType
      constraints
      numType
      numRules
      percentage
      exampleTxnHash
      exampleRuleId
      provider
      blockExplorerUrl
      blockExplorerName
    }
  }
`;

const CreateDefaultRule = gql`
  ${BaseDefaultRuleFields}
  mutation CreateDefaultRule($exampleRuleId: String!, $label: String!) {
    createDefaultRule(exampleRuleId: $exampleRuleId, label: $label) {
      ...BaseDefaultRuleFields
    }
  }
`;

const RuleToDefaultRule = gql`
  ${BaseDefaultRuleFields}
  mutation RuleToDefaultRule($transactionId: ID!, $label: String!) {
    ruleToDefaultRule(transactionId: $transactionId, label: $label) {
      ...BaseDefaultRuleFields
    }
  }
`;

// ==== portfolio ==== //
const GetPortfolio = gql`
  ${BaseAssetFields}
  query GetPortfolio(
    $clientId: ID!
    $type: AssetTypeEnum
    $limit: Int
    $page: Int
    $ascending: Boolean
    $sort: PortfolioSortColumnEnum
    $search: String
    $provider: String
  ) {
    getPortfolio(
      clientId: $clientId
      type: $type
      limit: $limit
      page: $page
      ascending: $ascending
      sort: $sort
      search: $search
      provider: $provider
    ) {
      balances {
        assetKey
        amount
        costBasisFiatAmount
        currentFiatAmount
        percentChangeAllTime
        gainOrLossFiatAmount
        hasPrice
        avgBasisFiatAmount
        currentTokenPriceFiatAmount
        estimatedCurrentValue
        asset {
          ...BaseAssetFields
        }
        accountIds
      }
      total
    }
  }
`;

const GetPortfolioAssets = gql`
  ${BaseAssetFields}
  query GetPortfolio(
    $clientId: ID!
    $type: AssetTypeEnum
    $limit: Int
    $page: Int
    $ascending: Boolean
    $sort: PortfolioSortColumnEnum
    $search: String
    $useCache: Boolean
    $provider: String
    $assetKey: String
  ) {
    getPortfolio(
      clientId: $clientId
      type: $type
      limit: $limit
      page: $page
      ascending: $ascending
      sort: $sort
      search: $search
      useCache: $useCache
      provider: $provider
      assetKey: $assetKey
    ) {
      balances {
        asset {
          ...BaseAssetFields
        }
        assetKey
        amount
        costBasisFiatAmount
        currentFiatAmount
        percentChangeAllTime
        gainOrLossFiatAmount
        hasPrice
        avgBasisFiatAmount
        currentTokenPriceFiatAmount
        estimatedCurrentValue
        accountIds
        breakdown {
          assetId
          accountId
          amount
          costBasisFiatAmount
          currentFiatAmount
          percentChangeAllTime
          gainOrLossFiatAmount
          hasPrice
          avgBasisFiatAmount
          currentTokenPriceFiatAmount
          estimatedCurrentValue
        }
      }
      total
    }
  }
`;

const GetPositions = gql`
  ${BaseAssetFields}
  ${BaseAccountFields}
  query GetPositions($clientId: ID!) {
    getPositions(clientId: $clientId) {
      account {
        ...BaseAccountFields
      }
      hasOpenPositions
      estimatedTotalFiatCurrentValue
      assets {
        asset {
          ...BaseAssetFields
        }
        amount
        basisFiatAmount
        currentFiatAmount
        estimatedCurrentFiatAmount
      }
    }
  }
`;

const SubmitPortfolioFeedback = gql`
  mutation SubmitPortfolioFeedback($message: String!) {
    submitPortfolioFeedback(message: $message)
  }
`;

// ==== ledger entries ==== //

const CreateLedgerEntry = gql`
  ${BaseLedgerEntryFields}
  mutation CreateLedgerEntry(
    $transactionId: ID!
    $params: CreateLedgerEntryInput!
    $links: [LedgerEntryLinkInput!]
  ) {
    createLedgerEntry(
      transactionId: $transactionId
      params: $params
      links: $links
    ) {
      ...BaseLedgerEntryFields
    }
  }
`;

const UpdateLedgerEntry = gql`
  ${BaseLedgerEntryFields}
  mutation UpdateLedgerEntry(
    $ledgerEntryId: ID!
    $params: UpdateOneLedgerEntryInput!
    $links: [LedgerEntryLinkInput!]
  ) {
    updateLedgerEntry(
      ledgerEntryId: $ledgerEntryId
      params: $params
      links: $links
    ) {
      ...BaseLedgerEntryFields
    }
  }
`;

const DeleteLedgerEntry = gql`
  mutation DeleteLedgerEntry($ledgerEntryId: ID!) {
    deleteLedgerEntry(ledgerEntryId: $ledgerEntryId)
  }
`;

const GetEntryLinks = gql`
  ${BaseLedgerEntryLinkFields}
  ${BaseLedgerEntryLinkWithCapacityAndLinksFields}
  query GetEntryLinks($ledgerEntryId: ID!) {
    getEntryLinks(ledgerEntryId: $ledgerEntryId) {
      toLinks {
        ...BaseLedgerEntryLinkFields
      }
      fromLinks {
        ...BaseLedgerEntryLinkWithCapacityAndLinksFields
      }
    }
  }
`;

const GetEntryLinksForTransfer = gql`
  ${BaseLedgerEntryLinkWithCapacityAndLinksFields}
  query GetEntryLinksForTransfer($transactionId: ID!, $transferId: String!) {
    getEntryLinksForTransfer(
      transactionId: $transactionId
      transferId: $transferId
    ) {
      ...BaseLedgerEntryLinkWithCapacityAndLinksFields
    }
  }
`;

const GetPossibleEntryLinks = gql`
  ${BaseLedgerEntryWithCapacityFields}
  query GetPossibleEntryLinks($assetId: ID!, $beforeDate: Date!) {
    getPossibleEntryLinks(assetId: $assetId, beforeDate: $beforeDate) {
      ...BaseLedgerEntryWithCapacityFields
    }
  }
`;

const DeleteClient = gql`
  mutation DeleteClient($clientId: ID!) {
    deleteClient(clientId: $clientId)
  }
`;

const SubmitPortfolioWaitlistEntry = gql`
  mutation SubmitPortfolioWaitlistEntry($email: String!) {
    submitPortfolioWaitlistEntry(email: $email)
  }
`;

const SubmitFeedback = gql`
  mutation SubmitFeedback($feedback: String!) {
    submitFeedback(feedback: $feedback)
  }
`;

const SubmitPortfolioBeta = gql`
  mutation SubmitPortfolioBeta {
    submitPortfolioBeta
  }
`;

const GetReportExport = gql`
  query GetReportExport(
    $clientId: ID!
    $type: ReportExportTypeEnum!
    $tagGroupId: ID
    $year: String!
    $forceSendEmail: Boolean
  ) {
    getReportExport(
      clientId: $clientId
      type: $type
      tagGroupId: $tagGroupId
      year: $year
      forceSendEmail: $forceSendEmail
    ) {
      downloadUrl
      message
    }
  }
`;

export const GetTransactionHistoryReport = gql`
  query GetTransactionHistoryReport($clientId: ID!) {
    getTransactionHistoryReport(clientId: $clientId) {
      downloadUrl
      message
    }
  }
`;

export const GetTransactionHistoryReportV2 = gql`
  query GetTransactionHistoryReportV2(
    $clientId: ID!
    $reviewed: Boolean
    $page: Int
    $limit: Int
    $startDate: Date
    $endDate: Date
    $accountIds: [ID!]
    $search: String
    $labeledPriority: Int
    $hasIncome: Boolean
    $hasNotes: Boolean
    $sortBy: String
    $ascending: Boolean
    $assetSymbolOrName: String
    $assetId: String
    $isMissingBasis: Boolean
    $assetKey: String
    $assetKeyV2: String
    $providers: [String!]
    $assetIds: [String!]
    $labels: [String!]
    $includeSpam: Boolean
    $assetType: String
  ) {
    getTransactionHistoryReportV2(
      clientId: $clientId
      page: $page
      reviewed: $reviewed
      limit: $limit
      assetKeyV2: $assetKeyV2
      startDate: $startDate
      endDate: $endDate
      labeledPriority: $labeledPriority
      hasIncome: $hasIncome
      hasNotes: $hasNotes
      accountIds: $accountIds
      search: $search
      sortBy: $sortBy
      ascending: $ascending
      assetSymbolOrName: $assetSymbolOrName
      assetId: $assetId
      isMissingBasis: $isMissingBasis
      assetKey: $assetKey
      providers: $providers
      assetIds: $assetIds
      labels: $labels
      includeSpam: $includeSpam
      assetType: $assetType
    ) {
      downloadUrl
      message
    }
  }
`;

const GetMonthlyPoints = gql`
  query GetMonthlyPoints($clientId: ID!, $year: String!) {
    getMonthlyPoints(clientId: $clientId, year: $year) {
      timestamp
      longTermCapGainsCents
      shortTermCapGainsCents
      totalCapGainsCents
      netIncomeCents
      totalCapGainsFormatted
      netIncomeFormatted
    }
  }
`;

const GetMonthlySummary = gql`
  query GetMonthlySummary($clientId: ID!, $year: String!, $month: Int!) {
    getMonthlySummary(clientId: $clientId, year: $year, month: $month) {
      timestamp
      numNeedingReview
      numReviewed
      numMissingBasis
      numHighPriority
      emailBody
      emailSubject
      email
      unlabeledTransactionUrl
      longTermCapGainsCents
      shortTermCapGainsCents
      totalCapGainsCents
      netIncomeCents
    }
  }
`;

const GetPnL = gql`
  ${BaseReportAccountInfoWithChildrenFields}
  query GetPnL($clientId: ID!) {
    getPnL(clientId: $clientId) {
      income {
        ...BaseReportAccountInfoWithChildrenFields
      }
      otherIncome {
        ...BaseReportAccountInfoWithChildrenFields
      }
      cogs {
        ...BaseReportAccountInfoWithChildrenFields
      }
      operationExpenses {
        ...BaseReportAccountInfoWithChildrenFields
      }
      otherExpenses {
        ...BaseReportAccountInfoWithChildrenFields
      }
      totalIncome
      totalCogs
      grossProfit
      totalOperatingExpenses
      totalOtherExpenses
      netOperatingIncome
      netOtherIncome
      netIncome
    }
  }
`;

const GetTaxYears = gql`
  query GetTaxYears($clientId: ID!) {
    getTaxYears(clientId: $clientId)
  }
`;

const GetTaxYearBrackets = gql`
  query GetTaxYearBrackets($clientId: ID!) {
    getTaxYearBrackets(clientId: $clientId) {
      start
      startFormatted
      end
      endFormatted
      taxYear
    }
  }
`;

const GetTaxesByAsset = gql`
  ${BaseAssetFields}
  query GetTaxesByAsset($clientId: ID!, $startDate: Date!, $endDate: Date!) {
    getTaxesByAsset(
      clientId: $clientId
      startDate: $startDate
      endDate: $endDate
    ) {
      assetId
      totalGain
      asset {
        ...BaseAssetFields
      }
    }
  }
`;

const GetLedgerAccountSubAccounts = gql`
  ${BaseLedgerAccountFields}
  query GetSubAccounts($parentAccountId: ID!, $depth: Int) {
    getSubAccounts(parentAccountId: $parentAccountId, depth: $depth) {
      ...BaseLedgerAccountFields
    }
  }
`;

const GetLedgerAccountWithSubAccounts = gql`
  ${BaseReportAccountInfoFields}
  ${BaseLedgerAccountFields}
  query GetLedgerAccount($accountId: ID!) {
    getLedgerAccount(accountId: $accountId) {
      path {
        ...BaseLedgerAccountFields
      }
      account {
        ...BaseReportAccountInfoFields
      }
      subAccounts {
        ...BaseReportAccountInfoFields
      }
    }
  }
`;

const CheckForErrors = gql`
  query CheckForErrors($clientId: ID!) {
    checkForErrors(clientId: $clientId)
  }
`;

const GetIncomeAndCapGains = gql`
  query GetIncomeAndCapGains($clientId: ID!, $year: String!) {
    getIncomeAndCapGains(clientId: $clientId, year: $year) {
      hasActiveSubscription
      capGainsShortTermProceeds
      capGainsShortTermBasis
      capGainsShortTerm
      capGainsLongTermProceeds
      capGainsLongTermBasis
      capGainsLongTerm
      capGainsTotal
      futuresProceeds
      futuresExpenses
      futuresFees
      futuresNet
      incomeTotal
      incomeBreakdown {
        accountName
        amount
        info
      }
      goodwill {
        accountName
        amount
        info
      }
      goodwillTotal
      isTooLargeCapGains
      isTooLargeIncome
    }
  }
`;

const GetReports = gql`
  ${BaseReportFields}
  query GetReports($clientId: ID!) {
    getReports(clientId: $clientId) {
      ...BaseReportFields
    }
  }
`;

const GetReportDownloadUrl = gql`
  query GetReportDownloadUrl($reportId: ID!) {
    getReportDownloadUrl(reportId: $reportId)
  }
`;

// ==== assets ==== //
const SearchTrackedAssets = gql`
  query searchTrackedAssets($query: String!) {
    searchTrackedAssets(query: $query) {
      name
      symbol
      coingeckoId
    }
  }
`;

const CreateAsset = gql`
  ${BaseAssetFields}
  mutation createAsset(
    $clientId: ID!
    $name: String!
    $coinGeckoTokenId: String
    $symbol: String
    $description: String
    $type: AssetTypeEnum
    $imageUrl: String
    $provider: String!
  ) {
    createAsset(
      clientId: $clientId
      name: $name
      coinGeckoTokenId: $coinGeckoTokenId
      symbol: $symbol
      description: $description
      type: $type
      imageUrl: $imageUrl
      provider: $provider
    ) {
      ...BaseAssetFields
    }
  }
`;

const CreateAssetV2 = gql`
  ${BaseAssetFields}
  mutation CreateAssetV2(
    $clientId: ID!
    $name: String!
    $contractAddress: String
    $coinGeckoTokenId: String
    $symbol: String
    $description: String
    $type: AssetTypeEnum
    $imageUrl: String
    $provider: String!
  ) {
    createAssetV2(
      clientId: $clientId
      name: $name
      contractAddress: $contractAddress
      coinGeckoTokenId: $coinGeckoTokenId
      symbol: $symbol
      description: $description
      type: $type
      imageUrl: $imageUrl
      provider: $provider
    ) {
      ...BaseAssetFields
    }
  }
`;

const SyncAssetMetadata = gql`
  ${BaseAssetFields}
  mutation SyncAssetMetadata($clientId: ID!) {
    syncAssetMetadata(clientId: $clientId) {
      ...BaseAssetFields
    }
  }
`;

const ReportUnpricedAsset = gql`
  mutation ReportUnpricedAsset(
    $assetId: ID!
    $chain: String!
    $contractAddress: String
    $symbol: String
  ) {
    reportUnpricedAsset(
      assetId: $assetId
      chain: $chain
      contractAddress: $contractAddress
      symbol: $symbol
    )
  }
`;

const AddPricedAsset = gql`
  mutation AddPricedAsset(
    $provider: String!
    $contractAddress: String!
    $symbol: String!
    $coingeckoId: String!
  ) {
    addPricedAsset(
      provider: $provider
      contractAddress: $contractAddress
      symbol: $symbol
      coingeckoId: $coingeckoId
    )
  }
`;

const GetAssetKeyInfo = gql`
  ${BaseAssetFields}
  query GetAssetKeyInfo(
    $clientId: ID!
    $assetKey: String
    $assetPricingKeyV2: String
  ) {
    getAssetKeyInfo(
      clientId: $clientId
      assetKey: $assetKey
      assetPricingKeyV2: $assetPricingKeyV2
    ) {
      name
      symbol
      iconImageUrl
      backupImageUrls
      coingeckoId
      type
      provider
      contractAddress
      firstAsset {
        ...BaseAssetFields
      }
    }
  }
`;

const GetCurrentAssetPrice = gql`
  query GetCurrentAssetPrice(
    $clientId: ID!
    $useCacheIfAvailable: Boolean
    $assetKey: String!
  ) {
    getCurrentAssetPrice(
      clientId: $clientId
      useCacheIfAvailable: $useCacheIfAvailable
      assetKey: $assetKey
    ) {
      timestamp
      value
      valueCents
      breakdown {
        assetId
        amount
        value
        price
      }
    }
  }
`;

const RemovePricedAsset = gql`
  mutation RemovePricedAsset($provider: String!, $contractAddress: String!) {
    removePricedAsset(provider: $provider, contractAddress: $contractAddress)
  }
`;

const SetAssetSpam = gql`
  ${BaseAssetFields}
  mutation SetAssetSpam($assetId: ID!, $isSpam: Boolean!) {
    setAssetSpam(assetId: $assetId, isSpam: $isSpam) {
      ...BaseAssetFields
    }
  }
`;

const UpdateAsset = gql`
  ${BaseAssetFields}
  mutation updateAsset(
    $assetId: ID!
    $name: String
    $symbol: String
    $description: String
    $type: AssetTypeEnum
    $imageUrl: String
    $isSpam: Boolean
    $isWorthless: Boolean
    $contractAddress: String
    $coingeckoId: String
  ) {
    updateAsset(
      assetId: $assetId
      name: $name
      symbol: $symbol
      description: $description
      type: $type
      imageUrl: $imageUrl
      isSpam: $isSpam
      isWorthless: $isWorthless
      contractAddress: $contractAddress
      coingeckoId: $coingeckoId
    ) {
      ...BaseAssetFields
    }
  }
`;

const MarkAssetKeyWorthless = gql`
  mutation MarkAssetKeyWorthless(
    $clientId: ID!
    $assetKey: String!
    $isWorthless: Boolean!
  ) {
    markAssetKeyWorthless(
      clientId: $clientId
      assetKey: $assetKey
      isWorthless: $isWorthless
    )
  }
`;

const UnsetSpam = gql`
  mutation UnsetSpam($assetId: ID!) {
    unsetSpam(assetId: $assetId)
  }
`;

const BulkMarkSpam = gql`
  mutation BulkMarkSpam($clientId: ID!, $assetIds: [ID!]!) {
    bulkMarkSpam(clientId: $clientId, assetIds: $assetIds)
  }
`;

const CheckForSpamReview = gql`
  mutation CheckForSpamReview($clientId: ID!) {
    checkForSpamReview(clientId: $clientId)
  }
`;

const GetEstimatedCostBasis = gql`
  ${BaseLedgerEntryFields}
  query GetEstimatedCostBasis($assetId: ID!) {
    getEstimatedCostBasis(assetId: $assetId) {
      entries {
        ...BaseLedgerEntryFields
      }
      totalCostBasisCents
    }
  }
`;

// === jobs === //

const GetTransactionImportJobs = gql`
  ${BaseJobFields}
  query getTransactionImportJobs($clientId: ID!) {
    getTransactionImportJobs(clientId: $clientId) {
      active {
        ...BaseJobFields
      }
      failed {
        ...BaseJobFields
      }
      completed {
        ...BaseJobFields
      }
    }
  }
`;

const GetJobs = gql`
  ${BaseJobFields}
  query getJobsForClient($clientId: ID!) {
    getJobs(clientId: $clientId) {
      ...BaseJobFields
    }
  }
`;

// === recalculate summary === //
const GetRecalculateSummaries = gql`
  ${BaseRecalculateSummaryFields}
  query GetRecalculateSummaries($clientId: ID!) {
    getRecalculateSummaries(clientId: $clientId) {
      ...BaseRecalculateSummaryFields
    }
  }
`; // do NOT get snapshots! too much data

const GetDiffV1 = gql`
  ${BaseRecalculateSnapshotDiffTxnPairFields}
  ${BaseRecalculateSummaryFields}
  query GetDiffV1(
    $clientId: ID!
    $oldRecalculateSummaryId: ID!
    $newRecalculateSummaryId: ID!
    $year: Int!
  ) {
    getDiffV1(
      clientId: $clientId
      oldRecalculateSummaryId: $oldRecalculateSummaryId
      newRecalculateSummaryId: $newRecalculateSummaryId
      year: $year
    ) {
      transactions {
        ...BaseRecalculateSnapshotDiffTxnPairFields
      }
      oldSummary {
        ...BaseRecalculateSummaryFields
      }
      newSummary {
        ...BaseRecalculateSummaryFields
      }
    }
  }
`;

// === misc === //
const CreateFilterFormSubmission = gql`
  mutation CreateFilterFormSubmission(
    $clientType: FilterFormClientEnum!
    $chainsType: FilterFormChainsEnum!
    $numTxns: FilterFormNumTxnsEnum!
    $name: String!
    $email: String!
  ) {
    createFilterFormSubmission(
      clientType: $clientType
      chainsType: $chainsType
      numTxns: $numTxns
      name: $name
      email: $email
    )
  }
`;

const GetNumCPAs = gql`
  query GetNumCPAs {
    getNumCPAs
  }
`;

const GetFilteredCPAs = gql`
  ${BaseFilterFormCPAFields}
  query GetFilteredCPAs($filterFormSubmissionId: ID!) {
    getFilteredCPAs(filterFormSubmissionId: $filterFormSubmissionId) {
      ...BaseFilterFormCPAFields
    }
  }
`;

const CreateFilterFormCPA = gql`
  mutation CreateFilterFormCPA(
    $isAcceptingIndividuals: Boolean!
    $isAcceptingBusinesses: Boolean!
    $isAcceptingEth: Boolean!
    $isAcceptingEthL2: Boolean!
    $isAcceptingOtherChains: Boolean!
    $numTxnsLowerBound: FilterFormNumTxnsEnum!
    $numTxnsUpperBound: FilterFormNumTxnsEnum!
    $name: String!
    $email: String!
    $twitter: String!
    $linkedin: String!
    $headline: String!
    $description: String!
    $confirmed: Boolean!
    $imgUrl: String!
  ) {
    createFilterFormCPA(
      isAcceptingIndividuals: $isAcceptingIndividuals
      isAcceptingBusinesses: $isAcceptingBusinesses
      isAcceptingEth: $isAcceptingEth
      isAcceptingEthL2: $isAcceptingEthL2
      isAcceptingOtherChains: $isAcceptingOtherChains
      numTxnsLowerBound: $numTxnsLowerBound
      numTxnsUpperBound: $numTxnsUpperBound
      name: $name
      email: $email
      twitter: $twitter
      linkedin: $linkedin
      headline: $headline
      description: $description
      confirmed: $confirmed
      imgUrl: $imgUrl
    )
  }
`;

const GetPotentialSpamAssets = gql`
  query GetPotentialSpamAssets {
    getPotentialSpamAssets {
      contractAddress
      provider
      exampleTxnHash
      assetName
      count
      minTxnCreatedAt
      numInitiatedFees
    }
  }
`;

const CreatePotentialSpamAsset = gql`
  mutation CreateSpamAsset(
    $contractAddress: String!
    $provider: AccountProviderEnum!
    $exampleTxnHash: String!
    $assetName: String!
    $isSpam: Boolean
  ) {
    createSpamAsset(
      contractAddress: $contractAddress
      provider: $provider
      exampleTxnHash: $exampleTxnHash
      assetName: $assetName
      isSpam: $isSpam
    ) {
      contractAddress
    }
  }
`;

const SendSlackNotification = gql`
  mutation SendSlackNotification($message: String!) {
    sendSlackNotification(message: $message)
  }
`;

const RerunGraph = gql`
  mutation RerunGraph(
    $clientId: ID!
    $useSpeedup: Boolean
    $doNotReplay: Boolean
  ) {
    rerunGraph(
      clientId: $clientId
      useSpeedup: $useSpeedup
      doNotReplay: $doNotReplay
    )
  }
`;

// replay
const GetReplayOverview = gql`
  query GetReplayOverview($clientId: ID!) {
    getReplayOverview(clientId: $clientId) {
      isReplayNeeded
      isUsingReplay
      numberOfTransactions
      estimatedTimeInMS
      dirtyCount
      transactions {
        id
        title
        description
        dirtyStatus
        createdAt
      }
    }
  }
`;

const GetRerunGraphJob = gql`
  ${BaseJobFields}
  query GetRerunGraphJob($clientId: ID!) {
    getRerunGraphJob(clientId: $clientId) {
      ...BaseJobFields
    }
  }
`;

const StartCheckout = gql`
  mutation StartCheckout(
    $clientId: ID!
    $lineItems: [LineItem!]!
    $promoCode: String
  ) {
    startCheckout(
      clientId: $clientId
      lineItems: $lineItems
      promoCode: $promoCode
    )
  }
`;

const StartSubscriptionCheckout = gql`
  mutation StartSubscriptionCheckout($clientId: ID!, $txnCeiling: Float!) {
    startSubscriptionCheckout(clientId: $clientId, txnCeiling: $txnCeiling) {
      clientSecret
      requiresPayment
      txnCeiling
      lineItems {
        name
        description
        amount
        amountCents
      }
    }
  }
`;

const StartUpgradeCheckout = gql`
  mutation StartUpgradeCheckout($clientId: ID!) {
    startUpgradeCheckout(clientId: $clientId) {
      clientSecret
      paymentIntentId
      amount
    }
  }
`;

const GetEligibleDiscountsForTaxYear = gql`
  query GetEligibleDiscountsForTaxYear($clientId: ID!, $taxYear: Int!) {
    getEligibleDiscountsForTaxYear(clientId: $clientId, taxYear: $taxYear) {
      amount
      amountCents
      type
      title
      infoMessage
    }
  }
`;

const GetMyActiveSubscription = gql`
  query GetMyActiveSubscription($clientId: ID!) {
    getMyActiveSubscription(clientId: $clientId) {
      hasSubscription
      maxAllowedForTaxYear
      currentCountForTaxYear
      hasPaidForUnlimitedCX
      subscription {
        id
        startPeriod
        endPeriod
        isFreeSubscription
        totalCents
        transactionCeiling
      }
      discountCalculation {
        spamDiscount
        likelySpamDiscount
        coinbaseWalletDiscount
        totalDiscount
        numPayableTxns
        totalNumTxns
        magicEdenDiscount
        discounts {
          number
          label
        }
      }
    }
  }
`;

const GetSubscriptions = gql`
  query GetSubscriptions($clientId: ID!) {
    getSubscriptions(clientId: $clientId) {
      id
      transactionCeiling
      startPeriod
      endPeriod
      isFreeSubscription
      totalCents
      taxYear
    }
  }
`;

const CreateSubscription = gql`
  mutation CreateSubscription($clientId: ID!, $txnCeiling: Float!) {
    createSubscription(clientId: $clientId, txnCeiling: $txnCeiling) {
      id
    }
  }
`;

const DeleteSubscription = gql`
  mutation DeleteSubscription($subscriptionId: ID!) {
    deleteSubscription(subscriptionId: $subscriptionId) {
      id
    }
  }
`;

const FreeSubscription = gql`
  mutation FreeSubscription($clientId: ID!, $notes: String!) {
    freeSubscription(clientId: $clientId, notes: $notes) {
      id
    }
  }
`;

const PacksForClient = gql`
  ${BasePackFields}
  query PacksForClient($clientId: ID!) {
    packsForClient(clientId: $clientId) {
      totalAvailable
      totalUsed
      packs {
        ...BasePackFields
      }
    }
  }
`;

const GetPaidTransactionOverview = gql`
  query GetPaidTransactionOverview($clientId: ID!, $endPeriod: Date) {
    getPaidTransactionOverview(clientId: $clientId, endPeriod: $endPeriod) {
      hasPaidForPeriod
      totalAvailable
      totalUsed
      totalLeft
      totalLeftForPeriod
      totalUpToPeriod
    }
  }
`;

const GetPlaidLinkToken = gql`
  query GetPlaidLinkToken {
    getPlaidLinkToken
  }
`;

const UploadFile = gql`
  mutation UploadFile($file: Upload!, $clientId: ID!, $expiresInSeconds: Int) {
    uploadFile(
      file: $file
      clientId: $clientId
      expiresInSeconds: $expiresInSeconds
    ) {
      url
      objectFileKey
      fileName
    }
  }
`;

const ClaimCode = gql`
  mutation ClaimCode($clientId: ID!, $referralCode: String!) {
    claimCode(referralCode: $referralCode, clientId: $clientId) {
      message
    }
  }
`;

const ClaimWeb3Reward = gql`
  mutation ClaimWeb3Reward($clientId: ID!, $rewardIdentifier: String!) {
    claimWeb3Reward(rewardIdentifier: $rewardIdentifier, clientId: $clientId) {
      message
    }
  }
`;

const RedeemReferralPrize = gql`
  mutation RedeemReferralPrize($clientId: ID!, $prize: ReferralPrizeEnum!) {
    redeemReferralPrize(prize: $prize, clientId: $clientId)
  }
`;

const GetReferralPrizes = gql`
  query GetReferralPrizes($clientId: ID!) {
    getReferralPrizes(clientId: $clientId) {
      available
      completed
    }
  }
`;

const GetWeb3Rewards = gql`
  query GetWeb3Rewards($clientId: ID!) {
    getWeb3Rewards(clientId: $clientId) {
      options {
        identifier
        name
        amountCents
        amount
        imageUrl
        isAvailable
        isCompleted
      }
    }
  }
`;

const GetPriceForDate = gql`
  query GetPriceForDate(
    $assetId: ID!
    $amount: Float
    $date: Date!
    $currency: CurrencyCodeEnum!
  ) {
    getPriceForDate(
      assetId: $assetId
      amount: $amount
      date: $date
      currency: $currency
    ) {
      pricePerAsset
      totalPrice
      hasPriceForDate
    }
  }
`;

const GetAssetKeyChart = gql`
  query GetAssetKeyChart(
    $clientId: ID!
    $interval: HistoricalBalanceIntervalEnum!
    $hardRefresh: Boolean
    $assetKey: String!
  ) {
    getAssetKeyChart(
      clientId: $clientId
      interval: $interval
      hardRefresh: $hardRefresh
      assetKey: $assetKey
    ) {
      timestamp
      value
    }
  }
`;

const GetCurrentHoldingsChart = gql`
  query GetCurrentHoldingsChart(
    $clientId: ID!
    $interval: HistoricalBalanceIntervalEnum!
    $hardRefresh: Boolean
    $assetKey: String!
    $accountIds: [ID!]
  ) {
    getCurrentHoldingsChart(
      clientId: $clientId
      interval: $interval
      hardRefresh: $hardRefresh
      assetKey: $assetKey
      accountIds: $accountIds
    ) {
      showWarning
      points {
        timestamp
        value
        amount
      }
    }
  }
`;

// Note: be careful changing the breakdown keys bc only a subset is actually available
const GetChart = gql`
  query GetChart(
    $clientId: ID!
    $interval: HistoricalBalanceIntervalEnum!
    $hardRefresh: Boolean
  ) {
    getChart(
      clientId: $clientId
      interval: $interval
      hardRefresh: $hardRefresh
    ) {
      showWarning
      points {
        timestamp
        value
        numberOfAssets
        numberOfNfts
        numberOfTokens
        breakdown {
          assetId
          amount
          value
          price
        }
      }
    }
  }
`;

const GetPortfolioValue = gql`
  query GetPortfolioValue($clientId: ID!, $useCacheIfAvailable: Boolean) {
    getPortfolioValue(
      clientId: $clientId
      useCacheIfAvailable: $useCacheIfAvailable
    ) {
      showWarning
      timestamp
      value
      valueCents
      breakdown {
        assetId
        amount
        value
        price
      }
    }
  }
`;

const GetAssetWarnings = gql`
  query GetAssetWarnings($clientId: ID!, $assetIds: [ID!]!) {
    getAssetWarnings(clientId: $clientId, assetIds: $assetIds) {
      assetId
      message
      type
    }
  }
`;

const GetOAuthLink = gql`
  mutation GetOAuthLink($clientId: ID!, $provider: String!) {
    getOAuthLink(clientId: $clientId, provider: $provider) {
      url
    }
  }
`;

const GetOAuthToken = gql`
  mutation GetOAuthToken(
    $clientId: ID!
    $provider: String!
    $code: String!
    $accountId: ID
  ) {
    getOAuthToken(
      clientId: $clientId
      provider: $provider
      code: $code
      accountId: $accountId
    )
  }
`;

const UpsertNftAsset = gql`
  mutation UpsertNftAsset(
    $clientId: ID!
    $assetKey: String!
    $contractAddress: String!
    $tokenId: String
    $name: String!
    $imageUrl: String
    $provider: String!
    $isSpam: Boolean
    $isWorthless: Boolean
    $isHidden: Boolean
    $overrideCurrentValue: Float
    $useAwakenPrice: Boolean
  ) {
    upsertNftAsset(
      clientId: $clientId
      assetKey: $assetKey
      contractAddress: $contractAddress
      tokenId: $tokenId
      name: $name
      imageUrl: $imageUrl
      provider: $provider
      isSpam: $isSpam
      isWorthless: $isWorthless
      isHidden: $isHidden
      overrideCurrentValue: $overrideCurrentValue
      useAwakenPrice: $useAwakenPrice
    ) {
      id
    }
  }
`;

const GetPortfolioV2CoinsAndDefi = gql`
  query GetPortfolioV2(
    $clientId: ID!
    $type: AssetTypeEnum
    $limit: Int
    $page: Int
    $ascending: Boolean
    $sort: PortfolioSortColumnEnum
    $search: String
    $useCache: Boolean
    $provider: String
    $assetKey: String
    $includeDefi: Boolean
    $includeNFTs: Boolean
    $prefetch: Boolean
    $onlyIncludedAccountIds: [ID!]
  ) {
    getPortfolioV2(
      clientId: $clientId
      type: $type
      limit: $limit
      page: $page
      ascending: $ascending
      sort: $sort
      search: $search
      useCache: $useCache
      provider: $provider
      assetKey: $assetKey
      includeDefi: $includeDefi
      includeNFTs: $includeNFTs
      prefetch: $prefetch
      onlyIncludedAccountIds: $onlyIncludedAccountIds
    ) {
      showWarning
      valueCents
      value
      timestamp
      dailyFiatAmountCents
      dailyPercentage
      dailyPercentageFormatted
      balanceTotalValueCents
      coinTotalValueCents
      balanceTotalValue
      defiTotalValueCents
      defiTotalValue
      nftTotalValueCents
      defiPositions {
        name
        provider
        logoUrl
        protocolContractAddress
        protocolDetailUrl
        totalValueCents
        totalValue
        items {
          name
          assetValueCents
          debtValueCents
          netValueCents
          principals {
            amount
            assetPriceCents
            fiatAmountCents
            symbol
            logoUrl
            assetId
            description
            type
          }
          debt {
            amount
            assetPriceCents
            fiatAmountCents
            symbol
            logoUrl
            assetId
            description
            type
          }
          rewards {
            amount
            assetPriceCents
            fiatAmountCents
            symbol
            logoUrl
            assetId
          }
        }
      }
      balances {
        assetKey
        groupKey
        assetPricingKey
        symbol
        iconImageUrl
        backupImageUrls
        name
        totalAmount
        totalDebt
        totalDebtFiatAmountCents
        totalFiatAmountCents
        assetPriceCents
        hasPrice
        type
        coinGeckoId
        contractAddress
        assetId
        provider
        providerImageUrl
        dailyFiatAmountCents
        dailyPercentage
        dailyPercentageFormatted
        percentOfPortfolio
        calculatedAmount
        isMissingBasis
        costBasisCents
        gainLossCents
        positionPercentage
        positionPercentageFormatted
      }
    }
  }
`;

const GetPortfolioV2NFTs = gql`
  ${BaseAccountFields}
  query GetPortfolioV2(
    $clientId: ID!
    $type: AssetTypeEnum
    $limit: Int
    $page: Int
    $ascending: Boolean
    $sort: PortfolioSortColumnEnum
    $search: String
    $useCache: Boolean
    $provider: String
    $assetKey: String
    $includeDefi: Boolean
    $includeNFTs: Boolean
    $onlyIncludedAccountIds: [ID!]
  ) {
    getPortfolioV2(
      clientId: $clientId
      type: $type
      limit: $limit
      page: $page
      ascending: $ascending
      sort: $sort
      search: $search
      useCache: $useCache
      provider: $provider
      assetKey: $assetKey
      includeDefi: $includeDefi
      includeNFTs: $includeNFTs
      onlyIncludedAccountIds: $onlyIncludedAccountIds
    ) {
      nftTotalValueCents
      nfts {
        name
        assetKey
        accountId
        tokenId
        provider
        providerImageUrl
        contractAddress
        previewImageUrl
        marketplaceUrl
        marketplaceImageUrl
        useAwakenPrice
        imageUrl
        videoUrl
        assetId
        floorPriceCents
        fiatPriceCents
        lastSalePriceCents
        lastSalePriceDate
        account {
          ...BaseAccountFields
        }
        costBasisCents
        gainLossCents
        positionPercentage
        positionPercentageFormatted
      }
    }
  }
`;

const RefreshPortfolioBalances = gql`
  mutation RefreshPortfolioBalances($clientId: ID!) {
    refreshPortfolioBalances(clientId: $clientId)
  }
`;

const GetPortfolioV2Collections = gql`
  query GetPortfolioV2Collections(
    $clientId: ID!
    $type: AssetTypeEnum
    $limit: Int
    $page: Int
    $ascending: Boolean
    $sort: PortfolioSortColumnEnum
    $search: String
    $useCache: Boolean
    $provider: String
    $assetKey: String
    $includeDefi: Boolean
    $includeNFTs: Boolean
  ) {
    getPortfolioV2(
      clientId: $clientId
      type: $type
      limit: $limit
      page: $page
      ascending: $ascending
      sort: $sort
      search: $search
      useCache: $useCache
      provider: $provider
      assetKey: $assetKey
      includeDefi: $includeDefi
      includeNFTs: $includeNFTs
    ) {
      collections {
        name
        externalUrl
        imageUrl
        totalValueCents
        simplehashCollectionId
        numberOfNFTs
        providerImageUrl
      }
    }
  }
`;

const GetAssetKeyChartV2 = gql`
  query GetAssetKeyChartV2(
    $clientId: ID!
    $interval: HistoricalBalanceIntervalEnum!
    $hardRefresh: Boolean
    $assetPricingKey: String!
  ) {
    getAssetKeyChartV2(
      clientId: $clientId
      interval: $interval
      hardRefresh: $hardRefresh
      assetPricingKey: $assetPricingKey
    ) {
      timestamp
      value
    }
  }
`;

const GetCurrentAssetPriceV2 = gql`
  query GetCurrentAssetPriceV2(
    $clientId: ID!
    $useCacheIfAvailable: Boolean
    $assetPricingKey: String!
  ) {
    getCurrentAssetPriceV2(
      clientId: $clientId
      useCacheIfAvailable: $useCacheIfAvailable
      assetPricingKey: $assetPricingKey
    ) {
      timestamp
      value
      valueCents
    }
  }
`;

const GetAssetPositions = gql`
  query GetAssetPositions($clientId: ID!, $assetPricingKey: String!) {
    getAssetPositions(clientId: $clientId, assetPricingKey: $assetPricingKey) {
      positions {
        groupKey
        url
        logoUrl
        name
        description
        descriptionImageUrl
        positionDescription
        accountId
        provider
        amount
        fiatAmountCents
        costBasisCents
      }
      totalAmount
      totalFiatAmountCents
      totalFiatAmountFormatted
      showWarning
      totalComputedAmount
      totalCostBasisFiatAmountCents
      totalCostBasisFiatAmountFormatted
      avgCostBasisFiatAmountCents
      avgCostBasisFiatAmountFormatted
      dailyFiatAmountCents
      dailyFiatAmountFormatted
      dailyPercentage
      dailyPercentageFormatted
      overallGainLossFiatAmountCents
      overallGainLossFiatAmountFormatted
      overallGainLossPercentage
      overallGainLossPercentageFormatted
    }
  }
`;

const GetReferralCommissions = gql`
  query GetReferralCommissions($clientId: ID!) {
    getReferralCommissions(clientId: $clientId) {
      total
      totalPaidCents
      totalPendingCents
      totalAvailableCents
      commissions {
        id
        commissionCents
        createdAt
      }
      referrals {
        id
      }
    }
  }
`;

const RequestPayout = gql`
  mutation RequestPayout($clientId: ID!) {
    requestPayout(clientId: $clientId) {
      id
    }
  }
`;

const GetReferralPayouts = gql`
  query GetReferralPayouts($clientId: ID!) {
    getReferralPayouts(clientId: $clientId) {
      total
      payouts {
        id
        status
        amountCents
        createdAt
      }
    }
  }
`;

const RequestBookkeepingQuote = gql`
  ${BaseBookkeepingQuoteFields}
  mutation RequestBookkeepingQuote(
    $clientId: ID!
    $taxYearsDescription: String
    $description: String
  ) {
    requestBookkeepingQuote(
      clientId: $clientId
      taxYearsDescription: $taxYearsDescription
      description: $description
    ) {
      ...BaseBookkeepingQuoteFields
    }
  }
`;

const UpdateBookkeepingQuote = gql`
  ${BaseBookkeepingQuoteFields}
  mutation UpdateBookkeepingQuote(
    $bookkeepingQuoteId: ID!
    $status: BookkeepingQuoteStatusEnum
    $upfrontPriceCents: Int
    $monthlyPriceCents: Int
    $quoteFileUrl: String
    $hasPaid: Boolean
    $paymentFrequency: String
  ) {
    updateBookkeepingQuote(
      bookkeepingQuoteId: $bookkeepingQuoteId
      status: $status
      upfrontPriceCents: $upfrontPriceCents
      monthlyPriceCents: $monthlyPriceCents
      quoteFileUrl: $quoteFileUrl
      hasPaid: $hasPaid
      paymentFrequency: $paymentFrequency
    ) {
      ...BaseBookkeepingQuoteFields
    }
  }
`;

const GetClientBookkeepingQuotes = gql`
  ${BaseBookkeepingQuoteFields}
  query GetClientBookkeepingQuotes($clientId: ID!) {
    getClientBookkeepingQuotes(clientId: $clientId) {
      ...BaseBookkeepingQuoteFields
    }
  }
`;

const GetPartnerBookkeepingQuotes = gql`
  ${BaseBookkeepingQuoteFields}
  query GetPartnerBookkeepingQuotes {
    getPartnerBookkeepingQuotes {
      ...BaseBookkeepingQuoteFields
    }
  }
`;

const GetBookkeepingQuote = gql`
  ${BaseBookkeepingQuoteFields}
  query GetBookkeepingQuote($bookkeepingId: ID!) {
    getBookkeepingQuote(bookkeepingId: $bookkeepingId) {
      ...BaseBookkeepingQuoteFields
    }
  }
`;

const ApplyForCreditMatch = gql`
  ${BaseClientFields}
  mutation ApplyForCreditMatch($clientId: ID!, $creditMatchFileKey: String!) {
    applyForCreditMatch(
      clientId: $clientId
      creditMatchFileKey: $creditMatchFileKey
    ) {
      ...BaseClientFields
    }
  }
`;

const GenerateBookkeepingInvoice = gql`
  mutation GenerateBookkeepingInvoice(
    $bookkeepingQuoteId: ID!
    $upfrontAmountCents: Float!
    $monthlyAmountCents: Float!
  ) {
    generateBookkeepingInvoice(
      bookkeepingQuoteId: $bookkeepingQuoteId
      upfrontAmountCents: $upfrontAmountCents
      monthlyAmountCents: $monthlyAmountCents
    )
  }
`;

const GeneratePaymentLink = gql`
  mutation GetPaymentLink(
    $clientId: ID!
    $amountCents: Int!
    $maxTransactionsCeiling: Int
  ) {
    getPaymentLink(
      clientId: $clientId
      amountCents: $amountCents
      maxTransactionsCeiling: $maxTransactionsCeiling
    )
  }
`;

const PayWithCryptoLink = gql`
  mutation PayWithCryptoLink($clientId: ID!, $maxTransactionsCeiling: Int!) {
    payWithCryptoLink(
      clientId: $clientId
      maxTransactionsCeiling: $maxTransactionsCeiling
    )
  }
`;

const GetClientBillingPortal = gql`
  query getClientBillingPortal($clientId: ID!) {
    getClientBillingPortal(clientId: $clientId)
  }
`;

const GetTransactions = gql`
  ${BaseFullTransactionFieldsV2}
  query GetTransactions($clientId: ID!, $transactionIds: [ID!]!) {
    getTransactions(clientId: $clientId, transactionIds: $transactionIds) {
      ...BaseFullTransactionFieldsV2
      options {
        labels {
          label
          value
          infoMessage
          category
          ruleType
          applicable
          applicableCautious
        }
        message
      }
    }
  }
`;

const SummarizeTransaction = gql`
  mutation SummarizeTransaction($transactionId: ID!) {
    summarizeTransaction(transactionId: $transactionId) {
      summary
      label
      description
      analysis
    }
  }
`;

const GetTokenInfo = gql`
  query GetTokenInfo($assetKey: String!) {
    getTokenInfo(assetKey: $assetKey) {
      about {
        description
        address
        links {
          coingecko
          website
          telegram
          twitter
          discord
          medium
        }
      }
      stats {
        marketCap
        v24hUSD
      }
      security {
        creationTime
        mintTime
      }
    }
  }
`;

const GetSupportedProviders = gql`
  query GetSupportedProviders($clientId: ID!) {
    getSupportedProviders(clientId: $clientId) {
      name
      iconImageUrl
    }
  }
`;

const GetTaxLots = gql`
  query GetTaxLots($clientId: ID!, $assetPricingKey: String!) {
    getTaxLots(clientId: $clientId, assetPricingKey: $assetPricingKey) {
      lots {
        id
        algorithm
        amount
        fiatAmountFormatted
        avgPricePerTokenFormatted
        fiatAmountCents
        position
        purchasedAt
        clientId
        transactionId
        assetId
        accountId
        account {
          id
          name
          description
          provider
          iconImageUrl
        }
        createdAt
        updatedAt
      }
    }
  }
`;

export const api = {
  bookkeepingQuotes: {
    retrieve: GetBookkeepingQuote,
    request: RequestBookkeepingQuote,
    update: UpdateBookkeepingQuote,
    listForClient: GetClientBookkeepingQuotes,
    listForPartner: GetPartnerBookkeepingQuotes,
  },
  users: {
    sendLogin: SendLoginEmail,
    create: CreateUser,
    verifyRecaptcha: VerifyRecaptcha,
    delete: DeleteUser,
    deleteMe: DeleteMe,
    update: UpdateUser,
    unenrollTwoFactor: UnenrollTwoFactor,
    createWithEmail: CreateUserWithEmail,
    me: GetMe,
    paymentMethods: GetPaymentMethods,
    clients: {
      getActive: GetMyActiveClient,
      setActive: SetMyActiveClient,
      discounts: {
        gme: {
          check: CheckGMEDiscount,
          apply: ApplyGMEDiscount,
        },
        magicEden: {
          check: CheckMagicEdenDiscount,
          apply: ApplyMagicEdenDiscount,
        },
      },
    },
  },
  pricing: {
    getPriceForDate: GetPriceForDate,
  },
  assets: {
    unsetSpam: UnsetSpam,
    bulkMarkSpam: BulkMarkSpam,
    checkForSpamReview: CheckForSpamReview,
    spam: GetClientSpamAssets,
    upsertNFT: UpsertNftAsset,
    searchTracked: SearchTrackedAssets,
    create: CreateAsset,
    createV2: CreateAssetV2,
    update: UpdateAsset,
    markWorthless: MarkAssetKeyWorthless,
    estimatedCostBasis: GetEstimatedCostBasis,
    syncMetadata: SyncAssetMetadata,
    reportUnpricedAsset: ReportUnpricedAsset,
    addPricedAsset: AddPricedAsset,
    removePricedAsset: RemovePricedAsset,
    getAssetKeyInfo: GetAssetKeyInfo,
    setAssetSpam: SetAssetSpam,
    getAssetWarnings: GetAssetWarnings,
    getChart: GetAssetKeyChart,
    getChartV2: GetAssetKeyChartV2,
    getCurrentPrice: GetCurrentAssetPrice,
    getHoldingsChart: GetCurrentHoldingsChart,
    getCurrentPriceV2: GetCurrentAssetPriceV2,
  },
  contact: {
    portfolioWaitlist: SubmitPortfolioWaitlistEntry,
    feedback: SubmitFeedback,
  },
  clients: {
    billingPortal: GetClientBillingPortal,
    applyForCredit: ApplyForCreditMatch,
    giveCredit: GiveCredit,
    adjustSubscriptionTier: AdjustSubscriptionTier,
    create: CreateClient,
    update: UpdateClient,
    updateOwner: UpdateClientUser,
    updateClientCostBasisAlgorithm: UpdateClientCostBasisAlgorithm,
    retrieve: GetClientById,
    list: GetMyClients,
    lock: ToggleLockClient,
    accounts: GetClientAccounts,
    transactions: GetClientTransactions,
    fetchForTransactionIds: GetTransactions,
    ledgerAccounts: GetClientLedgerAccounts,
    createLedgerAccount: CreateClientLedgerAccount,
    searchLedgerAccounts: SearchClientLedgerAccounts,
    __assets: GetClientAssets,
    assetOptions: GetClientAssetOptions,
    delete: DeleteClient,
    graph: async (clientId: string) => {
      const response = await client.get(`/v1/clients/${clientId}/graph`);
      return response.data;
    },
    // packs: PacksForClient,
    getPaidOverview: GetPaidTransactionOverview,
    cbid: {
      update: UpdateCoinbaseID,
      confirm: ConfirmCoinbaseID,
    },
  },
  clientMembers: {
    list: GetClientMembers,
    create: AddClientMember,
    remove: RemoveClientMember,
  },
  ledgerAccounts: {
    subAccounts: GetLedgerAccountSubAccounts,
    retrieve: GetLedgerAccountWithSubAccounts,
  },
  accounts: {
    createBatch: CreateBatchWallets,
    create: CreateAccount,
    createGroup: CreateAccountGroup,
    deleteGroup: DeleteAccountGroup,
    sync: SyncAccount,
    updateOutOfDate: UpdateAccounts,
    downloadFile: DownloadAccountFile,
    hardRefresh: HardRefreshAccount,
    cancelImport: CancelAccountImport,
    me: MyAccounts,
    syncAll: SyncAllClientAccounts,
    rename: RenameAccount,
    delete: DeleteAccount,
    getHatchfiLink: GetHatchfiLink,
    getVezgoLink: GetVezgoLink,
    syncHatchfi: SyncHatchfi,
    getPlaidLinkToken: GetPlaidLinkToken,
    getOAuthLink: GetOAuthLink,
    getOAuthToken: GetOAuthToken,
    update: UpdateAccount,
  },
  reports: {
    export: GetReportExport,
    transactionHistory: GetTransactionHistoryReport,
    transactionHistoryV2: GetTransactionHistoryReportV2,
    monthlyPoints: GetMonthlyPoints,
    monthlySummary: GetMonthlySummary,
    getPnL: GetPnL,
    getTaxYears: GetTaxYears,
    getTaxYearBrackets: GetTaxYearBrackets,
    getTaxesByAsset: GetTaxesByAsset,
    checkForErrors: CheckForErrors,
    getIncomeAndCapGains: GetIncomeAndCapGains,
    getReports: GetReports,
    getReportDownloadUrl: GetReportDownloadUrl,
  },
  ledgerEntries: {
    update: UpdateLedgerEntry,
    create: CreateLedgerEntry,
    delete: DeleteLedgerEntry,
    links: GetEntryLinks,
    getPossibleLinks: GetPossibleEntryLinks,
  },
  transactions: {
    transfers: {
      unhideTransfers: UnhideTransfers,
    },
    summarize: SummarizeTransaction,
    retrieve: GetTransaction,
    getPage: GetTransactionPage,
    redirectByHash: RedirectByHash,
    update: UpdateTransaction,
    hide: HideTransaction,
    updateTransfer: UpdateTransfer,
    createTransfer: CreateTransfer,
    updateFee: UpdateFee,
    create: CreateTransaction,
    sync: SyncTransaction,
    ledgerEntries: GetTransactionLedgerEntries,
    countTransactions: CountTransactions,
    getNumTxnTypes: GetNumTxnTypes,
    typeOptions: GetTransactionTypeOptions,
    availableLabelsForTransactions: GetLabelOptionsForTransactions,
    labelMany: LabelManyTransactions,
    linksForTransfer: GetEntryLinksForTransfer,
    uploadManual: UploadManualTransactions,
    countDirty: CountDirty,
    countNotDone: CountNotDone,
  },
  rules: {
    create: CreateRule,
    list: GetRules,
    updateLabel: UpdateRuleLabel,
    checkRules: CheckRules,
    checkRuleUsed: CheckRuleUsed,
    removeRule: RemoveRule,
    getOtherUserLabels: GetOtherUserLabels,
    getLabels: GetLabels,
  },
  defaultRules: {
    getNextDefaultRules: GetNextDefaultRules,
    createDefaultRule: CreateDefaultRule,
    ruleToDefaultRule: RuleToDefaultRule,
  },
  portfolio: {
    taxLots: GetTaxLots,
    supportedProviders: GetSupportedProviders,
    getTokenInfo: GetTokenInfo,
    get: GetPortfolio,
    requestBeta: SubmitPortfolioBeta,
    getPortfolioAssets: GetPortfolioAssets, // doesn't include the portfolio accounts
    getChart: GetChart,
    getPositions: GetPositions,
    getPortfolioValue: GetPortfolioValue,
    submitFeedback: SubmitPortfolioFeedback,
    getPortfolioV2CoinsAndDefi: GetPortfolioV2CoinsAndDefi,
    getPortfolioV2NFTs: GetPortfolioV2NFTs,
    refreshBalances: RefreshPortfolioBalances,
    getAssetPositions: GetAssetPositions,
  },
  jobs: {
    // transactionImport: GetTransactionImportJobs,
    list: GetJobs,
    // rerunGraph: GetRerunGraphJob,
  },
  misc: {
    filterForm: CreateFilterFormSubmission,
    getNumCPAs: GetNumCPAs,
    getFilteredCPAs: GetFilteredCPAs,
    createFilterFormCPA: CreateFilterFormCPA,
    getPotentialSpamAssets: GetPotentialSpamAssets,
    createSpamAsset: CreatePotentialSpamAsset,
    sendSlackNotification: SendSlackNotification,
  },
  graph: {
    rerun: RerunGraph,
    getReplayInfo: GetReplayOverview,
  },
  checkouts: {
    start: StartCheckout,
    startSubscription: StartSubscriptionCheckout,
    discounts: GetEligibleDiscountsForTaxYear,
    startUpgrade: StartUpgradeCheckout,
    generateBookkeepingInvoice: GenerateBookkeepingInvoice,
    generatePaymentLink: GeneratePaymentLink,
    payWithCryptoLink: PayWithCryptoLink,
  },
  subscriptions: {
    active: GetMyActiveSubscription,
    create: CreateSubscription,
    free: FreeSubscription,
    delete: DeleteSubscription,
    list: GetSubscriptions,
  },
  uploadFile: UploadFile,
  referrals: {
    claimCode: ClaimCode,
    claimWeb3Reward: ClaimWeb3Reward,
    redeemReferralPrize: RedeemReferralPrize,
    getReferralPrizes: GetReferralPrizes,
    getWeb3Rewards: GetWeb3Rewards,
    getReferralCommissions: GetReferralCommissions,
    getReferralPayouts: GetReferralPayouts,
    requestPayout: RequestPayout,
  },
  summaries: {
    list: GetRecalculateSummaries,
    diff: GetDiffV1,
  },
};
