import { useMutation } from "@apollo/client";
import { Box, Flex, Heading, HStack, Text, VStack } from "@chakra-ui/react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { show } from "redux-modal";
import { api } from "src/api";
import { MutationSubmitFeedbackArgs } from "src/api/generated/types";
import { Button } from "src/components";
import { useMyToast } from "src/hooks";
import { useTheme } from "src/hooks/useTheme";
import { colors } from "src/theme";

function Opportunities() {
  const theme = useTheme();
  const { clientId } = useParams();
  const dispatch = useDispatch();

  const toast = useMyToast();
  const [submitFeedback] = useMutation(api.contact.feedback);

  const _submitFeedback = async () => {
    const variables: MutationSubmitFeedbackArgs = {
      feedback: "I'm interested in crypto news.",
    };

    await submitFeedback({
      variables,
    });

    toast.show({
      message: "Joined waitlist! Thanks for your interest 🙏",
      status: "success",
    });
  };

  return (
    <Box
      maxWidth={"inherit"}
      bg={theme.background}
      // style={{
      //   padding: "1rem 2.5rem",
      // }}
    >
      <Box w="100%" paddingTop="1rem">
        {/* <FreeCreditBadge /> */}

        <HStack alignItems="center" padding="0.5rem 0">
          <Flex flex={1} alignItems="center" display="flex" flexDir="row">
            <Heading color={theme.header} margin="0" size="lg">
              Opportunities
            </Heading>
          </Flex>
        </HStack>

        <div style={{ marginTop: 10 }}>
          <Text
            style={{
              color: theme.text,
              maxWidth: 600,
              fontWeight: "normal",
              marginBottom: 15,
            }}
          >
            Ways for you to save money on taxes (or get Awaken for free).
          </Text>
          <br />
          <ListItem
            header="Community Codes"
            onClick={() => {
              dispatch(show("CommunityCodeModal"));
            }}
            backgroundColor={colors.purple50}
            subtitle={
              <>
                Get Awaken for <b>FREE</b> by making a code for your community
                and sharing it with them.
              </>
            }
            iconName="fa-sharp fa-users"
            buttonText="Apply for code"
          />
          <ListItem
            header="Tax Loss Harvest Dead NFTs (EVM)"
            backgroundColor={"#627EEA"}
            subtitle="Turn your worthless NFTs into tax write-offs."
            secondSubtitle="Supports Ethereum, Polygon, Arbitrum, Optimism, Base, Blast, and Avalanche"
            iconName="fa-sharp fa-piggy-bank"
            onClick={() => window.open("https://harvest.art", "_blank")}
          />
          <ListItem
            header="Tax Loss Harvest Dead NFTs (Solana)"
            backgroundColor={"#14F195"}
            subtitle="Turn your worthless NFTs into tax write-offs."
            secondSubtitle="Supports Solana"
            // iconName="fa-sharp fa-piggy-bank"
            customIcon={
              <img
                src={require("src/assets/awaken/icons/sol-black.png")}
                width={50}
                height={50}
              />
            }
            onClick={() => window.open("https://sol-incinerator.com", "_blank")}
          />
        </div>
      </Box>
    </Box>
  );
}

export const ListItem = ({
  header,
  subtitle,
  iconName,
  customIcon,
  backgroundColor = colors.gray80,
  secondSubtitle,
  onClick,
  buttonText: _buttonText,
}: {
  subtitle: string | JSX.Element;
  secondSubtitle?: string;
  header: string;
  iconName?: string;
  backgroundColor?: string;
  customIcon?: React.ReactNode;
  buttonText?: string;
  onClick?: () => void;
}) => {
  const theme = useTheme();

  const isHarvestNFTs = header.toLowerCase().includes("harvest");
  const buttonText =
    _buttonText || (isHarvestNFTs ? "Go to website" : "Learn more");

  return (
    <HStack
      padding="1rem"
      marginBottom="1.5rem"
      borderRadius="lg"
      border="1px solid"
      borderColor={theme.border}
      width="100%"
      spacing={4}
      _hover={{
        borderColor: theme.border,
        boxShadow: "sm",
      }}
      transition="all 0.2s"
    >
      <div
        style={{
          width: 45,
          height: 45,
          backgroundColor: backgroundColor,
          borderRadius: 50,
          flexShrink: 0,
          display: "flex",
          fontSize: 22,
          flexDirection: "row",
          justifyContent: "center",
          alignItems: "center",
          color: colors.white,
        }}
      >
        {customIcon || <i className={iconName || ""} />}
      </div>

      <VStack flex={1} alignItems="flex-start" spacing={1}>
        <Text color={theme.header} fontSize="lg" fontWeight="600">
          {header}
        </Text>
        <Text color={theme.text} fontSize="md">
          {subtitle}
        </Text>
        {secondSubtitle && (
          <Text color={theme.text} fontSize="xs">
            * {secondSubtitle}
          </Text>
        )}
      </VStack>

      <Button
        variant="outline"
        size="sm"
        onClick={onClick}
        color={theme.text} // Add this
        borderColor={theme.border} // Add this
        _hover={{
          // Add this
          backgroundColor: theme.medBackground,
        }}
        rightIcon={<i className="fa-sharp fa-arrow-right" />}
      >
        {buttonText}
      </Button>
    </HStack>
  );
};

export default Opportunities;
