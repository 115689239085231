import { Box, FormLabel, Text } from "@chakra-ui/react";
import { isNil } from "lodash";
import { CSSProperties } from "react";
import { Control, Controller, useForm } from "react-hook-form";
import { Maybe } from "src/core";
import { Info } from "./Info";

import ReactDatePicker from "react-datepicker";

import { noop } from "lodash/fp";
import "react-datepicker/dist/react-datepicker.css";
import { useTheme } from "src/hooks/useTheme";
import "./css/datepicker.css";

type MyReactDatePickerProps = {
  label?: string;
  labelIconName?: string;
  name?: string;
  hidden?: boolean;
  infoMessage?: string;
  visible?: boolean;
  control?: Control<any, any>;
  containerStyle?: CSSProperties;
  width?: string;
  value?: Maybe<Date | string>;
  onChange?: (date: Maybe<Date>) => void;
  isRequired?: boolean;
  placeholder?: string;
  dateFormat?: string;
  labelStyle?: CSSProperties;
  showTimeSelect?: boolean;
  dateInputClassName?: string;
  timeIntervals?: number;
  showTimeSelectOnly?: boolean;
  subtitle?: string;
};

export const DatePicker = (props: MyReactDatePickerProps) => {
  const { setValue } = useForm();
  const theme = useTheme();
  const className = theme.theme === "dark" ? "datepicker-darkmode" : undefined;

  if (props.hidden) {
    return null;
  }

  if (!isNil(props.visible) && !props.visible) {
    return null;
  }

  return (
    <Box style={{ marginBottom: "1rem", ...props.containerStyle }}>
      {props.label && (
        <>
          <FormLabel
            color={theme.header}
            fontSize="sm"
            style={props.labelStyle}
          >
            {props.labelIconName && (
              <i style={{ marginRight: 5 }} className={props.labelIconName} />
            )}
            {props.label}{" "}
            {props.isRequired && (
              <Text display="inline" color="red.500">
                *
              </Text>
            )}
            {props.infoMessage && <Info message={props.infoMessage} />}
          </FormLabel>
          {props.subtitle && (
            <Text fontSize="xs" color={theme.text} mb={2}>
              {props.subtitle}
            </Text>
          )}
        </>
      )}

      {props.control && props.name ? (
        <Controller
          control={props.control}
          name={props.name}
          render={({ field: { value, onChange, onBlur, ref } }) => {
            // console.log(
            //   new Date(value),
            //   DateTime.fromJSDate(new Date(value)).toUTC().toJSDate()
            // );
            return (
              <ReactDatePicker
                selected={value ? new Date(value) : null}
                onChange={onChange}
                showTimeSelect={props.showTimeSelect ?? true}
                dateFormat={props.dateFormat ?? "P"}
                placeholderText={props.placeholder}
                className={props.dateInputClassName ?? className}
                timeIntervals={props.timeIntervals}
                timeFormat="HH:mm"
                showTimeSelectOnly={props.showTimeSelectOnly}
              />
            );
          }}
        />
      ) : (
        <ReactDatePicker
          selected={props.value ? new Date(props.value) : null}
          onChange={props.onChange || noop}
          showTimeSelect={props.showTimeSelect ?? true}
          dateFormat={props.dateFormat ?? "Pp"}
          placeholderText={props.placeholder}
          className={props.dateInputClassName ?? className}
          timeIntervals={props.timeIntervals}
          timeFormat="HH:mm"
          showTimeSelectOnly={props.showTimeSelectOnly}
        />
      )}
    </Box>
  );
};
