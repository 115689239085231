import { truncate } from "lodash";
import { FC, useState } from "react";
import { useTheme } from "src/hooks/useTheme";
import { colors } from "src/theme";
import { DropFile, DropFileParams } from "./DropFile";
import { FileTab, FileTabInfo } from "./FileTab";

type GenerateCsvFlowProps = any;

export const GenerateCsvFlow: FC<GenerateCsvFlowProps> = () => {
  const [filesInfo, setFilesInfo] = useState<FileTabInfo[]>([]);
  const [activeFileTab, setActiveFileTab] = useState<FileTabInfo | null>(null);

  const theme = useTheme();

  const onDropFile = async (files: DropFileParams[]) => {
    const oldFiles = [...filesInfo];
    const newFiles = files.map(
      (file): FileTabInfo => ({
        id: file.acceptedFile.name + Math.random(),
        file: file.acceptedFile,
        headers: file.headers,
        cleanedCsvData: file.cleanedCsvData,
        rawRows: file.rawRows,
        cleanedRows: file.cleanedRows,
        gptDescription: "",
        description: "",
        columns: "",
      })
    );

    const allFiles = [...oldFiles, ...newFiles];

    setFilesInfo(allFiles);
    setActiveFileTab(newFiles[0]);
  };

  const onRemoveFile = (fileId: string) => {
    const newFiles = filesInfo.filter((file) => file.id !== fileId);

    setFilesInfo(newFiles);
    setActiveFileTab(newFiles[0]);
  };

  return (
    <>
      <div
        style={{ paddingBottom: 200 }}
        className="mx-auto flex w-full max-w-[1200px] flex-col items-start pt-12"
      >
        <div className="flex flex-row">
          <div
            style={{
              color: theme.text,
            }}
            className="mr-4 w-full text-left text-lg font-normal"
          >
            Save time formatting your cryptocurrency spreadsheets using AI 🤖.
            We may not be able to generate a CSV. If you are unable to get a
            CSV, you will need to manually format it using{" "}
            <a
              style={{ fontWeight: "bold" }}
              className="text-blue-500 text-md underline"
              href="https://help.awaken.tax/en/articles/10422149-how-to-format-your-csv-for-awaken-tax"
            >
              this guide
            </a>
            .
          </div>
        </div>

        <br />

        {/* <div style={{ marginTop: "0.5rem" }}>
          <div className="w-full text-left text-md font-helvetica font-normal">
            Having trouble? Just send an email to{" "}
            <a
              style={{ fontWeight: "bold" }}
              className="text-blue-500 text-md underline"
              href="mailto:andrew@awaken.tax?subject=CSV GPT Help"
            >
              andrew@awaken.tax
            </a>{" "}
            and we will help you out.
          </div>
        </div> */}

        <div
          style={{
            color: theme.header,
          }}
          className="w-full text-left text-lg font-semibold"
        >
          Drop up to five cryptocurrency CSVs below 👇
        </div>

        <br />

        <DropFile
          onRemoveFile={onRemoveFile}
          files={filesInfo}
          onDrop={onDropFile}
        />

        {filesInfo.length > 0 && (
          <div
            style={{ marginTop: "2rem", color: theme.text }}
            className="w-full text-left text-sm font-semibold"
          >
            Your CSVs:
          </div>
        )}

        <div
          style={{
            marginTop: "1rem",
            overflowX: "scroll",
            whiteSpace: "nowrap",
            display: "flex",
            width: "100%",
          }}
        >
          {filesInfo.map((info, index) => {
            const isActive = info.id === activeFileTab?.id;

            return (
              <div
                onClick={() => setActiveFileTab(info)}
                className={`px-4 mr-2 py-2 text-sm rounded-md cursor-pointer ${
                  isActive ? " text-black font-medium" : "font-medium"
                }`}
                style={{
                  color: isActive ? colors.white : theme.text,
                  backgroundColor: isActive
                    ? colors.primary
                    : theme.secondaryBackground,
                }}
                key={index}
              >
                {truncate(info.file?.name || "", { length: 40 })}
              </div>
            );
          })}
        </div>

        {filesInfo.map((info) => {
          const isActive = info.id === activeFileTab?.id;
          // console.log(info);
          return (
            <div
              key={info.id}
              style={{ width: "100%", display: isActive ? "inherit" : "none" }}
            >
              <FileTab fileInfo={info} />
            </div>
          );
        })}
      </div>
    </>
  );
};
