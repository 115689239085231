import Intercom from "@intercom/messenger-js-sdk";
import { onAuthStateChanged, signInWithCustomToken } from "firebase/auth";
import { compose } from "lodash/fp";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useSearchParams } from "react-router-dom";
import { BaseUserFields } from "src/api/fragments";
import { useMe } from "src/hooks";
import { setUserAuthStateChanged } from "src/redux/reducers/user";
import { identify } from "src/utils/analytics";
import { auth } from "src/utils/firebase";

export const useAuth = () => {
  const dispatch = useDispatch();
  const [search] = useSearchParams();
  const _setAuthStatus = compose(dispatch, setUserAuthStateChanged);
  const { me } = useMe();

  useEffect(() => {
    _setAuthStatus("NOT_LOADED");

    (async () => {
      const token = search.get("authToken");

      if (token) {
        await signInWithCustomToken(auth, token);
        _setAuthStatus("LOGGED_IN");
      }

      onAuthStateChanged(auth, async (user) => {
        if (user) {
          _setAuthStatus("LOGGED_IN");
        } else {
          _setAuthStatus("NOT_LOGGED_IN");
        }
      });
    })();
  }, []);

  const _initIntercom = (me: BaseUserFields) => {
    if (!me) {
      return;
    }

    Intercom({
      app_id: "y6qulf73",
      user_id: me.id, // IMPORTANT: Replace "user.id" with the variable you use to capture the user's ID
      name: me.name || "[no name]", // IMPORTANT: Replace "user.name" with the variable you use to capture the user's name
      email: me.email || "[no email]", // IMPORTANT: Replace "user.email" with the variable you use to capture the user's email
      created_at: _getCreatedAt(me),
      user_hash: me?.intercomUserHash || undefined,
      client_id: me?.activeClient?.id || undefined,
      active_client_url: `https://awaken.tax/clients/${me?.activeClient?.id}`,
    });
  };

  useEffect(() => {
    if (me) {
      // don't pass traits. we do that backend with the me() query
      identify(me);

      _initIntercom(me);
      // LogRocket.identify(me.id, {
      //   name: me.name || "",
      //   email: me.email,
      //   id: me.id || "",
      // });
    }
  }, [JSON.stringify(me)]);
};

const _getCreatedAt = (me: BaseUserFields) => {
  try {
    return Math.floor(new Date(me.createdAt)?.getTime() / 1_000);
  } catch (e) {
    return undefined;
  }
};
