import { PartialAssetFields } from "src/api/fragments";
import { Maybe } from "src/core";
import { generateOptionSchema, Option } from "src/utils/schemas";
import * as yup from "yup";

export type FormValues = {
  search: Maybe<string>;
  accounts: Maybe<Option[]>;
  labels: Maybe<Option[]>;
  providers: Maybe<Option[]>;
  startDate: Maybe<string>;
  endDate: Maybe<string>;
  assets: Maybe<
    (Option & {
      isUnnamedAsset: boolean;
      assets: PartialAssetFields[];
    })[]
  >;
  assetType: Maybe<Option>;
  isMissingBasis?: Maybe<boolean>;
  isNegativeBalance?: Maybe<boolean>;
  hasIncome?: Maybe<boolean>;
  hasNotes?: Maybe<boolean>;
  reviewed: Maybe<Option>;
};

export const getDefaultValues = (
  defaults?: Partial<FormValues>
): FormValues => ({
  search: defaults?.search || null,
  providers: defaults?.providers || null,
  accounts: defaults?.accounts || null,
  labels: defaults?.labels || null,
  startDate: defaults?.startDate || null,
  endDate: defaults?.endDate || null,
  assets: defaults?.assets || null,
  assetType: defaults?.assetType || null,
  isMissingBasis: defaults?.isMissingBasis ?? null,
  isNegativeBalance: defaults?.isNegativeBalance ?? null,
  hasIncome: defaults?.hasIncome ?? null,
  hasNotes: defaults?.hasNotes ?? null,
  reviewed: defaults?.reviewed || null,
});

export const schema = yup.object().shape({
  search: yup.string().label("Name").nullable(),
  providers: yup.array().of(generateOptionSchema(true)).nullable(),
  startDate: yup.date().label("Start date").nullable(),
  endDate: yup.date().label("End date").nullable(),
  isMissingBasis: yup.boolean().label("Missing basis").nullable(),
  isNegativeBalance: yup.boolean().label("Negative balance").nullable(),
  hasIncome: yup.boolean().label("Has income").nullable(),
  hasNotes: yup.boolean().label("Has notes").nullable(),
  labels: yup.array().of(yup.mixed()).nullable(),
  assets: yup.array().of(yup.mixed()).nullable(),
  accounts: yup.array().of(generateOptionSchema(true)).nullable(),
  reviewed: generateOptionSchema().nullable(),
  assetType: generateOptionSchema().nullable(),
});
