import {
  Box,
  Center,
  Divider,
  HStack,
  Image,
  Text,
  VStack,
} from "@chakra-ui/react";
import { useCallback } from "react";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import Logo from "src/assets/awaken/logos/logo-name.png";
import WhiteLogo from "src/assets/awaken/logos/white-logo.png";
import { NoNavBarPageTemplate } from "src/components/layouts/PageTemplate";

import { useLazyQuery, useMutation } from "@apollo/client";
import { yupResolver } from "@hookform/resolvers/yup";
import { signInWithCustomToken } from "firebase/auth";
import { isNil } from "lodash/fp";
import { useForm } from "react-hook-form";
import { api } from "src/api";
import { BaseUserFields } from "src/api/fragments";
import { MutationCreateUserWithEmailArgs } from "src/api/generated/types";
import { Button, Input } from "src/components";
import SecondaryText from "src/components/styled/SecondaryText";
import {
  DefaultErrors,
  failure,
  FailureOrSuccess,
  success,
  UnexpectedError,
} from "src/core";
import { useMyToast } from "src/hooks";
import { useReferralCode } from "src/hooks/useReferralCode";
import { useTheme } from "src/hooks/useTheme";
import { colors, other } from "src/theme";
import { auth } from "src/utils/firebase";
import { getMetadataInfo, isWhiteLabeledDomain } from "src/utils/whitelabel";
import * as yup from "yup";
import { AppleButton } from "../components/Apple";
import { GoogleButton } from "../components/Google";
import { getRedirectPath } from "../utils";

const schema = yup.object().shape({
  fullName: yup.string().required("Full name is required.").nullable(),
  email: yup.string().email().required("Email is required.").nullable(),
  password: yup.string().min(6).required("Password is required.").nullable(),
  referralCode: yup.string().nullable(),
});

type FormValues = {
  email: string;
  password: string;
  fullName: string;
  referralCode: string;
};

const DEFAULT_VALUES: FormValues = {
  email: "",
  password: "",
  fullName: "",
  referralCode: "",
};

export function Signup() {
  const [search] = useSearchParams();
  const { ref, setRefCodeLocalStorage } = useReferralCode();
  const toast = useMyToast();
  const navigate = useNavigate();

  const email = search.get("email") || null;
  const fullName = search.get("fullName") || null;

  // API hooks
  const [createUser] = useMutation<{
    createUserWithEmail?: { user: BaseUserFields; customToken: string };
  }>(api.users.createWithEmail);
  const [getMe] = useLazyQuery<{ me?: BaseUserFields }>(api.users.me, {
    fetchPolicy: "no-cache",
  });

  // Form hooks
  const {
    control,
    handleSubmit,
    formState: { isSubmitting },
  } = useForm<FormValues>({
    resolver: yupResolver(schema),
    defaultValues: {
      ...DEFAULT_VALUES,
      email: email || "",
      fullName: fullName || "",
      referralCode: ref || "",
    },
  });

  const _logAuthError = ({ message }: { message: string }) => {
    toast.show({ message, status: "error" });
  };

  const _navigateForUser = (
    user?: BaseUserFields,
    partialPath = "accounts"
  ) => {
    setRefCodeLocalStorage(null);

    const path = getRedirectPath(user, partialPath);
    // if the user has a valid referred by code -> we want to redirect them to a welcome page
    if (
      !isNil(user?.referredCreditCents) &&
      (user?.referredCreditCents || 0) > 0
    ) {
      const amountFreeCreditCents = user?.referredCreditCents ?? 0;

      navigate(
        `/welcome?redirect=${path}&referralCode=${
          user?.referredByCode || ""
        }&amountFreeCreditCents=${amountFreeCreditCents}`
      );
      return;
    }

    return navigate(`/marketing?redirect=${path}`);
  };

  // Functions
  const _createUser = useCallback(
    async (
      values: FormValues
    ): Promise<FailureOrSuccess<DefaultErrors, BaseUserFields>> => {
      try {
        const referredDomainUrl = window.location.hostname;

        const params: MutationCreateUserWithEmailArgs = {
          email: values.email,
          name: values.fullName,
          phoneNumber: null,
          password: values.password,
          referralCode: values.referralCode,
          referredDomainUrl,
        };

        const userResponse = await createUser({
          variables: params,
        });

        if (!userResponse.data?.createUserWithEmail) {
          return failure(new Error("No user returned."));
        }

        const { customToken, user } = userResponse.data.createUserWithEmail;

        await signInWithCustomToken(auth, customToken);

        return success(user);
      } catch (err) {
        return failure(new UnexpectedError(err));
      }
    },
    [createUser, ref]
  );

  const onSubmit = useCallback(
    async (values: FormValues) => {
      const currentUser = auth.currentUser;

      // if there is already a user locally and they have the same email,
      // check to see if they already exist in our db. and if not create the user otherwise can return
      if (currentUser && currentUser.email === values.email) {
        const res = await getMe();

        // if the user is trying to create an account for an email they are already signed in as,
        // just move them to the dashboard because they are good
        if (res.data?.me) {
          return _navigateForUser(res.data.me, "dashboard");
        }

        // if there is already a user for this email and they are already logged in with the firebase
        // user we just need to create their user in our system and send them to the dashboard
        // Note: this is safe because the authentication header will be set with a jwt that verified legitimacy
        // of the firebase account
        if (res.error || !res.data?.me) {
          const userResponse = await _createUser(values);

          if (userResponse.isSuccess()) {
            return _navigateForUser(userResponse.value);
          }
        }
      }

      const userResponse = await _createUser(values);

      if (userResponse.isFailure()) {
        return toast.show({
          status: "error",
          message: userResponse.error.message,
        });
      }

      return _navigateForUser(userResponse.value);
    },
    [_createUser]
  );

  const isWhiteLabel = isWhiteLabeledDomain();
  const metadata = getMetadataInfo();
  const theme = useTheme();

  return (
    <NoNavBarPageTemplate>
      <VStack
        padding="2rem 0"
        flexDir="column"
        minH="100vh"
        display="flex"
        justifyContent="center"
      >
        {/* <FreeTransactions refCode={ref} /> */}
        <Box
          display="flex"
          flexDir="column"
          maxWidth="30rem"
          margin="auto"
          padding="2rem"
          w="100%"
          borderRadius={other.borderRadius}
          bg={theme.background}
          border={`1px solid ${theme.border}`}
        >
          <form
            onSubmit={handleSubmit(onSubmit, (e) =>
              toast.show({
                status: "error",
                message:
                  e.email?.message ||
                  e.fullName?.message ||
                  e.password?.message ||
                  e.referralCode?.message ||
                  "",
              })
            )}
          >
            {!isWhiteLabel && (
              <Link to="/">
                <Center>
                  <Image
                    src={theme.theme === "light" ? Logo : WhiteLogo}
                    cursor="pointer"
                    w="10rem"
                    paddingTop="1rem"
                    paddingBottom="2rem"
                  />
                </Center>
              </Link>
            )}

            {metadata?.logo && (
              <Link to="/">
                <Center>
                  <Image
                    src={metadata.logo}
                    cursor="pointer"
                    w="15rem"
                    paddingTop="1rem"
                    paddingBottom="2rem"
                  />
                </Center>
              </Link>
            )}
            <GoogleButton
              label="Sign up"
              onError={_logAuthError}
              onSuccess={(u, fb, isNew) =>
                _navigateForUser(u, isNew ? "accounts" : "dashboard")
              }
              referralCode={ref}
            />
            <br />
            <br />
            <AppleButton
              label="Sign up"
              onError={_logAuthError}
              onSuccess={(u, fb, isNew) =>
                _navigateForUser(u, isNew ? "accounts" : "dashboard")
              }
              referralCode={ref}
            />
            <HStack margin="1rem 0" marginTop="2rem">
              <Divider />
              <SecondaryText text="or" margin="0 1rem" />
              <Divider />
            </HStack>

            <Box width="100%">
              <Input
                label="Full Name"
                isRequired
                control={control}
                autoComplete="name"
                name="fullName"
              />
              <Input
                label="Email"
                isRequired
                autoComplete="email"
                control={control}
                name="email"
              />
              <Input
                label="Password"
                isRequired
                control={control}
                type="password"
                name="password"
              />

              {ref && (
                <Input
                  label="Your code"
                  control={control}
                  bg={colors.green100}
                  borderColor={colors.green50}
                  focusBorderColor={colors.green50}
                  infoMessage="This will automatically be applied when you signup."
                  name="referralCode"
                />
              )}

              {/* <Checkbox>
                <Text fontSize="xs">
                  I opt-in to receiving notification emails regarding my
                  account.
                </Text>
              </Checkbox> */}
            </Box>

            <Text textAlign="center" fontSize="xs" color={theme.text}>
              By signing up, you agree to our{" "}
              <Link
                style={{
                  color: colors.primary,
                  fontWeight: "bold",
                }}
                to="/legal/terms-of-service"
              >
                Terms of Service
              </Link>
              .
            </Text>

            <br />

            <Button
              isLoading={isSubmitting}
              width="100%"
              variant="primary"
              type="submit"
              padding="1rem 2rem"
            >
              Sign up
            </Button>

            <Divider margin="2rem auto" />

            <Text margin="auto" color={theme.text}>
              Already have an account?&nbsp;
              <Box display="inline-block" textDecor="underline" color="black">
                <Link to="/login">
                  <Text color={colors.primary}>Log in</Text>
                </Link>
              </Box>
            </Text>
          </form>
        </Box>
      </VStack>
    </NoNavBarPageTemplate>
  );
}

const FreeTransactions = ({ refCode }: { refCode: string }) => {
  const [search] = useSearchParams();

  if (!refCode || refCode === "undefined" || refCode === "null") {
    return null;
    // return (
    //   <Box
    //     w="100%"
    //     maxWidth="30rem"
    //     style={{
    //       color: colors.white,
    //       background: `linear-gradient(30deg, ${colors.green60} 0%, ${colors.green50} 50%, ${colors.green70} 100%)`,
    //       padding: "0.75rem 0.25rem",
    //       borderRadius: 5,
    //       fontWeight: "600",
    //       margin: "0.5rem auto",
    //       fontSize: 15,
    //       textAlign: "center",
    //     }}
    //   >
    //     <i
    //       className="fa-sharp fa-gift"
    //       style={{ marginRight: 5, color: colors.white }}
    //     />{" "}
    //     Sign up for Awaken now and get $25 credit!{" "}
    //     <Info
    //       style={{ color: colors.white }}
    //       message="Awaken is free if you have less than 500 transactions a year. If you have more, you'll get a free $25 of credit. Note: this credit may expire within 30 days, so make sure to use it."
    //     />
    //   </Box>
    // );
  }

  return (
    <Box
      w="100%"
      maxWidth="30rem"
      style={{
        color: colors.white,
        background: `linear-gradient(30deg, ${colors.green60} 0%, ${colors.green50} 50%, ${colors.green70} 100%)`,
        padding: "0.75rem 0.5rem",
        borderRadius: 5,
        fontWeight: "600",
        margin: "0.5rem auto",
        fontSize: 15,
        textAlign: "center",
      }}
    >
      You are unlocking <b>FREE</b> credit with code {refCode} 🔑
    </Box>
  );
};
