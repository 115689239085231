import { useMutation } from "@apollo/client";
import { Box, Button, Text, VStack } from "@chakra-ui/react";
import { motion } from "framer-motion";
import { useNavigate, useSearchParams } from "react-router-dom";
import { api } from "src/api";
import { NoNavBarPageTemplate } from "src/components/layouts/PageTemplate";
import { useMe } from "src/hooks";
import { useIsLargeScreen } from "src/hooks/useScreenSize";
import { useTheme } from "src/hooks/useTheme";
import { colors, other } from "src/theme";

export function Marketing() {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const theme = useTheme();

  const sources = [
    { name: "X.com", icon: "fa-brands fa-x-twitter" },
    { name: "From a friend", icon: "fa-solid fa-user-group" },
    { name: "Google", icon: "fa-brands fa-google" },
    // news sites (NYT, WSJ, etc)
    {
      name: "From an article (ex. NYT, Forbes, Coinmonks)",
      icon: "fa-solid fa-newspaper",
    },
    { name: "Milkroad newsletter", icon: "fa-solid fa-newspaper" },
    { name: "Discord group", icon: "fa-brands fa-discord" },
    { name: "Telegram group", icon: "fa-brands fa-telegram" },
    { name: "YouTube video", icon: "fa-brands fa-youtube" },
    { name: "Other", icon: "fa-solid fa-ellipsis" },
  ];

  const [updateMe] = useMutation(api.users.update);
  const { me } = useMe();

  const handleSourceClick = (source: string) => {
    // Here you can add analytics tracking if needed

    void updateMe({
      variables: {
        marketingSource: source,
      },
    });

    const hasReferralCode = searchParams.get("referralCode");
    const amountFreeCreditCents = searchParams.get("amountFreeCreditCents");
    const redirect = searchParams.get("redirect");

    const activeClientId = me?.activeClient?.id;

    if (!activeClientId) {
      return navigate(redirect || "/dashboard");
    }

    // navigate to add account onboarding
    navigate(`/onboarding/${activeClientId}/add-account`);
  };

  const handleSkip = () => {
    const hasReferralCode = searchParams.get("referralCode");
    const amountFreeCreditCents = searchParams.get("amountFreeCreditCents");
    const redirect = searchParams.get("redirect");

    const activeClientId = me?.activeClient?.id;

    if (!activeClientId) {
      return navigate(redirect || "/dashboard");
    }

    // navigate to add account onboarding
    navigate(`/onboarding/${activeClientId}/add-account`);
  };

  const isLarge = useIsLargeScreen();

  return (
    <NoNavBarPageTemplate>
      <VStack
        w="100%"
        padding="2rem 0"
        flexDir="column"
        display="flex"
        minH="100vh"
        justifyContent="center"
      >
        <Box>
          <Box
            display="flex"
            flexDir="column"
            flex={1}
            maxWidth="35rem"
            margin="auto"
            padding="2rem"
            w="100%"
            borderRadius={other.borderRadius}
            bg={theme.background}
            border={`1px solid ${theme.border}`}
          >
            <Box width="100%">
              <Text
                color={theme.header}
                fontSize="3xl"
                fontWeight="700"
                marginBottom="2rem"
                textAlign="left"
                style={{
                  fontStretch: "extra-expanded",
                }}
              >
                How did you find us?
              </Text>

              <VStack spacing={4} marginTop="15px" width="100%" mx="auto">
                {sources.map((source) => (
                  <motion.div
                    key={source.name}
                    whileHover={{ scale: 1.05 }}
                    whileTap={{ scale: 0.95 }}
                    style={{ width: "100%" }}
                  >
                    <Box
                      as="button"
                      onClick={() => handleSourceClick(source.name)}
                      p={4}
                      borderRadius="lg"
                      border={`1px solid ${theme.border}`}
                      _hover={{
                        bg: colors.primary,
                        color: colors.white,
                      }}
                      transition="all 0.2s"
                      w="100%"
                    >
                      {/* Replace VStack with HStack */}
                      <Box
                        display="flex"
                        alignItems="center"
                        justifyContent="center"
                        gap={3}
                      >
                        <i
                          className={source.icon}
                          style={{
                            fontSize: "1.25rem",
                            width: 25,
                          }}
                        />
                        <Text
                          flex={1}
                          textAlign={"left"}
                          marginLeft={5}
                          fontSize="md"
                          fontWeight="500"
                        >
                          {source.name}
                        </Text>
                      </Box>
                    </Box>
                  </motion.div>
                ))}
              </VStack>

              <Box textAlign="center" mt={6}>
                <Button
                  variant="ghost"
                  color={theme.text}
                  onClick={handleSkip}
                  _hover={{ color: colors.primary }}
                >
                  Skip
                </Button>
              </Box>
            </Box>
          </Box>
        </Box>
      </VStack>
    </NoNavBarPageTemplate>
  );
}
