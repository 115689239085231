import { Box, HStack, Text, VStack } from "@chakra-ui/react";
import { isEmpty } from "lodash/fp";
import { useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { NoNavBarPageTemplate } from "src/components/layouts/PageTemplate";
import {
  AccountFileUploadModal,
  WalletAccountModal,
} from "src/components/modals";
import { AccountModal } from "src/components/modals/AccountModal";
import { AccountsWorkflow } from "src/components/modals/AccountModal/AccountsWorkflow";
import { AwakenCSVUploadModal } from "src/components/modals/AwakenCSVUploadModal";
import { WalletModal } from "src/components/modals/WalletModal";
import { Touchable } from "src/components/Touchable";
import { useMe, useMyToast } from "src/hooks";
import { useIsLargeScreen } from "src/hooks/useScreenSize";
import { useTheme } from "src/hooks/useTheme";
import { colors, other } from "src/theme";
import { D } from "src/utils/helpers";

export function AddAccount() {
  const toast = useMyToast();
  const [runConfetti, setRunConfetti] = useState(false);
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const redirect = searchParams.get("redirect");
  const amountFreeCreditCents = searchParams.get("amountFreeCreditCents");
  const credit = !isEmpty(amountFreeCreditCents)
    ? D(parseFloat(amountFreeCreditCents || "0"), "USD")
    : null;

  const { me } = useMe();
  const theme = useTheme();

  const onSuccess = () => {
    const redirect = searchParams.get("redirect");

    navigate(redirect || "/dashboard");
  };

  const isLarge = useIsLargeScreen();

  return (
    <NoNavBarPageTemplate>
      <AccountModal />
      <WalletAccountModal />
      <WalletModal />
      <AwakenCSVUploadModal />
      <AccountFileUploadModal />

      <VStack
        w="100%"
        padding="2rem 0"
        flexDir="column"
        display="flex"
        minH="100vh"
        justifyContent="flex-start"
      >
        <Box>
          <Box
            display="flex"
            flexDir="column"
            flex={1}
            maxWidth="50rem"
            margin="auto"
            padding="2rem"
            w={isLarge ? "50rem" : "100%"}
            borderRadius={other.borderRadius}
            bg={theme.background}
            border={`1px solid ${theme.border}`}
          >
            <Box width="100%">
              <HStack>
                <Text
                  color={theme.header}
                  fontSize="3xl"
                  flex={1}
                  style={{ fontStretch: "extra-expanded" }}
                  fontWeight="700"
                  marginBottom="0.5rem"
                >
                  Let's add your first account
                </Text>

                <Touchable
                  label="Skip"
                  onClick={() => navigate("/dashboard")}
                />
              </HStack>

              <Text color={theme.text} fontSize="lg" marginBottom="1rem">
                We support all the top exchanges and blockchains (Solana, BTC,
                L2s, you name it).
              </Text>

              <br />

              <AccountsWorkflow onSuccess={onSuccess} />
            </Box>
          </Box>
        </Box>
      </VStack>
    </NoNavBarPageTemplate>
  );
}

const ListItem = ({
  header,
  subtitle,
  iconName,
  backgroundColor = colors.gray80,
}: {
  subtitle: string;
  header: string;
  iconName: string;
  backgroundColor?: string;
}) => {
  const theme = useTheme();

  return (
    <HStack marginBottom="1.5rem">
      <div
        style={{
          width: 40,
          height: 40,
          backgroundColor,
          borderRadius: 50,
          flexShrink: 0,
          display: "flex",
          fontSize: 20,
          flexDirection: "row",
          justifyContent: "center",
          alignItems: "center",
          color: colors.white,
          marginRight: "0.5rem",
        }}
      >
        <i className={iconName} />
      </div>
      <VStack alignItems="flex-start">
        <Text color={theme.header} fontSize="lg" fontWeight="600">
          {header}
        </Text>
        <Text color={theme.text} marginTop="0 !important" fontSize="md">
          {subtitle}
        </Text>
      </VStack>
    </HStack>
  );
};
