import { useLazyQuery } from "@apollo/client";
import {
  Heading,
  HStack,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";
import moment from "moment-timezone";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { show } from "redux-modal";
import { api } from "src/api";
import { BaseJobFields } from "src/api/fragments";
import { Copy, Info } from "src/components";
import { JobFailureModal } from "src/components/modals/JobFailureModal";
import StatusTag from "src/components/styled/StatusTag";
import WhiteBox from "src/components/styled/WhiteBox";
import { useTheme } from "src/hooks/useTheme";
import { colors, other } from "src/theme";
import Helpers, { isLoadingGQL } from "src/utils/helpers";
import Loading from "src/views/Loading";

const ONE_MINUTE = 30 * 1000;

function Jobs() {
  const { clientId } = useParams<{ clientId: string }>();

  const [
    fetchJobs,
    { data: jobsData, networkStatus: jobsNetworkStatus, error },
  ] = useLazyQuery<{
    getJobs?: BaseJobFields[];
  }>(api.jobs.list, {
    notifyOnNetworkStatusChange: true,
    fetchPolicy: "cache-and-network",
    pollInterval: ONE_MINUTE,
  });

  useEffect(() => void fetchJobs({ variables: { clientId } }), [clientId]);

  if (isLoadingGQL(jobsNetworkStatus)) return <Loading />;

  const jobs = jobsData?.getJobs || [];

  // Filter Modal
  return (
    <div>
      <JobFailureModal />

      <HStack>
        <Heading flex={1} size="lg">
          Jobs
        </Heading>
      </HStack>
      <WhiteBox padding="0">
        <TableContainer>
          <Table
            variant="simple"
            sx={{ borderColor: "rgba(255, 255, 255, 0.1)" }}
          >
            <Thead>
              <Tr>
                <Th>Status</Th>
                <Th>Duration</Th>
                <Th>Info</Th>
              </Tr>
            </Thead>
            <Tbody>
              {error ? (
                <Text>{error.message}</Text>
              ) : (
                jobs.map((job) => <Job key={job.jobId} job={job} />)
              )}
            </Tbody>
          </Table>
        </TableContainer>
      </WhiteBox>
    </div>
  );
}

const InngestInfo = ({ job }: { job: BaseJobFields }) => {
  const { text } = useTheme();
  const inngestRunId = job.inngestRunId;
  if (!inngestRunId) {
    return <>No Run ID</>;
  }

  const isRerun = job.type === "rerun_graph";

  const fxn =
    job.type === "transaction_import"
      ? `awaken-import-workers-import-account`
      : job.type === "rerun_graph"
      ? "awaken-recalculate-workers-recalculate-client"
      : null;

  if (!fxn) {
    return null;
  }

  const baseUrl = `https://app.inngest.com/env/production/runs/${inngestRunId}`;

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
      }}
    >
      <a
        href={baseUrl}
        style={{
          fontWeight: "bold",
          marginBottom: 5,
          color: colors.primary,
        }}
        target="_blank"
      >
        Open Inngest <i className="fa-sharp fa-external-link-alt" />
      </a>
      {/* {isRerun && (
        <a
          href={largeBaseUrl}
          style={{
            marginBottom: 5,
            fontWeight: "bold",
            color: colors.primary,
          }}
          target="_blank"
        >
          Open Large Inngest <i className="fa-sharp fa-external-link-alt" />
        </a>
      )} */}
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          color: text,
        }}
      >
        {inngestRunId} <Copy value={inngestRunId} />
      </div>
    </div>
  );
};

const Job = ({ job }: { job: BaseJobFields }) => {
  const { secondaryBackground2, text } = useTheme();
  const dispatch = useDispatch();

  const _openModal = () => {
    dispatch(
      show("JobFailureModal", {
        failures: job.failures || [],
      })
    );
  };

  return (
    <Tr
      key={job.jobId}
      w="100%"
      cursor="pointer"
      borderBottom={other.boxBorder}
      overflowX="scroll"
      transition="0.1s ease-in-out"
      _hover={{
        backgroundColor: secondaryBackground2,
        transition: "0.2s ease-in-out",
      }}
    >
      <Td fontSize={14} color={text}>
        <JobStatus type={job.type} status={job.status} />{" "}
        <Copy
          copyMessage="Copied Job ID"
          containerStyle={{ display: "inline" }}
          value={job.jobId}
        />
      </Td>

      <Td fontSize={14} color={text}>
        <div>
          {/* elapsed time between the start and the end OR now if not end */}
          Time:{" "}
          {job.completedAt
            ? formatDuration(
                moment(job.completedAt).diff(moment(job.createdAt), "seconds")
              )
            : formatDuration(moment().diff(moment(job.createdAt), "seconds"))}
          <Info
            message={
              <>
                {moment(new Date(job.createdAt)).format("h:mma")}-
                {job.completedAt ? moment(job.completedAt).format("h:mma") : ""}{" "}
                {moment(new Date(job.createdAt)).format("M/D")}
              </>
            }
          />
        </div>
      </Td>

      <Td color={text}>
        <InngestInfo job={job} />
      </Td>
    </Tr>
  );
};

function formatDuration(seconds: number) {
  const hours = Math.floor(seconds / 3600);
  const minutes = Math.floor((seconds % 3600) / 60);
  const remainingSeconds = seconds % 60;
  let result = "";

  if (hours > 0) {
    result += `${hours}h, `;
  }
  if (minutes > 0) {
    result += `${minutes}m, `;
  }
  result += `${remainingSeconds}s`;

  return result;
}

const formatType = (type: string) =>
  type.split("_").map(Helpers.capitalize).join(" ");

const JobStatus = ({
  status,
  type: _type,
}: {
  status: string;
  type: string;
}) => {
  const type = formatType(_type);

  switch (status) {
    case "completed":
      return (
        <StatusTag
          type="success"
          label={type}
          infoMessage="Completed"
          iconName="fa-sharp fa-check"
        />
      );
    case "in_progress":
      return (
        <StatusTag
          type="warning"
          label={type}
          infoMessage="In Progress"
          iconName="fa-sharp fa-arrows-spin"
        />
      );
    case "queued":
      return (
        <StatusTag
          type="none"
          label={type}
          infoMessage="Queued"
          iconName="fa-sharp fa-grip-lines"
        />
      );
    case "canceled":
      return (
        <StatusTag
          type="none"
          label={type}
          infoMessage="Canceled"
          iconName="fa-sharp fa-grip-ban"
        />
      );
    case "failed":
      return (
        <StatusTag
          type="error"
          label={type}
          infoMessage="Failed"
          iconName="fa-sharp fa-xmark"
        />
      );
    default:
      return <div>--</div>;
  }
};

const _getS3Url = (logFileObjectKey: string) =>
  `https://s3.console.aws.amazon.com/s3/object/secure.awaken.tax?region=us-west-1&prefix=${logFileObjectKey}`;

export default Jobs;
