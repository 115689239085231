import { Box, Text } from "@chakra-ui/react";
import { useTheme } from "src/hooks/useTheme";

function YoutubeLink({ youtubeVideo }: { youtubeVideo: string | null }) {
  const theme = useTheme();

  if (!youtubeVideo) return null;

  return (
    <a href={youtubeVideo} target="_blank" rel="noreferrer">
      <Box
        _hover={{
          bg: theme.secondaryBackground,
        }}
        bg={theme.medBackground}
        style={{
          border: `1px solid ${theme.border}`,
          marginBottom: 10,
          padding: "7px 12px",
          borderRadius: 15,
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <i
          style={{
            color: "#FF0000",
            fontSize: 28,
            marginRight: 10,
          }}
          className="fab fa-youtube"
        />
        <div style={{ flex: 1 }}>
          <Text
            color={theme.text}
            style={{ fontStretch: "extra-expanded" }}
            fontWeight="bold"
            fontSize="sm"
            mb={"0"}
          >
            Watch our Youtube tutorial
          </Text>

          <Text color={theme.text} fontSize="xs">
            Please take one sec to leave a like 🙏. It means a lot to us.
          </Text>
        </div>

        <i
          className="fas fa-chevron-right"
          style={{ color: theme.text, fontSize: 16 }}
        />
      </Box>
    </a>
  );
}

export default YoutubeLink;
