import { Box, Flex, HStack, Text } from "@chakra-ui/react";
import { useContext, useMemo, useState } from "react";
import ReactPaginate from "react-paginate";
import { useParams } from "react-router-dom";
import { BaseAssetFields, BaseClientFields } from "src/api/fragments";
import {
  CurrencyCodeEnum,
  PortfolioAssetPosition,
  PortfolioBalance,
} from "src/api/generated/types";
import WhiteBox from "src/components/styled/WhiteBox";
import { Maybe } from "src/core";
import { useClientById } from "src/hooks";
import { useIsLargeScreen } from "src/hooks/useScreenSize";
import { useTheme } from "src/hooks/useTheme";
import { AssetKeyInfoContext } from "../utils";
import { AccountPosition } from "./Position";

type PortfolioAssetWithAssetInfo = PortfolioBalance & {
  assetInfo: Maybe<BaseAssetFields>;
};

export type PortfolioAccountWithAssetInfo = any & {
  assets: PortfolioAssetWithAssetInfo[];
};

const Accounts = () => {
  const { clientId, assetKey } = useParams<{
    clientId: string;
    assetKey: string;
  }>();
  const { client } = useClientById(clientId);
  const [isShown, setShown] = useState(true);

  const { position } = useContext(AssetKeyInfoContext);
  const positions = useMemo(() => {
    return position?.positions ?? [];
  }, [position]);

  const theme = useTheme();
  const isLarge = useIsLargeScreen();

  return (
    <Box
      border="none"
      paddingBottom={isLarge ? "0rem" : "5rem"}
      marginTop={isLarge ? "0" : "1.5rem"}
      style={{ width: "100%" }}
    >
      <HStack flex={1} marginBottom="1.25rem" alignItems="center">
        <Flex alignItems="center" flex={1}>
          <Text
            color={theme.header}
            fontSize={18}
            fontWeight="semibold"
            marginRight="5px"
            style={{ fontStretch: "extra-expanded" }}
          >
            Balances
          </Text>
        </Flex>
      </HStack>

      <div>
        <WhiteBox
          style={{
            boxShadow: "none", // other.lighterBoxShadow,
          }}
          padding="5px 0"
          marginTop="0"
          h="100%"
          minW="none"
        >
          {positions ? (
            <AccountPositions positions={positions} client={client} />
          ) : (
            <div
              style={{
                padding: "2rem",
                justifyContent: "center",
                alignItems: "center",
                display: "flex",
                color: theme.header,
              }}
            >
              No balances for asset
            </div>
          )}
        </WhiteBox>
      </div>
    </Box>
  );
};

const AccountPositions = ({
  positions,
  client,
}: {
  positions: PortfolioAssetPosition[];
  client: Maybe<BaseClientFields>;
}) => {
  const numPerPage = 3;
  const [page, setPage] = useState(0);
  const theme = useTheme();

  // Calculate pagination
  const offset = page * numPerPage;
  const paginatedPositions = positions.slice(offset, offset + numPerPage);
  const pageCount = Math.ceil(positions.length / numPerPage);

  const handlePageChange = ({ selected }: { selected: number }) => {
    setPage(selected);
  };

  return (
    <div>
      {paginatedPositions.map((breakdown, index) => (
        <AccountPosition
          key={index}
          position={breakdown}
          clientId={client?.id || ""}
          isLast={index === paginatedPositions.length - 1}
          currency={client?.currency || CurrencyCodeEnum.Usd}
        />
      ))}

      {pageCount > 1 && (
        <HStack padding="0.25rem 0">
          <HStack
            flex={1}
            marginRight="8px"
            alignItems="center"
            justifyContent="flex-end"
          >
            <ReactPaginate
              breakLabel=".."
              nextLabel={<i className="fa-sharp fa-chevron-right" />}
              onPageChange={({ selected }) => handlePageChange({ selected })}
              pageRangeDisplayed={0}
              marginPagesDisplayed={1}
              forcePage={page}
              initialPage={page || 0}
              pageCount={pageCount}
              previousLabel={<i className="fa-sharp fa-chevron-left" />}
              pageClassName={
                theme.theme === "light" ? "page-item" : "dark-page-item"
              }
              pageLinkClassName={
                theme.theme === "light" ? "page-link" : "dark-page-link"
              }
              previousClassName={
                theme.theme === "light" ? "page-item" : "dark-page-item"
              }
              previousLinkClassName={
                theme.theme === "light" ? "page-link" : "dark-page-link"
              }
              nextClassName={
                theme.theme === "light" ? "page-item" : "dark-page-item"
              }
              nextLinkClassName={
                theme.theme === "light" ? "page-link" : "dark-page-link"
              }
              breakClassName={
                theme.theme === "light" ? "page-item" : "dark-page-item"
              }
              breakLinkClassName={
                theme.theme === "light" ? "page-link" : "dark-page-link"
              }
              containerClassName="pagination"
              activeClassName="active"
            />
          </HStack>
        </HStack>
      )}
    </div>
  );
};

export default Accounts;
