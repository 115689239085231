import { useMutation } from "@apollo/client";
import { Box, Flex, HStack, Text, VStack } from "@chakra-ui/react";
import BigNumber from "bignumber.js";
import { SingleValueData } from "lightweight-charts";
import { isNil, keyBy, throttle } from "lodash";
import numbro from "numbro";
import qs from "query-string";
import React, { useContext, useEffect, useMemo, useRef, useState } from "react";
import CountUp from "react-countup";
import {
  Link,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import { api } from "src/api";
import { AssetTypeEnum, CurrencyCodeEnum } from "src/api/generated/types";
import { PortfolioFeedbackModal } from "src/components/modals/PortfolioFeedbackModal";
import { AssetIcon } from "src/components/styled/Assets";
import { Touchable } from "src/components/Touchable";
import { Maybe } from "src/core";
import { useClientById, useMe, useMyToast } from "src/hooks";
import { useIsLargeScreen } from "src/hooks/useScreenSize";
import { useTheme } from "src/hooks/useTheme";
import { getAssetOnMarketplaceOrCoingecko } from "src/modules/getAssetUrl";
import { colors } from "src/theme";
import { D } from "src/utils/helpers";
import Accounts from "./Balances/Accounts";
import { PriceChart } from "./Charts";
import { AwakenChartValuePoint } from "./Charts/types";
import Finances from "./Finances";
import TaxLots from "./TaxLots/TaxLots";
import TokenDetails from "./TokenDetails";
import { Transactions } from "./Transactions";
import { ChartType } from "./types";
import { AssetKeyInfoContext, TIME_FRAMES, TimeFrame } from "./utils";

const CHART_HEIGHT = 325;

type OverallGainLoss = {
  percent: string;
  color: string;
  amount: number;
  formattedAmount: string;
};

export const AssetDetails = ({ clientId }: { clientId: string }) => {
  const { assetKey } = useParams<{ assetKey: string }>();
  const { client } = useClientById(clientId);
  const { me } = useMe();
  const [search] = useSearchParams();

  const {
    name,
    firstAsset,
    assets,
    symbol,
    iconImageUrl,
    contractAddress,
    provider,
    coingeckoId,
    type,
    pointValueDollars,
    points,
    loadingPoints,
    currentValueDollars: pricingCurrentValue,
    currentPoint,
    timeFrame,
    setTimeFrame,
  } = useContext(AssetKeyInfoContext);
  const [markWorthless] = useMutation(api.assets.markWorthless);
  const toast = useMyToast();

  const navigate = useNavigate();
  const [selectedValue, _setSelectedValue] = useState<Maybe<number>>(null);

  const [selectedPoint, _setSelectedPoint] = useState<Maybe<SingleValueData>>(
    currentPoint ?? null
  );
  const [chartType, setChartType] = useState<ChartType>(ChartType.Price);

  const timezone = client?.timezone ?? "UTC";

  const _setTimeFrame = (t: Maybe<TimeFrame>) => {
    setTimeFrame(t);
    _updateUrl({ tf: t?.type || undefined });
  };

  const _updateUrl = (params: any) => {
    const newUrl = location.pathname + "?" + qs.stringify(params);

    navigate(newUrl, {
      replace: false, // please don't
    });
  };

  const throttledSelectedValue = useRef(throttle(_setSelectedValue, 0));
  const throttleSetSelectedPoint = useRef(throttle(_setSelectedPoint, 0));

  const { value: currentValue, isFiatCurrency } = useMemo(() => {
    if (chartType === ChartType.Price) {
      return { value: pricingCurrentValue, isFiatCurrency: true };
    }
    return { value: null, valueIsFiat: false };
  }, [pricingCurrentValue, chartType]);

  const startingValue = useMemo(() => points[0]?.value ?? null, [points]);

  const fullPointByTime = useMemo(
    () => keyBy(points, (p) => new Date(p.timestamp).toISOString()),
    [points]
  );
  const fullSelectedDataPoint = useMemo(() => {
    if (!selectedPoint) return null;
    const date = new Date((selectedPoint.time as any) * 1000).toISOString();
    const pointValue = fullPointByTime[date] || currentValue;

    return pointValue;
  }, [fullPointByTime, selectedPoint, currentValue]);

  const lineColor = useMemo(() => {
    const first = points[0];
    const last = points[points.length - 1];
    if (isNil(first) || isNil(last)) return colors.positive;
    return first.value > last.value ? colors.negative : colors.positive;
  }, [points]);

  const overallGainLoss = useMemo((): Maybe<OverallGainLoss> => {
    if (isNil(startingValue) || isNil(selectedValue) || !selectedValue) {
      return null;
    }

    const percent =
      startingValue > 0
        ? (selectedValue - startingValue) / startingValue
        : null;

    const netAmount = Math.floor(Math.abs(selectedValue - startingValue) * 100);

    return {
      percent:
        percent === null ? "N/A" : Math.abs(percent * 100).toFixed(2) + "%",
      color: isNil(percent) || percent >= 0 ? colors.positive : colors.negative,
      amount: selectedValue - startingValue,
      formattedAmount: isFiatCurrency
        ? D(netAmount, "USD").toFormat()
        : netAmount.toFixed(3),
    };
  }, [selectedValue, startingValue, isFiatCurrency]);

  const _markWorthless = async () => {
    try {
      if (!firstAsset) return;

      await markWorthless({
        variables: {
          assetKey: assetKey,
          isWorthless: !firstAsset.isWorthless,
          clientId,
        },
        refetchQueries: [
          api.portfolio.getPortfolioAssets,
          api.portfolio.getChart,
          api.portfolio.getPortfolioValue,
          api.clients.assetOptions,
        ],
      });
      toast.show({
        status: "success",
        message: "Successfully marked as worthless.",
      });
    } catch (err) {
      toast.show({
        status: "error",
        message:
          (err as any)?.message ||
          "Something went wrong, please try again later!",
      });
    }
  };

  useEffect(() => {
    throttledSelectedValue.current(currentPoint?.value ?? null);
    throttleSetSelectedPoint.current(currentPoint ?? null);
  }, [currentPoint]);

  useEffect(() => {
    if (!selectedPoint) {
      throttledSelectedValue.current(currentPoint?.value ?? null);
      throttleSetSelectedPoint.current(currentPoint);
    }
  }, [selectedPoint, currentPoint?.time]);

  useEffect(() => {
    const container = document.getElementById("asset-details-container");
    if (container) {
      container?.scrollTo({
        behavior: "auto",
        top: 0,
      });
    }
  }, [assetKey]);

  const chartIsLoading = loadingPoints && !points.length;
  const chartPoints = useMemo(
    (): SingleValueData[] =>
      points.map((p) => ({
        time: p.time,
        value: p.value,
      })),
    [points]
  );

  // just use the first asset. opensea cannot even link to a collection by contract they all have unique slugs like "bored-ape-yacht"
  // so we just link to an asset so it is easy for you to click to open up the collection (one extra click on the asset details page of opensea)

  const data = {
    coinGeckoTokenId: firstAsset?.coinGeckoTokenId || coingeckoId,
    type: firstAsset?.type || AssetTypeEnum.FungibleToken,
    contractAddress: firstAsset?.contractAddress || contractAddress,
    provider: firstAsset?.provider || provider,
    tokenId: firstAsset?.tokenId,
  };

  const assetInfo = getAssetOnMarketplaceOrCoingecko(data);

  const isLarge = useIsLargeScreen();
  const theme = useTheme();

  // console.log("pricing: ", pricingPoints);
  // console.log("holdings: ", holdingsPoints);

  return (
    <div
      id="asset-details-container"
      style={{
        width: "100%",
        padding: isLarge ? "2rem 3rem" : "1rem 0.5rem",
      }}
    >
      <PortfolioFeedbackModal />

      <HStack marginTop="0" marginBottom="1rem">
        <Link to={`/clients/${clientId}/portfolio`}>
          <Touchable
            label="Back"
            iconPosition="left"
            iconName="fa-sharp fa-chevron-left"
            style={{
              paddingLeft: "0.25rem",
              position: "relative",
              left: "-0.5rem",
              paddingRight: "1rem",
            }}
          />
        </Link>
      </HStack>
      <HStack
        alignItems="flex-start"
        justifyContent="flex-start"
        marginTop="0rem !important"
        style={{ width: "100%" }}
      >
        <VStack style={{ width: "100%" }} flex={1} alignItems="flex-start">
          <HStack width="100%" alignItems="flex-start">
            <Flex flex={1}>
              <VStack width="100%" alignItems="flex-start">
                <HStack w="100%" marginBottom="1rem" alignItems="center">
                  {type && (
                    <AssetIcon
                      size={40}
                      asset={{
                        iconImageUrl,
                        imageUrl: iconImageUrl,
                        type: type,
                        symbol: symbol || "",
                      }}
                    />
                  )}

                  <VStack alignItems="flex-start" flex={1}>
                    <Text color={theme.header} fontSize="lg" fontWeight="bold">
                      {type === AssetTypeEnum.Nft ? name : symbol}
                    </Text>
                    <Text
                      marginTop="0px !important"
                      fontSize="sm"
                      fontWeight="normal"
                      color={theme.header}
                    >
                      {name}
                    </Text>
                  </VStack>

                  {/* coingecko button */}
                  {assetInfo && (
                    <>
                      <a
                        href={`mailto:team@awaken.tax?subject=${encodeURIComponent(
                          "Portfolio asset is wrong"
                        )}&body=${encodeURIComponent(
                          `The price of ${
                            symbol || "my token"
                          } seems wrong. The token is ${
                            symbol || ""
                          } but the price is linked to ${coingeckoId || ""}.`
                        )}`}
                      >
                        <Touchable
                          iconName="fa-solid fa-flag"
                          label={isLarge ? "Report" : ""}
                        />
                      </a>
                    </>
                  )}
                </HStack>

                <HStack w="100%">
                  <Flex flex={1}>
                    <CurrentSelectedValue
                      selectedValue={selectedValue}
                      overallGainLoss={overallGainLoss}
                      isFiatCurrency={isFiatCurrency ?? true}
                      currency={client?.currency ?? CurrencyCodeEnum.Usd}
                      chartValuePoint={fullSelectedDataPoint}
                      chartType={chartType}
                    />
                  </Flex>
                </HStack>
              </VStack>
            </Flex>
          </HStack>

          {/* // Note: keep this here so the rendering is less jolty when the loading stops */}
          <div
            style={{
              width: "100%",
              height: CHART_HEIGHT,
              flexGrow: 0,
            }}
          >
            {!loadingPoints && !chartPoints.length ? (
              <div
                style={{
                  width: "100%",
                  height: "100%",
                  flexDirection: "row",
                  justifyContent: "center",
                  alignItems: "center",
                  display: "flex",
                }}
              >
                <Text color={theme.header}>No chart data</Text>
              </div>
            ) : (
              <Chart
                timezone={timezone}
                timeFrame={timeFrame}
                onHoverPoint={throttleSetSelectedPoint}
                onHoverValue={throttledSelectedValue}
                points={chartPoints}
                isLoading={loadingPoints}
                currentValue={null}
                lineColor={lineColor}
                chartType={chartType}
              />
            )}
          </div>

          <HStack w="100%" alignItems="center">
            <Flex flex={1}>
              {TIME_FRAMES.map((d) => (
                <TimeFrameOption
                  key={d.type}
                  isActive={timeFrame?.type === d.type}
                  timeFrame={d}
                  activeColor={colors.primary}
                  setActiveTimeFrame={_setTimeFrame}
                />
              ))}
            </Flex>
          </HStack>

          <br />
          <br />

          {!isLarge && (
            <>
              <TokenDetails />
              <Finances />
              <Accounts />
              <TaxLots />
            </>
          )}
          <Transactions />
        </VStack>

        {isLarge && (
          <div
            style={{
              width: 400,
              marginLeft: "2rem",
              maxHeight: 425,
              height: "100%",
              flexShrink: 0,
              paddingBottom: "2rem",
            }}
          >
            <TokenDetails />
            <Finances />
            <Accounts />
            <TaxLots />
          </div>
        )}
      </HStack>
    </div>
  );
};

const Chart = ({
  timezone,
  timeFrame,
  lineColor,
  currentValue,
  onHoverValue,
  onHoverPoint,
  points,
  isLoading,
  chartType,
}: {
  timezone: string;
  timeFrame: Maybe<TimeFrame>;
  lineColor: string;
  currentValue: Maybe<SingleValueData>;
  onHoverPoint: any;
  onHoverValue: any;
  points: SingleValueData[];
  isLoading: boolean;
  chartType: ChartType;
}) => {
  // if (chartType === ChartType.Holdings) {
  //   return (
  //     <HoldingsChart
  //       timezone={timezone}
  //       timeFrame={timeFrame}
  //       onHoverPoint={onHoverPoint}
  //       onHoverValue={onHoverValue}
  //       points={points}
  //       isLoading={isLoading}
  //       currentValue={currentValue}
  //       lineColor={lineColor}
  //     />
  //   );
  // }

  return (
    <PriceChart
      timezone={timezone}
      timeFrame={timeFrame}
      onHoverPoint={onHoverPoint}
      onHoverValue={onHoverValue}
      points={points}
      isLoading={isLoading}
      currentValue={currentValue}
      lineColor={colors.primary}
    />
  );
};

const CurrentSelectedValue = ({
  selectedValue,
  overallGainLoss,
  isFiatCurrency,
  currency,
  chartValuePoint,
  chartType,
}: {
  selectedValue: Maybe<number>;
  overallGainLoss: Maybe<OverallGainLoss>;
  isFiatCurrency: boolean;
  currency: CurrencyCodeEnum;
  chartValuePoint: Maybe<AwakenChartValuePoint>;
  chartType: ChartType;
}) => {
  // if the selected value with 2 decimals is 0, we need to show 8 decimals
  const showEightDecimals =
    !isNil(selectedValue) && new BigNumber(selectedValue?.toFixed(2)).lte(1);
  const theme = useTheme();

  return (
    <HStack alignItems="flex-start">
      <Box flex={1}>
        <Text
          style={{
            fontFamily:
              "Capsule Sans Display, system-ui, -apple-system, system-ui, Segoe UI, Helvetica, Arial, sans-serif",
          }}
          fontSize={32}
          color={theme.header}
          fontWeight="700"
        >
          $
          {!isNil(selectedValue) ? (
            <CountUp
              decimals={showEightDecimals ? 8 : 2}
              duration={0.25}
              preserveValue
              end={selectedValue}
            />
          ) : (
            "—"
          )}
        </Text>
        <HStack>
          {chartType === ChartType.Holdings && (
            <Text
              fontSize="sm"
              style={{
                display: "inline-block",
              }}
            >
              {numbro(chartValuePoint?.data?.amount || 0).format("0,0.[0000]")}{" "}
              tokens
            </Text>
          )}

          {chartType === ChartType.Price && (
            <HStack alignItems="center">
              <i
                className={
                  !overallGainLoss
                    ? "fa-sharp fa-none"
                    : overallGainLoss?.amount < 0
                    ? "fa-sharp fa-caret-down"
                    : "fa-sharp fa-caret-up"
                }
                style={{
                  fontSize: 14,
                  position: "relative",
                  // top: 1,
                  color: overallGainLoss?.color || colors.black,
                }}
              />
              <span
                style={{
                  marginLeft: 5,
                  color: overallGainLoss?.color || colors.black,
                  fontWeight: "700",
                }}
              >
                {overallGainLoss ? (
                  <>
                    {overallGainLoss.formattedAmount} ({overallGainLoss.percent}
                    )
                  </>
                ) : (
                  "—"
                )}
              </span>
            </HStack>
          )}
        </HStack>
      </Box>
    </HStack>
  );
};

const _TimeFrameOption = ({
  isActive,
  timeFrame,
  setActiveTimeFrame,
  activeColor,
}: {
  isActive?: boolean;
  timeFrame: TimeFrame;
  activeColor: string;
  setActiveTimeFrame: (timeFrame: TimeFrame) => void;
}) => {
  const theme = useTheme();

  return (
    <div
      style={{
        backgroundColor: isActive ? activeColor : theme.secondaryBackground,
        borderRadius: 8,
        marginRight: 5,
        padding: "0.3rem 0.75rem",
        fontSize: 12,
        fontWeight: "bold",
        color: isActive ? "white" : theme.text,
        cursor: "pointer",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
      onClick={(e) => {
        e.preventDefault();
        e.stopPropagation();
        setActiveTimeFrame(timeFrame);
      }}
    >
      {timeFrame.label}
    </div>
  );
};

const TimeFrameOption = React.memo(_TimeFrameOption);
