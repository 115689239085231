import { useQuery } from "@apollo/client";
import { Box, Center, Flex, HStack, Spinner, Text } from "@chakra-ui/react";
import BigNumber from "bignumber.js";
import { groupBy, orderBy, sum, truncate } from "lodash";
import { useEffect, useMemo, useState } from "react";
import ReactPaginate from "react-paginate";
import { useParams } from "react-router-dom";
import { api } from "src/api";
import { BaseAssetFields, BaseClientFields } from "src/api/fragments";
import {
  AssetTaxLot,
  CurrencyCodeEnum,
  PortfolioBalance,
  Query,
} from "src/api/generated/types";
import { AwakenTooltip } from "src/components";
import WhiteBox from "src/components/styled/WhiteBox";
import { Maybe } from "src/core";
import { useClientById, useMe } from "src/hooks";
import { useTheme } from "src/hooks/useTheme";
import { colors } from "src/theme";
import { formatNum } from "src/utils/helpers";
import { TaxLotPosition } from "./TaxLotPosition";

type PortfolioAssetWithAssetInfo = PortfolioBalance & {
  assetInfo: Maybe<BaseAssetFields>;
};

export type PortfolioAccountWithAssetInfo = any & {
  assets: PortfolioAssetWithAssetInfo[];
};

const TaxLots = () => {
  const { clientId, assetKey } = useParams<{
    clientId: string;
    assetKey: string;
  }>();
  const { client } = useClientById(clientId);
  const [isShown, setShown] = useState(true);
  const [selectedAccountId, setSelectedAccountId] = useState<string | null>(
    null
  );

  const { data, loading } = useQuery<Pick<Query, "getTaxLots">>(
    api.portfolio.taxLots,
    {
      variables: {
        clientId,
        assetPricingKey: assetKey || "",
      },
    }
  );

  const taxLots = data?.getTaxLots?.lots ?? [];
  const theme = useTheme();
  const { me } = useMe();
  const [page, setPage] = useState(0);

  const taxLotsByAccount = useMemo(() => {
    // Filter out lots with 0 amount before grouping
    const nonZeroLots = taxLots.filter((lot) =>
      new BigNumber(lot.fiatAmountCents).isGreaterThan(0)
    );
    return groupBy(nonZeroLots, (p) => p.accountId);
  }, [taxLots]);

  // Set initial selected account
  useEffect(() => {
    if (!selectedAccountId && Object.keys(taxLotsByAccount).length > 0) {
      setSelectedAccountId(Object.keys(taxLotsByAccount)[0]);
    }
  }, [taxLotsByAccount, selectedAccountId]);

  const hasTaxLots = taxLots && taxLots.length > 0;

  if (!hasTaxLots && !loading) {
    return null;
  }

  return (
    <Box
      border="none"
      paddingBottom="5rem"
      marginTop="1.5rem"
      style={{ width: "100%" }}
    >
      <HStack flex={1} marginBottom="1rem" alignItems="center">
        <Flex alignItems="center" flex={1}>
          <Text
            color={theme.header}
            fontSize={18}
            fontWeight="semibold"
            marginRight="5px"
            style={{ fontStretch: "extra-expanded" }}
          >
            Tax Lots
          </Text>
        </Flex>
        {/* <StatusTag
          type="beta"
          label="Beta"
          iconName="fa-sharp fa-vial"
          infoMessage="This feature is in beta, so there may be issues."
        /> */}
      </HStack>

      {/* <HStack flex={1} marginBottom="1.25rem" alignItems="center">
        <Flex alignItems="center" flex={1}>
          <Text color={theme.text} fontSize={14} fontWeight="regular">
            Your tax lots, when you sell assets this order will be used for your
            cost basis.
          </Text>
        </Flex>
      </HStack> */}

      <div>
        <WhiteBox
          style={{
            boxShadow: "none",
          }}
          padding="0"
          marginTop="0"
          h="100%"
          minW="none"
          marginBottom="3rem"
        >
          {loading ? (
            <Center p={8}>
              <Spinner size="md" color={theme.header} />
            </Center>
          ) : taxLots ? (
            <>
              <Box
                overflowX="auto"
                whiteSpace="nowrap"
                style={{
                  padding: 10,
                  borderTopRightRadius: 10,
                  borderTopLeftRadius: 10,
                  borderBottom: `1px solid ${theme.border}`,
                  background: theme.medBackground,
                }}
              >
                <Text
                  fontSize={10}
                  fontWeight="black"
                  style={{
                    color: theme.text,
                    marginBottom: 5,
                    fontStretch: "extra-expanded",
                  }}
                >
                  ACCOUNTS
                </Text>
                {Object.entries(taxLotsByAccount).map(([accountId, lots]) => {
                  const totalAmount = sum(
                    lots.map((p) => new BigNumber(p.amount).toNumber())
                  );

                  return (
                    <Box
                      key={accountId}
                      onClick={() => {
                        setSelectedAccountId(accountId);
                        setPage(0);
                      }}
                      cursor="pointer"
                      px={2}
                      py={1}
                      mb={0}
                      pr={1}
                      display="inline-block"
                      border={`2px solid ${theme.border}`}
                      bg={
                        selectedAccountId === accountId
                          ? colors.primary
                          : theme.secondaryBackground
                      }
                      _hover={{
                        bg:
                          selectedAccountId === accountId
                            ? colors.primary
                            : theme.secondaryBackground2,
                      }}
                      style={{
                        display: "inline-block",
                        flexDirection: "row",
                        alignItems: "center",
                        gap: "0.5rem",
                      }}
                      borderRadius={10}
                      mr={4}
                    >
                      <HStack>
                        <img
                          src={lots[0].account?.iconImageUrl}
                          alt={lots[0].account?.name}
                          style={{
                            width: 15,
                            height: 15,
                            marginRight: 5,
                            borderRadius: "50%",
                          }}
                        />

                        <AwakenTooltip
                          message={lots[0].account?.description || ""}
                        >
                          <Text
                            fontSize={14}
                            flex={1}
                            ml={"0 !important"}
                            color={
                              selectedAccountId === accountId
                                ? colors.white
                                : theme.text
                            }
                            fontWeight="medium"
                          >
                            {truncate(lots[0].account?.description || "", {
                              length: 10,
                            })}
                          </Text>
                        </AwakenTooltip>

                        <Box
                          style={{
                            width: 40,
                            height: 20,
                            fontSize: 10,
                            borderRadius: 5,
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                            alignItems: "center",
                            backgroundColor:
                              selectedAccountId === accountId
                                ? colors.white
                                : theme.medBackground,
                            color:
                              selectedAccountId === accountId
                                ? colors.primary
                                : theme.text,
                            fontWeight: "bold",
                          }}
                        >
                          {formatNum(totalAmount, false, "0,0.0")}
                        </Box>
                      </HStack>
                    </Box>
                  );
                })}
              </Box>

              {selectedAccountId && (
                <Box mb={2} mt={0}>
                  <TaxLotPositions
                    positions={taxLotsByAccount[selectedAccountId]}
                    client={client}
                    page={page}
                    setPage={setPage}
                  />
                </Box>
              )}
            </>
          ) : (
            <div
              style={{
                padding: "2rem",
                justifyContent: "center",
                alignItems: "center",
                display: "flex",
                color: theme.header,
              }}
            >
              No tax lots for this asset.
            </div>
          )}
        </WhiteBox>
      </div>
    </Box>
  );
};

const TaxLotPositions = ({
  positions: _positions,
  client,
  page,
  setPage,
}: {
  positions: AssetTaxLot[];
  client: Maybe<BaseClientFields>;
  page: number;
  setPage: (page: number) => void;
}) => {
  const numPerPage = 3;
  const theme = useTheme();

  // Calculate pagination
  const offset = page * numPerPage;
  const positions = useMemo(() => {
    // order in ascending order
    return orderBy(_positions, (p) => p.position, "asc");
  }, [_positions]);

  const paginatedPositions = positions.slice(offset, offset + numPerPage);
  const pageCount = Math.ceil(positions.length / numPerPage);

  const handlePageChange = ({ selected }: { selected: number }) => {
    setPage(selected);
  };

  if (!positions.length) {
    return (
      <div
        style={{
          padding: "2rem",
          justifyContent: "center",
          alignItems: "center",
          display: "flex",
          color: theme.header,
        }}
      >
        No tax lots for this asset.
      </div>
    );
  }

  return (
    <div>
      {paginatedPositions.map((taxLot, index) => (
        <TaxLotPosition
          key={index}
          position={taxLot}
          clientId={client?.id || ""}
          isLast={index === paginatedPositions.length - 1}
          currency={client?.currency || CurrencyCodeEnum.Usd}
        />
      ))}

      {pageCount > 1 && (
        <HStack padding="0.25rem 0" marginTop="0.5rem">
          <HStack
            flex={1}
            marginRight="8px"
            alignItems="center"
            justifyContent="flex-end"
          >
            <ReactPaginate
              breakLabel=".."
              nextLabel={<i className="fa-sharp fa-chevron-right" />}
              onPageChange={({ selected }) => handlePageChange({ selected })}
              pageRangeDisplayed={0}
              marginPagesDisplayed={1}
              forcePage={page}
              initialPage={page || 0}
              pageCount={pageCount}
              previousLabel={<i className="fa-sharp fa-chevron-left" />}
              pageClassName={
                theme.theme === "light" ? "page-item" : "dark-page-item"
              }
              pageLinkClassName={
                theme.theme === "light" ? "page-link" : "dark-page-link"
              }
              previousClassName={
                theme.theme === "light" ? "page-item" : "dark-page-item"
              }
              previousLinkClassName={
                theme.theme === "light" ? "page-link" : "dark-page-link"
              }
              nextClassName={
                theme.theme === "light" ? "page-item" : "dark-page-item"
              }
              nextLinkClassName={
                theme.theme === "light" ? "page-link" : "dark-page-link"
              }
              breakClassName={
                theme.theme === "light" ? "page-item" : "dark-page-item"
              }
              breakLinkClassName={
                theme.theme === "light" ? "page-link" : "dark-page-link"
              }
              containerClassName="pagination"
              activeClassName="active"
            />
          </HStack>
        </HStack>
      )}
    </div>
  );
};

export default TaxLots;
