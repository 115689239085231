import { Button, Container, Text, VStack } from "@chakra-ui/react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { connectModal, InjectedProps } from "redux-modal";
import { Modal } from "src/components/Modal";
import { useClientById, useMyToast } from "src/hooks";
import { useTheme } from "src/hooks/useTheme";
import { colors } from "src/theme";
import { trackEvent } from "src/utils/analytics";

type Props = InjectedProps & {
  title: string;
  subtitle?: string;
};

function _PleaseShareModal({
  handleHide,
  subtitle,
  title,
  show: isVisible,
}: Props) {
  const toast = useMyToast();
  const { clientId } = useParams<{ clientId: string }>();
  const dispatch = useDispatch();
  const { client } = useClientById(clientId, {
    onlyFetchClient: true,
  });
  const theme = useTheme();

  const _copyLink = () => {
    if (!client) return;

    const referralCode = client?.referralCode || "";

    navigator.clipboard.writeText(`https://awaken.tax?ref=${referralCode}`);

    toast.show({
      message:
        "Copied sharable link to clipboard! You can paste it in discord/imessage/twitter or wherever you want.",
      status: "info",
    });

    void trackEvent("Referral Link Copied", { referralCode });
  };

  const _shareToX = () => {
    if (!client) return;

    const text =
      "I'm using @AwakenTax for my taxes! By far the best crypto tax platform out there, def check it out.";
    const url = `https://awaken.tax?ref=${client?.referralCode || ""}`;
    const shareUrl = `https://twitter.com/intent/tweet?text=${encodeURIComponent(
      text
    )}&url=${encodeURIComponent(url)}`;

    window.open(shareUrl, "_blank");
  };

  const onHide = () => {
    handleHide();
  };

  return (
    <Modal
      title="Thanks for using Awaken!"
      titleHeaderProps={{
        fontSize: 24,
        color: theme.header,
      }}
      marginTop="1.5rem"
      isVisible={isVisible}
      handleHide={onHide}
      Footer={
        <VStack style={{ width: "100%" }}>
          <Button
            width="100%"
            bg={colors.green50}
            color={colors.white}
            marginBottom="0.5rem"
            marginTop="1.5rem"
            _hover={{ bg: colors.green40 }}
            onClick={_copyLink}
            style={{
              padding: "1.5rem 1rem",
            }}
          >
            Share Awaken with friends{" "}
            <i
              style={{
                marginLeft: 15,
                position: "absolute",
                right: 20,
                top: 15,
                fontSize: 20,
              }}
              className="fa-sharp fa-arrow-right"
            />
          </Button>

          <Button
            icon={
              <i
                style={{
                  fontSize: 16,
                  color: colors.primary,
                }}
                className="fab fa-x-twitter"
              />
            }
            onClick={_shareToX}
            style={{
              marginTop: "0.5rem",
              color: colors.white,
              width: "100%",
              padding: "1.5rem 1rem",
            }}
            bg={colors.black}
            _hover={{ bg: colors.black }}
            _active={{ bg: colors.black }}
            border={`1px solid ${colors.gray70}`}
            labelStyle={{
              textAlign: "center",
              width: "100%",
              color: colors.white,
            }}
          >
            Share on{" "}
            <i
              style={{
                marginLeft: 15,
                position: "absolute",
                right: 20,
                top: 15,
                fontSize: 20,
              }}
              className="fab fa-x-twitter"
            />
          </Button>
        </VStack>
      }
    >
      <Container padding="0px" marginTop="0px !important">
        <Text color={theme.text} fontSize="lg">
          {title}
          <br /> <br />
          {subtitle ? (
            <>
              {subtitle}
              <br />
              <br />
            </>
          ) : null}
          If you know anyone who is stressed about crypto taxes, please tell
          them about us! You'll also earn a 15% affiliate fee for every friend
          you refer. So if your friend pays $199, we'll pay you $30.
          <br />
          <br />
          You all are the fuel that helps us grow 🫰
        </Text>

        {/* <Textarea 
          value={notes}
          noOfLines={5}
          onChange={(e) => setNotes(e.target.value)}
          // label="Client Full Name"
          placeholder="Type here..."
        /> */}
      </Container>
    </Modal>
  );
}

export const PleaseShareModal = connectModal({
  name: "PleaseShareModal",
})(_PleaseShareModal);
