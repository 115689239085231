import { Box, Flex, Heading, HStack, Text, useTheme } from "@chakra-ui/react";
import { noop } from "lodash";
import { useState } from "react";
import { Input, NavBarPageTemplate } from "src/components";
import { AccountsWorkflow } from "src/components/modals/AccountModal/AccountsWorkflow";
import { colors, other } from "src/theme";

function Integrations() {
  const [search, setSearch] = useState("");
  const theme = useTheme();

  return (
    <NavBarPageTemplate bgColor={colors.white}>
      <Box position="relative" maxWidth={"inherit"}>
        <Box marginTop="3rem" w="100%">
          <HStack alignItems="center" padding="0rem 0">
            <Flex flex={1} alignItems="center" display="flex" flexDir="row">
              <Heading
                style={{
                  fontStretch: "extra-expanded",
                }}
                marginTop="0"
                marginBottom="1rem"
                size="lg"
                textAlign="center"
                w="100%"
              >
                Supported Blockchains & Wallets
              </Heading>
            </Flex>
          </HStack>

          <Text
            style={{
              fontSize: 16,
              maxWidth: 600,
              margin: "auto",
              color: colors.gray30,
              textAlign: "center",
              marginBottom: 25,
            }}
          >
            Awaken supports a wide range of blockchains and wallets to help you
            do your crypto taxes fast! You can see the full supported list below
            👇
          </Text>

          <div
            style={{
              maxWidth: 800,
              margin: "auto",
              padding: 10,
            }}
          >
            <AccountsWorkflow isReadOnly={true} onSuccess={noop} />
          </div>
        </Box>
      </Box>
    </NavBarPageTemplate>
  );
}

type SearchBarProps = {
  search: string;
  setSearch: any;
};

const SearchBar = ({ search, setSearch }: SearchBarProps) => {
  return (
    <Box
      display="flex"
      w="25rem"
      h="4rem"
      padding="1rem 2rem"
      justifyContent={"center"}
      alignItems="center"
      bgColor="white"
      borderRadius={other.borderRadius}
      boxShadow={other.boxShadow}
    >
      <Box
        height="100%"
        display="flex"
        justifyContent={"center"}
        alignItems="center"
      >
        <i
          className="far fa-search"
          style={{
            fontSize: "15px",
            color: colors.gray4,
          }}
        ></i>
      </Box>

      <Input
        borderTopLeftRadius={0}
        borderBottomLeftRadius={0}
        borderTopRightRadius={other.borderRadius}
        borderBottomRightRadius={other.borderRadius}
        value={search}
        onChange={(e) => setSearch(e.target.value)}
        margin="auto"
        border="0"
        height="100%"
        boxShadow="none"
        focusBorderColor="transparent"
        containerStyle={{
          flex: "1",
          height: "100%",
          margin: 0,
          boxShadow: "none",
        }}
      />
    </Box>
  );
};

export default Integrations;
