import {
  Badge,
  Box,
  Heading,
  HStack,
  Image,
  Link,
  Text,
  VStack,
} from "@chakra-ui/react";
import WhiteBox from "src/components/styled/WhiteBox";
import { useTheme } from "src/hooks/useTheme";

import _ from "lodash";

import CryptoTaxGirl from "src/assets/awaken/accountants/cryptotaxgirl.jpg";
import CryptoTaxSucks from "src/assets/awaken/accountants/cryptotaxsucks.jpg";
import Dan from "src/assets/awaken/accountants/dan.png";
import Darien from "src/assets/awaken/accountants/darien.jpg";
import Gordon from "src/assets/awaken/accountants/gordon.jpg";
import Matt from "src/assets/awaken/accountants/matt.jpg";
import Squeeze from "src/assets/awaken/accountants/squeeze.jpg";
import { Button } from "src/components";
import { Touchable } from "src/components/Touchable";
import { colors } from "src/theme";

const TaxProfessionalsInfo = _.sortBy(
  [
    {
      url: "https://twitter.com/@CryptoTaxSucks",
      name: "Crypto Tax Made Easy",
      isRecommended: true,
      img: CryptoTaxSucks,
      websiteUrl: "https://cryptotaxmadeeasy.com/",
      description:
        "Crypto Tax Made Easy is a team of crypto bookkeepers who have helped Awaken users with their taxes. Their customers swear by them (no joke!). Note: they are not CPAs.",
    },
    {
      url: "https://twitter.com/@The_NFT_CPA",
      name: "Dan Cournoyer",
      img: Dan,
      isRecommended: true,
      description:
        "Dan Cournoyer helped build Awaken. He taught us how to do crypto taxes. Before starting his own firm, Dan was a manager at PwC. Dan is a CPA.",
    },
    {
      url: "https://x.com/Darien_Advisors",
      name: "Darien Advisors",
      img: Darien,
      isRecommended: true,
      websiteUrl: "https://www.darienadvisors.io/",
      description:
        "These guys know their stuff. They've helped Awaken users with their taxes. They are crypto degens themselves so they can help with pretty much anything. They have CPAs on staff.",
    },

    {
      url: "https://twitter.com/@SqueezeTaxes",
      name: "Squeeze Taxes",
      img: Squeeze,
      isRecommended: true,
      description:
        "Squeeze has helped us grow the Awaken community and has provided advice for dozens of our users. Squeeze is not a CPA but is an EA.",
    },
    {
      url: "https://twitter.com/@EmDeeEm",
      name: "Matt Metras",
      img: Matt,
      description:
        "Matt is fantastic and tons of our customers love him. An EA who is well versed in all things crypto. Matt is not a CPA but is an EA.",
      isRecommended: true,
    },
    {
      name: "Andrew Gordon",
      url: "https://twitter.com/gordonlawltd",
      img: Gordon,
      description:
        "Andrew Gordon is a highly experienced tax attorney and CPA. He advises Awaken on complex tax matters like accounting for Uniswap V3. They are extremely well versed in all things crypto.",
      websiteUrl: "https://gordonlaw.com?utm_source=awaken",
    },
    {
      url: "https://twitter.com/@CryptoTaxGirl",
      name: "Crypto Tax Girl",
      img: CryptoTaxGirl,
      description:
        "Laura is a CPA and has been an industry leader in the crypto tax space since 2017. Her team offers crypto tax reports, tax return filing services, tax advisory services, and tax notice resolution services.",
    },
    { url: "https://twitter.com/@j_howe4", name: "CryptoTax_Monke" },
    { url: "https://twitter.com/@cocothecorncob", name: "El Coco" },

    { url: "https://twitter.com/@theDakotaBear", name: "Dakota Bear" },
    { url: "https://twitter.com/@carlosdquintana", name: "Carlos Quintana" },
    { url: "https://twitter.com/@CryptoTaxGuyETH", name: "Crypto Tax Guy" },
    { url: "https://twitter.com/@DeFiTaxCPA", name: "DeFi Tax CPAs" },
    { url: "https://twitter.com/@CryptoTaxCat", name: "Lambo McGee" },
    { url: "https://twitter.com/@jbreckCPA", name: "J Breck" },
    { url: "https://twitter.com/@donovanfinance", name: "Donovan Miller" },
    { url: "https://twitter.com/@erocknghiem", name: "Eric Nghiem" },
    { url: "https://twitter.com/@CarterFCS", name: "MC" },
    { url: "https://twitter.com/@TH3Collectour", name: "Collectour" },
    { url: "https://twitter.com/@TorchiaMatthew", name: "Matthew Torchia" },
    { url: "https://twitter.com/@MagisterTax", name: "Magister Tax" },
    { url: "https://twitter.com/@paulydcpa", name: "Pauly D" },
    { url: "https://twitter.com/@JamisonSites", name: "Jamison Sites" },
    { url: "https://twitter.com/@nyoungdumb", name: "Nick Young" },
    { url: "https://twitter.com/@MZeiterCPA", name: "M Zeiter" },
    { url: "https://twitter.com/@0xSeanCPA", name: "Sean.CPA" },
  ],
  []
);

export const TaxProfessionals = () => {
  const { header, text } = useTheme();
  return (
    <Box style={{ marginTop: 20 }}>
      <HStack>
        <VStack flex={1} style={{ alignItems: "flex-start" }}>
          <Heading
            color={header}
            flex={1}
            size="lg"
            margin="0"
            paddingTop="0"
            fontWeight="bold"
            style={{
              fontStretch: "extra-expanded",
            }}
          >
            Our favorite tax professionals
          </Heading>

          <Text
            style={{ marginTop: 15, color: text, maxWidth: 600 }}
            fontSize="md"
          >
            If you need tax advice or want help categorizing or reconciling your
            transactions, the below people are fantastic.
          </Text>
        </VStack>

        <a href="https://help.awaken.tax" target="_blank">
          <Button variant="primary">
            Help Center{" "}
            <i
              style={{ marginLeft: 5 }}
              className="fa-solid fa-circle-info"
            ></i>
          </Button>
        </a>
      </HStack>

      <Box
        w="100%"
        maxWidth="75rem"
        display="grid"
        gridTemplateColumns={{
          base: "1fr",
          md: "repeat(2, 1fr)",
          xl: "repeat(3, 1fr)",
        }}
        gap={6}
      >
        {TaxProfessionalsInfo.map((info) => (
          <TaxProfessional key={info.name} info={info} />
        ))}
      </Box>
    </Box>
  );
};

type TaxProfessionalProps = {
  info: {
    url: string;
    websiteUrl?: string;
    name: string;
    img?: string;
    description?: string;
    isRecommended?: boolean;
  };
};

const TaxProfessional = ({ info }: TaxProfessionalProps) => {
  const { text, header, yellowBg, secondaryBackground } = useTheme();
  return (
    <WhiteBox width="100%" position="relative">
      {info.isRecommended && (
        <Badge
          position="absolute"
          top={"15px"}
          right={"15px"}
          style={{
            // gold
            backgroundColor: yellowBg,
            color: colors.yellow40,
          }}
          variant="solid"
          borderRadius="full"
          padding="5px 10px"
        >
          Recommended <i className="fa-solid fa-star"></i>
        </Badge>
      )}

      {info.img && (
        <Image
          src={info.img}
          w="4rem"
          borderRadius={15}
          h="4rem"
          objectFit="contain"
          marginRight="0.5rem"
          marginTop="0.4rem"
        ></Image>
      )}

      <Text fontWeight="semibold" color={header} margin="0.75rem 0 0.4rem 0">
        {info.name}
      </Text>
      <Text
        height={100}
        overflow="hidden"
        fontSize="sm"
        color={text}
        margin="0"
      >
        {info.description}
      </Text>

      <HStack style={{ marginTop: 15 }}>
        <Link
          style={{
            textDecoration: "none",
          }}
          href={info.url}
          isExternal
        >
          <a href={info.url} target="_blank">
            <Touchable
              style={{
                textDecoration: "none",
                background: colors.gray10,
              }}
              labelStyle={{
                color: "#fff",
              }}
              iconStyle={{
                color: "#fff",
              }}
              label="X.com"
              iconName="fa-brands fa-x-twitter"
            />
          </a>
        </Link>

        {info.websiteUrl && (
          <Link
            style={{
              textDecoration: "none",
            }}
            href={info.websiteUrl}
            isExternal
          >
            <a href={info.websiteUrl} target="_blank">
              <Touchable
                style={{
                  textDecoration: "none",
                  background: secondaryBackground,
                }}
                labelStyle={{
                  color: header,
                }}
                iconStyle={{
                  color: header,
                }}
                label="Website"
                iconName="fa-solid fa-globe"
              />
            </a>
          </Link>
        )}
      </HStack>
    </WhiteBox>
  );
};
