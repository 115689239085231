import { Text } from "@chakra-ui/react";
import { compose } from "lodash/fp";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { show } from "redux-modal";
import { useTheme } from "src/hooks/useTheme";
import { colors } from "src/theme";
import WhiteBox from "../styled/WhiteBox";

type AddAccountProps = {
  location: string;
  numAccounts: number;
};

export function AddAccount({ location, numAccounts }: AddAccountProps) {
  const { clientId } = useParams<{ clientId: string }>();

  const dispatch = useDispatch();
  const _showModal = compose(dispatch, show);
  const { theme, background, header, border } = useTheme();

  const _onAddWallet = () => {
    _showModal("AccountModal", {
      location,
    });
  };

  const noAccounts = numAccounts === 0;

  return (
    <WhiteBox
      display="flex"
      w="100%"
      h="70px"
      marginTop="0"
      alignItems="center"
      justifyContent="center"
      cursor="pointer"
      onClick={_onAddWallet}
      minW="inherit"
      position="relative"
      width={300}
      textAlign="center"
      padding={{ base: "1.5rem 0.5rem", lg: "2rem 1rem" }}
      border="none"
      bg={colors.primary}
      borderRadius="lg"
      transition="all 0.2s ease-in-out"
      _hover={{
        transform: "scale(1.05)",
        filter: "brightness(1.1)",
        boxShadow: "lg",
      }}
    >
      <Text
        color="white"
        fontSize="lg"
        fontWeight="bold"
        display="flex"
        alignItems="center"
      >
        <Text as="span" fontSize="2xl" mr={2}>
          +
        </Text>
        Add New Account
      </Text>
    </WhiteBox>
  );
}
